import "../styles/style.css";

import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Logout from "../components/posts/PostNavigation/Logout";
import { IMAGES, STRINGS } from "../constants/constants";
import { updateAdditionalUserInfo, updateUser } from "../ducks/actions";
import { auth, firebase, functions } from "../firebase/firebaseConfig";
import {
  addEditProfileLink, GAtrackPageDetails, getProperty
} from "../helper/helper";
import FeedFooter from "./footer/FeedFooter";
import Header from "./header/Header";
import Home from "./Home";
import InviteHeader from "./InviteSection";
import Error from "./layouts/Error";
import Notification from "./layouts/Notifications";
import Loader from "./layouts/TextLoader";

const data = require("../data/data.json");
const apiName = require("../data/apiList.json");
const getUserDataES = functions.httpsCallable(apiName.getUserDataES);
const getUserDataWithoutAuth = functions.httpsCallable(
  apiName.getUserDataWithoutAuth
);
const getAnonymousUserData = functions.httpsCallable(
  apiName.getAnonymousUserData
);

class PathMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postData: this.props.$stateParams,
      userFeedPosts: [],
      isLoadingData: true,
      disableLoadMore: false,
      isError: false,
      nextPageToken: null,
      responseData: null,
      postId: null,
      showLogin: false,
      isLoggedIn: firebase.auth().currentUser,
      totalPost: null,
      phoneNumberExists: false,
      errorMessage: "",
      postOwner: null,
      isFirstLoading: true,
    };
    this.unsubscribe = function () {
      return undefined;
    };
  }

  removePathsListeners = () => {
    window.removeEventListener("scroll", this.onScrollCallFunc, false);
    this.unsubscribe();
  };

  componentWillUnmount = () => {
    this.removePathsListeners();
  };

  setUserFeedPosts = (postId) => {
    let userFeeds = this.state.userFeedPosts;

    if (!isEmpty(userFeeds)) {
      let findPost = userFeeds.findIndex((posts) => posts.id === postId);
      if (findPost !== -1) {
        userFeeds[findPost].userCollabTabStatus = "PENDING";
        this.setState({ userFeedPosts: userFeeds });
      }
    }
  };

  /* Gets the post with auth and without auth */
  getPostData = (isFirstLoad) => {
    const { userInfo, transition } = this.props;
    const { nextPageToken, totalPost } = this.state;
    const userId = transition.params().userId;
    this.setState({
      isFirstLoading: isFirstLoad,
    });
    if (!isEmpty(userId)) {
      const getPostsByUserId = userInfo.user
        ? this.isNewCreatedUser(userInfo.user)
          ? functions.httpsCallable(apiName.getPostsByUserIdWithoutAuthES)
          : functions.httpsCallable(apiName.getPostsByUserIdES)
        : functions.httpsCallable(apiName.getPostsByUserIdWithoutAuthES);

      GAtrackPageDetails();
      this.setState({ isLoadingData: true });
      /* It gets all the related post oF that particular userID */
      getPostsByUserId({
        userId,
        nextPageToken: isFirstLoad
          ? null
          : totalPost == null
          ? null
          : nextPageToken
          ? nextPageToken
          : null,
        pageSize: isFirstLoad ? 3 : 5,
        apiKey: data.apiKey,
        source: data.apiSource,
      })
        .then((response) => {
          const resPosts = response.data.posts;
          if (resPosts.length !== 0) {
            if (response.data.nextPageToken == null) {
              this.setState({ disableLoadMore: true });
            }
            let posts = [];
            if (isFirstLoad) {
              posts = resPosts;
            } else if (totalPost == null) {
              posts = resPosts;
            } else if (this.state.nextPageToken) {
              posts = [...this.state.userFeedPosts, ...resPosts];
            }
            this.setState({
              isError: false,
              errorMessage: "",
              userFeedPosts: posts,
              isLoadingData: false,
              nextPageToken: response.data.nextPageToken,
              totalPost: response.data.total ? response.data.total : null,
            });
          } else {
            this.setState({
              isError: false,
              errorMessage: "",
              isLoadingData: false,
              disableLoadMore: true,
            });
          }
          let lastPostCreationTime = new Date(resPosts[resPosts.length-1].auditInfo.createdTime).getTime();
          let currentTime = new Date().getTime();
          if(lastPostCreationTime > currentTime) {
            this.getPostData(false);
          }
        })
        .catch((error) => {
          const { code, message } = error;
          const errorMessage = getProperty(`labelError.${code}`) || message;
          this.setState({
            errorMessage: errorMessage,
            isError: true,
            isLoadingData: false,
          });
        });
    } else {
      if(navigator.userAgent.includes(STRINGS.INSTAGRAM)) {
        window.location.reload();
      } else {
        this.setState({ isError: true, isLoadingData: false });
      }      
    }
  };

  /* Called when user reaches at end of the loaded posts and shows loader */
  onScrollCallFunc = () => {
    const { isLoadingData, disableLoadMore, isError, userFeedPosts } = this.state;
    const lastPost = userFeedPosts[userFeedPosts.length-1];
    if(lastPost && document.getElementById("paths-"+lastPost.id)){
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight -
          document.getElementById("paths-"+lastPost.id).offsetHeight
      ) {
        if (
          !isLoadingData &&
          !disableLoadMore &&
          !isError &&
          this.state.nextPageToken != null
        ) {
          this.setState(
            {
              postId: null,
            },
            () => {
              this.getPostData(false);
            }
          );
        }
      }
    }
   
  };

  isNewCreatedUser = (user) => {
    if (user && user.metadata && user.metadata.creationTime) {
      const creationTime = new Date(user.metadata.creationTime).getTime();
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - creationTime;
      if (timeDifference / 60000 > 5) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  componentDidMount = () => {
    let self = this;
    if (!this.props.userInfo.user) {
      this.removePathsListeners();
    }
    this.unsubscribe = auth.onAuthStateChanged(function (user) {
      window.scrollTo(0, 0);
      if (user) {
        const isNewUser = self.isNewCreatedUser(user);
        addEditProfileLink(self.props.transition);
        self.props.updateUser({ user });
        if (isNewUser) {
          if (isEmpty(self.props.additionalUserInfo)) {
            setTimeout(() => {
              getUserDataES({ apiKey: data.apiKey, source: data.apiSource })
                .then((userData) => {
                  self.props.updateAdditionalUserInfo(userData.data);
                  if (isEmpty(self.state.postOwner)) {
                    self.getOwnerDetails();
                  }
                })
                .catch();
            }, 7000);
          }
        } else {
          if (isEmpty(self.props.additionalUserInfo)) {
            getUserDataES({ apiKey: data.apiKey, source: data.apiSource })
              .then((userData) => {
                self.props.updateAdditionalUserInfo(userData.data);
                if (isEmpty(self.state.postOwner)) {
                  self.getOwnerDetails();
                }
              })
              .catch();
          }
        }
      } else {
        self.props.updateUser({});
        self.props.updateAdditionalUserInfo({});
      }
      const { postData } = self.state;
      if (!isEmpty(postData.posts)) {
        self.setState({
          userFeedPosts: postData.posts,
          isLoadingData: false,
          nextPageToken: postData.nextPageToken,
          postId: postData.postId,
        });
      } else {
        self.getPostData(true);
      }
      if (user) {
        window.addEventListener("scroll", self.onScrollCallFunc, false);
      } else {
        window.addEventListener("scroll", self.onScrollCallFunc, false);
      }
      if (isEmpty(self.state.postOwner)) {
        self.getOwnerDetails();
      }
    });
  };

  /* To scroll in loaded Posts */
  scrollToPostView = () => {
    const { userFeedPosts, postId } = this.state;
    if (userFeedPosts.length > 0) {
      if (postId) {
        setTimeout(() => {
          const element = window.document.querySelector("#paths-" + postId);
          if (element) element.scrollIntoView();
        }, 100);
      }
    }
  };

  showLoginModal = (event) => {
    this.setState({ showLogin: true });
  };

  updateShowLogin = (msgType, isMessage, message) => {
    this.setState({ showLogin: !this.state.showLogin });
  };

  //Updating falg to show phone number warning
  showPhoneNumberExists = () => {
    this.setState({ phoneNumberExists: true });
  };

  //Closing phone number warning
  closePhoneNumberWarning = () => {
    this.setState({ phoneNumberExists: false });
  };

  //Get post owner details
  getOwnerDetails = () => {
    const { transition, userInfo } = this.props;
    const userId = transition.params().userId;
    const { postData } = this.state;

    if (userId) {
      if (!isEmpty(userInfo.user) && userInfo.user.uid === userId) {
        const { additionalUserInfo } = this.props;
        const { user } = additionalUserInfo;
        if (user) {
          const postOwner = {
            name: `${user.firstName} ${user.lastName}`,
            photoURL: user.profilePhotoUrls.original,
            pathUserName: user.userName,
            userId: userId,
          };
          this.setState({ postOwner: postOwner });
        }
      } else {
        if(postData && postData.postOwner) {
          const postOwner = {
            name: postData.postOwner.name,
            photoURL: postData.postOwner.photoURL,
            pathUserName: postData.postOwner.pathUserName,
            userId: postData.postOwner.userId,
          };
          this.setState({ postOwner: postOwner });
        } else {
          getUserDataWithoutAuth({
            userId,
            apiKey: data.apiKey,
            source: data.apiSource,
          })
            .then((response) => {
              const postOwner = {
                name: response.data.userName,
                photoURL: response.data.profilePhotoUrl,
                pathUserName: response.data.pathUserName,
                userId: userId,
              };
              this.setState({ postOwner: postOwner });
            })
            .catch((error) => {
              getAnonymousUserData({
                anonymousUserId: userId,
                apiKey: data.apiKey,
                source: data.apiSource,
              }).then((response) => {
                const postOwner = {
                  name: response.data.displayName,
                  photoURL: response.data.profilePhotoUrls.original,
                  pathUserName: response.data.userName,
                  userId: userId,
                };
                this.setState({ postOwner: postOwner });
              }).catch((error) => {});
            });
        }
      }
    }
  };

  showUpdatedPost = () => {
    this.getPostData(true);
  }

  render() {
    const {
      isError,
      isLoadingData,
      userFeedPosts,
      responseData,
      nextPageToken,
      showLogin,
      errorMessage,
      postData,
      isFirstLoading,
    } = this.state;
    const { phoneNumberExists, postOwner, totalPost } = this.state;
    const { userInfo, transition } = this.props;
    // {
      !showLogin && this.scrollToPostView();
    // }
    if (userFeedPosts && !isEmpty(userFeedPosts)) {
      if (window.addthis) {
        window.addthis.init();
        window.addthis.update("share", "url", window.location.href);
      }
    }
    if (isError && !isLoadingData) {
      return (
        <Fragment>
          <Header userInfo={userInfo} />
          <img alt="master shadow img" src={IMAGES.MASTER_SHADOW_IMAGE.default} className="feed-header-section-dividor"/>
          <InviteHeader
            isMainFeed={true}
            userInfo={userInfo}
            btnText={getProperty("label.join-now")}
            msgText={getProperty("label.feeds-header")}
            showPhoneNumberExists={this.showPhoneNumberExists}
            postOwner={postOwner}
          />

          {errorMessage ? (
            <Error message={errorMessage} postOwner={postOwner} />
          ) : (
            <Error
              message={getProperty("label.refresh-again")}
              showRefresh={true}
              postOwner={postOwner}
            />
          )}
          <Logout
            isLoadingData={isLoadingData}
            userInfo={userInfo}
            transition={transition}
            isPathMain={true}
          ></Logout>
          {phoneNumberExists && (
            <Notification
              type="warning"
              message={getProperty("label.phone-number-exists")}
              closeModal={this.closePhoneNumberWarning}
            />
          )}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Header userInfo={userInfo} />
          <img alt="master shadow img" src={IMAGES.MASTER_SHADOW_IMAGE.default} className="feed-header-section-dividor"/>
          {(!isLoadingData || !isEmpty(postOwner)) && (
            <InviteHeader
              isMainFeed={true}
              userInfo={userInfo}
              btnText={getProperty("label.join-now")}
              msgText={getProperty("label.feeds-header")}
              showPhoneNumberExists={this.showPhoneNumberExists}
              postOwner={postOwner}
            />
          )}
          {!isLoadingData && userFeedPosts && userFeedPosts.length === 0 && (
            <Fragment>
              <Error message={getProperty("label.no-post")} postOwner={postOwner}/>
            </Fragment>
          )}
          {phoneNumberExists && (
            <Notification
              type="warning"
              message={getProperty("label.phone-number-exists")}
              closeModal={this.closePhoneNumberWarning}
            />
          )}
          <div className="feed-page-desktop-margin">
            <Home
              isPathMain={true}
              userFeedPosts={userFeedPosts}
              transition={transition}
              nextPageToken={
                responseData ? responseData.nextPageToken : nextPageToken
              }
              userInfo={userInfo}
              getPosts={this.setUserFeedPosts}
              postData={postData}
              totalPost={totalPost}
              postOwner={postOwner}
              isLoadingData={isLoadingData}
              isFirstLoading={isFirstLoading}
              showUpdatedPost={this.showUpdatedPost}
            />
          </div>
          {isLoadingData && !isFirstLoading && (
            <Loader isRequiredMinHeight={true} />
          )}
          <img alt="master shadow img" src={IMAGES.MASTER_SHADOW_IMAGE.default} className="feed-footer-shadow" />
          <FeedFooter userInfo={userInfo} transition={transition} postOwner={postOwner}/>

          <Logout
            isLoadingData={isLoadingData}
            userInfo={userInfo}
            transition={transition}
            isPathMain={true}
          ></Logout>
        </Fragment>
      );
    }
  }
}

PathMain.proptype = {
  $stateParams: PropTypes.object,
  router: PropTypes.object,
  transition: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
  updateAdditionalUserInfo: (user) => dispatch(updateAdditionalUserInfo(user)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PathMain);
