import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { CloseButton, Modal, ModalBody } from 'react-bootstrap';
import { connect } from 'react-redux';

import { functions } from '../../firebase/firebaseConfig';
import { getPostTitle, getProperty } from '../../helper/helper';
import bloggerShare from '../../images/icons/blogger_share.png';
import siteLinkImage from '../../images/icons/site_link.png';
import Loader from './TextLoader';

const data = require('../../data/data.json');
const apiList = require('../../data/apiList.json');
const getPostMetadata = functions.httpsCallable(apiList.getPostMetadata);
const getPostMetadataWithoutAuth = functions.httpsCallable(apiList.getPostMetadataWithoutAuth);

class PostBloggerMetaData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBloggerLinks: false,
      showError: false,
      showLoader: false,
      metaLinks: {},
    };
  }

  componentDidMount() {}

  handleClick = () => {
    const { isClickable, goToPostDetails, parentPost } = this.props;
    isClickable && goToPostDetails(parentPost.id);
  };

  showMetadataPopup = () => {
    this.getBloggerLink();
    this.setState({ showBloggerLinks: true, showLoader: true });
  };

  hideMetadataPopup = () => {
    this.setState({ showBloggerLinks: false, showLoader: false, metaLinks: null, showError: false });
  };

  getBloggerLink = () => {
    const { post } = this.props;
    const { userInfo } = this.props;
    const getMetaData = userInfo.user ? getPostMetadata : getPostMetadataWithoutAuth;
    getMetaData({ apiKey: data.apiKey, source: data.apiSource, postId: post.id })
      .then((response) => {
        this.setState({ metaLinks: response.data, showLoader: false, showError: false });
      })
      .catch((error) => {
        this.setState({ showLoader: false, showError: true });
      });
  };

  openMetaLink = (url) => {
    window.open(url, '_blank');
  };

  renderImageLink = () => {
    const { metaLinks } = this.state;
    const { imageURLS } = metaLinks;
    const links = imageURLS.map((url, index) => (
      <div key={index} className='link-item' onClick={() => this.openMetaLink(url)}>
        <img alt="site link img" src={siteLinkImage} />
        <span className='metadata-url'>{url}</span>
        <span className='metadata-external-link'>
          <i className='fa fa-external-link'></i>
        </span>
      </div>
    ));
    return links;
  };

  renderSimpleLink = () => {
    const { metaLinks } = this.state;
    const { hrefURLS } = metaLinks;
    const links = hrefURLS.map((url, index) => (
      <div
        key={index}
        className="link-item"
        onClick={() => this.openMetaLink(url)}
      >
        <img alt="site link img" src={siteLinkImage} />
        <span className="metadata-url">{url}</span>
        <span className="metadata-external-link">
          <i className="fa fa-external-link"></i>
        </span>
      </div>
    ));
    return links;
  };

  render() {
    const { isClickable, post } = this.props;
    const { showBloggerLinks, showError, showLoader, metaLinks } = this.state;

    return (
      <Fragment>
        {!isClickable && (
          <div className="metadata-link-container">
            <span className="metadata-link-title">
              {getPostTitle(post.title)}
            </span>
            <img
              alt="blogger share img"
              src={bloggerShare}
              className="metadata-link-image"
              onClick={() => this.showMetadataPopup()}
            ></img>
          </div>
        )}
        {showBloggerLinks && (
          <Modal
            show={showBloggerLinks}
            dialogClassName="modal-lg custom-dialog blogger-dialog"
            onHide={this.hideMetadataPopup}
            size="lg"
          >
            <ModalBody className="metalink-dialog-container">
              <CloseButton onClick={this.hideMetadataPopup} />
              <div className="post-header-main">
                <div className="post-header-text">
                  <p className="post-title">{getPostTitle(post.title)}</p>
                  <span className="post-user-name">
                    {getProperty("blogger-post.title")}
                  </span>
                </div>
              </div>

              {showLoader && <Loader />}

              {!showLoader && !isEmpty(metaLinks) && (
                <div className="row link-item-wrapper">
                  {!isEmpty(metaLinks.imageURLS) && (
                    <div className="col-12 link-item-container">
                      <p className="title">
                        {getProperty("blogger-post.image-links")}
                      </p>
                      {this.renderImageLink()}
                    </div>
                  )}

                  {!isEmpty(metaLinks.hrefURLS) && (
                    <div className="col-12 link-item-container">
                      <p className="title">
                        {getProperty("blogger-post.simple-links")}
                      </p>
                      {this.renderSimpleLink()}
                    </div>
                  )}
                </div>
              )}
              {!showLoader &&
                !isEmpty(metaLinks) &&
                isEmpty(metaLinks.hrefURLS) &&
                isEmpty(metaLinks.imageURLS) && (
                  <div className="no-item-container">
                    <p className="title">{getProperty("label.no-content")}</p>
                  </div>
                )}
              {showError && (
                <div className="no-item-container">
                  <p className="title">
                    {getProperty("labelError.something-wrong")}
                  </p>
                </div>
              )}
            </ModalBody>
          </Modal>
        )}
      </Fragment>
    );
  }
}

PostBloggerMetaData.proptype = {
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostBloggerMetaData);
