import { createStore } from 'redux';

import rootReducer from '../ducks/reducers/index';

const intialState = {};

const store = createStore(
  rootReducer,
  intialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
