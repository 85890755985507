import React, { useState, memo } from "react";
import OrderCollabTag from "./OrderCollabTag";
import PropTypes from "prop-types";

const MakeCollabTagOrder = (props) => {
  const [postData, setPostdata] = useState(props.$stateParams);
  const [showOrder, setShowOrder] = useState(false);

  const updateShowCollabTag = () => {
    setShowOrder(!showOrder);
  }  

  return(
    <OrderCollabTag
        updateShowCollabTag={updateShowCollabTag}
        isPopup={false}
        transition={props.transition}
        postData={postData}
      />
  );

}

MakeCollabTagOrder.proptype = {
  $stateParams: PropTypes.object,
  user: PropTypes.object,
};

export default memo(MakeCollabTagOrder);