export const validateForm = (comment, file) => {
  if ((comment != null && comment !== '') || file != null) {
    return true;
  }
  return false;
};
export const validateImage = (file) => {
  var allowedExtension = ['jpeg', 'jpg', 'png', 'ico', 'bmp', 'svg', 'gif', 'apng'];
  var fileExtension = getExtension(file);

  var isValid = false;
  if (fileExtension != null) {
    if (allowedExtension.includes(fileExtension)) {
      isValid = true;
    }
  }
  return isValid;
};
export const validateVideo = (file) => {
  var allowedExtension = ['mp4','mov','wmv','mkv','webm'];
  var fileExtension = getExtension(file);
  var isValid = false;
  if (fileExtension != null) {
    if (allowedExtension.includes(fileExtension)) {
      isValid = true;
    }
  }
  return isValid;
}

const getExtension = (file) => {
  if (file.type !== '' || file.type !== null) {
    var parts = file.type.split('/');
    return parts[parts.length - 1];
  }
  return null;
};
