import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { addCowner } from '../../helper/helper';
import ConfirmModal from '../layouts/ConfirmDialog';
import LoginModal from '../loginpage/LoginModal';
import MainHeader from './MainHeader';
import PostHeader from './PostHeader';

// const data = require('../../data/data.json');
class InviteHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      isCollabUser: false,
      showLoader: false,
      isMessage: false,
      displayMessage: '',
      msgType: '',
    };
  }

  updateShowLogin = (msgType, isMessage, message) => {
    const { post } = this.props;
    if (msgType && isMessage && message) {
      this.props.getPosts(post.id);
      this.setState({
        msgType: msgType,
        isMessage: isMessage,
        message: message,
        showLogin: !this.state.showLogin,
        isCollabUser: false,
      });
    } else {
      this.setState({ showLogin: !this.state.showLogin, isCollabUser: false });
    }
  };

  collabUser = () => {
    this.setState({ isCollabUser: true, showLogin: false });
  };

  closeModal = () => {
    this.setState({ isCollabUser: false, showLogin: false, showLoader: false });
  };

  collabUserWithPost = async () => {
    this.setState({ showLoader: true });
    const { post, userInfo, invitationId, additionalUserInfo } = this.props;

    if (post) {
      try {
        const isInvite = true;
        let addCoownerData = await addCowner(post, userInfo.user, additionalUserInfo, isInvite, invitationId);
        if (addCoownerData) {
          this.setState({
            msgType: addCoownerData.type,
            isMessage: addCoownerData.isMessage,
            displayMessage: addCoownerData.displayMessage,
          });
          this.props.getPosts(post.id);
        }
        this.closeModal();
      } catch (error) {
        this.closeModal();
      }
    }
  };

  closeAlert = () => {
    this.setState({
      isMessage: false,
      displayMessage: '',
      msgType: '',
    });
  };

  getUserName = () => {
    const { additionalUserInfo, userInfo } = this.props;
    if (additionalUserInfo.user) {
      const { firstName, lastName } = additionalUserInfo.user;
      let displayName = '';
      if (firstName && lastName) {
        displayName = `${firstName} ${lastName}`;
      } else {
        displayName = userInfo.user ? userInfo.user.email : '';
      }

      return displayName;
    }
  };

  render() {
    const { btnText, msgText, imageUrl, owner, post, isMainFeed, userInfo, invitationId, postOwner, transition, postData } = this.props;
    const { isInvitationAccepted } = this.props;
    let approved = post && post.coOwners && post.coOwners.approved ? post.coOwners.approved : [];
    let userId = userInfo && userInfo.user && userInfo.user.uid;
    let userName = this.getUserName();
    let isPostOwner = post && post.owner ? post.owner.userId : '';
    let isCoOwner = approved && approved.findIndex((coowner) => coowner.userId === userId);
    let isPending = post && post.userCollabTabStatus && post.userCollabTabStatus === 'PENDING' ? true : false;
    return (
      <Fragment>
        {isMainFeed ? (
          <MainHeader
            userCollabSetup={
              isMainFeed ? this.updateShowLogin : this.props.userInfo.user ? this.collabUser : this.updateShowLogin
            }
            btnText={btnText}
            msgText={msgText}
            post={post}
            closeModal={this.closeAlert}
            type={this.state.msgType}
            message={this.state.displayMessage}
            isMessage={this.state.isMessage}
            userInfo={userInfo}
            owner={postOwner}
          />
        ) : (
          <PostHeader
            userCollabSetup={userInfo.user ? this.collabUser : this.updateShowLogin}
            btnText={btnText}
            msgText={msgText}
            owner={owner}
            isDisable={isCoOwner === '-1' && isPostOwner !== userId && !isPending ? false : true}
            imageUrl={imageUrl}
            closeModal={this.closeAlert}
            type={this.state.msgType}
            message={this.state.displayMessage}
            isMessage={this.state.isMessage}
            title={userName ? `${userName}` : ''}
            isPostAvailable={!isEmpty(post) && !isInvitationAccepted}
            post={post}
            transition={transition}
            userInfo={userInfo}
            postData={postData}
            postOwner={postOwner}
          />
        )}

        {this.state.showLogin && (
          <LoginModal
            owner={owner}
            show={this.state.showLogin}
            updateShowLogin={this.updateShowLogin}
            post={post}
            isInvite={true}
            invitationId={invitationId}
            showPhoneNumberExists={this.props.showPhoneNumberExists}
          />
        )}

        <ConfirmModal
          isCollabUser={this.state.isCollabUser}
          closeModal={this.closeModal}
          collabUserWithPost={this.collabUserWithPost}
          showLoader={this.state.showLoader}
          isInvite={true}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, {})(InviteHeader);
