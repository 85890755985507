import React, { Component, Fragment } from "react";
import { IMAGES, NAVIGATION } from "../../constants/constants";
import { getProperty } from "../../helper/helper";
import PayPal from "./PayPal";
import QRCodeWithPost from "../posts/QRCode/QRCodeWithPost";
import domtoimage from "dom-to-image";
import firebase from "firebase/app";
import { functions } from "../../firebase/firebaseConfig";
import "firebase/storage";
import { auth } from "../../firebase/firebaseConfig";

const apiName = require("../../data/apiList.json");
const apiData = require("../../data/data.json");

const sendEmailForOrderCollabTag = functions.httpsCallable(
  apiName.sendEmailForOrderCollabTag
);

class OrderCollabTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChooseQuantity: true,
      isReviewCollabTag: false,
      isReviewOrder: false,
      isOrderDone: false,
      isRadio1: true,
      isRadio2: false,
      isRadio3: false,
      isRadio4: false,
      isRadio5: false,
      isRadio6: false,
      isCheckout: false,
      selectedRadio: "Radio1",
      orderDetails: {},
      emailAddress: "",
      loginUserEmail: "",
      shippingAddress: "",
      isReviewButton: false,
      paymentFailureMessage: "",
      errorMessage: "",
      isErrorMessage: false,
      isPaymentFailed: false,
      collabBase64Data: "",
    };
  }

  componentDidMount = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          loginUserEmail: user.email,
        });
      }
    });
  };

  makeOrder = () => {
    const { postData } = this.props;
    const storage = firebase.storage();
    if (this.state.isChooseQuantity) {
      this.setState({
        isChooseQuantity: false,
        isReviewCollabTag: true,
      });
    } else if (this.state.isReviewOrder) {
      if (this.state.loginUserEmail !== "") {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();
        const currentTime =
          (today.getHours() < 10 ? "0" : "") +
          today.getHours() +
          ":" +
          (today.getMinutes() < 10 ? "0" : "") +
          today.getMinutes();
        today = mm + "/" + dd + "/" + yyyy;
        let quantity;
        if (this.state.selectedRadio === "Radio1") {
          quantity = 50;
        } else if (this.state.selectedRadio === "Radio2") {
          quantity = 500;
        } else if (this.state.selectedRadio === "Radio3") {
          quantity = 5000;
        } else if (this.state.selectedRadio === "Radio4") {
          quantity = 100;
        } else if (this.state.selectedRadio === "Radio5") {
          quantity = 1000;
        } else if (this.state.selectedRadio === "Radio6") {
          quantity = 10000;
        }
        storage
          .ref(postData.userId + "/posts/" + postData.postId + "/collabtag/")
          .child("Collab Tag Composite")
          .getDownloadURL()
          .then((url) => {
            const data = {
              apiKey: apiData.apiKey,
              email: this.state.loginUserEmail,
              orderDetails: {
                date: today,
                time: currentTime,
                quantity: quantity,
                memoryURL: postData.postUrl,
                shippingAddress: this.state.shippingAddress,
                attachments: [
                  {
                    filename: "collab-tag.png",
                    URL: url,
                  },
                ],
              },
              user: {
                displayName: postData.post.auditInfo.createdBy,
                phoneNumber: null,
              },
            };
            sendEmailForOrderCollabTag(data)
              .then((response) => {})
              .catch();
          });
        this.setState({
          isReviewOrder: false,
          isOrderDone: true,
        });
      } else {
        this.setState({
          errorMessage: getProperty("label.no-login-user"),
          isErrorMessage: true,
        });
      }
    }
  };

  editOrder = () => {
    this.setState({
      isChooseQuantity: true,
      isReviewOrder: false,
    });
  };

  manageQuantity = (quantity) => {
    if (quantity === "radio-1") {
      this.setState({
        isRadio1: true,
        isRadio2: false,
        isRadio3: false,
        isRadio4: false,
        isRadio5: false,
        isRadio6: false,
        isReviewButton: false,
        selectedRadio: "Radio1",
        isCheckout: false,
        paymentFailureMessage: "",
        isPaymentFailed: false,
      });
    } else if (quantity === "radio-2") {
      this.setState({
        isRadio1: false,
        isRadio2: true,
        isRadio3: false,
        isRadio4: false,
        isRadio5: false,
        isRadio6: false,
        isReviewButton: false,
        selectedRadio: "Radio2",
        isCheckout: false,
        paymentFailureMessage: "",
        isPaymentFailed: false,
      });
    } else if (quantity === "radio-3") {
      this.setState({
        isRadio1: false,
        isRadio2: false,
        isRadio3: true,
        isRadio4: false,
        isRadio5: false,
        isRadio6: false,
        isReviewButton: false,
        selectedRadio: "Radio3",
        isCheckout: false,
        paymentFailureMessage: "",
        isPaymentFailed: false,
      });
    } else if (quantity === "radio-4") {
      this.setState({
        isRadio1: false,
        isRadio2: false,
        isRadio3: false,
        isRadio4: true,
        isRadio5: false,
        isRadio6: false,
        isReviewButton: false,
        selectedRadio: "Radio4",
        isCheckout: false,
        paymentFailureMessage: "",
        isPaymentFailed: false,
      });
    } else if (quantity === "radio-5") {
      this.setState({
        isRadio1: false,
        isRadio2: false,
        isRadio3: false,
        isRadio4: false,
        isRadio5: true,
        isRadio6: false,
        isReviewButton: false,
        selectedRadio: "Radio5",
        isCheckout: false,
        paymentFailureMessage: "",
        isPaymentFailed: false,
      });
    } else if (quantity === "radio-6") {
      this.setState({
        isRadio1: false,
        isRadio2: false,
        isRadio3: false,
        isRadio4: false,
        isRadio5: false,
        isRadio6: true,
        isReviewButton: false,
        selectedRadio: "Radio6",
        isCheckout: false,
        paymentFailureMessage: "",
        isPaymentFailed: false,
      });
    }
  };

  handleCheckout = () => {
    this.setState({
      isCheckout: true,
    });
  };

  handleFailureCallback = (errorData) => {
    this.setState({
      isPaymentFailed: true,
      paymentFailureMessage:
        getProperty("label.payment-failure-message") + errorData,
    });
  };

  handleCallback = (childData) => {
    let given_name = childData.payer.name.given_name
      ? childData.payer.name.given_name + " "
      : "";
    let surname = childData.payer.name.surname
      ? childData.payer.name.surname + " "
      : "";
    let address_line_1 = childData.purchase_units[0].shipping.address
      .address_line_1
      ? childData.purchase_units[0].shipping.address.address_line_1 + " "
      : "";
    let address_line_2 = childData.purchase_units[0].shipping.address
      .address_line_2
      ? childData.purchase_units[0].shipping.address.address_line_2 + " "
      : "";
    let admin_area_2 = childData.purchase_units[0].shipping.address.admin_area_2
      ? childData.purchase_units[0].shipping.address.admin_area_2 + " "
      : "";
    let admin_area_1 = childData.purchase_units[0].shipping.address.admin_area_1
      ? childData.purchase_units[0].shipping.address.admin_area_1 + " "
      : "";
    let postal_code = childData.purchase_units[0].shipping.address.postal_code
      ? childData.purchase_units[0].shipping.address.postal_code + " "
      : "";
    let address =
      given_name +
      surname +
      address_line_1 +
      address_line_2 +
      admin_area_2 +
      admin_area_1 +
      postal_code;
    this.setState({
      orderDetails: childData,
      emailAddress: childData.purchase_units[0].payee.email_address,
      shippingAddress: address,
      isReviewButton: true,
      isReviewCollabTag: false,
      isReviewOrder: true,
    });
  };

  storeCollabTagInFirebase = () => {
    const { postData } = this.props;
    const canvas = document.getElementById("collab-tag");
    let dataURL;
    domtoimage
      .toPng(canvas, { bgcolor: "white" })
      .then(function (dataUrl) {
        const storage = firebase.storage();
        const uploadTask = storage
          .ref(postData.userId + "/posts/" + postData.postId + "/collabtag/")
          .child("Collab Tag Composite")
          .putString(dataUrl, "data_url", { contentType: "image/jpg" });
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {},
          () => {
            storage
              .ref(
                postData.userId + "/posts/" + postData.postId + "/collabtag/"
              )
              .child("Collab Tag Composite")
              .getDownloadURL()
              .then((url) => {
                dataURL = url;
              });
          }
        );
      })
      .catch();
  };

  backToEntry = () => {
    const { transition, postData } = this.props;
    let userId = "";
    let postId = "";
    if (postData.post && postData.post.parentPostOwnerId) {
      userId = postData.post.parentPostOwnerId;
    } else {
      userId = postData.userId;
    }
    if (postData.post && postData.post.parentPostId) {
      postId = postData.post.parentPostId;
    } else {
      postId = postData.postId;
    }
    transition.router.stateService.go(NAVIGATION.POSTDETAIL, {
      userId: userId,
      postId: postId,
    });
  };

  render() {
    const {
      isChooseQuantity,
      isReviewOrder,
      isOrderDone,
      isRadio1,
      isRadio2,
      isRadio3,
      isRadio4,
      isRadio5,
      isRadio6,
      // isCheckout,
      selectedRadio,
      orderDetails,
      emailAddress,
      shippingAddress,
      // isReviewButton,
      paymentFailureMessage,
      // isPaymentFailed,
      isReviewCollabTag,
      // collabBase64Data,
      errorMessage,
      isErrorMessage,
    } = this.state;
    const { updateShowCollabTag, isPopup, postData } = this.props;
    return (
      <Fragment>
        {isPopup ? (
          <div>
            {isChooseQuantity && (
              <div>
                <div
                  className="collab-tags-header row"
                  style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
                >
                  <div className="col-4">
                    <img
                      alt=""
                      src={IMAGES.GROUP_COLLAB_TAGS.default}
                      className="multi-qr-code"
                    />
                  </div>
                  <div className="col-8 collab-tags-header-text">
                    <div className="collab-tag-header-title">
                      <img
                        alt=""
                        src={IMAGES.SHIP_TO_DOOR.default}
                        className="ship-address-icon"
                      />
                      <p className="get-collab-tags-text">
                        {getProperty("label.get-collab-tags")}
                      </p>
                    </div>
                    <div className="collab-tags-shiping-header">
                      <p>{getProperty("label.collab-tag-shipping-header")}</p>
                    </div>
                  </div>
                  <div className="collab-tags-choose-quantity">
                    <p className="choose-quantity-text">
                      {getProperty("label.choose-quantity")}
                    </p>
                    <div className="d-flex-radio">
                      <div
                        className="radio"
                        onClick={() => this.manageQuantity("radio-1")}
                      >
                        <input id="radio-1" name="radio" type="radio" />
                        <label for="radio-1" className="radio-label">
                          {getProperty("label.number-fifty")}
                        </label>
                      </div>
                      <div
                        className="radio"
                        onClick={() => this.manageQuantity("radio-2")}
                      >
                        <input id="radio-2" name="radio" type="radio" />
                        <label for="radio-2" className="radio-label">
                          {getProperty("label.number-five-hundred")}
                        </label>
                      </div>
                      <div
                        className="radio"
                        onClick={() => this.manageQuantity("radio-3")}
                      >
                        <input id="radio-3" name="radio" type="radio" />
                        <label for="radio-3" className="radio-label">
                          {getProperty("label.number-five-thousand")}
                        </label>
                      </div>
                      <div
                        className="radio"
                        onClick={() => this.manageQuantity("radio-4")}
                      >
                        <input id="radio-4" name="radio" type="radio" />
                        <label for="radio-4" className="radio-label">
                          {getProperty("label.number-hundred")}
                        </label>
                      </div>
                      <div
                        className="radio"
                        onClick={() => this.manageQuantity("radio-5")}
                      >
                        <input id="radio-5" name="radio" type="radio" />
                        <label for="radio-5" className="radio-label">
                          {getProperty("label.number-thousand")}
                        </label>
                      </div>
                      <div
                        className="radio"
                        onClick={() => this.manageQuantity("radio-6")}
                      >
                        <input id="radio-6" name="radio" type="radio" />
                        <label for="radio-6" className="radio-label">
                          {getProperty("label.number-ten-thousand")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="collab-tags-total">
                    <p className="choose-quantity-text">
                      {getProperty("label.total")}
                    </p>
                    <div className="collab-tags-total-details">
                      <p className="collab-details-first">
                        {getProperty("label.collab-details-first-heading")}
                      </p>
                      {isRadio1 && (
                        <p className="collab-details-second">
                          {getProperty("label.five-dollar")}
                        </p>
                      )}
                      {isRadio2 && (
                        <p className="collab-details-second">
                          {getProperty("label.fifty-dollar")}
                        </p>
                      )}
                      {isRadio3 && (
                        <p className="collab-details-second">
                          {getProperty("label.five-hundred-dollar")}
                        </p>
                      )}
                      {isRadio4 && (
                        <p className="collab-details-second">
                          {getProperty("label.ten-dollar")}
                        </p>
                      )}
                      {isRadio5 && (
                        <p className="collab-details-second">
                          {getProperty("label.hundred-dollar")}
                        </p>
                      )}
                      {isRadio6 && (
                        <p className="collab-details-second">
                          {getProperty("label.thousand-dollar")}
                        </p>
                      )}
                      <p className="collab-details-third">
                        {getProperty("label.collab-details-third-heading")}
                      </p>
                    </div>
                  </div>
                  <div className="collab-tags-express-checkout">
                    <p className="choose-quantity-text">
                      {getProperty("label.express-checkout")}
                    </p>
                    <img
                      alt="pay pal img"
                      src={IMAGES.PAY_PAL_IMAGE.default}
                      className="pay_pal_image"
                    />
                  </div>
                  <div className="collab-tags-button">
                    <button
                      className="review-purchase-btn text-center"
                      onClick={this.makeOrder}
                    >
                      {getProperty("label.review-purchase")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isReviewOrder && (
              <div
                className="collab-tags-header"
                style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
              >
                <div className="collab-tags-review-header">
                  <p>{getProperty("label.review-order")}</p>
                </div>
                <div className="collab-tag-order-details-review">
                  <div className="row">
                    <div className="col-3 order-detail-title">
                      <p>{getProperty("label.quantity")}</p>
                    </div>
                    <div className="col-9 order-detail-content">
                      {isRadio1 && <p>{getProperty("label.number-fifty")}</p>}
                      {isRadio2 && (
                        <p>{getProperty("label.number-five-hundred")}</p>
                      )}
                      {isRadio3 && (
                        <p>{getProperty("label.number-five-thousand")}</p>
                      )}
                      {isRadio4 && <p>{getProperty("label.number-hundred")}</p>}
                      {isRadio5 && (
                        <p>{getProperty("label.number-thousand")}</p>
                      )}
                      {isRadio6 && (
                        <p>{getProperty("label.number-ten-thousand")}</p>
                      )}
                    </div>
                    <div className="col-3 order-detail-title">
                      <p>{getProperty("label.total-uppercase")}</p>
                    </div>
                    <div className="col-9 order-detail-content">
                      {isRadio1 && <p>{getProperty("label.five-dollar")}</p>}
                      {isRadio2 && <p>{getProperty("label.fifty-dollar")}</p>}
                      {isRadio3 && (
                        <p>{getProperty("label.five-hundred-dollar")}</p>
                      )}
                      {isRadio4 && <p>{getProperty("label.ten-dollar")}</p>}
                      {isRadio5 && <p>{getProperty("label.hundred-dollar")}</p>}
                      {isRadio6 && (
                        <p>{getProperty("label.thousand-dollar")}</p>
                      )}
                    </div>
                    <div className="col-3 order-detail-title">
                      <p>{getProperty("label.contact")}</p>
                    </div>
                    <div className="col-9 order-detail-content">
                      <p></p>
                    </div>
                    <div className="col-3 order-detail-title">
                      <p>{getProperty("label.shipping")}</p>
                    </div>
                    <div className="col-9 order-detail-content">
                      <p></p>
                    </div>
                    <div className="col-3 order-detail-title">
                      <p>{getProperty("label.billing")}</p>
                    </div>
                    <div className="col-9 order-detail-content">
                      <p></p>
                    </div>
                    <div className="col-3 order-detail-title">
                      <p>{getProperty("label.payment")}</p>
                    </div>
                    <div className="col-9 order-detail-content">
                      <p>{getProperty("label.paypal")}</p>
                    </div>
                  </div>
                  <div className="collab-tags-purchase-button">
                    <button
                      className="purchase-btn text-center"
                      onClick={this.makeOrder}
                    >
                      {getProperty("label.complete-purchase")}
                    </button>
                    <p className="edit-order" onClick={this.editOrder}>
                      {getProperty("label.edit-order")}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {isOrderDone && (
              <div
                className="collab-tags-header"
                style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
              >
                <div className="collab-tags-review-header">
                  <p>{getProperty("label.thank-you")}</p>
                </div>
                <div className="collab-tags-done-content">
                  <p>{getProperty("label.collab-tag-done-heading")}</p>
                </div>
                <div className="row">
                  <div className="col-3 order-detail-title">
                    <p>{getProperty("label.contact")}</p>
                  </div>
                  <div className="col-9 order-detail-content">
                    <p></p>
                  </div>
                  <div className="col-3 order-detail-title">
                    <p>{getProperty("label.shipping")}</p>
                  </div>
                  <div className="col-9 order-detail-content">
                    <p></p>
                  </div>
                </div>
                <div className="collab-tags-purchase-button">
                  <button
                    className="order-done-btn text-center"
                    onClick={updateShowCollabTag}
                  >
                    {getProperty("label.done-uppercase")}
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="back-arrow-icon">
                <span className="back-arrow-link" onClick={this.backToEntry}>
                  <span aria-hidden="true" className="icon-style">
                    <i className="fa fa-arrow-left"></i>
                  </span>
                </span>
              </div>
              {isChooseQuantity && (
                <div>
                  <div className="collab-tags-header-uri row">
                    <div className="col-4">
                        <img
                          alt="group collab tags"
                        src={IMAGES.GROUP_COLLAB_TAGS.default}
                        className="multi-qr-code-uri"
                      />
                    </div>
                    <div className="col-8 collab-tags-header-text">
                      <div className="collab-tag-header-title">
                          <img
                            alt="ship to door"
                          src={IMAGES.SHIP_TO_DOOR.default}
                          className="ship-address-icon-uri"
                        />
                        <p className="get-collab-tags-text-uri">
                          {getProperty("label.get-collab-tags")}
                        </p>
                      </div>
                      <div className="collab-tags-shiping-header-uri">
                        <p>{getProperty("label.collab-tag-shipping-header")}</p>
                      </div>
                    </div>
                    <div className="collab-tags-choose-quantity">
                      <p className="choose-quantity-text-uri">
                        {getProperty("label.choose-quantity")}
                      </p>
                      <div className="d-flex-radio">
                        <div
                          className="radio"
                          onClick={() => this.manageQuantity("radio-1")}
                        >
                          <input
                            id="radio-1"
                            name="radio"
                            type="radio"
                            checked={isRadio1 ? true : false}
                          />
                          <label for="radio-1" className="radio-label">
                            {getProperty("label.number-fifty")}
                          </label>
                        </div>
                        <div
                          className="radio"
                          onClick={() => this.manageQuantity("radio-2")}
                        >
                          <input
                            id="radio-2"
                            name="radio"
                            type="radio"
                            checked={isRadio2 ? true : false}
                          />
                          <label for="radio-2" className="radio-label">
                            {getProperty("label.number-five-hundred")}
                          </label>
                        </div>
                        <div
                          className="radio"
                          onClick={() => this.manageQuantity("radio-3")}
                        >
                          <input
                            id="radio-3"
                            name="radio"
                            type="radio"
                            checked={isRadio3 ? true : false}
                          />
                          <label for="radio-3" className="radio-label">
                            {getProperty("label.number-five-thousand")}
                          </label>
                        </div>
                        <div
                          className="radio"
                          onClick={() => this.manageQuantity("radio-4")}
                        >
                          <input
                            id="radio-4"
                            name="radio"
                            type="radio"
                            checked={isRadio4 ? true : false}
                          />
                          <label for="radio-4" className="radio-label">
                            {getProperty("label.number-hundred")}
                          </label>
                        </div>
                        <div
                          className="radio"
                          onClick={() => this.manageQuantity("radio-5")}
                        >
                          <input
                            id="radio-5"
                            name="radio"
                            type="radio"
                            checked={isRadio5 ? true : false}
                          />
                          <label for="radio-5" className="radio-label">
                            {getProperty("label.number-thousand")}
                          </label>
                        </div>
                        <div
                          className="radio"
                          onClick={() => this.manageQuantity("radio-6")}
                        >
                          <input
                            id="radio-6"
                            name="radio"
                            type="radio"
                            checked={isRadio6 ? true : false}
                          />
                          <label for="radio-6" className="radio-label">
                            {getProperty("label.number-ten-thousand")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="collab-tags-total">
                      <p className="choose-quantity-text-uri">Total</p>
                      <div className="collab-tags-total-details">
                        <p className="collab-details-first-uri">
                          {getProperty("label.collab-details-first-heading")}
                        </p>
                        {isRadio1 && (
                          <p className="collab-details-second">
                            {getProperty("label.five-dollar")}
                          </p>
                        )}
                        {isRadio2 && (
                          <p className="collab-details-second">
                            {getProperty("label.fifty-dollar")}
                          </p>
                        )}
                        {isRadio3 && (
                          <p className="collab-details-second">
                            {getProperty("label.five-hundred-dollar")}
                          </p>
                        )}
                        {isRadio4 && (
                          <p className="collab-details-second">
                            {getProperty("label.ten-dollar")}
                          </p>
                        )}
                        {isRadio5 && (
                          <p className="collab-details-second">
                            {getProperty("label.hundred-dollar")}
                          </p>
                        )}
                        {isRadio6 && (
                          <p className="collab-details-second">
                            {getProperty("label.thousand-dollar")}
                          </p>
                        )}
                        {!isRadio1 &&
                          !isRadio2 &&
                          !isRadio3 &&
                          !isRadio4 &&
                          !isRadio5 &&
                          !isRadio6 && (
                            <p className="collab-details-second">$0.00</p>
                          )}
                        <p className="collab-details-third-uri">
                          {getProperty("label.collab-details-third-heading")}
                        </p>
                      </div>
                    </div>
                    <div className="collab-tags-express-checkout">
                      <p className="choose-quantity-text-uri">
                        {getProperty("label.express-checkout")}
                      </p>
                      <div className="collab-tags-payment-uri">
                        {/* <p className="payment-failure-message">
                          {paymentFailureMessage}
                        </p>
                        {isCheckout ? (
                          <PayPal
                            selectedRadio={selectedRadio}
                            orderDetails={orderDetails}
                            parentCallbackSuccess={this.handleCallback}
                            parentCallbackFailure={this.handleFailureCallback}
                          />
                        ) : (
                          <button
                            className="make-payment-btn text-center"
                            onClick={this.handleCheckout}
                          >
                            {getProperty("label.make-payment")}
                          </button>
                        )} */}
                        <button
                          className="review-purchase-btn text-center"
                          onClick={this.makeOrder}
                        >
                          {getProperty("label.review-purchase")}
                        </button>
                      </div>
                    </div>
                    {/* <div className="collab-tags-button-uri">
                      {isReviewButton ? (
                        <button
                          className="review-purchase-btn text-center"
                          onClick={this.makeOrder}
                        >
                          {getProperty("label.review-purchase")}
                        </button>
                      ) : (
                        <button
                          className="review-purchase-disabled-btn text-center"
                          disabled={true}
                        >
                          {getProperty("label.review-purchase")}
                        </button>
                      )}
                    </div> */}
                  </div>
                </div>
              )}
              {isReviewCollabTag && (
                <div
                  className=".collab-tags-header-uri"
                  onLoad={this.storeCollabTagInFirebase}
                >
                  <div className="collab-tags-review-header">
                    <p>{getProperty("label.review-collab-tag")}</p>
                  </div>
                  <div className="high-reslution-collab-tag-review">
                    <QRCodeWithPost
                      post={postData.post}
                      postHeader={false}
                      highResolution={true}
                      qrCodeValue={postData.postUrl}
                    />
                  </div>
                  <div className="collab-tags-total">
                    <div className="collab-tags-total-details">
                      {isRadio1 && (
                        <p className="collab-qty-details-first-uri">
                          {getProperty("label.fifty-quantity")}
                        </p>
                      )}
                      {isRadio2 && (
                        <p className="collab-qty-details-first-uri">
                          {getProperty("label.five-hundred-quantity")}
                        </p>
                      )}
                      {isRadio3 && (
                        <p className="collab-qty-details-first-uri">
                          {getProperty("label.five-thousand-quantity")}
                        </p>
                      )}
                      {isRadio4 && (
                        <p className="collab-qty-details-first-uri">
                          {getProperty("label.hundred-quantity")}
                        </p>
                      )}
                      {isRadio5 && (
                        <p className="collab-qty-details-first-uri">
                          {getProperty("label.thousand-quantity")}
                        </p>
                      )}
                      {isRadio6 && (
                        <p className="collab-qty-details-first-uri">
                          {getProperty("label.ten-thousand-quantity")}
                        </p>
                      )}
                      {isRadio1 && (
                        <p className="collab-price-details-second-uri">
                          {getProperty("label.five-dollar")}
                        </p>
                      )}
                      {isRadio2 && (
                        <p className="collab-price-details-second-uri">
                          {getProperty("label.fifty-dollar")}
                        </p>
                      )}
                      {isRadio3 && (
                        <p className="collab-price-details-second-uri">
                          {getProperty("label.five-hundred-dollar")}
                        </p>
                      )}
                      {isRadio4 && (
                        <p className="collab-price-details-second-uri">
                          {getProperty("label.ten-dollar")}
                        </p>
                      )}
                      {isRadio5 && (
                        <p className="collab-price-details-second-uri">
                          {getProperty("label.hundred-dollar")}
                        </p>
                      )}
                      {isRadio6 && (
                        <p className="collab-price-details-second-uri">
                          {getProperty("label.thousand-dollar")}
                        </p>
                      )}
                      <p className="collab-details-third-uri">
                        {getProperty("label.collab-details-third-heading")}
                      </p>
                    </div>
                  </div>
                  <div className="collab-tags-express-checkout">
                    <p className="choose-quantity-text-uri">
                      {getProperty("label.make-payment")}
                    </p>
                    <div className="collab-tags-payment-uri">
                      <p className="payment-failure-message">
                        {paymentFailureMessage}
                      </p>
                      <PayPal
                        selectedRadio={selectedRadio}
                        orderDetails={orderDetails}
                        parentCallbackSuccess={this.handleCallback}
                        parentCallbackFailure={this.handleFailureCallback}
                      />
                    </div>
                  </div>
                </div>
              )}
              {isReviewOrder && (
                <div className=".collab-tags-header-uri">
                  <div className="collab-tags-review-header">
                    <p>{getProperty("label.review-order")}</p>
                  </div>
                  <div className="collab-tag-order-details-review">
                    <div className="row">
                      <div className="col-3 order-detail-title-uri">
                        <p>{getProperty("label.quantity")}</p>
                      </div>
                      <div className="col-9 order-detail-content-uri">
                        {isRadio1 && <p>{getProperty("label.number-fifty")}</p>}
                        {isRadio2 && (
                          <p>{getProperty("label.number-five-hundred")}</p>
                        )}
                        {isRadio3 && (
                          <p>{getProperty("label.number-five-thousand")}</p>
                        )}
                        {isRadio4 && (
                          <p>{getProperty("label.number-hundred")}</p>
                        )}
                        {isRadio5 && (
                          <p>{getProperty("label.number-thousand")}</p>
                        )}
                        {isRadio6 && (
                          <p>{getProperty("label.number-ten-thousand")}</p>
                        )}
                      </div>
                      <div className="col-3 order-detail-title-uri">
                        <p>{getProperty("label.total-uppercase")}</p>
                      </div>
                      <div className="col-9 order-detail-content-uri">
                        {isRadio1 && <p>{getProperty("label.five-dollar")}</p>}
                        {isRadio2 && <p>{getProperty("label.fifty-dollar")}</p>}
                        {isRadio3 && (
                          <p>{getProperty("label.five-hundred-dollar")}</p>
                        )}
                        {isRadio4 && <p>{getProperty("label.ten-dollar")}</p>}
                        {isRadio5 && (
                          <p>{getProperty("label.hundred-dollar")}</p>
                        )}
                        {isRadio6 && (
                          <p>{getProperty("label.thousand-dollar")}</p>
                        )}
                      </div>
                      <div className="col-3 order-detail-title-uri">
                        <p>{getProperty("label.contact")}</p>
                      </div>
                      <div className="col-9 order-detail-content-uri">
                        <p>{emailAddress}</p>
                      </div>
                      <div className="col-3 order-detail-title-uri">
                        <p>{getProperty("label.shipping")}</p>
                      </div>
                      <div className="col-9 order-detail-content-uri">
                        <p>{shippingAddress}</p>
                      </div>
                      <div className="col-3 order-detail-title-uri">
                        <p>{getProperty("label.billing")}</p>
                      </div>
                      <div className="col-9 order-detail-content-uri">
                        <p>{shippingAddress}</p>
                      </div>
                      <div className="col-3 order-detail-title-uri">
                        <p>{getProperty("label.payment")}</p>
                      </div>
                      <div className="col-9 order-detail-content-uri">
                        <p>{getProperty("label.paypal")}</p>
                      </div>
                    </div>
                    <div className="collab-tags-confirm-purchase">
                      <p className="choose-quantity-text-uri">
                        {getProperty("label.confirm-purchase")}
                      </p>
                      <div className="collab-tags-confirm-purchase-button-uri">
                        <button
                          className="purchase-btn text-center"
                          onClick={this.makeOrder}
                        >
                          {getProperty("label.looks-good")}
                        </button>
                        <p className="edit-order-uri" onClick={this.editOrder}>
                          {getProperty("label.edit-order")}
                        </p>
                      </div>
                    </div>
                    {isErrorMessage && <p>{errorMessage}</p>}
                  </div>
                </div>
              )}
              {isOrderDone && (
                <div className="collab-tags-header-uri">
                  <div className="collab-tags-review-header">
                    <p>{getProperty("label.thank-you")}</p>
                  </div>
                  <div className="collab-tags-done-content">
                    <p>{getProperty("label.collab-tag-done-heading")}</p>
                  </div>
                  <div className="row">
                    <div className="col-3 order-detail-title-uri">
                      <p>{getProperty("label.contact")}</p>
                    </div>
                    <div className="col-9 order-detail-content-uri">
                      <p>{emailAddress}</p>
                    </div>
                    <div className="col-3 order-detail-title-uri">
                      <p>{getProperty("label.shipping")}</p>
                    </div>
                    <div className="col-9 order-detail-content-uri">
                      <p>{shippingAddress}</p>
                    </div>
                  </div>
                  <div className="collab-tags-purchase-button-uri">
                    <button
                      className="order-done-btn text-center"
                      onClick={this.backToEntry}
                    >
                      {getProperty("label.done-uppercase")}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-2"></div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default OrderCollabTag;
