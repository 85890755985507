import domtoimage from 'dom-to-image';

export const downloadQRCode = () => { // For Onclick on QR code - its download QR
    // let canvas = document.getElementById('qr-code');
    // if(canvas === null) {
       let canvas = document.getElementById('big-attached-qr-code');
    // }
    const pngUrl = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qr-code.jpeg';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    return;
};

export const downloadCollabTag = () => { // For Onclick on Save full Collab Tag - its download full collab tag
    const canvas = document.getElementById('collab-tag');
    domtoimage.toJpeg(canvas, {bgcolor:"white"})
    .then(function (dataUrl) {           
        var image = new Image();
        image.src = dataUrl;

        var w = window.open("",'_blank');
        w.document.write(image.outerHTML);
        w.document.close();
    })
    .catch(function (error) {
        console.error('oops, something went wrong!', error);
    });
   
    return;
};

