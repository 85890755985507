import "../../styles/style.css";

import React, { Fragment } from "react";


const TextLoader = (props) => {
  const { onButtonLoader, isRequiredMinHeight } = props;
  const buttonLoader = onButtonLoader === "" ? "btn-loader" : "loader";
  const loaderText = props.loaderText || "";
  return (
    <Fragment>
      {isRequiredMinHeight && (
        <div
          className={`${buttonLoader} text-center`}
          style={{ minHeight: 45 , marginBottom: 50 }}
        >
          <span aria-hidden="true">
            {loaderText} &nbsp; &nbsp;
            <i className="fa fa-circle-o-notch fa-spin" />
          </span>
        </div>
      )}
      {!isRequiredMinHeight && (
        <div
          className={`${buttonLoader} text-center`}
          style={{ minHeight: 250, marginTop: 70 }}
        >
          <span aria-hidden="true">
            {loaderText} &nbsp; &nbsp;
            <i className="fa fa-circle-o-notch fa-spin" />
          </span>
        </div>
      )}
    </Fragment>
  );
};

export default TextLoader;
