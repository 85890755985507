import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { functions } from "../../firebase/firebaseConfig";
import { getProperty } from "../../helper/helper";
import defaultUserImage from "../../images/icons/user.jpg";
import { downloadQRCode } from "../../util/util";
import PrintQRCode from "./PrintQRCode";

// const data = require("../../data/data.json");
const apiName = require("../../data/apiList.json");
const getUserDataWithoutAuth = functions.httpsCallable(
  apiName.getUserDataWithoutAuth
);
class FeedUserProfile extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
      photoUrl: "",
      name: "",
      pathUserName: "",
      emailAddress: "",
      phoneNumber: "",
    };
  }

  handleImageError = (ev) => {
    ev.target.src = defaultUserImage;
  };

  componentDidMount = () => {
    const { isPopup, postOwner } = this.props;
    if (isPopup) {
      if (postOwner) {
        this.setState({
          photoUrl: postOwner.photoURL,
          pathUserName: postOwner.pathUserName,
          name: postOwner.name,
        });
      }
    } else {
      if (postOwner) {
        this.setState({
          photoUrl: postOwner.photoURL,
          pathUserName: postOwner.pathUserName,
          name: postOwner.name,
        });
      }
    }
  };

  printQRCode = () => {
    window.print();
  };

  copyURL = () => {
    const dummy = document.createElement("p");
    dummy.textContent = window.location.href;
    document.body.appendChild(dummy);

    const range = document.createRange();
    range.setStartBefore(dummy);
    range.setEndAfter(dummy);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  render() {
    const {
      photoUrl,
    } = this.state;
    // const { user } = this.props.additionalUserInfo;
    const { owner, isPopup, closePopup, postOwner } = this.props;
    // let userName = "";
    // if (user) {
    //   userName = user.userName;
    // }
    // let displayName = "";
    // if (user && user.firstName && user.lastName) {
    //   displayName = user.firstName + " " + user.lastName;
    // }
    if (isPopup) {
      return (
        <Fragment>
          <div
            className="user-profile-section-popup make-me-sticky"
            style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
          >
            <div className="user-profile-back-arrow">
              <span className="back-arrow-link" onClick={closePopup}>
                <i className="fa fa-arrow-left"></i>
              </span>
            </div>
            <div className="profile-header-web-feed">
              {owner && owner.photoURL && (
                <img
                  alt="user icon feed"
                  src={owner.photoURL}
                  className="user-icon-feed"
                  onError={this.handleImageError}
                />
              )}
              {owner && owner.name && (
                <p className="display-name-feed">{owner.name}</p>
              )}
              {owner && owner.pathUserName && (
                <p className="display-user-name">{owner.pathUserName}</p>
              )}
            </div>
            {/* <div className="row">
              <div className="col-9 user-info">
                {phoneNumber && (
                  <div className="user-info-icon">
                    <p className="info-item">{phoneNumber}</p>
                  </div>
                )}
                {emailAddress && (
                  <div className="user-info-icon">
                    <p className="info-item">{emailAddress}</p>
                  </div>
                )}
              </div>
              <div className="col-3 user-info-icons">
                {phoneNumber && (
                  <div className="trash-iocns">
                    <img
                      src={IMAGES.PHONE_LOGO.default}
                      className="info-icon"
                    />
                    <img
                      src={IMAGES.MESSAGE_ICON.default}
                      className="message-icon"
                    />
                  </div>
                )}
                {emailAddress && (
                  <div className="trash-iocns">
                    <img
                      src={IMAGES.EMAIL_LOGO.default}
                      className="info-icon"
                    />
                  </div>
                )}
              </div>
            </div> */}
            {/* <div className="user-profile-see-personal-page">
              <span
                className="d-inline-block btn-personal-page"
                onClick={closePopup}
              >
                {getProperty("label.see-personal-page")}
              </span>
            </div> */}
            <div className="user-profile-qr-code-container-popup">
              <PrintQRCode
                qrcodeValue={window.location.href}
                userProfile={true}
                qrcodehighresolution={false}
                postHeader={false}
              />
            </div>
            <div>
              <p className="user-profile-copy-url" onClick={this.copyURL}>
                {getProperty("label.copy-url")}
              </p>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="user-profile-section make-me-sticky">
            <div className="profile-header-web-feed">
              {postOwner && postOwner.photoURL && (
                <img
                  alt="user icon feed"
                  src={postOwner.photoURL}
                  className="user-icon-feed"
                  onError={this.handleImageError}
                />
              )}
              {!postOwner && (
                <img
                  alt="user icon default feed"
                  src={photoUrl}
                  className="user-icon-default-feed"
                  onError={this.handleImageError}
                />
              )}
              {postOwner && postOwner.name && (
                <p className="display-name-feed">{postOwner.name}</p>
              )}
              {postOwner && postOwner.pathUserName && (
                <p className="display-user-name">{postOwner.pathUserName}</p>
              )}
            </div>
            {/* <div className="row">
              <div className="col-9 user-info">
                {phoneNumber && (
                  <div className="user-info-icon">
                    <p className="info-item">{phoneNumber}</p>
                  </div>
                )}
                {emailAddress && (
                  <div className="user-info-icon">
                    <p className="info-item">{emailAddress}</p>
                  </div>
                )}
              </div>
              <div className="col-3 user-info-icons">
                {phoneNumber && (
                  <div className="trash-iocns">
                    <img
                      src={IMAGES.PHONE_LOGO.default}
                      className="info-icon"
                    />
                    <img
                      src={IMAGES.MESSAGE_ICON.default}
                      className="message-icon"
                    />
                  </div>
                )}
                {emailAddress && (
                  <div className="trash-iocns">
                    <img
                      src={IMAGES.EMAIL_LOGO.default}
                      className="info-icon"
                    />
                  </div>
                )}
              </div>
            </div> */}
            <div className="user-profile-qr-code-container">
              <PrintQRCode
                qrcodeValue={window.location.href}
                // userProfile={true}
                // qrcodehighresolution = {false}
                // postHeader={false}
              />
            </div>
            <div className="download-qr-link">
              <p onClick={downloadQRCode}>
                {getProperty("label.save-qr-jpeg")}
              </p>
              <p onClick={this.printQRCode}>
                {getProperty("label.print-qr-code")}
              </p>
            </div>
            <div className="hidden-qr-code">
              <PrintQRCode
                qrcodeValue={window.location.href}
                isDownloadQRCode={true}
              />
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps)(FeedUserProfile);
