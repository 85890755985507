import { AsYouType } from 'libphonenumber-js/mobile';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { analytics, functions } from '../firebase/firebaseConfig';
import * as language from '../resources';
import {googleStoreLink, appleStoreLink} from '../constants/constants';
const data = require('../data/data.json');


export async function addCowner(post, userData, additionalUserInfo, isInvite, invitationId) {
  var responseData = {};

  let postCoOwner = buildPostCoOwnerData(userData, additionalUserInfo);
  let postOwner = post.owner;

  postOwner.displayName = post.auditInfo.createdBy;
  let postId = post.id;

  const postCoOwnerFunction = functions.httpsCallable('addCoOwnerInPost');
  const addCoOwnerFromInvitation = functions.httpsCallable('addCoOwnerFromInvitation');

  if (isInvite) {
    await addCoOwnerFromInvitation({ invitationId: invitationId, apiKey: data.apiKey, source: data.apiSource })
      .then((response) => {
        responseData['type'] = 'info';
        responseData['isMessage'] = true;
        responseData['displayMessage'] = 'Thanks.. Collab request is sent successfully.';
      })
      .catch((error) => {});
  } else {
    await postCoOwnerFunction({
      postCoOwner: postCoOwner,
      postId: postId,
      postOwner: postOwner,
      apiKey: data.apiKey,
      source: data.apiSource,
    })
      .then((response) => {
        if (response && response.data != null) {
          responseData['type'] = 'danger';
          responseData['isMessage'] = true;
          responseData['displayMessage'] = response.data.error.message;
        } else {
          responseData['type'] = 'info';
          responseData['isMessage'] = true;
          responseData['displayMessage'] = 'Thanks.. Collab request is sent successfully.';
        }
      })
      .catch((error) => {
        responseData['type'] = 'danger';
        responseData['isMessage'] = true;
        responseData['displayMessage'] = 'Please Try After SomeTime';
      });
  }

  return responseData;
}

export async function updateCowner(coOwnerDetails, postId) {
  var responseData = {};

  const updateCoowner = functions.httpsCallable('updateCoOwnerInPost');

  await updateCoowner({
    coOwnerDetails: coOwnerDetails,
    postId: postId,
    apiKey: data.apiKey,
    source: data.apiSource,
  })
    .then((response) => {
      if (response && response.data != null) {
        responseData['type'] = 'danger';
        responseData['isMessage'] = true;
        responseData['displayMessage'] = response.data.error.message;
      } else {
        responseData['type'] = 'info';
        responseData['isMessage'] = true;
        responseData['displayMessage'] = 'Collaboration successfully';
      }
    })
    .catch((error) => {
      responseData['type'] = 'danger';
      responseData['isMessage'] = true;
      responseData['displayMessage'] = 'Please Try After SomeTime';
    });

  return responseData;
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return 'iOS';
  } else if (userAgent.match(/Android/i)) {
    return 'Android';
  }
}

export function getSignupWelcomeMessage(receiver) {
  return `Hello ${receiver},%0aSo you can begin the friendly collaborations on your memories,%0aDon&#39;t forgot to get Paths on your mobile device here :%0aiOS : ${data.iOS_App_Url} %0aAndroid : ${data.android_App_Url}`;
}

export function sendSignupWelcomeSMS() {
  let responseData = {};
  const sendSMSData = functions.httpsCallable('sendSignupWelcomeSMS');

  sendSMSData({
    apiKey: data.apiKey,
    messageKey: 'WELCOME_SMS',
    source: data.apiSource,
  })
    .then((response) => {
      responseData = response;
    })
    .catch((error) => {
      responseData = error;
    });

  return responseData;
}

export function redirectToAppStore() {
  const isMobileView = getMobileOperatingSystem();

  if (isMobileView === 'Android') {
    window.location.href = googleStoreLink;
  } else if (isMobileView === 'iOS') {
    window.location.href = appleStoreLink;
  }
}

const getNavigatorLanguage = () => {
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
};

export const getProperty = (key) => {
  let locale = getNavigatorLanguage();
  let langObj = language.en;
  try {
    langObj = eval(`language.${locale}`);
  } catch (error) {}
  return get(langObj || language.en, key);
};

export const validateEmailAddress = (emailId) => {
  let emailRegex = new RegExp(/^\w+([\.-]?[+\w-._]+)+@\w+([+\.:._-]?\w+)+(\.[a-zA-Z0-9]{2,})+$/);
  return emailRegex.test(String(emailId).toLowerCase());
};

// To Report Page View
export const GAtrackPageDetails = () => {
  //fetching hash value will return value after '#' chatacters in url
  let pageName = window.location.hash;
  pageName = pageName.substring(1, pageName.length);

  analytics.setCurrentScreen(pageName, pageName); // sets `screen_name` parameter
  analytics.logEvent('screen_view', { screen_name: pageName, screen_class: 'Webview' }); // log event with `screen_name` parameter attached
};

// To Report User's login method
export const GAtrackLoginDetails = (loginMethod) => {
  analytics.setUserProperties({ login_method: loginMethod });
};

// To Report Userid
export const GAtrackUserIdDetails = (userId) => {
  analytics.setUserProperties({ user_id: userId });
};

//Truncated post title to 40 characters
export const getPostTitle = (title) => {
  if (title.length > 40) {
    return title.substring(0, 40);
  } else {
    return title;
  }
};

//Creating Co-owner details to pass in collab request
export const buildPostCoOwnerData = (userData, additionalUserInfo) => {
  let userId = userData.uid;
  const { firstName, lastName, profilePhotoUrls } = additionalUserInfo.user;
  let displayName = '';
  if (firstName && lastName) {
    displayName = `${firstName} ${lastName}`;
  } else {
    displayName = userData.email;
  }
  let photoURL = profilePhotoUrls.original;

  return {
    userId,
    photoURL,
    displayName,
  };
};

export const isValidPostData = (post) => {
  return !isEmpty(post.attachments) || post.description || post.htmlContent || !isEmpty(post.myspace);
};

export const getFormattedPhoneNumber = (countryCode, mobileNumber) => {
  const formattedNumber = new AsYouType(countryCode).input(mobileNumber);
  return formattedNumber;
};

export const getOriginalPhoneNumber = (mobileNumber) => {
  return mobileNumber.replace(/[^0-9]/g, '');
};

export const validateMobileNumber = (mobileNumber) => {
  let mobileRegex = new RegExp(/^[1-9]{1}[0-9]{9}$/);
  return mobileRegex.test(String(mobileNumber).toLowerCase());
};

export const addEditProfileLink = (transition) => {
  //fetching DOM elements by class name
  var list = document.getElementsByClassName('footer-menu');

  //iterating list of DOM elements
  [...list].forEach(function (item) {
    var lastElement = item.lastElementChild.lastElementChild;
    const { editProfile } = data;
    if (lastElement) {
      //fetching last element and checking Edit Profile label
      var lastAnchor = lastElement.lastElementChild.lastElementChild;
      if (lastAnchor.innerText === editProfile.label) {
        return;
      } else {
        const menu = item.querySelector('#navigation ul');
        // add menu item
        menu.appendChild(createMenuItem(editProfile.label, transition));
      }
    }
  });
};

export const createMenuItem = (name, transition) => {
  let li = document.createElement('li');
  let a = document.createElement('a');
  let span = document.createElement('span');
  span.textContent = name;
  a.appendChild(span);
  a.classList.add('paths-footer-link');
  a.addEventListener('click', () => onClickEditProfile(transition), false);
  li.appendChild(a);

  return li;
};

//Handle click on Edit Profile link
const onClickEditProfile = (transition) => {
  const { editProfile } = data;
  if (transition) {
    transition.router.stateService.go(editProfile.pageUrl, {
      userId: transition.params().userId,
    });
  }
};

// Validate user name with regex pattern
export const validateUserName = (userName) => {
  const userNameRegex = /^[a-zA-Z0-9_.]{4,10}$/;
  return userNameRegex.test(userName);
};
