import '../../../styles/style.css';

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { NAVIGATION } from '../../../constants/constants';
import { updateUser } from '../../../ducks/actions';
import { addCowner } from '../../../helper/helper';
import defaultUserImage from '../../../images/icons/user.jpg';
import ConfirmModal from '../../layouts/ConfirmDialog';
import CoOwnerDialog from '../../layouts/CoOwnerDialog';
import GetPathsOverlay from '../../layouts/GetPathsOverlay';
import Notification from '../../layouts/Notifications';
import LoginModal from '../../loginpage/LoginModal';
import NavigationBar from './NavigationBar';

const data = require('../../../data/data.json');

class PostNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postId: this.props.$stateParams,
      selectedIndex: -1,
      previousSelectedIndex: -1,
      showLogin: false,
      isCollabUser: false,
      showLoader: false,
      displayMessage: '',
      isMessage: false,
      type: '',
      isCollabUserAdded: false,
      displayDialog: false,
      showDoneScreen: false,
      showGetPaths: false,
      newPostId: "",
      newCollaboratorId: "",
      unlinkedCollaboratorId: ""
    };
  }

  componentDidMount() {
    const { postData, post } = this.props;    
    if(postData && postData.newPostId) {
      this.setState({
        newPostId: postData.newPostId
      });
    } else if(postData && postData.userId !== post.owner.userId) {
      this.setState({
        newCollaboratorId: postData.userId
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedIndex != null &&
      nextProps.selectedIndex !== undefined &&
      nextProps.selectedIndex !== this.state.selectedIndex
    ) {
      this.setState({ selectedIndex: nextProps.selectedIndex });
    }
  }
  handleClick = (coOwner, index) => {
    const { userInfo, transition, postOwner, post } = this.props;
    const {previousSelectedIndex} = this.state;
    if (coOwner) {
      //stop switching navigation if post not found for loggedin co-owner
      if (userInfo.user && userInfo.user.uid === coOwner.userId && !coOwner.postId) {        
        // let userId = "";
        // if(userInfo && userInfo.uid) {
        //  // userId = userInfo.uid;
        // }
        transition.router.stateService.go(NAVIGATION.CREATE_NEW_POST, {
          userId: postOwner.userId,
          postOwner: postOwner,
          userInfo: userInfo,
          parentPostId: post.id,
          parentPostPrivacy: post.private,
        });
      } else {
        if(index !== previousSelectedIndex) {
          this.setState({ selectedIndex: index, previousSelectedIndex: index });
          this.props.handleClick(coOwner, index);
        } else {
          this.collabUserFeedPage(coOwner);
        }        
      }
    } else {
      if(index !== previousSelectedIndex) {
        this.setState({ selectedIndex: index, previousSelectedIndex: index });
        this.props.handleClick(coOwner, index);
      } else {
        this.collabUserFeedPage(coOwner);
      }      
    }
  };

  collabUserFeedPage = (coOwner) => {
    const { isPathMain } = this.props;
    if(!isPathMain && coOwner && coOwner.userId) {
      let currentURL = window.location.href.split("/posts");
      let lastReplaceIndex = currentURL[0].lastIndexOf("/");
      let newFeedURL = currentURL[0].substring(0,lastReplaceIndex+1);
      newFeedURL = newFeedURL+coOwner.userId;
      window.open(newFeedURL,"_blank");
    }
  }

  closeWelcomeModal = () => {
    this.setState({ displayDialog: false });
  };

  updateShowLogin = (msgType, isMessage, message) => {
    const { post } = this.props;
    if (msgType && isMessage && message) {
      this.setState({ showLogin: !this.state.showLogin });
      if (msgType === 'info' && this.props.isInvite) this.props.getPosts(post.id);
      this.setState({
        isCollabUserAdded: !this.props.isInvite && msgType === 'info' && message !== data.invitaionAlreadyAccepted,
        type: msgType,
        isMessage: isMessage,
        displayMessage: message,
        isCollabUser: false,
      });
    } else {
      this.setState({ showLogin: !this.state.showLogin, isCollabUser: false });
    }
    const collabLabel = document.getElementById("join-collab-tag-icon");
    if(collabLabel) {
      collabLabel.style.display = "none";
    }    
  };

  collabUser = () => {
    const { post } = this.props;    
    if(post && post.collabtabAutoApproveStatus) {
      this.collabUserWithPost();
    } else {
      this.setState({ isCollabUser: true, showLogin: false });
    }    
    const collabLabel = document.getElementById("join-collab-tag-icon");
    if(collabLabel) {
      collabLabel.style.display = "none";
    } 
  };

  closeModal = () => {
    this.setState({
      isCollabUser: false,
      showLogin: false,
      showLoader: false,
    });
  };

  closeAlert = () => {
    this.setState({
      isMessage: false,
      displayMessage: '',
      type: '',
    });
  };

  changeActiveTab = () => {
    const { newPostId, newCollaboratorId } = this.state;
    const { post, postData } = this.props;
    if((postData && postData.newPostId) || newCollaboratorId) {
      if (post.coOwners && post.coOwners.approved.length > 0) {
        post.coOwners.approved.map((coOwner, index) => {
          if(newPostId) {
            if(coOwner.postId === postData.newPostId) {
              this.setState({
                selectedIndex : index,
                newPostId: "",
              });
              this.handleClick(coOwner, index);
            }
          } else if(newCollaboratorId) {
            if(coOwner.userId === newCollaboratorId) {
              this.setState({
                selectedIndex : index,
                newCollaboratorId : "",
              });
              this.handleClick(coOwner, index);
            }
          }          
        });
      }
    }
  }

  changeActiveTabToPostOwner = () => {
    this.setState({
      selectedIndex: -1,
      unlinkedCollaboratorId: this.props.unlinkedPostId
    });
    this.handleClick(null, -1);
  }

  /**
   * If image is broken, Adding default user image.
   */
  handleImageError = (ev) => {
    ev.target.src = defaultUserImage;
  };

  /* Getting the posts of coOwner and moving from one post to another*/
  getPostItems = () => {
    const { post, userInfo, unlinkedPostId } = this.props;
    const { selectedIndex, newPostId, newCollaboratorId, unlinkedCollaboratorId } = this.state;
    var navItems = [];
    if((selectedIndex === -1 && newPostId) || newCollaboratorId) {
      this.changeActiveTab();
    }

    navItems.push(
      <li className={selectedIndex === -1 ? 'active ' : ''}>
        <a href={() => false} className='nav-link anchor_style' onClick={() => this.handleClick(null, -1)}>
          <img alt="post owner" src={post.owner.photoURL} onError={this.handleImageError} />
          {post.private && (
            <span className='private-icon'>
              <i className='fa fa-lock'></i>
            </span>
          )}
        </a>
      </li>
    );

    if (post.coOwners && post.coOwners.approved.length > 0) {
      post.coOwners.approved.map((coOwner, index) => {
        if(coOwner && !coOwner.private) {
          if(unlinkedPostId) {
            if(unlinkedPostId !== coOwner.userId) {
              navItems.push(
                <li className={selectedIndex === index ? "active" : ""}>
                  <a
                    href={() => false}
                    className="nav-link anchor_style"
                    onClick={() => this.handleClick(coOwner, index)}
                  >
                    <img
                      src={coOwner.photoURL}
                      alt=""
                      onError={this.handleImageError}
                    />
                    {coOwner.private && (
                      <span className="private-icon">
                        <i className="fa fa-lock"></i>
                      </span>
                    )}
                  </a>
                </li>
              );
            } else {
              if(unlinkedCollaboratorId === "") {
                this.changeActiveTabToPostOwner();
              }
            }
          } else {
            navItems.push(
              <li className={selectedIndex === index ? "active" : ""}>
                <a
                  href={() => false}
                  className="nav-link anchor_style"
                  onClick={() => this.handleClick(coOwner, index)}
                >
                  <img
                    src={coOwner.photoURL}
                    alt=""
                    onError={this.handleImageError}
                  />
                  {coOwner.private && (
                    <span className="private-icon">
                      <i className="fa fa-lock"></i>
                    </span>
                  )}
                </a>
              </li>
            );
          }
        } else if(userInfo && userInfo.user && coOwner.userId === userInfo.user.uid) {
          navItems.push(
            <li className={selectedIndex === index ? "active" : ""}>
              <a
                href={() => false}
                className="nav-link anchor_style"
                onClick={() => this.handleClick(coOwner, index)}
              >
                <img
                  src={coOwner.photoURL}
                  alt=""
                  onError={this.handleImageError}
                />
                {coOwner.private && (
                  <span className="private-icon">
                    <i className="fa fa-lock"></i>
                  </span>
                )}
              </a>
            </li>
          );
        }
      });
    }
    return navItems;
  };

  collabUserWithPost = async () => {
    this.setState({ showLoader: true });
    const { post, userInfo, additionalUserInfo, showUpdatedPost } = this.props;
    if (post) {
      try {
        let addCoownerData = await addCowner(
          post,
          userInfo.user,
          additionalUserInfo,
          this.props.isInvite,
          this.props.invitationId
        );
        if (addCoownerData) {
          if (this.props.isInvite) {
            this.props.getPosts(post.id);
          }
          this.setState({
            isCollabUserAdded: !this.props.isInvite && addCoownerData.type === 'info',
            type: addCoownerData.type,
            isMessage: addCoownerData.isMessage,
            displayMessage: addCoownerData.displayMessage,
          });
        }
        this.closeModal();
        setTimeout(() => {
          showUpdatedPost();
        }, 2000);        
      } catch (error) {
        this.closeModal();
      }
    }
  };

  updateDoneScreen = () => {    
    this.setState({
      showDoneScreen: !this.state.showDoneScreen,
    });
  };

  manageGetPaths = () => {
    this.setState({
      showGetPaths: !this.state.showGetPaths,
    });
  };

  render() {
    const { post, userInfo, isSinglePost } = this.props;
    const { owner, isInvite } = this.props;
    const { isCollabUserAdded, displayDialog, showDoneScreen, showGetPaths, unlinkedCollaboratorId } = this.state;
    let approved = post && post.coOwners && post.coOwners.approved ? post.coOwners.approved : [];
    let userId = userInfo && userInfo.user && userInfo.user.uid;
    let isPostOwner = post && post.owner ? post.owner.userId : '';
    let isCoOwner = approved && approved.findIndex((coowner) => coowner.userId === userId);
    let isPending =
      (post && post.userCollabTabStatus && (post.userCollabTabStatus === 'PENDING' || post.userCollabTabStatus === 'CANCELED')) || isCollabUserAdded ? true : false;

    var navItems = this.getPostItems();
    return (
      <Fragment>
        <div className='feed-navigation'>
          <div className='navbar-root paths-navbar'>
            {this.state.isMessage && !isInvite && (
              <Notification closeModal={this.closeAlert} type={this.state.type} message={this.state.displayMessage} />
            )}
            <NavigationBar
              navItems={navItems}
              userCollabSetup={userInfo.user ? this.collabUser : this.updateShowLogin}
              isCoOwner={isCoOwner}
              isPostOwner={isPostOwner}
              isPending={isPending}
              userId={userId}
              isSinglePost={isSinglePost}
              isLoggedIn={userInfo.user ? true : false}
              post={post}
              unlinkedCollaboratorId={unlinkedCollaboratorId}
              userInfo={userInfo}
            />
          </div>
        </div>

        {(!userInfo.user || showDoneScreen) && this.state.showLogin && (
          <LoginModal
            show={this.state.showLogin}
            updateShowLogin={this.updateShowLogin}
            post={post}
            isInvite={this.props.isInvite}
            invitationId={this.props.invitationId}
            owner={owner}
            showPhoneNumberExists={this.props.showPhoneNumberExists}
            showSignUpPopup={true}
            showDoneScreen={showDoneScreen}
            isMainHeader={true}
            updateDoneScreen={this.updateDoneScreen}
            manageGetPaths={this.manageGetPaths}
          />
        )}
        {showGetPaths && (
          <GetPathsOverlay show={showGetPaths} manageGetPaths={this.manageGetPaths} />
        )}

        <ConfirmModal
          isCollabUser={this.state.isCollabUser}
          closeModal={this.closeModal}
          collabUserWithPost={this.collabUserWithPost}
          showLoader={this.state.showLoader}
          isInvite={this.props.isInvite}
          post={post}
        />
        {displayDialog && (
          <CoOwnerDialog show={displayDialog} closeModal={this.closeWelcomeModal} isCollabClicked={true} />
        )}
      </Fragment>
    );
  }
}

PostNavigation.proptype = {
  $stateParams: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostNavigation);
