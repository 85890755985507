import "../../../styles/style.css";

import isEmpty from "lodash/isEmpty";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { functions } from "../../../firebase/firebaseConfig";
import { GAtrackPageDetails, getProperty } from "../../../helper/helper";
import InviteHeader from "../../InviteSection";
import Error from "../../layouts/Error";
import MySpacePost from "../../layouts/MySpacePost";
import Notification from "../../layouts/Notifications";
import PostBloggerContent from "../../layouts/PostBloggerContent";
import PostBloggerMetaData from "../../layouts/PostBloggerMetaData";
import PostCarousel from "../../layouts/PostCarousel";
import PostDescription from "../../layouts/PostDescription";
import PostProvider from "../../layouts/PostProvider";
import Loader from "../../layouts/TextLoader";
import PostComment from "../PostComment/PostComment";
import PostHeader from "../PostHeader";
import PostNavigation from "../PostNavigation/PostNavigation";
import SignIn from "../../../components/loginpage/SignIn";
import EntryUserProfile from "../../../components/UserProfile/EntryUserProfile";

// import "../QRCode/styles.css";
import WelcomeOverlay from "../../layouts/WelcomeOverlay";
import EntryFooter from "../../footer/EntryFooter";
import LoginModal from "../../loginpage/LoginModal";
import GetPathsOverlay from "../../layouts/GetPathsOverlay";
import OptionsOverlay from "../../layouts/OptionsOverlay";
import FeedUserProfile from '../../UserProfile/FeedUserProfile'
const data = require("../../../data/data.json");
const apiName = require("../../../data/apiList.json");
const getUserDataWithoutAuth = functions.httpsCallable(
  apiName.getUserDataWithoutAuth
);
const getAnonymousUserData = functions.httpsCallable(
  apiName.getAnonymousUserData
);

class PostItemData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: this.props.post,
      selectedUser: this.props.collabUser ? this.props.collabUser : [],
      collaboratorNotPosted: false,
      showLoader: false,
      selectedIndex: -1,
      phoneNumberExists: false,
      width: window.innerWidth,
      postOwner: null,
      isWelcomeOverlay: false,
      showLogin: false,
      showSignUpPopup: true,
      showSignIn: false,
      showGetPaths: false,
      showOptionOverlay: false,
      selectedPostId: "",
      isSelectedPostPrivate: false,
      unlinkedPostId: "",
      showDoneScreen: false
    };
  }

  componentDidMount() {
    // const { postOwner } = this.state;
    if (window.addthis) {
      window.addthis.init();
      window.addthis.update("share", "url", window.location.href);
    }
    const { post, postData, userInfo } = this.props;
    const userId = postData.userId;
    this.setState({
      post: post,
    });
    if (!isEmpty(this.state.selectedUser)) {
      if (
        this.props.post.coOwners &&
        this.props.post.coOwners.approved.length > 0
      ) {
        this.props.post.coOwners.approved.map((coOwner, index) => {
          if (coOwner.userId === this.state.selectedUser.userId) {
            return this.handleClick(coOwner, index);
          } else {
            return null;
          }
        });
      }
    }

    this.setNavigationToTop("feed-navigation");
    window.addEventListener("resize", this.updateDimensions);
    if(postData && postData.postOwner) {
      const postOwner = {
        name: postData.postOwner.name,
        photoURL: postData.postOwner.photoURL,
        pathUserName: postData.postOwner.pathUserName,
        userId: postData.postOwner.userId,
      };
      this.setState({ postOwner: postOwner });
      if(isEmpty(userInfo)) {
        setTimeout(() => {
          this.showWelcomeOverlay();
        },4000);
      }
    } else {
      getUserDataWithoutAuth({
        userId,
        apiKey: data.apiKey,
        source: data.apiSource,
      })
        .then((response) => {
          const postOwner = {
            name: response.data.userName,
            photoURL: response.data.profilePhotoUrl,
            pathUserName: response.data.pathUserName,
            userId: userId,
          };
          this.setState({ postOwner: postOwner });
          if(isEmpty(userInfo)) {
            setTimeout(() => {
              this.showWelcomeOverlay();
            },4000);
          }          
        })
        .catch((error) => {
          getAnonymousUserData({
            anonymousUserId: userId,
            apiKey: data.apiKey,
            source: data.apiSource,
          }).then((response) => {
            const postOwner = {
              name: response.data.displayName,
              photoURL: response.data.profilePhotoUrls.original,
              pathUserName: response.data.userName,
              userId: userId,
            };
            this.setState({ postOwner: postOwner });
            if(isEmpty(userInfo)) {
              setTimeout(() => {
                this.showWelcomeOverlay();
              },4000);
            }
          }).catch((error) => {});
        });
    }
  }

  showWelcomeOverlay = () => {
    this.setState({ isWelcomeOverlay: true });
  };

  manageWelcomePopup = () => {
    this.setState({ isWelcomeOverlay: !this.state.isWelcomeOverlay });
  };

  showLoginModal = (event) => {
    this.setState({ showLogin: true, isWelcomeOverlay: false });
  };

  showSignInModal = () => {
    this.setState({ showSignIn: true, isWelcomeOverlay: false });
  };

  updateShowLogin = () => {
    this.setState({
      showLogin: !this.state.showLogin,
    });
  };

  updateShowSignIn = () => {
    this.setState({
      showSignIn: !this.state.showSignIn,
    });
  };

  updateDoneScreen = () => {
    this.setState({
      showDoneScreen: !this.state.showDoneScreen,
    });
  };

  manageGetPaths = () => {
    this.setState({
      showGetPaths: !this.state.showGetPaths,
    });
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  setNavigationToTop = (className) => {
    let element = document.getElementsByClassName(className);
    if (element) {
      var headerOffset = 30;
      var elementPosition = element[0].getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  /* When press on the coOwner,it gets the post of particular coOwner */
  onPressCoOwner = (post) => {
    if (this.state.collaboratorNotPosted) {
      this.setState({
        collaboratorNotPosted: false,
      });
    }
    this.setState({
      post: post,
      showLoader: false,
    });
  };

  resetDefaultUser = (isReset) => {
    if (isReset) {
      if (this.state.collaboratorNotPosted) {
        this.setState({
          collaboratorNotPosted: false,
        });
      }
      this.setState({
        post: this.props.post,
        showLoader: false,
      });
    }
  };

  /* When coOwer's number of post is not empty and owner click on that, it gives the 
  coOwner's post */
  handleClick = (coOwner, postIndex) => {
    const { userInfo } = this.props;

    const getPostsById = userInfo.user
      ? functions.httpsCallable("getPostByIdES")
      : functions.httpsCallable("getPostByIdWithoutAuth");
    GAtrackPageDetails();

    this.setState({
      selectedUser: coOwner,
      showLoader: true,
      selectedIndex: postIndex,
    });
    if (coOwner != null && coOwner.postId) {
      getPostsById({
        postId: coOwner.postId,
        includeComments: true,
        apiKey: data.apiKey,
        source: data.apiSource,
      })
        .then((res) => {
          if (!isEmpty(res)) {
            this.onPressCoOwner(res.data);
          } else {
            this.setState({
              showLoader: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          });
        });
    } else if (postIndex === -1) {
      this.resetDefaultUser(true);
    } else if (!coOwner.postId) {
      this.setState({
        collaboratorNotPosted: true,
        showLoader: false,
      });
    }
  };

  //Updating falg to show phone number warning
  showPhoneNumberExists = () => {
    this.setState({ phoneNumberExists: true });
  };

  //Closing phone number warning
  closePhoneNumberWarning = () => {
    this.setState({ phoneNumberExists: false });
  };

  manageOptionOverlay = (postId, isPrivate) => {
    this.setState({
      showOptionOverlay: !this.state.showOptionOverlay,
      selectedPostId: postId,
      isSelectedPostPrivate: isPrivate
    });
  };

  hideUnlinkedPost = (coOwnerId) => {
    this.setState({
      unlinkedPostId: coOwnerId
    });
  }

  render() {
    const {
      post,
      isLoadingData,
      isError,
      collaboratorNotPosted,
      selectedUser,
      showLoader,
      selectedIndex,
      postOwner,
      isWelcomeOverlay,
      showLogin,
      showSignIn,
      showGetPaths,
      showOptionOverlay,
      isSelectedPostPrivate,
      selectedPostId,
      unlinkedPostId,
      showDoneScreen
    } = this.state;
    const {
      transition,
      nextPageToken,
      posts,
      getPostByPostId,
      userInfo,
      additionalUserInfo,
      isSinglePost,
      postData,
    } = this.props;
    const imageUrl =
      additionalUserInfo && additionalUserInfo.user
        ? additionalUserInfo.user.profilePhotoUrls.original
        : null;

    const windowURL = window.location.href;
    const entryPage = windowURL.includes("posts");
    let entryCreatorFirstName = "", entryTitle= "";
    if(postOwner && postOwner.name) {
      const entryCreatorName = (postOwner.name).split(" ");
      entryCreatorFirstName = entryCreatorName[0];
      if(post && post.title) {
        entryTitle = post.title;
      }
    }    
    if (isLoadingData) {
      return <Loader />;
    } else if (isError) {
      return (
        <Fragment>
          <Error />
        </Fragment>
      );
    } else {
      if (isSinglePost) {
        return (
          <Fragment>
            {isEmpty(userInfo.user) && isWelcomeOverlay && (
              <WelcomeOverlay 
                show={isWelcomeOverlay} 
                manageWelcomePopup={this.manageWelcomePopup} 
                entryCreatorFirstName={entryCreatorFirstName} 
                entryTitle={entryTitle}
                showLoginModal={this.showLoginModal}
                showSignInModal={this.showSignInModal}
              />
            )}
            {(isEmpty(userInfo) || showDoneScreen) && showLogin && (
              <LoginModal
                show={true}
                updateShowLogin={this.updateShowLogin}
                showPopup={true}
                showSignUpPopup={this.state.showSignUpPopup}
                manageGetPaths={this.manageGetPaths}
                isWelcomeOverlay={true}
                updateWelcomePopUp={this.manageWelcomePopup}
                //manageGetPaths={this.manageGetPaths}
                entryCreatorFirstName={entryCreatorFirstName}
                showDoneScreen={showDoneScreen}
                updateDoneScreen={this.updateDoneScreen}
              />
            )}
            {(isEmpty(userInfo) || showDoneScreen) && showSignIn && (
              <LoginModal
                show={showSignIn}
                updateShowLogin={this.updateShowSignIn}
                manageGetPaths={this.manageGetPaths}
                isWelcomeOverlay={true}
                updateWelcomePopUp={this.manageWelcomePopup}
                //manageGetPaths={this.manageGetPaths}
                entryCreatorFirstName={entryCreatorFirstName}
                showDoneScreen={showDoneScreen}
                updateDoneScreen={this.updateDoneScreen}
              />
            )}
            {showGetPaths && (
              <GetPathsOverlay show={showGetPaths} manageGetPaths={this.manageGetPaths} />
            )}
            <div className="row justify-content-md-center entry-page-desktop-margin">
              <div className="paths-user-profile col-md-3">
                {entryPage && <EntryUserProfile post={post} postHeader={false} transition={transition} postData={postData} postOwner={postOwner}/>}
                {!entryPage && <FeedUserProfile />}
              </div>
              <div className="sigle-feed-post col-md-6">
                {this.state.phoneNumberExists && (
                  <Notification
                    type="warning"
                    message={getProperty("label.phone-number-exists")}
                    closeModal={this.closePhoneNumberWarning}
                  />
                )}
                <InviteHeader
                  isMainFeed={false}
                  imageUrl={imageUrl}
                  owner={post && post.auditInfo.createdBy}
                  post={post}
                  userInfo={this.props.userInfo}
                  showPhoneNumberExists={this.showPhoneNumberExists}
                  transition={transition}
                  postData={postData}
                  postOwner={postOwner}
                />

                <PostNavigation
                  {...this.props}
                  post={this.props.post}
                  isArrowVisible={true}
                  handleClick={this.handleClick}
                  onPressCollaborator={this.onPressCoOwner}
                  resetDefaultUser={this.resetDefaultUser}
                  transition={transition}
                  nextPageToken={nextPageToken}
                  posts={posts}
                  isPathMain={false}
                  selectedIndex={selectedIndex}
                  selectedUser={this.state.selectedUser}
                  getPosts={getPostByPostId}
                  showPhoneNumberExists={this.showPhoneNumberExists}
                  isSinglePost={isSinglePost}
                  unlinkedPostId={unlinkedPostId}
                  postOwner={postOwner}
                />
                {!collaboratorNotPosted ? (
                  <PostHeader
                    post={post}
                    selectedUser={selectedUser}
                    isClickable={false}
                    transition={transition}
                    manageOptionOverlay={(postId, isPrivate) => this.manageOptionOverlay(postId, isPrivate)}
                  />
                ) : (
                  <div className="post-header-main">
                    <div className="post-header-text">
                      <span className="post-user-name">
                        {selectedUser.displayName}
                      </span>
                    </div>
                  </div>
                )}

                {!collaboratorNotPosted ? (
                  showLoader ? (
                    <div className="nopost-container">
                      <Loader />
                    </div>
                  ) : (
                    <div className="feed-post-carousel">
                      {post.provider && (
                        <PostProvider
                          provider={post.provider}
                          owner={post.owner}
                        />
                      )}
                      <PostCarousel isClickable={false} post={post} />
                    </div>
                  )
                ) : showLoader ? (
                  <div className="nopost-container">
                    <Loader />
                  </div>
                ) : (
                  <div className="nopost-container">
                    <h1>
                      {getProperty("label.waiting-for-1")}{" "}
                      {selectedUser.displayName}{" "}
                      {getProperty("label.waiting-for-2")}
                    </h1>
                  </div>
                )}
                {!collaboratorNotPosted
                  ? post.description &&
                    !showLoader && (
                      <PostDescription
                        post={post}
                        isClickable={false}
                        description={post.description}
                      />
                    )
                  : ""}
                {!collaboratorNotPosted
                  ? post.htmlContent && (
                      <Fragment>
                        <PostBloggerContent isClickable={false} post={post} />
                        <PostBloggerMetaData isClickable={false} post={post} />
                      </Fragment>
                    )
                  : ""}

                {!collaboratorNotPosted
                  ? post.myspace && (
                      <MySpacePost isClickable={false} post={post} />
                    )
                  : ""}

                {!collaboratorNotPosted && !showLoader && (
                  <PostComment
                    transition={transition}
                    post={post}
                    showPhoneNumberExists={this.showPhoneNumberExists}
                  />
                )}

                <EntryFooter />

                {showOptionOverlay && (
                  <OptionsOverlay 
                    show={showOptionOverlay} 
                    manageOptionOverlay={this.manageOptionOverlay} 
                    userInfo={userInfo} 
                    selectedPostId={selectedPostId} 
                    selectedUserId={transition.params().userId} 
                    isSelectedPostPrivate={isSelectedPostPrivate}
                    postOwner={postOwner}
                    post={post}
                    transition={transition}
                    hideUnlinkedPost={this.hideUnlinkedPost}
                    isPathMain={false}
                    showUpdatedPost={() => this.props.showUpdatedPost()}
                    postData={postData}
                  />
                )}
              </div>
              <div className="paths-sign-up-content col-md-3">
                <SignIn />
              </div>
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <div className="row justify-content-md-center">
              <div className="paths-user-profile col-md-3">
                {entryPage && <EntryUserProfile transition={transition} postOwner={postOwner}/>}
                {!entryPage && <FeedUserProfile />}
              </div>
              <div className="feed-post col-md-6">
                {this.state.phoneNumberExists && (
                  <Notification
                    type="warning"
                    message={getProperty("label.phone-number-exists")}
                    closeModal={this.closePhoneNumberWarning}
                  />
                )}
                <InviteHeader
                  isMainFeed={false}
                  imageUrl={imageUrl}
                  owner={post && post.auditInfo.createdBy}
                  post={post}
                  userInfo={this.props.userInfo}
                  showPhoneNumberExists={this.showPhoneNumberExists}
                  postOwner={postOwner}
                />

                <PostNavigation
                  {...this.props}
                  post={this.props.post}
                  isArrowVisible={true}
                  handleClick={this.handleClick}
                  onPressCollaborator={this.onPressCoOwner}
                  resetDefaultUser={this.resetDefaultUser}
                  transition={transition}
                  nextPageToken={nextPageToken}
                  posts={posts}
                  isPathMain={false}
                  selectedIndex={selectedIndex}
                  selectedUser={this.state.selectedUser}
                  getPosts={getPostByPostId}
                  showPhoneNumberExists={this.showPhoneNumberExists}
                  showUpdatedPost={() => this.props.showUpdatedPost()}
                  postOwner={postOwner}
                />
                {!collaboratorNotPosted ? (
                  <PostHeader
                    post={post}
                    selectedUser={selectedUser}
                    isClickable={false}
                    manageOptionOverlay={(postId, isPrivate) => this.manageOptionOverlay(postId, isPrivate)}
                  />
                ) : (
                  <div className="post-header-main">
                    <div className="post-header-text">
                      <span className="post-user-name">
                        {selectedUser.displayName}
                      </span>
                    </div>
                  </div>
                )}

                {!collaboratorNotPosted ? (
                  showLoader ? (
                    <div className="nopost-container">
                      <Loader />
                    </div>
                  ) : (
                    <div className="feed-post-carousel">
                      {post.provider && (
                        <PostProvider
                          provider={post.provider}
                          owner={post.owner}
                        />
                      )}
                      <PostCarousel isClickable={false} post={post} />
                    </div>
                  )
                ) : showLoader ? (
                  <div className="nopost-container">
                    <Loader />
                  </div>
                ) : (
                  <div className="nopost-container">
                    <h1>
                      {getProperty("label.waiting-for-1")}{" "}
                      {selectedUser.displayName}{" "}
                      {getProperty("label.waiting-for-2")}
                    </h1>
                  </div>
                )}
                {!collaboratorNotPosted
                  ? post.description &&
                    !showLoader && (
                      <PostDescription
                        post={post}
                        isClickable={false}
                        description={post.description}
                      />
                    )
                  : ""}
                {!collaboratorNotPosted
                  ? post.htmlContent && (
                      <Fragment>
                        <PostBloggerContent isClickable={false} post={post} />
                        <PostBloggerMetaData isClickable={false} post={post} />
                      </Fragment>
                    )
                  : ""}

                {!collaboratorNotPosted
                  ? post.myspace && (
                      <MySpacePost isClickable={false} post={post} />
                    )
                  : ""}

                {!collaboratorNotPosted && !showLoader && (
                  <PostComment
                    transition={transition}
                    post={post}
                    showPhoneNumberExists={this.showPhoneNumberExists}
                  />
                )}

                <EntryFooter />

                {showOptionOverlay && (
                  <OptionsOverlay 
                    show={showOptionOverlay} 
                    manageOptionOverlay={this.manageOptionOverlay} 
                    userInfo={userInfo} 
                    selectedPostId={selectedPostId} 
                    selectedUserId={transition.params().userId} 
                    isSelectedPostPrivate={isSelectedPostPrivate}
                    postOwner={postOwner}
                    post={post}
                    transition={transition}
                    isPathMain={false}
                    showUpdatedPost={() => this.props.showUpdatedPost()}
                    //postOwner={postOwner}
                    postData={postData}
                  />
                )}
              </div>
              <div className="paths-sign-up-content col-md-3">
                <SignIn />
              </div>
            </div>
          </Fragment>
        );
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, {})(PostItemData);
