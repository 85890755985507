import "../../../styles/style.css";

import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { IMAGES } from "../../../constants/constants";
import { updateAdditionalUserInfo, updateUser } from "../../../ducks/actions";
import { auth, functions } from "../../../firebase/firebaseConfig";
import {
  addEditProfileLink,
  GAtrackPageDetails,
  getProperty,
  isValidPostData,
} from "../../../helper/helper";
import Header from "../../header/Header";
import Error from "../../layouts/Error";
import Notification from "../../layouts/Notifications";
import Loader from "../../layouts/TextLoader";
import Logout from "../PostNavigation/Logout";
import PostItemData from "./PostItemData";

const data = require("../../../data/data.json");
const apiName = require("../../../data/apiList.json");
const getUserDataES = functions.httpsCallable("getUserDataES");
const getUserDataWithoutAuth = functions.httpsCallable(
  apiName.getUserDataWithoutAuth
);

class PostItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postData: this.props.$stateParams,
      post: [],
      isLoadingData: false,
      isError: false,
      errorMessage: "",
      showLogin: false,
      phoneNumberExists: false,
      postOwner: null,
    };
  }

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    let self = this;
    const { postData } = self.state;
    this.unsubscribe = auth.onAuthStateChanged(function (user) {
      if (user) {
        addEditProfileLink(self.props.transition);
        self.props.updateUser({ user });
        if (isEmpty(self.props.additionalUserInfo)) {
          getUserDataES({ apiKey: data.apiKey, source: data.apiSource })
            .then((userData) => {
              self.props.updateAdditionalUserInfo(userData.data);
            })
            .catch((error) => {});
        }
      } else {
        self.props.updateUser({});
        self.props.updateAdditionalUserInfo({});
      }
      //if post is empty then only fetch or update post state
      if (isEmpty(self.state.post)) {
        if (isEmpty(postData.collabUserID)) {
          self.getPostByPostId(postData.postId);
        } else {
          if (postData.posts) {
            postData.posts.map((post, index) => {
              if (post.id === postData.postId) {
                self.setState({ post: post });
              }
            });
          }
        }
      }
    });
  };

  /* It gets the particular post with the help of postID  */
  getPostByPostId = (postId) => {
    const { userInfo } = this.props;
    const { postData } = this.state;
    const userId = postData.userId;
    this.setState({ isLoadingData: true });
    const getPostsById = userInfo.user
      ? functions.httpsCallable("getPostByIdES")
      : functions.httpsCallable("getPostByIdWithoutAuth");
    GAtrackPageDetails();
    getPostsById({
      postId: postId,
      includeComments: true,
      apiKey: data.apiKey,
      source: data.apiSource,
    })
      .then((res) => {
        this.setState({
          post: res.data,
          isLoadingData: false,
          errorMessage: "",
          isError: false,
        });
      })
      .catch((error) => {
        const { code, message } = error;
        const errorMessage = getProperty(`labelError.${code}`) || message;
        getUserDataWithoutAuth({
          userId,
          apiKey: data.apiKey,
          source: data.apiSource,
        })
          .then((response) => {
            const postOwner = {
              name: response.data.userName,
              photoURL: response.data.profilePhotoUrl,
              pathUserName: response.data.pathUserName,
              userId: userId,
            };
            this.setState({
              errorMessage: errorMessage,
              isLoadingData: false,
              isError: true,
              postOwner: postOwner,
            });
          })
          .catch((error) => {
            this.setState({
              errorMessage: errorMessage,
              isLoadingData: false,
              isError: true,
            });
          });
      });
  };

  updateShowLogin = (msgType, isMessage, message) => {
    this.setState({ showLogin: !this.state.showLogin });
  };

  //Updating falg to show phone number warning
  showPhoneNumberExists = () => {
    this.setState({ phoneNumberExists: true });
  };

  showLoginModal = (event) => {
    this.setState({ showLogin: true });
  };

  //Closing phone number warning
  closePhoneNumberWarning = () => {
    this.setState({ phoneNumberExists: false });
  };

  showUpdatedPost = () => {
    const { postData } = this.state;
    this.getPostByPostId(postData.postId);
  };

  render() {
    const {
      post,
      postData,
      isLoadingData,
      isError,
      errorMessage,
      phoneNumberExists,
      postOwner,
    } = this.state;
    const { userInfo, transition } = this.props;

    let entryCreatorFirstName = "";
    if (post && post.auditInfo && post.auditInfo.createdBy) {
      const entryCreatorName = post.auditInfo.createdBy.split(" ");
      entryCreatorFirstName = entryCreatorName[0];
    }
    if (isLoadingData) {
      return (
        <Fragment>
          <Header
            userInfo={userInfo}
            entryCreatorFirstName={entryCreatorFirstName}
          />
          <img
            alt="master shadow img"
            src={IMAGES.MASTER_SHADOW_IMAGE.default}
            className="feed-header-section-dividor"
          />
          <Loader />
        </Fragment>
      );
    } else if (isError) {
      return (
        <Fragment>
          <Header
            userInfo={userInfo}
            entryCreatorFirstName={entryCreatorFirstName}
          />
          <img
            alt="master shadow img"
            src={IMAGES.MASTER_SHADOW_IMAGE.default}
            className="feed-header-section-dividor"
          />
          {errorMessage ? (
            <Error
              message={errorMessage}
              postOwner={postOwner}
              userInfo={userInfo}
              transition={transition}
              postData={postData}
            />
          ) : (
            <Error
              message={getProperty("label.refresh-again")}
              showRefresh={true}
            />
          )}
          {phoneNumberExists && (
            <Notification
              type="warning"
              message={getProperty("label.phone-number-exists")}
              closeModal={this.closePhoneNumberWarning}
            />
          )}
          <Logout
            isLoadingData={isLoadingData}
            userInfo={userInfo}
            transition={transition}
          ></Logout>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Header
            userInfo={userInfo}
            entryCreatorFirstName={entryCreatorFirstName}
          />
          <img
            alt="master shadow img"
            src={IMAGES.MASTER_SHADOW_IMAGE.default}
            className="feed-header-section-dividor"
          />
          {!post && <Error message={getProperty("label.no-post")} />}
          {post && isValidPostData(post) && (
            <PostItemData
              post={post}
              transition={transition}
              nextPageToken={postData.nextPageToken}
              posts={postData.posts}
              collabUserID={
                postData.collabUserID ? postData.collabUserID.postId : ""
              }
              collabUser={postData.collabUserID ? postData.collabUserID : ""}
              userInfo={userInfo}
              isInvite={postData.isInvite}
              getPostByPostId={this.getPostByPostId}
              isSinglePost={true}
              postData={postData}
              showUpdatedPost={this.showUpdatedPost}
            />
          )}
          <Logout
            isLoadingData={isLoadingData}
            userInfo={userInfo}
            transition={transition}
          ></Logout>
          {/* <SignIn></SignIn> */}
        </Fragment>
      );
    }
  }
}

PostItem.proptype = {
  $stateParams: PropTypes.object,
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
  updateAdditionalUserInfo: (user) => dispatch(updateAdditionalUserInfo(user)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostItem);
