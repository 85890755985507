import "../../styles/style.css";

import firebase from "firebase/app";
import isEmpty from "lodash/isEmpty";
import React, { Component } from "react";
import { connect } from "react-redux";

import { updateAdditionalUserInfo, updateUser } from "../../ducks/actions";
import { firebaseApp, functions } from "../../firebase/firebaseConfig";
import { addCowner } from "../../helper/helper";
import {
  GAtrackLoginDetails,
  GAtrackUserIdDetails,
  getProperty,
} from "../../helper/helper";
import SignUp from "./SignUp";
// import { useNavigate } from "react-router-dom";

const data = require("../../data/data.json");
const apiName = require("../../data/apiList.json");
const getUserDataES = functions.httpsCallable(apiName.getUserDataES);
const addUser = functions.httpsCallable(apiName.addUser);
const saveFBTimelinePosts = functions.httpsCallable(
  apiName.saveFBTimelinePosts
);
const saveProviderToken = functions.httpsCallable(apiName.saveProviderToken);
const setPastPullerResourceStatus = functions.httpsCallable(
  apiName.setPastPullerResourceStatus
);
const mailchimpSubscribe = functions.httpsCallable(apiName.mailchimpSubscribe);
const searchUserByUsername = functions.httpsCallable(
  apiName.searchUserByUsername
);
const sendOtpToUserFor2FA = functions.httpsCallable(
  apiName.sendOtpToUserFor2FA
);
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      password: "",
      error: "",
      isLoggedIn: false,
      showPopup: false,
      showLoader: false,
      onButtonLoader: "",
      emailErrors: "",
      isValidPin: false,
      pinError: "",
      enteredPin: "",
      resendPin: "",
      pin: "",
      isResendPin: false,
      isEnteredPinValid: false,
    };
  }

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  handleEmailChange = (evt) => {
    this.setState({
      emailId: evt.target.value,
      emailErrors: "",
      error: "",
    });
  };

  handlePasswordChange = (evt) => {
    this.setState({
      password: evt.target.value,
      passwordErrors: "",
      error: "",
    });
  };

  handlePinChange = (evt) => {
    this.setState({
      enteredPin: evt.target.value,
      pinError: "",
    });
  };

  onKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      this.loginUser();
    }
  };

  validateEmailAddress(emailId) {
    const expression =
      /^\w+([\.-]?[+\w-._]+)+@\w+([+\.:._-]?\w+)+(\.[a-zA-Z0-9]{2,})+$/;
    return expression.test(String(emailId).toLowerCase());
  }

  validateUserName = (username) => {
    const regex = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
    return !regex.test(username);
  };

  /* Do validations on Email, Mobile number, password */
  handleValidation() {
    let { emailId, password, errors } = this.state;
    let isValid = true;
    //Email
    if (emailId.includes("@")) {
      if (!emailId) {
        isValid = false;
        errors = getProperty("login.enter-email");
        this.setState({
          emailErrors: errors,
          showLoader: false,
        });
      } else if (emailId) {
        const lastAtPos = emailId.lastIndexOf("@");
        const lastDotPos = emailId.lastIndexOf(".");
        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            emailId.indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            emailId.length - lastDotPos > 2
          )
        ) {
          isValid = false;
          errors = getProperty("login.not-valid-email");
          this.setState({
            emailErrors: errors,
            showLoader: false,
          });
        }
      } else if (this.validateEmailAddress(emailId)) {
        isValid = true;
      }
    } else {
      if (!emailId) {
        isValid = false;
        errors = getProperty("login.enter-username");
        this.setState({ emailErrors: errors });
      } else if (!this.validateUserName(emailId)) {
        isValid = false;
        errors = getProperty("login.not-valid-username");
        this.setState({ emailErrors: errors, showLoader: false, });
      } else {
        errors = "";
        this.setState({ emailErrors: errors });
      }
    }

    if (!password) {
      isValid = false;
      errors = getProperty("login.enter-password");
      this.setState({
        passwordErrors: errors,
        showLoader: false,
      });
    } else if (password.length < 6) {
      isValid = false;
      errors = getProperty("login.warning-password");
      this.setState({
        passwordErrors: errors,
        showLoader: false,
      });
    }
    if (!isValid) {
      this.setState({ loader: false });
    }
    return isValid;
  }

  addUserAsCoOwner = (user) => {
    let userData = JSON.parse(JSON.stringify(user));
    const { post, isInvite, invitationId, additionalUserInfo } = this.props;

    let coOwnerType = "",
      isMessage = null,
      displayMessage = "";
    addCowner(post, userData, additionalUserInfo, isInvite, invitationId)
      .then((addCoownerData) => {
        if (addCoownerData) {
          coOwnerType = addCoownerData.type;
          isMessage = addCoownerData.isMessage;
          displayMessage = addCoownerData.displayMessage;
        }
        this.setState({ isLoggedIn: true });
        this.props.updateShowLogin(coOwnerType, isMessage, displayMessage);
      })
      .catch((error) => {
        coOwnerType = "";
        isMessage = null;
        displayMessage = "";
      });
  };

  isNewCoOwner = (user) => {
    const { post } = this.props;
    const { coOwners } = post;
    if (isEmpty(coOwners)) {
      return true;
    }
    const { approved } = coOwners;

    return (
      !approved.some((item) => item.userId === user.uid) &&
      post.owner.userId !== user.uid
    );
  };

  resendOTP = () => {
    let { isResendPin } = this.state;
    const otp = Math.floor(100000 + Math.random() * 900000);
    if (!isResendPin) {
      this.setState({
        pin: otp,
        isEnteredPinValid: true,
        resendPin: getProperty("login.resent"),
        isResendPin: true,
      });
      setTimeout(() => {
        this.setState({ isEnteredPinValid: false, pin: "" });
      }, 600000);
      setTimeout(() => {
        this.setState({
          resendPin: getProperty("login.resend"),
          isResendPin: false,
          pinError: "",
        });
      }, 5000);
      let param = {
        otp: otp,
        email: this.state.emailId,
        apiKey: data.apiKey,
      };
      sendOtpToUserFor2FA(param).then().catch();
    } else {
      this.setState({
        pinError: getProperty("login.can-not-resend"),
      });
    }
  };

  signInWithEmail = () => {
    const { emailId, password } = this.state;
    const { post } = this.props;
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(emailId, password)
      .then((response) => {
        GAtrackLoginDetails(data.LOGIN_with_EMAIL);
        GAtrackUserIdDetails(response.user.uid);
        this.props.updateUser(JSON.parse(JSON.stringify(response)));
        getUserDataES({ apiKey: data.apiKey, source: data.apiSource })
          .then((userData) => {
            this.props.updateAdditionalUserInfo(userData.data);
            if (post) {
              if (this.isNewCoOwner(response.user)) {
                this.addUserAsCoOwner(response.user);
              } else {
                this.setState({ isLoggedIn: true });
                this.props.updateShowLogin(
                  "info",
                  true,
                  data.invitaionAlreadyAccepted
                );
              }
            } else {
              this.setState({ isLoggedIn: true });
              this.props.updateShowLogin("", null, " ");
            }
          })
          .catch((error) => {});
        this.props.manageGetPaths();
        const updatedValue = parseInt(localStorage.getItem(emailId)) + 1;
        localStorage.setItem(emailId, updatedValue);
      })
      .catch((error) => {
        if (parseInt(localStorage.getItem(emailId)) === 0) {
          localStorage.removeItem(emailId);
        }
        this.setState({
          isValidPin: false,
          enteredPin: "",
        });
        this.updateErrorMessage(error);
      });
  };

  /* When user LogIn with Firebase auth and JSON Conversion */
  loginUser = () => {
    this.setState({ showLoader: true, error: "" });

    if (this.handleValidation()) {
      const {
        emailId,
        password,
        isValidPin,
        pin,
        enteredPin,
        isEnteredPinValid,
      } = this.state;
      const { post, manageEmailId } = this.props;
      manageEmailId(emailId);
      if (emailId.includes("@")) {
        if (localStorage.getItem(emailId)) {
          if (isValidPin) {
            if (
              isEnteredPinValid &&
              pin &&
              enteredPin &&
              parseInt(enteredPin) === pin
            ) {
              this.signInWithEmail();
            } else {
              this.setState({
                pinError: getProperty("login.not-valid-pin"),
                showLoader: false,
              });
            }
          } else {
            this.signInWithEmail();
          }
        } else {
          const otp = Math.floor(100000 + Math.random() * 900000);
          localStorage.setItem(emailId, 0);
          this.setState({
            isValidPin: true,
            showLoader: false,
            isEnteredPinValid: true,
            pin: otp,
            resendPin: getProperty("login.resend"),
          });
          setTimeout(() => {
            this.setState({ pin: "", isEnteredPinValid: false });
          }, 600000);
          let param = {
            otp: otp,
            email: emailId,
            apiKey: data.apiKey,
          };
          sendOtpToUserFor2FA(param).then().catch();
        }
      } else {
        searchUserByUsername({
          userName: emailId,
          apiKey: data.apiKey,
        })
          .then((resp) => {
            const data = resp.data;
            if (data) {
              const users = data.users;
              if (users && users.length > 0) {
                const userEmail = users[0].email;
                firebaseApp
                  .auth()
                  .signInWithEmailAndPassword(userEmail, password)
                  .then((response) => {
                    GAtrackLoginDetails(data.LOGIN_with_EMAIL);
                    GAtrackUserIdDetails(response.user.uid);
                    this.props.updateUser(JSON.parse(JSON.stringify(response)));
                    getUserDataES({
                      apiKey: data.apiKey,
                      source: data.apiSource,
                    })
                      .then((userData) => {
                        this.props.updateAdditionalUserInfo(userData.data);
                        if (post) {
                          if (this.isNewCoOwner(response.user)) {
                            this.addUserAsCoOwner(response.user);
                          } else {
                            this.setState({ isLoggedIn: true });
                            this.props.updateShowLogin(
                              "info",
                              true,
                              data.invitaionAlreadyAccepted
                            );
                          }
                        } else {
                          this.setState({ isLoggedIn: true });
                          this.props.updateShowLogin("", null, " ");
                        }
                      })
                      .catch((error) => {});
                    //add userId at the end of url for redirect to user profile page
                    //  const loginUserId = users[0].uid;
                    // window.location.href = "#/" + loginUserId;
                    this.props
                      .manageGetPaths
                      // loginUserId
                      ();
                  })
                  .catch((error) => {
                    this.updateErrorMessage(error);
                  });
              } else {
                const errors = getProperty("login.not-valid-username");
                this.setState({
                  error: errors,
                  showLoader: false,
                });
              }
            }
          })
          .catch((error) => {
            this.updateErrorMessage(error);
          });
      }
    }
  };

  socialLogin = (loginOption) => {
    let provider, token;
    const { post, isInvite, invitationId } = this.props;
    this.setState({ showLoader: true, error: "" });
    switch (loginOption) {
      case data.google:
        provider = new firebase.auth.GoogleAuthProvider();
        break;
      case data.facebook:
        provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope("public_profile");
        provider.addScope("user_posts");
        provider.addScope("email");
        break;
      case data.apple:
        provider = new firebase.auth.OAuthProvider(loginOption);
        provider.addScope("email");
        provider.addScope("name");
        break;
      default:
    }

    firebaseApp
      .auth()
      .signInWithPopup(provider)
      .then(async (response) => {
        if (response.additionalUserInfo.isNewUser) {
          let userData = JSON.parse(JSON.stringify(response.user));
          //set source value and invitation id in user data while ceating user
          userData.source = "WEB";
          if (isInvite) {
            userData.invitationId = invitationId;
          }
          if (loginOption === data.facebook) {
            token = response.credential.accessToken;
          }
          addUser({
            user: userData,
            apiKey: data.apiKey,
            source: data.apiSource,
          })
            .then(async (resp) => {
              this.trackSocialLoginDetails(loginOption, response.user);
              getUserDataES({ apiKey: data.apiKey, source: data.apiSource })
                .then((userData) => {
                  this.props.updateAdditionalUserInfo(userData.data);
                  if (post) {
                    if (this.isNewCoOwner(response.user)) {
                      this.addUserAsCoOwner(response.user);
                    } else {
                      this.setState({ isLoggedIn: true });
                      this.props.updateShowLogin(
                        "info",
                        true,
                        data.invitaionAlreadyAccepted
                      );
                    }
                  } else {
                    this.setState({ isLoggedIn: true });
                    this.props.updateShowLogin("", null, "");
                  }
                })
                .catch((error) => {
                  this.setState({ showLoader: false });
                });
              if (loginOption === data.facebook) {
                this.addFacebookTimelinePosts(token);
                this.saveProviderTokenInApp(token);
                this.setPastPullerResourceStatusInApp();
              }

              // let sendWelcomeEmail = await mailchimpSubscribe({
              await mailchimpSubscribe({
                apiKey: data.apiKey,
                source: data.apiSource,
              });
              this.props.updateUser(JSON.parse(JSON.stringify(response.user)));
            })
            .catch((error) => {
              this.updateErrorMessage(error);
            });
        } else {
          this.trackSocialLoginDetails(loginOption, response.user);
          getUserDataES({ apiKey: data.apiKey, source: data.apiSource })
            .then((userData) => {
              this.props.updateAdditionalUserInfo(userData.data);
              if (post) {
                if (this.isNewCoOwner(response.user)) {
                  this.addUserAsCoOwner(response.user);
                } else {
                  this.setState({ isLoggedIn: true });
                  this.props.updateShowLogin(
                    "info",
                    true,
                    data.invitaionAlreadyAccepted
                  );
                }
              } else {
                this.setState({ isLoggedIn: true });
                this.props.updateShowLogin("", null, "");
              }
            })
            .catch((error) => {
              this.updateErrorMessage(error);
            });
          this.props.updateUser(JSON.parse(JSON.stringify(response.user)));
        }
      })
      .catch((error) => {
        this.updateErrorMessage(error);
      });
  };

  updateErrorMessage = (error) => {
    //fetching error message based on code else showing default error message
    const { code } = error;
    const errorMessage =
      getProperty(`labelError.${code}`) ||
      getProperty("labelError.something-wrong");

    this.setState({
      error: errorMessage,
      showLoader: false,
    });
  };

  trackSocialLoginDetails = (loginOption, user) => {
    //tracking user id details
    if (user) {
      GAtrackUserIdDetails(user.uid);
    }

    //tracking user's login option
    if (loginOption === data.google) {
      GAtrackLoginDetails(data.LOGIN_with_GOOGLE);
    } else if (loginOption === data.facebook) {
      GAtrackLoginDetails(data.LOGIN_with_FACEBOOK);
    } else if (loginOption === data.apple) {
      GAtrackLoginDetails(data.LOGIN_with_APPLE);
    }
  };

  //Adding facebook posts to user's timeline on App
  addFacebookTimelinePosts = (token) => {
    let params = {
      accessToken: token,
      apiKey: data.apiKey,
      source: data.apiSource,
    };
    saveFBTimelinePosts(params);
  };

  //Updating facebook provide token in App
  saveProviderTokenInApp = (token) => {
    const providerDetails = {
      provider: data.facebook,
      currentToken: token,
      apiKey: data.apiKey,
      source: data.apiSource,
    };
    //calling saveProviderToken
    saveProviderToken(providerDetails);
  };

  //Updating status of Facebook PastPuller in App
  setPastPullerResourceStatusInApp = () => {
    const pastPullerProviderDetails = {
      provider: data.facebook,
      apiKey: data.apiKey,
      source: data.apiSource,
    };
    //calling setPastPullerResourceStatus
    setPastPullerResourceStatus(pastPullerProviderDetails);
  };

  /* To set user loggedIn */
  updateIsLoggedIn = () => {
    this.setState({
      isLoggedIn: true,
      showPopup: false,
    });
  };

  hidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  manageLoader = (loaderValue) => {
    this.setState({
      showLoader: loaderValue,
    });
  };

  render() {
    const {
      isLoggedIn,
      emailId,
      password,
      passwordErrors,
      emailErrors,
      error,
      showPopup,
      showLoader,
      isValidPin,
      pinError,
      resendPin,
      enteredPin,
    } = this.state;
    const {
      owner,
      invitationId,
      isInvite,
      showSignUpPopup,
      showDoneScreen,
      updateDoneScreen,
      isMainHeader,
      manageGetPaths,
      entryCreatorFirstName,
    } = this.props;
    
    if (showSignUpPopup) {
      return (
        <div className="paths-login-container">
          {!isLoggedIn && (
            <div>
              {showLoader && (
                <div className="login-overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              )}
              <div
                className="row login-options"
                style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
              >
                {showPopup && (
                  <div className="col-sm-12 col-xs-12 col-lg-12 line">
                    <h2 className="text-header text-center">
                      {getProperty("label.sign-in")}
                    </h2>
                    {owner && isInvite && (
                      <p className="text-success signup-text">
                        To make it simple to merge your memories with{" "}
                        {owner.name}'s and more!
                      </p>
                    )}
                    <input
                      className="btn-social-media"
                      type="emailId"
                      name="emailId"
                      value={emailId}
                      onChange={this.handleEmailChange}
                      onKeyDown={this.onKeyDown}
                      placeholder={getProperty("login.user-name")}
                      required
                    />
                    <p className="error">{emailErrors}</p>
                    <input
                      className="btn-social-media"
                      type="password"
                      name="password"
                      value={password}
                      onChange={this.handlePasswordChange}
                      onKeyDown={this.onKeyDown}
                      placeholder={getProperty("login.password")}
                      required
                    />
                    <p className="error">{passwordErrors}</p>
                    {error && <span className="error">{error}</span>}
                    <div className="login-btn-container">
                      <button
                        className="login-btn text-center"
                        onClick={this.loginUser}
                      >
                        {getProperty("label.login")}
                      </button>
                      <span
                        className="forgot-password-link"
                        onClick={this.props.updaForgotPasswordFlag}
                      >
                        {getProperty("login.forgot-password")}
                      </span>
                    </div>
                    <p className="signup-link-text-center">
                      {getProperty("login.new-user")}
                      <span
                        className="pl-1 sign-up-toggle"
                        onClick={this.togglePopup}
                      >
                        {getProperty("label.sign-up")}
                      </span>
                    </p>
                    {isValidPin && (
                      <div>
                        <p className="signup-pin-header">
                          {getProperty("label.sign-up-pin-heading")}
                        </p>
                        <p className="pin-heading">
                          {getProperty("label.validate")}
                        </p>
                        <p className="name-container">
                          <input
                            className="input-btn placeholder"
                            type="text"
                            name="enteredPin"
                            value={enteredPin}
                            onChange={this.handlePinChange}
                            placeholder={getProperty("login.6-digit-pin")}
                            id="pin"
                          />
                        </p>
                        <p className="error">{pinError}</p>
                        <div className="signup-modal">
                          <span
                            className="signup-btn text-center"
                            onClick={this.loginUser}
                          >
                            {getProperty("label.confirm")}
                          </span>
                        </div>
                        <p
                          className="resend-pin-button"
                          onClick={this.resendOTP}
                        >
                          {resendPin}
                        </p>
                      </div>
                    )}
                  </div>
                )}

                <div className="col-sm-12 col-xs-12 col-lg-12 align-items-center line">
                  {!showPopup && (
                    <SignUp
                      updateIsLoggedIn={this.updateIsLoggedIn}
                      hidePopup={this.hidePopup}
                      post={this.props.post}
                      updateShowLogin={this.props.updateShowLogin}
                      isInvite={this.props.isInvite}
                      invitationId={invitationId}
                      owner={owner}
                      showPhoneNumberExists={this.props.showPhoneNumberExists}
                      showDoneScreen={showDoneScreen}
                      updateDoneScreen={updateDoneScreen}
                      isMainHeader={isMainHeader}
                      manageLoader={(loaderValue) =>
                        this.manageLoader(loaderValue)
                      }
                      togglePopup={this.togglePopup}
                      manageGetPaths={manageGetPaths}
                      entryCreatorFirstName={entryCreatorFirstName}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="paths-login-container">
          {!isLoggedIn && (
            <div>
              {showLoader && (
                <div className="login-overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              )}
              <div
                className="row login-options"
                style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
              >
                {!showPopup && (
                  <div className="col-sm-12 col-xs-12 col-lg-12 line">
                    <h2 className="text-header text-center">
                      {getProperty("label.sign-in")}
                    </h2>
                    {owner && isInvite && (
                      <p className="text-success signup-text">
                        To make it simple to merge your memories with{" "}
                        {owner.name}'s and more!
                      </p>
                    )}
                    <input
                      className="btn-social-media"
                      type="emailId"
                      name="emailId"
                      value={emailId}
                      onChange={this.handleEmailChange}
                      onKeyDown={this.onKeyDown}
                      placeholder={getProperty("login.user-name")}
                      required
                    />
                    <p className="error">{emailErrors}</p>
                    <input
                      className="btn-social-media"
                      type="password"
                      name="password"
                      value={password}
                      onChange={this.handlePasswordChange}
                      onKeyDown={this.onKeyDown}
                      placeholder={getProperty("login.password")}
                      required
                    />
                    <p className="error">{passwordErrors}</p>
                    {error && <span className="error">{error}</span>}
                    <div className="login-btn-container">
                      <button
                        className="login-btn text-center"
                        onClick={this.loginUser}
                      >
                        {getProperty("label.login")}
                      </button>
                      <span
                        className="forgot-password-link"
                        onClick={this.props.updaForgotPasswordFlag}
                      >
                        {getProperty("login.forgot-password")}
                      </span>
                    </div>
                    <p className="signup-link-text-center">
                      {getProperty("login.new-user")}
                      <span
                        className="pl-1 sign-up-toggle"
                        onClick={this.togglePopup}
                      >
                        {getProperty("label.sign-up")}
                      </span>
                    </p>
                    {isValidPin && (
                      <div>
                        <p className="signup-pin-header">
                          {getProperty("label.sign-up-pin-heading")}
                        </p>
                        <p className="pin-heading">
                          {getProperty("label.validate")}
                        </p>
                        <p className="name-container">
                          <input
                            className="input-btn placeholder"
                            type="text"
                            name="enteredPin"
                            value={enteredPin}
                            onChange={this.handlePinChange}
                            placeholder={getProperty("login.6-digit-pin")}
                            id="pin"
                          />
                        </p>
                        <p className="error">{pinError}</p>
                        <div className="signup-modal">
                          <span
                            className="signup-btn text-center"
                            onClick={this.loginUser}
                          >
                            {getProperty("label.confirm")}
                          </span>
                        </div>
                        <p
                          className="resend-pin-button"
                          onClick={this.resendOTP}
                        >
                          {resendPin}
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {showPopup && (
                  <div className="col-sm-12 col-xs-12 col-lg-12 align-items-center line">
                    <SignUp
                      updateIsLoggedIn={this.updateIsLoggedIn}
                      hidePopup={this.hidePopup}
                      post={this.props.post}
                      updateShowLogin={this.props.updateShowLogin}
                      isInvite={this.props.isInvite}
                      invitationId={invitationId}
                      owner={owner}
                      showPhoneNumberExists={this.props.showPhoneNumberExists}
                      showDoneScreen={showDoneScreen}
                      updateDoneScreen={updateDoneScreen}
                      isMainHeader={isMainHeader}
                      manageLoader={(loaderValue) =>
                        this.manageLoader(loaderValue)
                      }
                      togglePopup={this.togglePopup}
                      manageGetPaths={manageGetPaths}
                      entryCreatorFirstName={entryCreatorFirstName}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, {
  updateUser: updateUser,
  updateAdditionalUserInfo: updateAdditionalUserInfo,
})(Login);
