import React, { memo } from "react";
import { Modal, ModalBody, CloseButton } from "react-bootstrap";
import CollabTagOperations from "./CollabTagOperations";

const CollabTagModal = (props) => {
  return (
    <div>
      <Modal
        show={props.show}
        dialogClassName="modal-lg custom-dialog"
        onHide={props.manageCollabTag}
        {...props}
        size="lg"
        backdrop="static"
      >
        <ModalBody>
          <CollabTagOperations post={props.post} transition={props.transition} mobileOS={props.mobileOS} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default memo(CollabTagModal);
