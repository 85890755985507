import '../../styles/style.css';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { STRINGS } from '../../constants/constants';
import { updateUser } from '../../ducks/actions';
import { functions } from '../../firebase/firebaseConfig';
import { GAtrackPageDetails, getProperty } from '../../helper/helper';
import Error from '../layouts/Error';
import MySpacePost from '../layouts/MySpacePost';
import OptionsOverlay from '../layouts/OptionsOverlay';
import PostBloggerContent from '../layouts/PostBloggerContent';
import PostCarousel from '../layouts/PostCarousel';
import PostDescription from '../layouts/PostDescription';
import Loader from '../layouts/TextLoader';
import PostHeader from './PostHeader';
import PostNavigation from './PostNavigation/PostNavigation';

const data = require('../../data/data.json');
class UserPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null,
      selectedUser: [],
      collaboratorNotPosted: false,
      showLoader: false,
      showOptionOverlay: false,
      selectedPostId: "",
      isSelectedPostPrivate: false,
      unlinkedPostId: ""
    };
  }

  /* Open post in the detailed view whenever click on any particular post*/
  goToPostDetails = (postId) => {
    const { transition, nextPageToken, userFeedPosts, postOwner } = this.props;
    const userId = transition.params().userId;
    transition.router.stateService.go('postDetail', {
      postId: postId,
      userId,
      nextPageToken: nextPageToken,
      posts: userFeedPosts,
      collabUserID: this.state.selectedUser ? this.state.selectedUser : null,
      postOwner: postOwner,
    });
  };

  componentDidMount() {
    const { post } = this.props;
    this.setState({
      post: post,
    });
    setTimeout(() => {
      sessionStorage.removeItem(STRINGS.DELETED_POST_ID);
    }, 4000);    
  }

  /* When press on the coOwner,it gets the post of particular coOwner */
  onPressCoOwner = (post) => {
    if (this.state.collaboratorNotPosted) {
      this.setState({
        collaboratorNotPosted: false,
      });
    }
    this.setState({
      post: post,
      showLoader: false,
    });
  };

  resetDefaultUser = (isReset) => {
    if (isReset) {
      if (this.state.collaboratorNotPosted) {
        this.setState({
          collaboratorNotPosted: false,
        });
      }
      this.setState({
        post: this.props.post,
        showLoader: false,
      });
    }
  };

  /* When coOwer's number of post is not empty and owner clicks on that, it gives the 
  coOwner's post */
  handleClick = (coOwner, postIndex) => {
    const { userInfo } = this.props;
    const getPostsById = userInfo.user
      ? functions.httpsCallable('getPostByIdES')
      : functions.httpsCallable('getPostByIdWithoutAuth');
    GAtrackPageDetails();
    this.setState({
      selectedUser: coOwner,
      showLoader: true,
    });
    if (coOwner != null && coOwner.postId) {
      getPostsById({ postId: coOwner.postId, includeComments: true, apiKey: data.apiKey, source: data.apiSource })
        .then((res) => {
          if (!isEmpty(res)) {
            this.onPressCoOwner(res.data);
          } else {
            this.setState({
              showLoader: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          });
        });
    } else if (postIndex === -1) {
      this.resetDefaultUser(true);
    } else if (!coOwner.postId) {
      this.setState({
        collaboratorNotPosted: true,
        showLoader: false,
      });
    }
  };

  manageOptionOverlay = (postId, isPrivate) => {
    this.setState({
      showOptionOverlay: !this.state.showOptionOverlay,
      selectedPostId: postId,
      isSelectedPostPrivate: isPrivate
    });
  };

  hideUnlinkedPost = (coOwnerId) => {
    this.setState({
      unlinkedPostId: coOwnerId
    });
  }

  render() {
    const { post, collaboratorNotPosted, selectedUser, showLoader, showOptionOverlay, selectedPostId, isSelectedPostPrivate, unlinkedPostId } = this.state;
    const { transition, isPathMain, userInfo, postOwner, showUpdatedPost } = this.props;
    
    if (!post) return <Error message={getProperty('label.no-post')} />;
    return (
      <Fragment>
        <div className="row">
        <div className='feed-post' id={`paths-${post.id}`}>
          <PostNavigation
            post={this.props.post}
            isArrowVisible={false}
            handleClick={this.handleClick}
            onPressCollaborator={this.onPressCoOwner}
            resetDefaultUser={this.resetDefaultUser}
            transition={transition}
            isPathMain={isPathMain}
            userInfo={userInfo}
            getPosts={this.props.getPosts}
            showPhoneNumberExists={this.showPhoneNumberExists}
            unlinkedPostId={unlinkedPostId}
          />

          {!collaboratorNotPosted ? (
            <PostHeader post={post} selectedUser={selectedUser} isClickable={true} manageOptionOverlay={(postId, isPrivate) => this.manageOptionOverlay(postId, isPrivate)} />
          ) : (
            <div className='post-header-main'>
              <div className='post-header-text'>
                <span className='post-user-name'>{selectedUser.displayName}</span>
              </div>
            </div>
          )}

          {!collaboratorNotPosted ? (
            showLoader ? (
              <div className='nopost-container'>
                <Loader />
              </div>
            ) : (
              <div className='feed-post-carousel'>
                <PostCarousel
                  isClickable={true}
                  post={post}
                  parentPost={this.props.post}
                  goToPostDetails={this.goToPostDetails}
                />
              </div>
            )
          ) : showLoader ? (
            <div className='nopost-container'>
              <Loader />
            </div>
          ) : (
            <div className='nopost-container'>
              <h1>
                {getProperty('label.waiting-for-1')} {selectedUser.displayName} {getProperty('label.waiting-for-2')}
              </h1>
            </div>
          )}
          {!collaboratorNotPosted
            ? post.description && !isEmpty(post.attachments) && (
                <PostDescription
                  description={post.description}
                  isClickable={true}
                  post={post}
                  parentPost={this.props.post}
                  goToPostDetails={this.goToPostDetails}
                  isPostCarousel={!isEmpty(post.attachments) ? true : false}
                />
              )
            : ''}
          {!collaboratorNotPosted
            ? post.htmlContent && (
                <PostBloggerContent
                  isClickable={true}
                  post={post}
                  parentPost={this.props.post}
                  goToPostDetails={this.goToPostDetails}
                />
              )
            : ''}

          {!collaboratorNotPosted
            ? post.myspace && (
                <MySpacePost
                  isClickable={true}
                  post={post}
                  parentPost={this.props.post}
                  isPathMain={true}
                  goToPostDetails={this.goToPostDetails}
                />
              )
            : ''}

          {showOptionOverlay && (
            <OptionsOverlay 
              show={showOptionOverlay} 
              manageOptionOverlay={this.manageOptionOverlay} 
              userInfo={userInfo} 
              selectedPostId={selectedPostId} 
              selectedUserId={transition.params().userId} 
              isSelectedPostPrivate={isSelectedPostPrivate}
              postOwner={postOwner}
              post={post}
              transition={transition}
              hideUnlinkedPost={this.hideUnlinkedPost}
              isPathMain={isPathMain}
              showUpdatedPost={showUpdatedPost}
            />
          )}       
        </div>
        </div>
      </Fragment>
    );
  }
}

UserPost.proptype = {
  router: PropTypes.object,
  transition: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPost);
