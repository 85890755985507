import React, { useEffect, useRef, useState } from "react";

export default function PayPal(props) {
  const paypalref = useRef();
  const selectedRadio = props.selectedRadio;
  let [totalAmount, setTotalAmount] = useState(0.0);

  function onSuccess(order) {
    props.parentCallbackSuccess(order);
  }

  function onFailure(err) {
    var error = err + "";
    props.parentCallbackFailure(error.split("\n")[0]);
  }

  useEffect(() => {
    if (selectedRadio === "Radio1") {
      // setTotalAmount((totalAmount = 5.0));
      setTotalAmount(5.0);
    } else if (selectedRadio === "Radio2") {
      // setTotalAmount((totalAmount = 50.0));
      setTotalAmount(50.0);
    } else if (selectedRadio === "Radio3") {
      // setTotalAmount((totalAmount = 500.0));
      setTotalAmount(500.0);
    } else if (selectedRadio === "Radio4") {
      // setTotalAmount((totalAmount = 10.0));
      setTotalAmount(10.0);
    } else if (selectedRadio === "Radio5") {
      // setTotalAmount((totalAmount = 100.0));
      setTotalAmount(100.0);
    } else if (selectedRadio === "Radio6") {
      // setTotalAmount((totalAmount = 1000.0));
      setTotalAmount(1000.0);
    }
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "ordering collag tags",
                amount: {
                  currency_code: "USD",
                  value: totalAmount,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          onSuccess(order);
        },
        onError: (err) => {
          onFailure(err);
        },
      })
      .render(paypalref.current);
  }, []);

  return (
    <div>
      <div ref={paypalref}></div>
    </div>
  );
}
