import "../../../styles/style.css";

import React, { Fragment } from "react";
import { IMAGES } from "../../../constants/constants";
import { getProperty } from "../../../helper/helper";

const NavigationBar = (props) => {
  const {
    navItems,
    userCollabSetup,
    isCoOwner,
    isPostOwner,
    userId,
    isPending,
    isSinglePost,
    isLoggedIn,
    unlinkedCollaboratorId,
    userInfo,
  } = props;
  const classAnchor =
    navItems && navItems.length > 1 ? "icon-style-plus" : "icon-plus";
  return (
    <div className="clearboth">
      <nav className="navbar navbar-nav mb-4 navbar-path header-spacing">
        <ul className="nav tabs group nav-pills collabtab-list">
          {navItems[0]}
          <li className="nav-item margintop">
            {isCoOwner === "-1" && isPostOwner !== userId && !isPending && (
              <div>
                <a
                  className={`nav-link ${classAnchor}`}
                  href="/#"
                  onClick={userCollabSetup}
                >
                  <img
                    src={IMAGES.ADD_ME.default}
                    alt="add me icon"
                    className="add-me-icon"
                  />
                </a>
                {isSinglePost && (
                  <div
                    className="d-inline-block collabtab-label"
                    onClick={isLoggedIn ? "" : userCollabSetup}
                  >
                    {!isLoggedIn && (
                      <Fragment>
                        <p className="signup-join-text-label">
                          {getProperty("label.sign-up-and-join")}
                        </p>
                        <p className="collabtab-text-label">
                          {getProperty("label.collabtab")}
                        </p>
                      </Fragment>
                    )}
                    {isLoggedIn && (
                      <div>
                        <p className="signup-join-text-label">
                          {getProperty("label.join-this")}
                        </p>
                        <p className="collabtab-text-label">
                          {getProperty("label.collabtab")}
                        </p>
                      </div>
                    )}
                    <div className="bottom-arrow"></div>
                  </div>
                )}
              </div>
            )}
            {unlinkedCollaboratorId &&
              userInfo &&
              userInfo.user &&
              userInfo.user.uid &&
              unlinkedCollaboratorId === userInfo.user.uid && (
                <a
                  className={`nav-link ${classAnchor}`}
                  onClick={userCollabSetup}
                  href="/#"
                >
                  <img
                    src={IMAGES.ADD_ME.default}
                    alt="add me icon"
                    className="add-me-icon"
                  />
                </a>
              )}
            {isPending && (
              <a href="/#" className={`nav-link ${classAnchor} marker-icon`}>
                <i className="fa fa-hourglass-half"></i>
              </a>
            )}
          </li>
          {navItems.length > 1 && navItems.splice(1, navItems.length)}
        </ul>
      </nav>
    </div>
  );
};

export default NavigationBar;
