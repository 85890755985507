import "../../styles/style.css";

import isEmpty from "lodash/isEmpty";
import React, { Component, Fragment } from "react";

import { getPostTitle } from "../../helper/helper";
import { IMAGES } from "../../constants/constants";

class PostHeader extends Component {
  render() {
    const { post, selectedUser, isClickable, manageOptionOverlay } = this.props;
    return (
      <Fragment>
        {post.auditInfo && post.auditInfo.createdTime && isClickable && (
          <div className="post-header-main">
            <div className="post-header-text">
              {!isEmpty(post.title) ? (
                <p className="post-title">{getPostTitle(post.title)}</p>
              ) : !isEmpty(selectedUser) ? (
                <span className="post-user-name">
                  {selectedUser.displayName}&nbsp;&nbsp;
                </span>
              ) : (
                post.auditInfo &&
                post.auditInfo.createdBy && (
                  <span className="post-user-name">
                    {post.auditInfo.createdBy}&nbsp;&nbsp;
                  </span>
                )
              )}

              {!isEmpty(post.title) ? (
                !isEmpty(selectedUser) ? (
                  <span className="post-user-name">
                    {selectedUser.displayName}&nbsp;&nbsp;
                  </span>
                ) : (
                  post.auditInfo &&
                  post.auditInfo.createdBy && (
                    <span className="post-user-name">
                      {post.auditInfo.createdBy}&nbsp;&nbsp;
                    </span>
                  )
                )
              ) : (
                <span></span>
              )}
              <div className="user-location">
                {!isEmpty(post.place) && (
                  <span className="post-user-location">
                    <span>
                      <i className="icon icon-map-marker icon-rotate-360"></i>
                    </span>
                    &nbsp;
                    {post.place}&nbsp;|&nbsp;
                  </span>
                )}
                <span className="post-user-location">
                  {new Date(
                    post.auditInfo.createdTime._seconds
                      ? post.auditInfo.createdTime._seconds * 1000
                      : post.auditInfo.createdTime
                  ).toDateString()}
                </span>
              </div>
              <img
                alt="three-dots-icon"
                src={IMAGES.THREE_DOTS.default}
                className="three-dots-icon"
                onClick={() => manageOptionOverlay(post.id, post.private)}
              />
            </div>
          </div>
        )}
        {post.auditInfo && post.auditInfo.createdTime && !isClickable && (
          <div className="post-header-main">
            <div className="post-header-text">
              {!isEmpty(post.title) ? (
                <p className="post-title">{getPostTitle(post.title)}</p>
              ) : !isEmpty(selectedUser) ? (
                post.auditInfo &&
                post.auditInfo.createdTime && (
                  <span className="post-user-name">
                    {selectedUser.displayName}&nbsp;|&nbsp;
                    {new Date(
                      post.auditInfo.createdTime._seconds
                        ? post.auditInfo.createdTime._seconds * 1000
                        : post.auditInfo.createdTime
                    ).toDateString()}
                  </span>
                )
              ) : (
                post.auditInfo &&
                post.auditInfo.createdBy && (
                  <span className="post-user-name">
                    {post.auditInfo.createdBy}&nbsp;|&nbsp;
                    {new Date(
                      post.auditInfo.createdTime._seconds
                        ? post.auditInfo.createdTime._seconds * 1000
                        : post.auditInfo.createdTime
                    ).toDateString()}
                  </span>
                )
              )}

              {!isEmpty(post.title) ? (
                !isEmpty(selectedUser) ? (
                  post.auditInfo &&
                  post.auditInfo.createdTime && (
                    <span className="post-user-name">
                      {selectedUser.displayName}&nbsp;|&nbsp;
                      {new Date(
                        post.auditInfo.createdTime._seconds
                          ? post.auditInfo.createdTime._seconds * 1000
                          : post.auditInfo.createdTime
                      ).toDateString()}
                    </span>
                  )
                ) : (
                  post.auditInfo &&
                  post.auditInfo.createdBy && (
                    <span className="post-user-name">
                      {post.auditInfo.createdBy}&nbsp;|&nbsp;
                      {new Date(
                        post.auditInfo.createdTime._seconds
                          ? post.auditInfo.createdTime._seconds * 1000
                          : post.auditInfo.createdTime
                      ).toDateString()}
                    </span>
                  )
                )
              ) : (
                <span></span>
              )}

              {!isEmpty(post.place) && (
                <span className="post-user-location">
                  <span>
                    <i className="icon icon-map-marker icon-rotate-360"></i>
                  </span>
                  {post.place}&nbsp;&nbsp;
                </span>
              )}
              <img
                alt="three-dots-icon"
                src={IMAGES.THREE_DOTS.default}
                className="three-dots-icon"
                onClick={() => manageOptionOverlay(post.id, post.private)}
              />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default PostHeader;
