export const IMAGES = {
    MASTER_SHADOW_IMAGE : require('../images/icons/master-shadow.png'),
    QA_CODE_IMAGE : require('../images/icons/qr_order.png'),
    ADD_ME :require('../images/icons/add_me.png'),
    TEACHABLE_TAKAWAY_ICON : require('../images/icons/tt_grey.png'),
    GRATITUDE_JOURNAL_ICON : require('../images/icons/gj_grey.png'),
    LINK_LIST_ICON : require('../images/icons/link_list.png'),
    POST_COMMENT_ICON : require('../images/icons/dm.png'),
    COMMENT_OPTIONS_ICON : require('../images/icons/grey.png'),
    SIGN_UP_IMAGE : require('../images/icons/sign_up_button.png'),
    THREE_DOTS : require('../images/icons/3_dots.png'),
    APP_STORE : require('../images/icons/apple-button.png'),
    PLAY_STORE : require('../images/icons/google-button.png'),
    HIDE_PASSWORD : require('../images/icons/hide.png'),
    SHOW_PASSWORD : require('../images/icons/show-hide.png'),
    TRASH_IMAGE : require('../images/icons/trash.png'),
    PATHS_ICON : require('../images/icons/paths-icon.png'),
    PHONE_LOGO : require('../images/icons/phone_logo.png'),
    EMAIL_LOGO : require('../images/icons/email.png'),
    LINK_ICON : require('../images/icons/link_icon.png'),
    MESSAGE_ICON : require('../images/icons/group_message.png'),
    GROUP_COLLAB_TAGS : require('../images/icons/group_collab_tag.png'),
    SHIP_TO_DOOR : require('../images/icons/ship_to_door.png'),
    PAY_PAL_IMAGE : require('../images/icons/pay_pal.png'),
    WOODEN_BACKGROUND : require('../images/icons/wooden-background.png'),
    QR_ICON : require('../images/icons/qr-icon.png'),
    PRINT_ICON : require('../images/icons/print-locally.png'),
    SAVE_ICON : require('../images/icons/save-locally.png'),
    TINY_CHECK : require('../images/icons/tiny-check.png'),
    TINY_UNCHECK : require('../images/icons/tiny-x.png'),
    BACK_ARROW : require('../images/icons/back_arrow.png'),
    COLLAB_NOW : require('../images/icons/collab_now.png'),
    PLAY_STORE_ICON : require('../images/icons/play-store-icon.png'),
    APP_STORE_ICON : require('../images/icons/app-store-icon.png'),
    PATHS_COLOUR_LOGO : require('../images/logo/paths-color-logo.png'),
    NEW_PATHS_LOGO : require('../images/logo/new-paths-logo.png'),
    GLOBE_ICON : require("../images/icons/globe-icon.png"),
    CREATE_ENTRY_BUTTON : require("../images/icons/create_entry_button.png"),
    CLOSE_BUTTON : require("../images/icons/close_button.png"),
    CAMERA_ICON : require("../images/icons/camera_icon.png"),
    MY_SPACE_ICON : require("../images/icons/my_space.png"),
    EDIT_BUTTON : require("../images/icons/edit_button.png"),
    EXCLAMATION_ICON : require("../images/icons/exclamation_icon.png"),
    ANIMATED_PATHS_LOGO : require("../images/logo/animated-paths-logo.gif")
};

export const NAVIGATION = {
    POSTDETAIL: "postDetail",
    SAVECOLLABTAG: "saveCollabTag",
    ORDERCOLLABTAG: "orderCollabTag",
    POSTS: "posts",
    CREATE_NEW_POST: "createNewPost",
    GETPATHS: "https://www.getpaths.com",
    PATHS_FEEDBACK: "https://forms.gle/FTr91uCzc8i9fTd96",
    TERMS_OF_USE: "https://www.getpaths.com/terms-of-service",
    PRIVACY_POLICY: "https://www.getpaths.com/legal-privacy-policy",
    HELP: "https://www.getpaths.com/paths-help",
}

export const STRINGS = {
    MAX_DESCRIPTION_LENGTH: 200,
    HOME: "home",
    TERMS: "terms",
    PRIVACY: "privacy",
    HELP: "help",
    INSTAGRAM: "Instagram",
    PATHS_PROD: "https://app.getpaths.com/?u=",
    PATHS_QA: "https://test-app.getpaths.com/?u=",
    POST_QUERY_PARAM: "&p=",
    POST_FRAGMENT: "#/",
    POST_PARAM: "/posts/",
    CREATE_MEMORY: "create-memory",
    MEMORY_SETTINGS: "memory-settings",
    FRIENDS_OPTION: "friends-option",
    FOLLOWING_OPTION: "following-option",
    FOLLOWER_OPTION: "follower-option",
    FORWARD_SLASH: "/",
    VIDEO: "video",
    IMAGE: "image",
    PUBLIC: "public",
    PRIVATE: "private",
    HYPHEN: "-",
    TEN: "10",
    ZERO: "0",
    CONSTANT_T: "T",
    COLON: ":",
    OPEN: "OPEN",
    CLOSE: "CLOSE",
    FRIEND: "friend",
    TRACKING: "tracking",
    TRACKER: "tracker",
    NEXT: "Next",
    PUBLISH: "Publish",
    PATHS_PROD_STORAGE_BUCKET: "gs://pathsprod",
    FACEBOOK_SHARE_URL: "https://www.facebook.com/sharer/sharer.php?u=",
    INVITED: "Invited",
    INVITE: "Invite",
    GOOGLE: "google",
    APPLE: "apple",
    IOS: "iOS",
    ANDROID: "Android",
    DELETED_POST_ID: "deletedPostId",
    FIREBASE_STORAGE: "firebasestorage",
    DATA_URL: "data_url"
}

export const googleStoreLink =
  "https://play.google.com/store/apps/details?id=com.getpaths.paths";
export const appleStoreLink = "https://apps.apple.com/app/id1480890359";
export const privacyPageLink="";
export const termsPageLink="";
export const publicStorageBucket="";
