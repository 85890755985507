import React, { Component, Fragment } from 'react';

import mySpaceIcon from '../../images/icons/myspace_white.png';
import PostCarousel from './PostCarousel';

export default class MySpacePost extends Component {
  handleClick = () => {
    const { isClickable, goToPostDetails, parentPost } = this.props;
    isClickable && goToPostDetails(parentPost.id);
  };

  /**
   * Creating an array of attachment from myspace.
   */
  buildmySpaceAttachments = () => {
    const { post } = this.props;
    const { myspace } = post;
    const mySpaceAttachments = { attachments: [myspace.attachments] };

    return mySpaceAttachments;
  };

  render() {
    const { post, isPathMain, goToPostDetails, parentPost } = this.props;
    const { myspace } = post;
    const mySpaceAttachments = this.buildmySpaceAttachments();

    return (
      <Fragment>
        <div onClick={() => this.handleClick()} className='myspace-container mt-4'>
          <div className='post-header-main'>
            <div className='post-header-text'>
              <p className='post-title'>
                <img alt="myspace logo" className='myspace-logo' src={mySpaceIcon} />
                <span>{myspace.title}</span>
              </p>
            </div>
          </div>
          <div className='feed-post-carousel'>
            <PostCarousel
              isClickable={isPathMain || false}
              post={mySpaceAttachments}
              isMySpace={true}
              parentPost={parentPost}
              goToPostDetails={goToPostDetails}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
