import React, { memo, useRef } from "react";
import { IMAGES, NAVIGATION } from "../../constants/constants";
import { getProperty } from "../../helper/helper";
import QRCodeWithPost from "../posts/QRCode/QRCodeWithPost";
import { downloadQRCode } from "../../util/util";
import ReactToPrint from "react-to-print";
import PrintQRCode from "../UserProfile/PrintQRCode";

const CollabTagOperations = (props) => {
  const printCollabTagRef = useRef();

  const printColabTag = () => {
    return <span>{getProperty("label.print")}</span>;
  };

  const showOrderCollabTag = () => {
    const transition = props.transition;
    const post = props.post;
    transition.router.stateService.go(NAVIGATION.ORDERCOLLABTAG, {
      postId: post.id,
      userId: post.owner.userId,
      post: post,
      postUrl: window.location.href,
    });
  };

  const highResolutionCollabTag = () => {
    const transition = props.transition;
    const post = props.post;
    transition.router.stateService.go(NAVIGATION.SAVECOLLABTAG, {
      postId: post.id,
      userId: post.owner.userId,
      post: post,
      postUrl: window.location.href,
    });
  };

  const goToFeedPage = () => {
    const transition = props.transition;
    const post = props.post;
    let userId = "";
    if (post && post.parentPostOwnerId) {
      userId = post.parentPostOwnerId;
    } else {
      userId = post.owner.userId;
    }
    transition.router.stateService.go(NAVIGATION.POSTS, {
      userId: userId,
    });
  };

  return (
    <div
      style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
      className="collab-tag-operations"
    >
      <div className="collab-tag-operations-header">
        <p className="collab-tag-operations-header-first">
          {getProperty("label.done-congrats")}
        </p>
        <p className="collab-tag-operations-header-second">
          {getProperty("label.more-contributors")}
        </p>
      </div>
      <div className="collab-tab-section">
        <QRCodeWithPost
          post={props.post}
          postHeader={false}
          highResolution={false}
          userProfile={false}
          isPopup={true}
        />
      </div>
      <div className="collab-tag-operations-footer">
        <div className="row save-qr-collab-tag-operation">
          {props.mobileOS && props.mobileOS !== "iOS" && (
            <div className="col">
              <img
                src={IMAGES.SAVE_ICON.default}
                className="more-contribution-screen-icon"
                onClick={highResolutionCollabTag}
              />
              &nbsp;&nbsp;
              <span onClick={highResolutionCollabTag}>
                {getProperty("label.save")}
              </span>
            </div>
          )}
          <div className="col save-qr-only-operation">
            <img
              src={IMAGES.QR_ICON.default}
              className="more-contribution-screen-icon"
              onClick={downloadQRCode}
            />
            &nbsp;&nbsp;
            <span onClick={downloadQRCode}>
              {getProperty("label.save-qr-only")}
            </span>
          </div>
        </div>
        {/* <div className="print-collab-tag-operation">
          <img
            src={IMAGES.PRINT_ICON.default}
            className="more-contribution-screen-icon"
          />
          &nbsp;&nbsp;
          <ReactToPrint
            trigger={printColabTag}
            content={() => printCollabTagRef.current}
          />
          <div className="hidden-collab-tag-print">
            <QRCodeWithPost
              ref={printCollabTagRef}
              post={props.post}
              postHeader={false}
              highResolution={false}
              userProfile={true}
            />
          </div>
        </div> */}
        <div className="row order-collab-tag-operation">
          {/* <div className="col-6">
            <img
              src={IMAGES.SHIP_TO_DOOR.default}
              className="more-contribution-screen-icon"
              onClick={showOrderCollabTag}
            />
            &nbsp;&nbsp;
            <span onClick={showOrderCollabTag}>
              {getProperty("label.order")}
            </span>
          </div> */}
          <div className="col-12">
            <p className="collab-tag-done-btn" onClick={goToFeedPage}>
              {getProperty("label.done-uppercase")}
            </p>
          </div>
        </div>
      </div>
      <div className="hidden-qr-code">
        <PrintQRCode
          qrcodeValue={window.location.href}
          isDownloadQRCode={true}
        />
      </div>
    </div>
  );
};

export default memo(CollabTagOperations);
