import React, { Component, Fragment } from "react";
import { IMAGES } from "../../constants/constants";
import { getProperty } from "../../helper/helper";
import LoginModal from "../loginpage/LoginModal";
import { auth } from "../../firebase/firebaseConfig";
import GetPathsOverlay from "../layouts/GetPathsOverlay";
import {googleStoreLink, appleStoreLink} from '../../constants/constants';

class SignIn extends Component {
  constructor() {
    super();
    this.state = {
      showLogin: false,
      showSignUpPopup: true,
      isLoggedIn: false,
      showSignIn: false,
      showDoneScreen: false,
      showGetPaths: false,
      loginUserId:'',
    };
  }

  showLoginModal = (event) => {
    this.setState({ showLogin: true });
  };

  showSignInModal = () => {
    this.setState({ showSignIn: true });
  };

  updateShowLogin = () => {
    this.setState({
      showLogin: !this.state.showLogin,
    });
  };

  updateShowSignIn = () => {
    this.setState({
      showSignIn: !this.state.showSignIn,
    });
  };

  redirectOnAppStore = (storeName) => {
    if (storeName === "apple") {
      window.open(appleStoreLink, "_blank");
    } else {
      window.open(googleStoreLink, "_blank");
    }
  };

  componentDidMount = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          isLoggedIn: true,
        });
      } else {
        this.setState({
          isLoggedIn: false,
        });
      }
    });
  };

  updateDoneScreen = () => {
    this.setState({
      showDoneScreen: !this.state.showDoneScreen,
    });
  };

  manageGetPaths = (
    // loginUserId
  ) => {
    this.setState({
      showGetPaths: !this.state.showGetPaths,
      // loginUserId: loginUserId,
    });
    // if (loginUserId !== '') {
    //   //add userId at the end of url for redirect to user post listing screen
    //    window.location.href = "#/" + loginUserId;
    // }
  };

  render() {
    const {
      showLogin,
      isLoggedIn,
      showSignIn,
      showDoneScreen,
      showGetPaths,
      loginUserId,
    } = this.state;
    const footerBtnClass = isLoggedIn
      ? "login-sign-up-footer"
      : "sign-up-footer";
    return (
      <Fragment>
        <div className="paths-sign-up make-me-sticky">
          {!isLoggedIn && <p>{getProperty("label.sign-up-card-heading")}</p>}
          {!isLoggedIn && (
            <span
              className="d-inline-block btn-headersection"
              onClick={this.showLoginModal}
            >
              {getProperty("label.sign-up-uppercase")}
            </span>
          )}

          <Fragment>
            {(!isLoggedIn || showDoneScreen) && showLogin && (
              <LoginModal
                show={true}
                updateShowLogin={this.updateShowLogin}
                showPopup={true}
                showSignUpPopup={this.state.showSignUpPopup}
                showDoneScreen={showDoneScreen}
                isMainHeader={true}
                updateDoneScreen={this.updateDoneScreen}
                manageGetPaths={this.manageGetPaths}
              />
            )}
            {showGetPaths && (
              <GetPathsOverlay
                loginUserId={loginUserId}
                show={showGetPaths}
                manageGetPaths={this.manageGetPaths}
              />
            )}
          </Fragment>

          {!isLoggedIn && (
            <p className="sign-in-label" onClick={this.showSignInModal}>
              {getProperty("label.sign-in")}
            </p>
          )}

          <Fragment>
            {!isLoggedIn && showSignIn && (
              <LoginModal
                show={showSignIn}
                updateShowLogin={this.updateShowSignIn}
                manageGetPaths={this.manageGetPaths}
              />
            )}
          </Fragment>

          {!isLoggedIn && (
            <p className="header-sign-in-label" onClick={this.showSignInModal}>
              {getProperty("label.sign-in")}
            </p>
          )}

          <div className={footerBtnClass}>
            <span>
              <img
                alt="AppStore img"
                src={IMAGES.APP_STORE.default}
                onClick={() => this.redirectOnAppStore("apple")}
                className="app-store-img"
              />
              <img
                alt="PlayStore img"
                src={IMAGES.PLAY_STORE.default}
                onClick={() => this.redirectOnAppStore("google")}
                className="play-store-img"
              />
            </span>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SignIn;
