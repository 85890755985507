import { UPDATE_ADDITIONAL_USER_INFO, UPDATE_USER } from './actionType';

const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    payload: user,
  };
};

const updateAdditionalUserInfo = (additionalUserInfo) => {
  return {
    type: UPDATE_ADDITIONAL_USER_INFO,
    payload: additionalUserInfo,
  };
};

export { updateUser, updateAdditionalUserInfo };
