import "../../../styles/style.css";

import isEmpty from "lodash/isEmpty";
import React, { Component } from "react";
import { connect } from "react-redux";

import { updateUser } from "../../../ducks/actions";
import { functions } from "../../../firebase/firebaseConfig";
import { validateForm, validateImage } from "../../../util/validation";
import PostCommentBox from "./PostCommentBox";
import { IMAGES } from "../../../constants/constants";

const data = require("../../../data/data.json");

const addCommentOnPost = functions.httpsCallable("addCommentOnPost");
class PostComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: null,
      post: [],
      selectedFile: null,
      fileType: null,
      showError: false,
      comments: [],
      isSelectFile: false,
      commentsList: [],
      isCommentPosting: false,
      showLogin: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.post.id !== nextProps.post.id) {
      this.setState({
        post: nextProps.post,
        comments: nextProps.post.comments,
      });
      this.getCommentList(nextProps.post, nextProps.post.comments);
    }
  }

  /* It gets all the images if have any in comments section */
  getContent = (comment) => {
    var commentImage = [];
    comment.attachments.length > 0 &&
      comment.attachments.map((feed) => {
        if (feed.type === "image") {
          commentImage.push(
            <img alt="comment img" src={feed.URL} className="img-fluid comment-image" />
          );
        }
      });
    return commentImage;
  };

  componentDidMount() {
    const { post } = this.props;
    this.setState({
      post: post,
      comments: post.comments,
    });
    this.getCommentList(post, post.comments);
  }

  selectImage = (event) => {
    event.preventDefault();
    this.setState({
      comment: event.target.value,
      showError: false,
    });
  };

  changeSelectedImage = (event) => {
    event.preventDefault();
    let files = event.target.files;
    let type = files[0].type;
    const fileType = files[0].type.split("/")[0];

    if (validateImage(files[0])) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        this.setState({
          selectedFile: e.target.result,
          isSelectFile: true,
          base64: reader.result,
          type: type,
          fileType: fileType,
          showError: false,
        });
      };
    } else {
      this.setState({
        showError: true,
      });
    }
  };

  /* Handles each type of comments and add that comments on particular post */
  postCommentData = (event) => {
    const { userInfo, additionalUserInfo } = this.props;
    const { comment, selectedFile } = this.state;
    if (userInfo.user) {
      if (
        (comment && !isEmpty(comment.trim())) ||
        (selectedFile && !isEmpty(selectedFile))
      ) {
        if (validateForm(comment, selectedFile)) {
          this.setState({
            isCommentPosting: true,
          });
          var commentJson = this.getCommentData();
          var tempComment = commentJson;
          var loggedInUser = userInfo.user;
          let displayName, photoURL;
          if (additionalUserInfo.user) {
            const { firstName, lastName, profilePhotoUrls } =
              additionalUserInfo.user;
            if (firstName && lastName) {
              displayName = `${firstName}  ${lastName}`;
            } else {
              displayName = loggedInUser.email;
            }
            if (profilePhotoUrls.small) {
              photoURL = profilePhotoUrls.small;
            } else {
              photoURL = loggedInUser.photoURL;
            }
          }
          tempComment = {
            ...tempComment,
            owner: {
              userId: loggedInUser.uid,
              displayName: displayName,
              photoURL: photoURL,
            },
          };
          const commentData = this.setCommentData();
          let commentsData = this.state.comments;
          commentsData.push(tempComment);
          this.setState({
            comments: commentsData,
            selectedFile: null,
            fileType: "",
            comment: "",
            isSelectFile: false,
            isCommentPosting: false,
            showError: false,
          });
          this.getCommentList(this.props.post, commentsData);
          addCommentOnPost({
            comment: commentData,
            apiKey: data.apiKey,
            source: data.apiSource,
          })
            .then((resp) => {})
            .catch((error) => {
              this.setState({
                isCommentPosting: false,
                showError: false,
              });
            });
        } else {
          this.setState({
            showError: true,
          });
        }
      } else {
        this.setState({
          showError: true,
        });
      }
    } else {
      this.setState({
        showLogin: true,
      });
    }
  };

  /* Sets the comments with all the comment messages and attachments with related PostID */
  setCommentData() {
    const { base64, type, selectedFile, post } = this.state;
    let attachments = [];
    if (base64 && selectedFile) {
      var base64result = base64.split(",")[1];
      let file = {
        base64Data: base64result,
        fileType: type,
      };
      attachments.push(file);
    }
    let comment = {
      postId: post.id,
      message: this.state.comment,
      attachments: attachments,
    };
    return comment;
  }

  /* It gets all the comment messages, attachments with that related particular postID */
  getCommentData = () => {
    var commentData = {
      postId: String,
      message: String,
      attachments: [],
    };
    var attachment = {
      type: String,
      URL: String,
    };
    const { fileType, selectedFile, comment, post } = this.state;
    attachment.type = fileType;
    attachment.URL = selectedFile;
    commentData.postId = post.id;
    commentData.message = comment;
    if (selectedFile != null) {
      commentData.attachments.push(attachment);
    }
    return commentData;
  };

  /* Used to remove images from comment section */
  removeImage = () => {
    this.fileInput.value = "";
    this.setState({
      selectedFile: null,
      isSelectFile: false,
      fileType: "",
    });
  };

  /* Used to get all the previous comments done by the users before in sequence of 
  latest to old */
  getCommentList = (post, commentdata) => {
    var commentsList = [];
    if (!isEmpty(commentdata)) {
      let comments = [...commentdata];
      comments.map((comment) => {
        var commentImage = this.getContent(comment);
        if (commentImage.length > 0 || comment.message.length > 0) {
          commentsList.push(
            <li className="media">
              <img
                src={comment.owner ? comment.owner.photoURL : ""}
                alt=""
                className="img-circle comment-user-img"
              />
              <div className="media-body">
                <span className="text-muted pull-right">
                  <p className="text-muted comment-date">
                    <img alt="" src={IMAGES.COMMENT_OPTIONS_ICON.default} />
                  </p>
                </span>
                <p className="comment-owner">
                  {comment.owner && comment.owner.displayName}
                </p>
                <div className="incoming_msg">
                  {commentImage.length > 0 && commentImage}
                  {comment.message && (
                    <div className="received_msg">
                      <div className="received_withd_msg">
                        <pre>{comment.message}</pre>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        }
      });
      this.setState({
        commentsList: commentsList,
        comment: "",
      });
    } else {
      this.setState({
        commentsList: commentsList,
        comment: "",
      });
    }
  };

  updateShowLogin = (msgType, isMessage, message) => {
    this.setState({ showLogin: !this.state.showLogin });
  };
  valueChange = (ref) => {
    this.fileInput = ref;
  };

  render() {
    const {
      isSelectFile,
      selectedFile,
      commentsList,
      showLogin,
      comment,
      showError,
    } = this.state;

    return (
      <div className="row feed-post-comment mt-5">
        <div className="col-12 comment-wrapper panel panel-info post-comment">
          <img alt="shadow img" src={IMAGES.MASTER_SHADOW_IMAGE.default} />
          <p className="comment-head">ENTRY COMMENTS</p>
          <ul className="media-list">{commentsList}</ul>
          <PostCommentBox
            postCommentData={this.postCommentData}
            selectedFile={selectedFile}
            removeImage={this.removeImage}
            isSelectFile={isSelectFile}
            comment={comment}
            showError={showError}
            changeSelectedImage={this.changeSelectedImage}
            selectImage={this.selectImage}
            post={this.props.post}
            showLogin={showLogin}
            updateShowLogin={this.updateShowLogin}
            valueChange={this.valueChange}
            showPhoneNumberExists={this.props.showPhoneNumberExists}
            postCommentIcon={IMAGES.POST_COMMENT_ICON}
            userInfo={this.props.userInfo}
          />
          <div className="clearfix"></div>
          {commentsList.length > 0 && <hr />}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostComment);
