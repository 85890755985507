import "../../styles/style.css";

import React, { Component, Fragment } from "react";

import TeachableAndGratitude from "./TeachableAndGratitude";
import LinkList from "./LinkList";
import { IMAGES } from "../../constants/constants";
import { getProperty } from "../../helper/helper";
import Linkify from "react-linkify";

class PostDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  /* Open post in the detailed view whenever click on any particular post */
  goToPostDetail = (id) => {
    this.props.goToPostDetails(id);
  };

  render() {
    const { description, isClickable, post, parentPost, isPostCarousel } =
      this.props;
    let MAX_LENGTH = !isPostCarousel ? 280 : 50;
    const { expanded } = this.state;
    if (post.siteLinks && post.siteLinks.length === 1) {
      return (
        <Fragment>
          <div className="feed-post-footer">
            {description && (
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target="blank"
                    href={decoratedHref}
                    key={key}
                    className="linkify-post-description"
                  >
                    {decoratedText}
                  </a>
                )}
              >
                <pre className="post-description">
                  <p
                    onClick={() =>
                      isClickable && this.goToPostDetail(parentPost.id)
                    }
                  >
                    {isClickable && !expanded && description.length > MAX_LENGTH
                      ? `${description.substring(0, MAX_LENGTH)}`
                      : description}
                    {isClickable && description.length > MAX_LENGTH && (
                      <a href className="text-secondary">
                        <span
                          onClick={() => this.setState({ expanded: !expanded })}
                        >
                          {expanded ? "...less" : "...more"}
                        </span>
                      </a>
                    )}
                  </p>
                </pre>
              </Linkify>
            )}
            {!isClickable && post.teachableTakeaway && (
              <TeachableAndGratitude
                iconClass={"teachable-takeaway-icon"}
                title={post.teachableTakeaway.title}
                ttGJdescription={post.teachableTakeaway.description}
                icon={IMAGES.TEACHABLE_TAKAWAY_ICON}
                ttGjTitle={getProperty("label.teachable-takaway")}
              />
            )}

            {!isClickable && post.gratitudeJournal && (
              <TeachableAndGratitude
                iconClass={"gratitude-journal-icon"}
                title={post.gratitudeJournal.title}
                ttGJdescription={post.gratitudeJournal.description}
                icon={IMAGES.GRATITUDE_JOURNAL_ICON}
                ttGjTitle={getProperty("label.gratitude-journal")}
              />
            )}

            {!isClickable &&
              post.siteLinks &&
              post.siteLinks.length > 0 &&
              post.siteLinks.length === 1 &&
              post.siteLinks.map((link, index) => {
                return (
                  <LinkList
                    isLink={true}
                    iconClass={"link"}
                    url={link}
                    title={"WWW.GETPATHS.COM"}
                    ttGJdescription={
                      "Paths is a new way to view your social media presence. With a pinch-zoom timeline of your posts you can go from years to hours in seconds..."
                    }
                    icon={IMAGES.LINK_LIST_ICON}
                    ttGjTitle={getProperty("label.link-list")}
                    index={index}
                    siteLinksLength={post.siteLinks.length}
                  />
                );
              })}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="feed-post-footer">
            {description && (
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target="blank"
                    href={decoratedHref}
                    key={key}
                    className="linkify-post-description"
                  >
                    {decoratedText}
                  </a>
                )}
              >
                <pre className="post-description">
                  <p
                    onClick={() =>
                      isClickable && this.goToPostDetail(parentPost.id)
                    }
                  >
                    {isClickable && !expanded && description.length > MAX_LENGTH
                      ? `${description.substring(0, MAX_LENGTH)}`
                      : description}
                    {isClickable && description.length > MAX_LENGTH && (
                      <a href className="text-secondary">
                        <span
                          onClick={() => this.setState({ expanded: !expanded })}
                        >
                          {expanded ? "...less" : "...more"}
                        </span>
                      </a>
                    )}
                  </p>
                </pre>
              </Linkify>
            )}
            {!isClickable && post.teachableTakeaway && (
              <TeachableAndGratitude
                iconClass={"teachable-takeaway-icon"}
                title={post.teachableTakeaway.title}
                ttGJdescription={post.teachableTakeaway.description}
                icon={IMAGES.TEACHABLE_TAKAWAY_ICON}
                ttGjTitle={getProperty("label.teachable-takaway")}
              />
            )}

            {!isClickable && post.gratitudeJournal && (
              <TeachableAndGratitude
                iconClass={"gratitude-journal-icon"}
                title={post.gratitudeJournal.title}
                ttGJdescription={post.gratitudeJournal.description}
                icon={IMAGES.GRATITUDE_JOURNAL_ICON}
                ttGjTitle={getProperty("label.gratitude-journal")}
              />
            )}
        
            <div className="row m-0 justify-content-center tt-gj-wrapper">
              <div className="col-md-11 mt-3 link-list-container">
                {!isClickable &&
                  post.siteLinks &&
                  post.siteLinks.length > 0 &&
                  post.siteLinks.map((link, index) => {
                    return (
                      <LinkList
                        isLink={true}
                        iconClass={"link"}
                        url={link}
                        title={"WWW.GETPATHS.COM"}
                        ttGJdescription={
                          "Paths is a new way to view your social media presence. With a pinch-zoom timeline of your posts you can go from years to hours in seconds..."
                        }
                        icon={IMAGES.LINK_LIST_ICON}
                        ttGjTitle={getProperty("label.link-list")}
                        index={index}
                        siteLinksLength={post.siteLinks.length}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}
export default PostDescription;
