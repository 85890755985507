import React from 'react';
import { IMAGES } from '../../constants/constants';

import { getProperty } from '../../helper/helper';
import HeaderSection from './HeaderSection';

const PostHeader = (props) => {
  return (
    <div className='invite-postheader col-md-12'>
      <HeaderSection
        title={props.title}
        message={props.owner && props.owner.name && `${props.owner.name} ${getProperty('label.invite-header')}`}
        imageUrl={props.imageUrl}
        qrCode={IMAGES.QA_CODE_IMAGE}
        post={props.post}
        transition={props.transition}
        userInfo={props.userInfo}
        postData={props.postData}
        postOwner={props.postOwner}
      />
    </div>
  );
};

export default PostHeader;
