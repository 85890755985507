import React, { Component } from 'react';
import { CloseButton, Modal, ModalBody } from 'react-bootstrap';

import { getProperty } from '../../helper/helper';
import {googleStoreLink, appleStoreLink} from '../../constants/constants';
class CoOwnerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: '',
      countryCalling: '',
      countryCode: '',
      showLoader: false,
      isPhoneNumberAdded: false,
    };
  }

  redirectOnAppStore = (storeName) => {
    if (storeName === 'apple') {
      window.open(appleStoreLink, '_blank');
    } else {
      window.open(googleStoreLink, '_blank');
    }
  };

  render() {
    const { show, closeModal } = this.props;

    return (
      <Modal show={show} dialogClassName='modal-lg custom-dialog' onHide={closeModal}>
        <ModalBody>
          <div className='invite-confirmation' style={{ maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' }}>
            <div className='close-collab-dialog'>
              <CloseButton onClick={closeModal} />
            </div>
            <div className='welcome-dialog-header'>
              <p>{getProperty('coowner-popup.line1')}</p>
              <h3 className='header'>{getProperty('coowner-popup.line2')}</h3>
              <p>
                <span>{getProperty('coowner-popup.line3')}</span>
                <span>{getProperty('coowner-popup.line4')}</span>
                <span>{getProperty('coowner-popup.line5')}</span>
              </p>
              <p style={{ color: '#28a745' }}>{getProperty('coowner-popup.line6')}</p>
            </div>
            <div className='text-center'>
              <div className='store-button apple-button' onClick={() => this.redirectOnAppStore('apple')}></div>
              <div className='store-button google-button' onClick={() => this.redirectOnAppStore('google')}></div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default CoOwnerDialog;
