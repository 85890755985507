import logo from './logo.svg';
import './App.css';

import { UIRouter, UIView, hashLocationPlugin } from "@uirouter/react";
import React, { Component } from "react";
import { Provider as ReduxProvider } from "react-redux";

import store from "./redux/store";
import { app_states, uiRouterConfigFn } from "./routerconf/routerConfig";

class App extends Component {
  render() {
    return (
      <ReduxProvider store={store}>
        <UIRouter
          plugins={[hashLocationPlugin]}
          states={app_states}
          config={uiRouterConfigFn}
        >
          <div className="paths-web-container paths-background">
            <div className="container-fluid">
              <div className="row justify-content-md-center">
                <div className="paths-web-content col-md-12">
                  <UIView />
                </div>
              </div>
            </div>
          </div>
        </UIRouter>
      </ReduxProvider>
    );
  }
}

/* class App extends Component {
  render() {
    return (
      <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <p>Hello World</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
    );
  }
} */

export default App;
