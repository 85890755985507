import React, { Component, Fragment } from "react";
import { IMAGES } from "../../../constants/constants";
import PrintQRCode from "../../UserProfile/PrintQRCode";
import { getMobileOperatingSystem, getProperty } from "../../../helper/helper";
import CollabTagModal from "../../CollabTag/CollabTagModal";

class QRCodeWithPost extends Component {
  constructor() {
    super();
    this.state = {
      showCollabTagPopup: false,
      mobileOS: "",
    };
  }

  componentDidMount = () => {
    this.setState({
      mobileOS: getMobileOperatingSystem(),
    })
  }

  manageCollabTagPopup = () => {
    const { postHeader } = this.props;
    if (postHeader) {
      this.setState({
        showCollabTagPopup: true,
      });
    }
  };

  manageCollabTag = () => {
    const { showCollabTagPopup } = this.state;
    this.setState({
      showCollabTagPopup: !showCollabTagPopup,
    });
  };

  parseCreateDate = () => {
    const { post } = this.props;
    let createDate = new Date().toDateString();
    if (post && post.auditInfo && post.auditInfo.createdTime) {
      let createdTime = post.auditInfo.createdTime;
      if (createdTime._seconds) {
        createDate = new Date(createdTime._seconds * 1000).toDateString();
      } else {
        createDate = new Date(createdTime).toDateString();
      }
    }
    return createDate;
  };

  render() {
    const {
      post,
      postHeader,
      highResolution,
      userProfile,
      isPopup,
      transition,
      qrCodeValue,
    } = this.props;
    const { showCollabTagPopup, mobileOS } = this.state;
    const createDate = this.parseCreateDate();
    let MAX_LENGTH = 80;
    const qrCodeClass = isPopup ? "popup-qr-code-header" : "qr-code-header";
    return (
      <Fragment>
        {highResolution ? (
          <div
            className="qr-code-header-without-margin"
            id="collab-tag-high-resolution"
          >
            <div className="qr-code-header-content">
              <img
                alt="path icon"
                src={IMAGES.PATHS_ICON.default}
                className="user-profile-card-header-icon-without-margin"
              />
              <p className="user-profile-card-header-text-without-margin">
                {getProperty("label.qr-code-header-text")}
              </p>
            </div>
            <div className="feed-navigation qr-code">
              <div className="navbar-root paths-navbar">
                <div className="clearboth">
                  <nav className="navbar navbar-nav mb-4 navbar-path header-spacing">
                    <ul className="nav tabs group nav-pills collabtab-list">
                      <li className="active ">
                        <a
                          href={window.location}
                          className="nav-link anchor_style user-profile-card"
                        >
                          <div className="circle-without-margin"></div>
                        </a>
                      </li>
                      <li className="nav-item margintop">
                        <a
                          href={window.location}
                          className="nav-link icon-plus"
                        >
                          <img
                            alt="add me icon"
                            src={IMAGES.ADD_ME.default}
                            className="add-me-icon user-profile-card-without-margin"
                          />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="code-header-main qr-code">
              <div className="post-header-text user-profile-card-high-resolution">
                {post && post.title && (
                  <p className="post-title user-profile-card-without-margin">
                    {post.title}
                  </p>
                )}
                {post && post.auditInfo && post.auditInfo.createdBy && (
                  <span className="post-user-name user-profile-card-without-margin">
                    {post.auditInfo.createdBy}&nbsp;|&nbsp;{createDate}
                  </span>
                )}
                {post && post.place && (
                  <p className="post-place user-profile-card-without-margin">
                    {post.place}
                  </p>
                )}
              </div>
            </div>
            <div className="feed-post-carousel qr-code">
              <PrintQRCode
                qrcodeValue={qrCodeValue}
                postHeader={postHeader}
                qrcodehighresolution={true}
              />
            </div>
            {post && post.description && (
              <p className="single-post-description user-profile-card-without-margin">
                {post.description != null &&
                post.description.length > MAX_LENGTH
                  ? post.description.substring(0, MAX_LENGTH)
                  : post.description}
              </p>
            )}
          </div>
        ) : (
          <div
            className={qrCodeClass}
            id="collab-tag-with-margin"
            onClick={this.manageCollabTagPopup}
          >
            <div className="qr-code-header-content">
              <img
                alt="user profile icon"
                src={IMAGES.PATHS_ICON.default}
                className="user-profile-card-header-icon"
              />
              <p className="user-profile-card-header-text">
                {getProperty("label.qr-code-header-text")}
              </p>
            </div>
            <div className="feed-navigation qr-code">
              <div className="navbar-root paths-navbar">
                <div className="clearboth">
                  <nav className="navbar navbar-nav mb-4 navbar-path header-spacing">
                    <ul className="nav tabs group nav-pills collabtab-list">
                      <li className="active ">
                        <a
                          href={window.location}
                          className="nav-link anchor_style user-profile-card"
                        >
                          <div className="circle"></div>
                        </a>
                      </li>
                      <li className="nav-item margintop">
                        <a
                          href={window.location}
                          className="nav-link icon-plus"
                        >
                          <img
                            alt="add me icon"
                            src={IMAGES.ADD_ME.default}
                            className="add-me-icon user-profile-card"
                          />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="code-header-main qr-code">
              <div className="post-header-text user-profile-card">
                {post && post.title && (
                  <p className="post-title user-profile-card">{post.title}</p>
                )}
                {post && post.auditInfo && post.auditInfo.createdBy && (
                  <span className="post-user-name user-profile-card">
                    {post.auditInfo.createdBy}&nbsp;|&nbsp;{createDate}
                  </span>
                )}
              </div>
            </div>
            <div className="feed-post-carousel qr-code">
              <PrintQRCode
                qrcodeValue={window.location.href}
                userProfile={userProfile}
                qrcodehighresolution={false}
                postHeader={postHeader}
                isPopup={isPopup}
              />
            </div>
            <div className="feed-post-carousel-description">
              {post && post.description && (
                <p className="single-post-description user-profile-card">
                  {post.description != null &&
                  post.description.length > MAX_LENGTH
                    ? post.description.substring(0, MAX_LENGTH)
                    : post.description}
                </p>
              )}
            </div>
          </div>
        )}
        {showCollabTagPopup && (
          <CollabTagModal
            show={showCollabTagPopup}
            manageCollabTag={this.manageCollabTag}
            post={post}
            transition={transition}
            mobileOS={mobileOS}
          />
        )}
      </Fragment>
    );
  }
}

export default QRCodeWithPost;
