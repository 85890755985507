import "../../../styles/style.css";

import React, { Component, Fragment } from "react";

import { getProperty } from "../../../helper/helper";
import LoginModal from "../../loginpage/LoginModal";
import GetPathsOverlay from "../../layouts/GetPathsOverlay";

class PostCommentBox extends Component {
  constructor() {
    super();
    this.state = {
      showGetPaths: false,
    };
  }

  manageGetPaths = () => {
    this.setState({
      showGetPaths: !this.state.showGetPaths,
    });
  };

  render() {
    const {showGetPaths} = this.state;
    const {
      postCommentData,
      isSelectFile,
      selectedFile,
      removeImage,
      comment,
      selectImage,
      changeSelectedImage,
      showError,
      showLogin,
      updateShowLogin,
      valueChange,
      postCommentIcon,
      userInfo,
    } = this.props;

    return (
      <Fragment>
        <div className="mb-3">
          {isSelectFile && (
            <div className="profile-pic">
              <img
                alt="profile pic"
                className="img-fluid img-thumbnail"
                src={selectedFile}
              />
              <a href={() => false} className="edit" onClick={removeImage}>
                <span className="text-white">
                  <i className="fa fa-remove"></i>
                </span>
              </a>
            </div>
          )}
          <div className="input-group logout-input-group post-comment-box align-items-end">
            <textarea
              ref="comment"
              className="form-control form-comment-area"
              placeholder={getProperty("comment-section.write-comment")}
              name="comment"
              type="text"
              value={comment}
              onChange={selectImage}
              rows="3"
            />
            <div className="input-group-append fileattachment">
              <label className="lab">
                <span className="icon-style">
                  <i className="fa fa-paperclip"></i>
                </span>
                <input
                  type="file"
                  name="file"
                  className="inputfile"
                  onChange={changeSelectedImage}
                  ref={valueChange}
                  onClick={(event) => {
                    event.currentTarget.value = null;
                  }}
                  accept=".jpeg,.jpg,.png,.apng,.gif,.svg,.bmp,.ico"
                />
              </label>
              <img alt="" src={postCommentIcon.default} onClick={postCommentData} />
            </div>
          </div>
        </div>
        {showError && (
          <span className="error comment-warning">
            {getProperty("comment-section.warning-attachment")}
          </span>
        )}

        {!userInfo.user && showLogin && (
          <LoginModal
            show={showLogin}
            updateShowLogin={updateShowLogin}
            showPhoneNumberExists={this.props.showPhoneNumberExists}
            manageGetPaths={this.manageGetPaths}
          />
        )}

        {showGetPaths && (
          <GetPathsOverlay
            show={showGetPaths}
            manageGetPaths={this.manageGetPaths}
          />
        )}
      </Fragment>
    );
  }
}

export default PostCommentBox;
