import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import InviteHeader from './index';
import InviteParentPostMessage from './InviteParentPostMessage';

const InviteParentPostWrapper = (props) => {
  return (
    <Fragment>
      <InviteHeader
        isMainFeed={props.isMainFeed}
        imageUrl={props.imageUrl}
        owner={props.owner}
        userInfo={props.userInfo}
        getPosts={props.getPostByPostId}
        showPhoneNumberExists={props.showPhoneNumberExists}
        isInvitationAccepted={props.isInvitationAccepted}
      />
      <InviteParentPostMessage parentPostId={props.parentPostId} parentPostOwnerId={props.parentPostOwnerId} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, {})(InviteParentPostWrapper);
