import React from 'react';

import { getProperty } from '../../helper/helper';

const InviteParentPostMessage = (props) => {
  const { parentPostOwnerId, parentPostId } = props;
  return (
    <div className='error text-center parent-post-error'>
      <div className='paths-web-error'>
        <p>{getProperty('label.invite-parent-post-line-1')}</p>
        <p>{getProperty('label.invite-parent-post-line-2')}</p>
        <p>
          <a href={`${window.location.pathname}#/${parentPostOwnerId}/posts/${parentPostId}`}>
            {`${window.location.host}${window.location.pathname}#/${parentPostOwnerId}/posts/${parentPostId}`}
          </a>
        </p>
      </div>
    </div>
  );
};
export default InviteParentPostMessage;
