import React, { memo, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { IMAGES, NAVIGATION, STRINGS } from "../../constants/constants";
import { functions } from "../../firebase/firebaseConfig";
import { getProperty } from "../../helper/helper";
import {publicStorageBucket} from '../../constants/constants';
const data = require("../../data/data.json");
const apiName = require("../../data/apiList.json");
const updatePostVisibility = functions.httpsCallable(
  apiName.updatePostVisibility
);
const deletePostById = functions.httpsCallable(apiName.deletePostById);
const unlinkCollaboratorPost = functions.httpsCallable(
  apiName.unlinkCollaboratorPost
);
const reportPost = functions.httpsCallable(apiName.reportPost);

const OptionsOverlay = (props) => {
  const [showSocialMediaButton, setShowSocialMediaButton] = useState(false);
  const [isConfirmReportPost, setIsConfirmReportPost] = useState(false);
  const [showReportPostStatus, setShowReportPostStatus] = useState(false);
  const [isPostReported, setIsPostReported] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [postURL, setPostURL] = useState("");
  const [postPrivacy, setPostPrivacy] = useState(props.isSelectedPostPrivate);
  const [postHostUserId, setPostHostUserId] = useState("");

  useEffect(() => {
    if (props.post && props.post.parentPostOwnerId) {
      setPostHostUserId(props.post.parentPostOwnerId);
    } else {
      setPostHostUserId(props.post.owner.userId);
    }
  }, []);

  const editSelectedPost = () => {
    const { transition, postOwner, userInfo, post } = props;
    let userId = "";
    if (userInfo && userInfo.uid) {
      userId = userInfo.uid;
    }
    transition.router.stateService.go(NAVIGATION.CREATE_NEW_POST, {
      userId: postOwner.userId,
      postOwner: postOwner,
      userInfo: userInfo,
      post: post,
    });
  };

  const deleteSelectedPost = () => {
    setShowLoader(true);
    let params = {
      postId: props.selectedPostId,
      apiKey: data.apiKey,
      source: data.apiSource,
    };
    deletePostById(params)
      .then((response) => {
        if (props.post && props.post.parentPostId) {
          props.hideUnlinkedPost(props.post.owner.userId);
          setShowLoader(false);
          props.manageOptionOverlay();
        } else {
          sessionStorage.setItem(STRINGS.DELETED_POST_ID, props.selectedPostId);
          const { transition, isPathMain } = props;
          if (!isPathMain) {
            transition.router.stateService.go(NAVIGATION.POSTS, {
              userId: props.post.owner.userId,
            });
          } else {
            setShowLoader(false);
            props.showUpdatedPost();
            props.manageOptionOverlay();
          }
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const pushToFacebook = () => {
    let prodPostURL = "";
    if (publicStorageBucket === STRINGS.PATHS_PROD_STORAGE_BUCKET) {
      prodPostURL =
        STRINGS.PATHS_PROD +
        props.selectedUserId +
        STRINGS.POST_QUERY_PARAM +
        props.selectedPostId +
        STRINGS.POST_FRAGMENT +
        props.selectedUserId +
        STRINGS.POST_PARAM +
        props.selectedPostId;
    } else {
      prodPostURL =
        STRINGS.PATHS_QA +
        props.selectedUserId +
        STRINGS.POST_QUERY_PARAM +
        props.selectedPostId +
        STRINGS.POST_FRAGMENT +
        props.selectedUserId +
        STRINGS.POST_PARAM +
        props.selectedPostId;
    }
    let encodedURL = encodeURIComponent(prodPostURL);
    let anchorElement = document.createElement("a");
    anchorElement.href = STRINGS.FACEBOOK_SHARE_URL + encodedURL;
    anchorElement.target = "blank";
    anchorElement.click();
    props.manageOptionOverlay();
  };

  const managePostPrivacy = () => {
    setShowLoader(true);
    let postData = {
      private: Boolean,
      postId: String,
    };
    if (props.selectedPostId) {
      postData.postId = props.selectedPostId;
    }
    postData.private = !props.isSelectedPostPrivate;
    let params = {
      post: postData,
      apiKey: data.apiKey,
      source: data.apiSource,
    };
    updatePostVisibility(params)
      .then((response) => {
        setPostPrivacy(!postPrivacy);
        props.manageOptionOverlay();
        props.showUpdatedPost();
      })
      .catch((error) => {
        props.manageOptionOverlay();
        setShowLoader(false);
      });
  };

  const copyPostUrl = () => {
    let prodPostURL = "";
    if (publicStorageBucket === STRINGS.PATHS_PROD_STORAGE_BUCKET) {
      console.log("STRINGS.PATHS_PROD-STRINGS.PATHS_PROD", STRINGS.PATHS_PROD);
      prodPostURL =
        STRINGS.PATHS_PROD +
        props.selectedUserId +
        STRINGS.POST_QUERY_PARAM +
        props.selectedPostId +
        STRINGS.POST_FRAGMENT +
        props.selectedUserId +
        STRINGS.POST_PARAM +
        props.selectedPostId;
    } else {
      console.log("STRINGS.PATHS_QA--STRINGS.PATHS_QA", STRINGS.PATHS_QA);
      prodPostURL =
        STRINGS.PATHS_QA +
        props.selectedUserId +
        STRINGS.POST_QUERY_PARAM +
        props.selectedPostId +
        STRINGS.POST_FRAGMENT +
        props.selectedUserId +
        STRINGS.POST_PARAM +
        props.selectedPostId;
    }
    console.log(".....", prodPostURL);
    const dummy = document.createElement("p");
    dummy.textContent = prodPostURL;
    document.body.appendChild(dummy);

    const range = document.createRange();
    range.setStartBefore(dummy);
    range.setEndAfter(dummy);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");
    document.body.removeChild(dummy);
    props.manageOptionOverlay();
  };

  const sharePostUrl = () => {
    let prodPostURL = "";
    if (publicStorageBucket === STRINGS.PATHS_PROD_STORAGE_BUCKET) {
      prodPostURL =
        STRINGS.PATHS_PROD +
        props.selectedUserId +
        STRINGS.POST_QUERY_PARAM +
        props.selectedPostId +
        STRINGS.POST_FRAGMENT +
        props.selectedUserId +
        STRINGS.POST_PARAM +
        props.selectedPostId;
    } else {
      prodPostURL =
        STRINGS.PATHS_QA +
        props.selectedUserId +
        STRINGS.POST_QUERY_PARAM +
        props.selectedPostId +
        STRINGS.POST_FRAGMENT +
        props.selectedUserId +
        STRINGS.POST_PARAM +
        props.selectedPostId;
    }
    setPostURL(prodPostURL);
    setShowSocialMediaButton(true);
  };

  const disconnectCollabTab = () => {
    setShowLoader(true);
    let params = {
      postId: String,
      coOwnerUserId: String,
      apiKey: data.apiKey,
      source: data.apiSource,
    };
    params.postId = props.post.parentPostId;
    params.coOwnerUserId = props.post.owner.userId;
    unlinkCollaboratorPost(params)
      .then((response) => {
        props.hideUnlinkedPost(params.coOwnerUserId);
        setShowLoader(false);
        props.manageOptionOverlay();
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const postReport = () => {
    if (props.post && !props.post.isReported) {
      if (!isConfirmReportPost && !showReportPostStatus && !isPostReported) {
        setIsConfirmReportPost(true);
      } else if (isConfirmReportPost) {
        setIsConfirmReportPost(false);
        setShowReportPostStatus(true);
      } else {
        setShowLoader(true);
        let params = {
          postId: String,
          apiKey: data.apiKey,
          source: data.apiSource,
        };
        params.postId = props.post.id;
        reportPost(params)
          .then((response) => {
            setShowReportPostStatus(false);
            setIsPostReported(true);
            setShowLoader(false);
          })
          .catch((error) => {
            setShowLoader(false);
          });
      }
    } else {
      setShowReportPostStatus(true);
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        dialogClassName="modal-lg options-overlay-dialog"
        onHide={() => props.manageOptionOverlay()}
        size="lg"
        backdrop="static"
        fullscreen={true}
      >
        <ModalBody
          onClick={
            showSocialMediaButton ? () => props.manageOptionOverlay() : ""
          }
        >
          <div>
            {showLoader && (
              <div className="login-overlay">
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            )}
            {!showSocialMediaButton &&
              !isConfirmReportPost &&
              !showReportPostStatus &&
              !isPostReported && (
                <div
                  className="options-overlay"
                  style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
                >
                  {props.userInfo &&
                    props.userInfo.user &&
                    props.userInfo.user.uid === props.post.owner.userId && (
                      <div
                        className="options-overlay-option"
                        onClick={() => editSelectedPost()}
                      >
                        {getProperty("option-overlay.edit")}
                      </div>
                    )}
                  {props.userInfo &&
                    props.userInfo.user &&
                    props.userInfo.user.uid === props.post.owner.userId && (
                      <div
                        className="options-overlay-option"
                        onClick={() => deleteSelectedPost()}
                      >
                        {getProperty("option-overlay.delete")}
                      </div>
                    )}
                  {props.userInfo &&
                    props.userInfo.user &&
                    props.userInfo.user.uid === props.post.owner.userId && (
                      <div
                        className="options-overlay-option"
                        onClick={() => pushToFacebook()}
                      >
                        {getProperty("option-overlay.push-to-facebook")}
                      </div>
                    )}
                  <div className="options-overlay-option" onClick={copyPostUrl}>
                    {getProperty("option-overlay.copy-url")}
                  </div>
                  {props.userInfo &&
                    props.userInfo.user &&
                    props.userInfo.user.uid !== props.post.owner.userId &&
                    props.userInfo.user.uid === postHostUserId && (
                      <div
                        className="options-overlay-option"
                        onClick={disconnectCollabTab}
                      >
                        {getProperty("option-overlay.disconnect-tab")}
                      </div>
                    )}
                  {props.userInfo &&
                    props.userInfo.user &&
                    props.userInfo.user.uid !== props.post.owner.userId && (
                      <div
                        className="options-overlay-option"
                        onClick={postReport}
                      >
                        {getProperty("option-overlay.report")}
                      </div>
                    )}
                  {props.userInfo &&
                    props.userInfo.user &&
                    props.userInfo.user.uid === props.post.owner.userId && (
                      <div
                        className="options-overlay-option"
                        onClick={sharePostUrl}
                      >
                        {getProperty("option-overlay.share")}
                      </div>
                    )}
                  {props.userInfo &&
                    props.userInfo.user &&
                    props.userInfo.user.uid === props.post.owner.userId &&
                    (postPrivacy ? (
                      <div
                        className="options-overlay-option"
                        onClick={() => managePostPrivacy()}
                      >
                        {getProperty("option-overlay.mark-as-public")}
                      </div>
                    ) : (
                      <div
                        className="options-overlay-option"
                        onClick={() => managePostPrivacy()}
                      >
                        {getProperty("option-overlay.mark-as-private")}
                      </div>
                    ))}
                  <div
                    className="options-overlay-option"
                    onClick={() => props.manageOptionOverlay()}
                  >
                    {getProperty("option-overlay.cancel")}
                  </div>
                </div>
              )}
            {isConfirmReportPost && (
              <div className="confirm-report-post">
                <div className="confirm-report-post-text">
                  <p>
                    {getProperty("option-overlay.sure-to-report-this-post")}
                  </p>
                </div>
                <hr />
                <div className="confirm-report-post-ok row">
                  <div
                    className="confirm-report-post-cancel col-6 pt-2 pb-2"
                    onClick={() => props.manageOptionOverlay()}
                  >
                    {getProperty("option-overlay.cancel")}
                  </div>
                  <div className="col-6 pt-2 pb-2" onClick={() => postReport()}>
                    {getProperty("option-overlay.report")}
                  </div>
                </div>
              </div>
            )}
            {showReportPostStatus && (
              <div className="options-overlay">
                <div className="options-overlay-option">
                  <img
                    alt="exclamation icon"
                    src={IMAGES.EXCLAMATION_ICON.default}
                    className="report-post-exclamation-icon"
                  />
                  {props.post && props.post.isReported ? (
                    <p>
                      {getProperty(
                        "option-overlay.you-already-reported-this-post"
                      )}
                    </p>
                  ) : (
                    <p>
                      {getProperty("option-overlay.is-this-post-inappropriate")}
                    </p>
                  )}
                  <hr />
                  {props.post && props.post.isReported ? (
                    <div
                      className="report-post-btn pt-2"
                      onClick={() => props.manageOptionOverlay()}
                    >
                      {getProperty("option-overlay.reported")}
                    </div>
                  ) : (
                    <div
                      className="report-post-btn pt-2"
                      onClick={() => postReport()}
                    >
                      {getProperty("option-overlay.report-post")}
                    </div>
                  )}
                </div>
                <div
                  className="options-overlay-option"
                  onClick={() => props.manageOptionOverlay()}
                >
                  {getProperty("option-overlay.cancel")}
                </div>
              </div>
            )}
            {isPostReported && (
              <div className="confirm-report-post">
                <div className="confirm-report-post-text">
                  <p>
                    {getProperty(
                      "option-overlay.thanks-for-helping-paths-civilized"
                    )}
                  </p>
                </div>
                <hr />
                <div
                  className="confirm-report-post-ok pt-2 pb-2"
                  onClick={() => props.manageOptionOverlay()}
                >
                  {getProperty("option-overlay.ok")}
                </div>
              </div>
            )}
            {showSocialMediaButton && (
              <div
                className="social-media-buttons"
                onClick={() => props.manageOptionOverlay()}
              >
                <FacebookShareButton
                  url={postURL}
                  quote={"CampersTribe - World is yours to explore"}
                  hashtag="#camperstribe"
                  className="individual-social-media-buttons"
                >
                  <FacebookIcon size={100} round={true} />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={postURL}
                  title={"CampersTribe - World is yours to explore"}
                  className="individual-social-media-buttons"
                >
                  <WhatsappIcon size={100} round={true} />
                </WhatsappShareButton>
                <TwitterShareButton
                  url={postURL}
                  title={"CampersTribe - World is yours to explore"}
                  hashtag="#camperstribe"
                  className="individual-social-media-buttons"
                >
                  <TwitterIcon size={100} round={true} />
                </TwitterShareButton>
                <TelegramShareButton
                  title={"CampersTribe - World is yours to explore"}
                  url={postURL}
                  className="individual-social-media-buttons"
                >
                  <TelegramIcon size={100} round={true} />
                </TelegramShareButton>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default memo(OptionsOverlay);
