import React, { Fragment, memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IMAGES, NAVIGATION, STRINGS } from "../../constants/constants";
import defaultUserImage from "../../images/icons/user.jpg";
import { getProperty } from "../../helper/helper";
import PrintQRCode from "../UserProfile/PrintQRCode";
import ChooseCarousel from "./ChooseCarousel";
import { functions } from "../../firebase/firebaseConfig";
import firebase from "firebase/app";
import "firebase/storage";
import { isEmpty } from "lodash";
import { downloadQRCode } from "../../util/util";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {googleStoreLink, appleStoreLink} from '../../constants/constants';
import {publicStorageBucket} from '../../constants/constants';
const handleImageError = (ev) => {
  ev.target.src = defaultUserImage;
};
const data = require("../../data/data.json");
const apiName = require("../../data/apiList.json");
const addPost = functions.httpsCallable(apiName.addPost);
const addAttachmentsInPost = functions.httpsCallable(
  apiName.addAttachmentsInPost
);
const getUserCollaborators = functions.httpsCallable(
  apiName.getUserCollaborators
);
const sendCollabtabInvitation = functions.httpsCallable(
  apiName.sendCollabtabInvitation
);
const searchUsersByNameAndEmailES = functions.httpsCallable(
  apiName.searchUsersByNameAndEmailES
);
const addCoOwnerPost = functions.httpsCallable(apiName.addCoOwnerPost);
const updatePostVisibility = functions.httpsCallable(
  apiName.updatePostVisibility
);
const updatePost = functions.httpsCallable(apiName.updatePost);

const CreateMemoryScreen = (props) => {
  const [isCreateMemory, setIsCreateMemory] = useState(true);
  const [isMemorySetting, setIsMemorySetting] = useState(false);
  const [isInvite, setIsInvite] = useState(false);
  const [isQRConfirm, setIsQRConfirm] = useState(false);
  const [isGetTheApp, setIsGetTheApp] = useState(false);
  const [isChooseCarousel, setIsChooseCarousel] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isTeachableTakeaway, setIsTeachableTakeaway] = useState(false);
  const [isGratitudeJournal, setIsGratitudeJournal] = useState(false);
  const [isLinkList, setIsLinkList] = useState(false);
  const [isOpenCollabTab, setIsOpenCollabTab] = useState(false);
  const [isAutoApprove, setIsAutoApprove] = useState(true);
  const [isFacebook, setIsFacebook] = useState(false);
  const [isSkipQR, setIsSkipQR] = useState(false);
  const [postData, setPostdata] = useState(props.$stateParams);
  const [photoUrl, setPhotoUrl] = useState("");
  const [footerShadowClass, setFooterShadowClass] = useState(
    STRINGS.CREATE_MEMORY
  );
  const [carouelData, setCarouselData] = useState([]);
  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [location, setLocation] = useState("");
  const [teachableTakeawayTitle, setTeachableTakeawayTitle] = useState("");
  const [teachableTakeawayDescription, setTeachableTakeawayDescription] =
    useState("");
  const [gratitudeJournalTitle, setGratitudeJournalTitle] = useState("");
  const [gratitudeJournalDescription, setGratitudeJournalDescription] =
    useState("");
  const [link, setLink] = useState("");
  const [linkList, setLinkList] = useState([]);
  const [defaultCreationTime, setDefaultCreationTime] = useState("");
  const [isTTPublic, setIsTTPublic] = useState(true);
  const [isGJPublic, setIsGJPublic] = useState(true);
  const [newPostId, setNewPostId] = useState("");
  const [friendList, setFriendList] = useState({});
  const [followingList, setFollowingList] = useState({});
  const [followerList, setFollowerList] = useState({});
  const [searchUsersData, setSearchUsersData] = useState({});
  const [inviteUsersSelectedType, setInviteUserSelectedType] = useState(
    STRINGS.FRIENDS_OPTION
  );
  const [searchUserKeyword, setSearchUserKeyword] = useState("");
  const [createdPostUrl, setCreatedPostUrl] = useState("");
  const [newPostData, setNewPostData] = useState({});

  const carouselDataSettings = {
    dots: true,
    infinite: false,
    arrows: false,
  };

  useEffect(() => {
    if (
      postData &&
      postData.userInfo &&
      postData.userInfo.user &&
      postData.userInfo.user.photoURL
    ) {
      setPhotoUrl(postData.userInfo.user.photoURL);
    }
    if (!isEmpty(postData.post)) {
      setPostTitle(postData.post.title);
      document.getElementById("memory-title").value = postData.post.title;
      setPostDescription(postData.post.description);
      document.getElementById("memory-description").value =
        postData.post.description;
      setIsPrivate(postData.post.private);
      setLocation(postData.post.place);
      if (postData.post.auditInfo && postData.post.auditInfo.createdTime) {
        let postCreationDate = new Date(postData.post.auditInfo.createdTime);
        let postDefaultCreationTime =
          postCreationDate.getFullYear() +
          STRINGS.HYPHEN +
          ((postCreationDate.getMonth() + 1 < STRINGS.TEN ? STRINGS.ZERO : "") +
            (postCreationDate.getMonth() + 1)) +
          STRINGS.HYPHEN +
          ((postCreationDate.getDate() < STRINGS.TEN ? STRINGS.ZERO : "") +
            postCreationDate.getDate()) +
          STRINGS.CONSTANT_T +
          ((postCreationDate.getHours() < STRINGS.TEN ? STRINGS.ZERO : "") +
            postCreationDate.getHours()) +
          STRINGS.COLON +
          ((postCreationDate.getMinutes() < STRINGS.TEN ? STRINGS.ZERO : "") +
            postCreationDate.getMinutes());
        setDefaultCreationTime(postDefaultCreationTime);
      }
      if (postData.post && postData.post.teachableTakeaway) {
        setIsTeachableTakeaway(true);
        setTeachableTakeawayTitle(postData.post.teachableTakeaway.title);
        setTeachableTakeawayDescription(
          postData.post.teachableTakeaway.description
        );
        setIsTTPublic(
          postData.post.teachableTakeaway.privacy === STRINGS.PUBLIC
            ? true
            : false
        );
      }
      if (postData.post && postData.post.gratitudeJournal) {
        setIsGratitudeJournal(true);
        setGratitudeJournalTitle(postData.post.gratitudeJournal.title);
        setGratitudeJournalDescription(
          postData.post.gratitudeJournal.description
        );
        setIsGJPublic(
          postData.post.gratitudeJournal.privacy === STRINGS.PUBLIC
            ? true
            : false
        );
      }
      if (
        postData.post &&
        postData.post.siteLinks &&
        postData.post.siteLinks.length > 0
      ) {
        setIsLinkList(true);
        postData.post.siteLinks.map((link) => {
          linkList.push({
            id: Math.floor(Math.random() * 10) + 1,
            name: link,
          });
        });
      }
      postData.post.attachments.map((singleAttachment) => {
        let attachmentData = {
          data: String,
          type: String,
          extension: String,
          thumbnail: String,
        };
        attachmentData.data = singleAttachment.URL;
        if (singleAttachment.type === STRINGS.VIDEO) {
          attachmentData.thumbnail = singleAttachment.thumbnailURL;
        } else {
          attachmentData.thumbnail = singleAttachment.backgroundImageURL;
        }
        attachmentData.type = singleAttachment.type;
        carouelData.push(attachmentData);
      });
    }
  }, []);

  const manageSelectedOption = (selectedOption) => {
    if (selectedOption === STRINGS.FRIENDS_OPTION) {
      document.getElementById(STRINGS.FRIENDS_OPTION).className =
        "col-3 selected-search-option";
      setInviteUserSelectedType(STRINGS.FRIENDS_OPTION);
      document.getElementById(STRINGS.FOLLOWING_OPTION).className =
        "col-3 search-option";
      document.getElementById(STRINGS.FOLLOWER_OPTION).className =
        "col-3 search-option";
      document.getElementById("off-paths-option").className =
        "col-3 search-option";
    } else if (selectedOption === STRINGS.FOLLOWING_OPTION) {
      document.getElementById(STRINGS.FRIENDS_OPTION).className =
        "col-3 search-option";
      document.getElementById(STRINGS.FOLLOWING_OPTION).className =
        "col-3 selected-search-option";
      setInviteUserSelectedType(STRINGS.FOLLOWING_OPTION);
      document.getElementById(STRINGS.FOLLOWER_OPTION).className =
        "col-3 search-option";
      document.getElementById("off-paths-option").className =
        "col-3 search-option";
    } else if (selectedOption === STRINGS.FOLLOWER_OPTION) {
      document.getElementById(STRINGS.FRIENDS_OPTION).className =
        "col-3 search-option";
      document.getElementById(STRINGS.FOLLOWING_OPTION).className =
        "col-3 search-option";
      document.getElementById(STRINGS.FOLLOWER_OPTION).className =
        "col-3 selected-search-option";
      setInviteUserSelectedType(STRINGS.FOLLOWER_OPTION);
      document.getElementById("off-paths-option").className =
        "col-3 search-option";
    } else if (selectedOption === "off-paths-option") {
      document.getElementById(STRINGS.FRIENDS_OPTION).className =
        "col-3 search-option";
      document.getElementById(STRINGS.FOLLOWING_OPTION).className =
        "col-3 search-option";
      document.getElementById(STRINGS.FOLLOWER_OPTION).className =
        "col-3 search-option";
      document.getElementById("off-paths-option").className =
        "col-3 selected-search-option";
    }
  };

  const uploadAttachmentToFirebaseStorage = (response) => {
    let result = [];
    return new Promise(async (resolve, reject) => {
      try {
        if (response && response.data) {
          const userId = postData.userInfo.user.uid;
          const postId = response.data.id
            ? response.data.id
            : response.data.postData.id;
          for (let i = 0; i < carouelData.length; i++) {
            if (!carouelData[i].data.includes(STRINGS.FIREBASE_STORAGE)) {
              let data = {
                URL: "",
                description: null,
                type: "",
              };
              result.push(data);
              const timestamp = new Date().getTime();
              const extension = carouelData[i].extension;
              const filename = timestamp + 1000 * i + "." + extension;
              const filePath = `/${userId}/posts/${postId}`;
              let mime = "";
              data.type = carouelData[i].type;
              mime = carouelData[i].type + STRINGS.FORWARD_SLASH + extension;
              if (carouelData[i].type === STRINGS.VIDEO) {
                const thumbnailFileName = `${
                  filename.split(".")[0]
                }_thumbnail.jpg`;
                const storage = await firebase.storage();
                await storage
                  .ref(`${filePath}/${thumbnailFileName}`)
                  .putString(
                    String(carouelData[i].thumbnail),
                    STRINGS.DATA_URL,
                    {
                      contentType: "image/jpeg",
                    }
                  );
                const thumbnailURL = await storage
                  .ref(`${filePath}/${thumbnailFileName}`)
                  .getDownloadURL();
                if (thumbnailURL) {
                  data.thumbnailURL = thumbnailURL;
                }
              }
              const storage = await firebase.storage();
              const uploadTask = await storage
                .ref(`${filePath}/${filename}`)
                .putString(String(carouelData[i].data), STRINGS.DATA_URL, {
                  contentType: mime,
                });
              const urlDownload = await storage
                .ref(`${filePath}/${filename}`)
                .getDownloadURL();
              data.URL = decodeURIComponent(urlDownload);
              result[i] = data;
              if (i + 1 === carouelData.length) {
                let postAttachment = {
                  postId: postId,
                  attachments: result,
                };
                resolve(postAttachment);
              }
            } else {
              let data = {
                URL: "",
                description: null,
                type: "",
              };
              data.type = carouelData[i].type;
              data.URL = carouelData[i].data;
              if (carouelData[i].thumbnail) {
                data.thumbnailURL = carouelData[i].thumbnail;
              }
              result[i] = data;
              if (i + 1 === carouelData.length) {
                let postAttachment = {
                  postId: postId,
                  attachments: result,
                };
                resolve(postAttachment);
              }
            }
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const getTeachableTakeawayData = () => {
    let ttData = {
      description: "",
      privacy: "",
      title: "",
    };
    if (isTeachableTakeaway && teachableTakeawayDescription) {
      ttData.description = teachableTakeawayDescription;
    }
    if (isTeachableTakeaway && teachableTakeawayTitle) {
      ttData.title = teachableTakeawayTitle;
    }
    if (isTeachableTakeaway) {
      if (isTTPublic) {
        ttData.privacy = STRINGS.PUBLIC;
      } else {
        ttData.privacy = STRINGS.PRIVATE;
      }
    }
    return ttData;
  };

  const getGratitudeJournalData = () => {
    let gjData = {
      description: "",
      privacy: "",
      title: "",
    };
    if (isGratitudeJournal && gratitudeJournalDescription) {
      gjData.description = gratitudeJournalDescription;
    }
    if (isGratitudeJournal && gratitudeJournalTitle) {
      gjData.title = gratitudeJournalTitle;
    }
    if (isGratitudeJournal) {
      if (isGJPublic) {
        gjData.privacy = STRINGS.PUBLIC;
      } else {
        gjData.privacy = STRINGS.PRIVATE;
      }
    }
    return gjData;
  };

  const getLinkListData = () => {
    let llObject = [];
    linkList.map((list) => {
      llObject.push(list.name);
    });
    return llObject;
  };

  const navigateNextScreen = async () => {
    if (isCreateMemory) {
      if (carouelData.length > 0 || postDescription) {
        let currentDate = new Date();
        if (isEmpty(defaultCreationTime)) {
          let defaultTimeOfSelectedCarousel =
            currentDate.getFullYear() +
            STRINGS.HYPHEN +
            ((currentDate.getMonth() + 1 < STRINGS.TEN ? STRINGS.ZERO : "") +
              (currentDate.getMonth() + 1)) +
            STRINGS.HYPHEN +
            ((currentDate.getDate() < STRINGS.TEN ? STRINGS.ZERO : "") +
              currentDate.getDate()) +
            STRINGS.CONSTANT_T +
            ((currentDate.getHours() < STRINGS.TEN ? STRINGS.ZERO : "") +
              currentDate.getHours()) +
            STRINGS.COLON +
            ((currentDate.getMinutes() < STRINGS.TEN ? STRINGS.ZERO : "") +
              currentDate.getMinutes());
          setDefaultCreationTime(defaultTimeOfSelectedCarousel);
        }
        setIsCreateMemory(false);
        setIsMemorySetting(true);
        setFooterShadowClass(STRINGS.MEMORY_SETTINGS);
      }
    } else if (isMemorySetting) {
      if (isEmpty(postData.post)) {
        document.getElementById("memory-setting-next-btn").innerHTML =
          '<i class = "fa fa-spinner fa-spin" style = "font-size:25px"></i> ';
        let teachableTakeawayObject = getTeachableTakeawayData();
        let gratitudeJournalObject = getGratitudeJournalData();
        let linkListObect = getLinkListData();
        let postDetails = {
          description: postDescription,
          attachments: [],
          private: isPrivate,
          createdTime: new Date(defaultCreationTime).getTime(),
          title: postTitle,
          // siteLinks: [],
          owner: {
            userId: postData.userInfo.user.uid,
          },
          auditInfo: {
            createdBy: postData.userInfo.user.email,
            createdTime: new Date(),
          },
          collabtabStatus: isOpenCollabTab ? STRINGS.OPEN : STRINGS.CLOSE,
          collabtabAutoApproveStatus: isAutoApprove,
          siteLinks: linkListObect,
          place: location,
        };
        if (teachableTakeawayDescription) {
          postDetails.teachableTakeaway = teachableTakeawayObject;
        }
        if (gratitudeJournalDescription) {
          postDetails.gratitudeJournal = gratitudeJournalObject;
        }
        let params = {
          post: postDetails,
          apiKey: data.apiKey,
          source: data.apiSource,
        };
        if (postData && postData.parentPostId) {
          params.parentPostId = postData.parentPostId;
          params.parentPostPrivacy = postData.parentPostPrivacy;
        }
        const createNewPost = postData.parentPostId ? addCoOwnerPost : addPost;
        createNewPost(params)
          .then(async (response) => {
            setNewPostId(response.data.id);
            setNewPostData(response.data);
            if (carouelData.length > 0) {
              let attachmentRequestArray =
                await uploadAttachmentToFirebaseStorage(response);
              if (attachmentRequestArray) {
                addAttachmentsInPost({
                  ...attachmentRequestArray,
                  apiKey: data.apiKey,
                  source: data.apiSource,
                })
                  .then()
                  .catch((error) => {
                    // document.getElementById("memory-setting-next-btn").innerHTML =
                    //   STRINGS.NEXT;
                  });
              }
            }
            if (isEmpty(postData.parentPostId)) {
              await getUserCollaborators({
                nextPageToken: null,
                type: STRINGS.FRIEND,
                apiKey: data.apiKey,
                source: data.apiSource,
              })
                .then(async (res) => {
                  setFriendList(res);
                  await getUserCollaborators({
                    nextPageToken: null,
                    type: STRINGS.TRACKING,
                    apiKey: data.apiKey,
                    source: data.apiSource,
                  })
                    .then(async (res) => {
                      setFollowingList(res);
                      await getUserCollaborators({
                        nextPageToken: null,
                        type: STRINGS.TRACKER,
                        apiKey: data.apiKey,
                        source: data.apiSource,
                      })
                        .then((res) => {
                          setFollowerList(res);
                          setIsMemorySetting(false);
                          setIsInvite(true);
                        })
                        .catch((error) => {
                          document.getElementById(
                            "memory-setting-next-btn"
                          ).innerHTML = STRINGS.NEXT;
                        });
                    })
                    .catch((error) => {
                      document.getElementById(
                        "memory-setting-next-btn"
                      ).innerHTML = STRINGS.NEXT;
                    });
                })
                .catch((error) => {
                  document.getElementById("memory-setting-next-btn").innerHTML =
                    STRINGS.NEXT;
                });
            } else {
              setIsMemorySetting(false);
              setIsGetTheApp(true);
            }
          })
          .catch((error) => {
            document.getElementById("memory-setting-next-btn").innerHTML =
              STRINGS.NEXT;
          });
      } else {
        updatePostData();
      }
    } else if (isInvite) {
      let prodPostURL = "";
      if (publicStorageBucket === STRINGS.PATHS_PROD_STORAGE_BUCKET) {
        prodPostURL =
          STRINGS.PATHS_PROD +
          postData.userInfo.user.uid +
          STRINGS.POST_QUERY_PARAM +
          newPostId +
          STRINGS.POST_FRAGMENT +
          postData.userInfo.user.uid +
          STRINGS.POST_PARAM +
          newPostId;
        setCreatedPostUrl(prodPostURL);
      } else {
        prodPostURL =
          STRINGS.PATHS_QA +
          postData.userInfo.user.uid +
          STRINGS.POST_QUERY_PARAM +
          newPostId +
          STRINGS.POST_FRAGMENT +
          postData.userInfo.user.uid +
          STRINGS.POST_PARAM +
          newPostId;
        setCreatedPostUrl(prodPostURL);
      }
      if (isFacebook) {
        let anchorElement = document.createElement("a");
        anchorElement.href = STRINGS.FACEBOOK_SHARE_URL + prodPostURL;
        anchorElement.target = "blank";
        anchorElement.click();
      }

      if (isPrivate) {
        document.getElementById("memory-setting-pubish-btn").innerHTML =
          '<i class = "fa fa-spinner fa-spin" style = "font-size:25px"></i> ';
        let privacyData = {
          private: Boolean,
          postId: String,
        };
        privacyData.private = isPrivate;
        privacyData.postId = newPostId;
        let params = {
          post: privacyData,
          apiKey: data.apiKey,
          source: data.apiSource,
        };
        updatePostVisibility(params)
          .then((response) => {
            setIsInvite(false);
            if (isSkipQR) {
              setIsQRConfirm(true);
            } else {
              setIsGetTheApp(true);
            }
          })
          .catch((error) => {
            document.getElementById("memory-setting-pubish-btn").innerHTML =
              STRINGS.PUBLISH;
          });
      } else {
        setIsInvite(false);
        if (isSkipQR) {
          setIsQRConfirm(true);
        } else {
          setIsGetTheApp(true);
        }
      }
    } else if (isQRConfirm) {
      setIsQRConfirm(false);
      setIsGetTheApp(true);
    }
  };

  const updatePostData = () => {
    document.getElementById("memory-setting-next-btn").innerHTML =
      '<i class = "fa fa-spinner fa-spin" style = "font-size:25px"></i> ';
    let teachableTakeawayObject = getTeachableTakeawayData();
    let gratitudeJournalObject = getGratitudeJournalData();
    let linkListObect = getLinkListData();
    let postDetails = {
      description: postDescription,
      attachments: [],
      private: isPrivate,
      createdTime: new Date(defaultCreationTime).getTime(),
      title: postTitle,
      // siteLinks: [],
      owner: {
        userId: postData.userInfo.user.uid,
      },
      auditInfo: {
        createdBy: postData.userInfo.user.email,
        createdTime: new Date(),
      },
      collabtabStatus: isOpenCollabTab ? STRINGS.OPEN : STRINGS.CLOSE,
      collabtabAutoApproveStatus: isAutoApprove,
      siteLinks: linkListObect,
      place: location,
      postId: postData.post.id,
    };
    if (teachableTakeawayDescription) {
      postDetails.teachableTakeaway = teachableTakeawayObject;
    }
    if (gratitudeJournalDescription) {
      postDetails.gratitudeJournal = gratitudeJournalObject;
    }
    let params = {
      post: postDetails,
      apiKey: data.apiKey,
      source: data.apiSource,
    };
    updatePost(params)
      .then(async (response) => {
        setNewPostId(response.data.id);
        setNewPostData(response.data);
        if (carouelData.length > 0) {
          let attachmentRequestArray = await uploadAttachmentToFirebaseStorage(
            response
          );
          if (attachmentRequestArray) {
            addAttachmentsInPost({
              ...attachmentRequestArray,
              apiKey: data.apiKey,
              source: data.apiSource,
            })
              .then((response) => {
                if (isSkipQR || isFacebook) {
                  let prodPostURL = "";
                  if (
                    publicStorageBucket === STRINGS.PATHS_PROD_STORAGE_BUCKET
                  ) {
                    prodPostURL =
                      STRINGS.PATHS_PROD +
                      postData.userInfo.user.uid +
                      STRINGS.POST_QUERY_PARAM +
                      newPostId +
                      STRINGS.POST_FRAGMENT +
                      postData.userInfo.user.uid +
                      STRINGS.POST_PARAM +
                      newPostId;
                    setCreatedPostUrl(prodPostURL);
                  } else {
                    prodPostURL =
                      STRINGS.PATHS_QA +
                      postData.userInfo.user.uid +
                      STRINGS.POST_QUERY_PARAM +
                      newPostId +
                      STRINGS.POST_FRAGMENT +
                      postData.userInfo.user.uid +
                      STRINGS.POST_PARAM +
                      newPostId;
                    setCreatedPostUrl(prodPostURL);
                  }
                  if (isFacebook) {
                    let anchorElement = document.createElement("a");
                    anchorElement.href =
                      STRINGS.FACEBOOK_SHARE_URL + prodPostURL;
                    anchorElement.target = "blank";
                    anchorElement.click();
                  }
                }
                setIsMemorySetting(false);
                isSkipQR ? setIsQRConfirm(true) : setIsGetTheApp(true);
              })
              .catch((error) => {
                // document.getElementById("memory-setting-next-btn").innerHTML =
                //   STRINGS.NEXT;
              });
          }
        } else {
          setIsMemorySetting(false);
          setIsGetTheApp(true);
        }
      })
      .catch((error) => {});
  };

  const inviteUser = (inviteUserId) => {
    document.getElementById("invite-" + inviteUserId).innerHTML =
      '<i class = "fa fa-spinner fa-spin"></i> ';
    let params = {
      postId: newPostId,
      coOwnerUserId: inviteUserId,
      apiKey: data.apiKey,
      source: data.apiSource,
    };
    sendCollabtabInvitation(params)
      .then((response) => {
        document.getElementById("invite-" + inviteUserId).innerHTML =
          STRINGS.INVITED;
      })
      .catch((error) => {
        document.getElementById("invite-" + inviteUserId).innerHTML =
          STRINGS.INVITE;
      });
  };

  const searchUsers = (event) => {
    setSearchUserKeyword(event.target.value);
    setSearchUsersData({});
    if (event.target.value.length > 2) {
      let params = {
        searchKeyword: event.target.value,
        nextPageToken: null,
        apiKey: data.apiKey,
        source: data.apiSource,
      };
      searchUsersByNameAndEmailES(params)
        .then((res) => {
          document.getElementById("search-user-input").style.paddingBottom =
            "40px";
          setSearchUsersData(res.data);
        })
        .catch();
    } else if (event.target.value.length === 0) {
      document.getElementById("search-user-input").style.paddingBottom = "10px";
      setSearchUsersData({});
    }
  };

  const clearUserSearchArea = () => {
    setSearchUserKeyword("");
    setSearchUsersData({});
    document.getElementById("search-invite-user").value = "";
    document.getElementById("search-user-input").style.paddingBottom = "10px";
  };

  const showCreatedFullPost = () => {
    const { transition } = props;
    let userId = "";
    let postID = "";
    let newCollaboratorPostId = "";
    if (isEmpty(postData.post)) {
      newCollaboratorPostId = postData.parentPostId
        ? newPostData.collaboratorPostId
        : "";
    } else {
      newCollaboratorPostId =
        newPostData && newPostData.id ? newPostData.id : "";
    }
    if (isEmpty(postData.post)) {
      if (postData && postData.parentPostId) {
        userId = postData.userId;
        postID = postData.parentPostId;
      } else {
        userId = postData.userInfo.user.uid;
        postID = newPostId;
      }
    } else {
      if (newPostData && newPostData.parentPostOwnerId) {
        userId = newPostData.parentPostOwnerId;
        postID = newPostData.parentPostId;
      } else {
        userId = newPostData.owner.userId;
        postID = newPostData.id;
      }
    }
    transition.router.stateService.go(NAVIGATION.POSTDETAIL, {
      userId,
      postId: postID,
      newPostId: newCollaboratorPostId,
    });
  };

  const addSingleCarouselDataToArray = async (singleCarouselData) => {
    if (carouelData.length + singleCarouselData.length <= 10) {
      await singleCarouselData.map((data) => {
        setCarouselData((carouelData) => [...carouelData, data]);
      });
    }
  };

  const addLinkToList = () => {
    if (link) {
      setLinkList((previousValue) => [
        ...linkList,
        {
          id: Math.floor(Math.random() * 10) + 1,
          name: link,
        },
      ]);
      setLink("");
      document.getElementById("link-list-url").value = "";
    }
  };

  const removeLinkFromList = (url, urlId) => {
    try {
      let data = [...linkList];
      let filteredData = data.filter(
        (item) => item.id !== urlId && item.name !== url
      );
      setLinkList(filteredData);
    } catch (error) {}
  };

  const removeCarouselFromList = (index) => {
    try {
      let data = [...carouelData];
      let filteredData = data.filter((item, itemIndex) => itemIndex !== index);
      setCarouselData(filteredData);
    } catch (error) {}
  };

  const highResolutionCollabTag = () => {
    const { transition } = props;
    transition.router.stateService.go(NAVIGATION.SAVECOLLABTAG, {
      postId: newPostId,
      userId: postData.userInfo.user.uid,
      post: newPostData,
      postUrl: createdPostUrl,
    });
  };

  const redirectOnAppStore = (storeName) => {
    if (storeName === STRINGS.APPLE) {
      window.open(appleStoreLink, "_blank");
    } else {
      window.open(googleStoreLink, "_blank");
    }
  };

  const backToFeed = () => {
    const { transition } = props;
    transition.router.stateService.go(NAVIGATION.POSTS, {
      userId: postData.userId,
      postOwner: postData.postOwner,
    });
  };

  return (
    <Fragment>
      {(isCreateMemory || isMemorySetting) && (
        <div className="create-memory">
          <img
            alt="back arrow"
            src={IMAGES.BACK_ARROW.default}
            onClick={backToFeed}
          />
          {isCreateMemory && (
            <div className="create-memory-header">
              <p>{getProperty("create-post.create-memory")}</p>
            </div>
          )}
          {isMemorySetting && (
            <div className="create-memory-header">
              <p>{getProperty("create-post.memory-setting")}</p>
            </div>
          )}
        </div>
      )}
      {(isCreateMemory || isMemorySetting || isInvite) && (
        <img
          alt="master shadow img"
          src={IMAGES.MASTER_SHADOW_IMAGE.default}
          className="create-memory-header-shadow"
        />
      )}
      {isChooseCarousel && (
        <ChooseCarousel
          show={isChooseCarousel}
          manageCarouselOverlay={() =>
            setIsChooseCarousel((isChooseCarousel) => !isChooseCarousel)
          }
          manageCarouselData={(carouelData) =>
            addSingleCarouselDataToArray(carouelData)
          }
          setPostCreationTime={
            isEmpty(defaultCreationTime)
              ? (creationTime) => setDefaultCreationTime(creationTime)
              : () => setDefaultCreationTime(defaultCreationTime)
          }
        />
      )}
      {isCreateMemory && (
        <div className="create-memory-enter-details-scetion">
          <div className="feed-navigation">
            <div className="navbar-root paths-navbar">
              <div className="clearboth">
                <nav className="navbar navbar-nav mb-4 navbar-path header-spacing">
                  <ul className="nav tabs group nav-pills collabtab-list create-memory-post-navigation">
                    <li className="active">
                      <a href className="nav-link anchor_style">
                        <img
                          alt="profile"
                          src={photoUrl}
                          className="create-memory-profile-photo"
                        />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="create-memory-post-header-main">
            <div className="create-memory-post-header-text">
              <input
                type="text"
                id="memory-title"
                className="create-memory-post-title"
                placeholder="TITLE"
                onChange={(event) => setPostTitle(event.target.value)}
                maxLength="40"
              />
            </div>
          </div>
          <div className="create-memory-carousel-description-section">
            <img
              alt="camera icon"
              src={IMAGES.CAMERA_ICON.default}
              onClick={() =>
                setIsChooseCarousel((isChooseCarousel) => !isChooseCarousel)
              }
            />
            {carouelData.length > 0 && (
              <div className="carousel-slider">
                <Slider {...carouselDataSettings}>
                  {carouelData.map((singleCarouselData, index) =>
                    singleCarouselData.type === STRINGS.IMAGE ? (
                      <div>
                        <img
                          alt="uncheck icon"
                          src={IMAGES.TINY_UNCHECK.default}
                          className="single-carousel-remove-icon"
                          onClick={() => removeCarouselFromList(index)}
                        />
                        <img
                          alt="single carousal img"
                          src={singleCarouselData.data}
                          className="single-carousel-image"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          alt="tiny uncheck icon"
                          src={IMAGES.TINY_UNCHECK.default}
                          className="single-carousel-remove-icon"
                          onClick={() => removeCarouselFromList(index)}
                        />
                        <img
                          alt="single carousel img"
                          src={singleCarouselData.thumbnail}
                          className="single-carousel-image"
                        />
                      </div>
                    )
                  )}
                </Slider>
              </div>
            )}
            <div>
              <textarea
                rows="5"
                cols="40"
                placeholder="Tap here to type.."
                className="create-memory-description-section"
                id="memory-description"
                onChange={(event) => setPostDescription(event.target.value)}
              ></textarea>
            </div>
          </div>
          {isTeachableTakeaway && (
            <div className="create-memory-tt-section">
              <div className="tt-section-header">
                <p>{getProperty("create-post.teachable-takeaway")}</p>
                <img
                  alt="close button"
                  src={IMAGES.CLOSE_BUTTON.default}
                  onClick={() => {
                    setIsTeachableTakeaway(
                      (isTeachableTakeaway) => !isTeachableTakeaway
                    );
                    setTeachableTakeawayTitle("");
                    setTeachableTakeawayDescription("");
                    setIsTTPublic(true);
                  }}
                />
              </div>
              <div className="create-memory-tt-title">
                <img
                  alt="teachable takaway icon"
                  src={IMAGES.TEACHABLE_TAKAWAY_ICON.default}
                />
                <input
                  type="text"
                  id="tt-title"
                  className="tt-title-input-field"
                  placeholder="Add Title..."
                  onChange={(event) =>
                    setTeachableTakeawayTitle(event.target.value)
                  }
                  value={teachableTakeawayTitle ? teachableTakeawayTitle : ""}
                />
                <div className="create-memory-tt-privacy">
                  {isTTPublic && (
                    <img
                      alt="show password"
                      src={IMAGES.SHOW_PASSWORD.default}
                      className="tt-public-privacy"
                      onClick={() => setIsTTPublic(false)}
                    />
                  )}
                  {!isTTPublic && (
                    <img
                      alt="hide password"
                      src={IMAGES.HIDE_PASSWORD.default}
                      className="tt-public-privacy"
                      onClick={() => setIsTTPublic(true)}
                    />
                  )}
                </div>
              </div>
              <hr />
              <div className="create-memory-tt-description">
                <textarea
                  rows="2"
                  cols="30"
                  id="tt-description"
                  placeholder="Add description..."
                  className="tt-title-description-field"
                  onChange={(event) =>
                    setTeachableTakeawayDescription(event.target.value)
                  }
                  value={
                    teachableTakeawayDescription
                      ? teachableTakeawayDescription
                      : ""
                  }
                ></textarea>
              </div>
            </div>
          )}
          {isGratitudeJournal && (
            <div className="create-memory-tt-section">
              <div className="tt-section-header">
                <p>{getProperty("create-post.gratitude-journal")}</p>
                <img
                  alt="close button"
                  src={IMAGES.CLOSE_BUTTON.default}
                  onClick={() => {
                    setIsGratitudeJournal(
                      (isGratitudeJournal) => !isGratitudeJournal
                    );
                    setGratitudeJournalTitle("");
                    setGratitudeJournalDescription("");
                    setIsGJPublic(true);
                  }}
                />
              </div>
              <div className="create-memory-tt-title">
                <img
                  alt="gratitude journal icon"
                  src={IMAGES.GRATITUDE_JOURNAL_ICON.default}
                />
                <input
                  type="text"
                  id="gj-title"
                  className="tt-title-input-field"
                  placeholder="Add Title..."
                  onChange={(event) =>
                    setGratitudeJournalTitle(event.target.value)
                  }
                  value={gratitudeJournalTitle ? gratitudeJournalTitle : ""}
                />
                <div className="create-memory-tt-privacy">
                  {isGJPublic && (
                    <img
                      alt="show password"
                      src={IMAGES.SHOW_PASSWORD.default}
                      className="tt-public-privacy"
                      onClick={() => setIsGJPublic(false)}
                    />
                  )}
                  {!isGJPublic && (
                    <img
                      alt="hide password"
                      src={IMAGES.HIDE_PASSWORD.default}
                      className="tt-public-privacy"
                      onClick={() => setIsGJPublic(true)}
                    />
                  )}
                </div>
              </div>
              <hr />
              <div className="create-memory-tt-description">
                <textarea
                  rows="2"
                  cols="30"
                  placeholder="Add description..."
                  className="tt-title-description-field"
                  onChange={(event) =>
                    setGratitudeJournalDescription(event.target.value)
                  }
                  value={
                    gratitudeJournalDescription
                      ? gratitudeJournalDescription
                      : ""
                  }
                ></textarea>
              </div>
            </div>
          )}
          {isLinkList && (
            <div className="create-memory-tt-section">
              <div className="tt-section-header">
                <img
                  alt="link list icon"
                  src={IMAGES.LINK_LIST_ICON.default}
                  className="create-memory-link-list-icon"
                />
                <p>{getProperty("create-post.link-list")}</p>
                <img
                  alt="close button"
                  src={IMAGES.CLOSE_BUTTON.default}
                  onClick={() => {
                    setIsLinkList((isLinkList) => !isLinkList);
                    setLinkList([]);
                  }}
                />
              </div>
              {linkList &&
                linkList.length !== 0 &&
                linkList.map((url) => (
                  <div key={url.id}>
                    <div className="create-memory-tt-title">
                      <img alt="globe icon" src={IMAGES.GLOBE_ICON.default} />
                      <p>{url.name}</p>
                      <img
                        alt="tiny uncheck"
                        src={IMAGES.TINY_UNCHECK.default}
                        className="remove-link-btn"
                        onClick={(link, linkId) =>
                          removeLinkFromList(url.name, url.id)
                        }
                      />
                    </div>
                    <hr />
                  </div>
                ))}
              <div className="create-memory-tt-title">
                <img alt="globe icon" src={IMAGES.GLOBE_ICON.default} />
                <input
                  type="text"
                  id="link-list-url"
                  className="tt-title-input-field"
                  placeholder="Add new website link here"
                  onChange={(event) => setLink(event.target.value)}
                />
              </div>
              <hr />
              <div className="create-memory-tt-description">
                <p
                  className="link-list-save-button"
                  onClick={() => addLinkToList()}
                >
                  {getProperty("create-post.save")}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      {isMemorySetting && (
        <div className={`${footerShadowClass}-enter-details-scetion`}>
          <div className="memory-edit-location-creation-time">
            <div className="memory-edit-location-time">
              <img alt="edit button" src={IMAGES.EDIT_BUTTON.default} />
              <input
                type="text"
                id="input-location"
                className="memory-location-time-input-field"
                placeholder="Location"
                onChange={(event) => setLocation(event.target.value)}
                value={location ? location : ""}
              />
            </div>
            <div className="memory-edit-location-time">
              <img alt="edit button" src={IMAGES.EDIT_BUTTON.default} />
              <input
                type="datetime-local"
                id="edit-creation-time"
                className="memory-location-time-input-field"
                defaultValue={defaultCreationTime}
                onChange={(event) => setDefaultCreationTime(event.target.value)}
              />
            </div>
          </div>
          {isEmpty(postData.parentPostId) && (
            <div>
              <hr />
              <div className="memory-collaborators-invite-more">
                <div className="collaborators-invite-more-text">
                  <p>
                    {getProperty("create-post.can-collaborator-invite-more")}
                  </p>
                  <div className="make-memory-private">
                    <div
                      className="memory-settings-custom-checkbox"
                      onClick={() => {
                        setIsOpenCollabTab(
                          (isOpenCollabTab) => !isOpenCollabTab
                        );
                        isOpenCollabTab
                          ? (document.getElementById(
                              "open-collabtab"
                            ).style.color = "#C2BCAF")
                          : (document.getElementById(
                              "open-collabtab"
                            ).style.color = "#F19A7E");
                      }}
                    >
                      {!isOpenCollabTab && (
                        <div className="d-inline-block memory-settings-custom-checkbox-grey"></div>
                      )}
                      {isOpenCollabTab && (
                        <div className="d-inline-block memory-settings-custom-checkbox-orange"></div>
                      )}
                    </div>
                    <span id="open-collabtab">
                      {getProperty("create-post.open-collabtab")}
                    </span>
                  </div>
                </div>
                {!isOpenCollabTab && (
                  <div className="collaborators-invite-more-text">
                    <p>{getProperty("create-post.auto-approve-collabtab")}</p>
                    <div className="make-memory-private">
                      <div
                        className="memory-settings-custom-checkbox"
                        onClick={() => {
                          setIsAutoApprove((isAutoApprove) => !isAutoApprove);
                          isAutoApprove
                            ? (document.getElementById(
                                "auto-approval"
                              ).style.color = "#C2BCAF")
                            : (document.getElementById(
                                "auto-approval"
                              ).style.color = "#F19A7E");
                        }}
                      >
                        {!isAutoApprove && (
                          <div className="d-inline-block memory-settings-custom-checkbox-grey"></div>
                        )}
                        {isAutoApprove && (
                          <div className="d-inline-block memory-settings-custom-checkbox-orange"></div>
                        )}
                      </div>
                      <span id="auto-approval">
                        {getProperty("create-post.yes")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <hr />
              <div className="memory-settings-social-media-checkbox">
                <div className="collaborators-invite-more-text">
                  <p>{getProperty("create-post.post-this-to-social-media")}</p>
                  <div className="make-memory-private">
                    <div
                      className="memory-settings-custom-checkbox"
                      onClick={() => {
                        setIsFacebook((isFacebook) => !isFacebook);
                        isFacebook
                          ? (document.getElementById("facebook").style.color =
                              "#C2BCAF")
                          : (document.getElementById("facebook").style.color =
                              "#F19A7E");
                      }}
                    >
                      {!isFacebook && (
                        <div className="d-inline-block memory-settings-custom-checkbox-grey"></div>
                      )}
                      {isFacebook && (
                        <div className="d-inline-block memory-settings-custom-checkbox-orange"></div>
                      )}
                    </div>
                    <span id="facebook">
                      {getProperty("create-post.facebook")}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="memory-settings-promote-collabtab-offline">
                <div className="collaborators-invite-more-text">
                  <p>{getProperty("create-post.promote-collabtab-offline")}</p>
                  <div className="make-memory-private">
                    <div
                      className="memory-settings-custom-checkbox"
                      onClick={() => {
                        setIsSkipQR((isSkipQR) => !isSkipQR);
                        isSkipQR
                          ? (document.getElementById(
                              "see-qr-options"
                            ).style.color = "#C2BCAF")
                          : (document.getElementById(
                              "see-qr-options"
                            ).style.color = "#F19A7E");
                      }}
                    >
                      {!isSkipQR && (
                        <div className="d-inline-block memory-settings-custom-checkbox-grey"></div>
                      )}
                      {isSkipQR && (
                        <div className="d-inline-block memory-settings-custom-checkbox-orange"></div>
                      )}
                    </div>
                    <span id="see-qr-options">
                      {isSkipQR
                        ? getProperty("create-post.see-qr-options")
                        : getProperty("create-post.skip-qr-options")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isInvite && (
        <Fragment>
          <div className="invite-users-header">
            <div className="search-all-invite-user" id="search-user-input">
              <img
                alt="back arrow"
                src={IMAGES.BACK_ARROW.default}
                onClick={backToFeed}
                className="invite-user-back-arrow"
              />
              <input
                type="text"
                id="search-invite-user"
                className="search-invite-user-input-field"
                placeholder="Search all on Paths"
                onChange={(event) => searchUsers(event)}
              />
              <img
                alt="close button"
                src={IMAGES.CLOSE_BUTTON.default}
                className="clear-invite-input-field"
                onClick={() => clearUserSearchArea()}
              />
            </div>
            {isEmpty(searchUserKeyword) && (
              <div className="row search-friends-followers-following">
                <div
                  className="col-3 selected-search-option"
                  id="friends-option"
                  onClick={() => manageSelectedOption("friends-option")}
                >
                  <p>{getProperty("create-post.friends")}</p>
                </div>
                <div
                  className="col-3 search-option"
                  id="following-option"
                  onClick={() => manageSelectedOption("following-option")}
                >
                  <p>{getProperty("create-post.following")}</p>
                </div>
                <div
                  className="col-3 search-option"
                  id="follower-option"
                  onClick={() => manageSelectedOption("follower-option")}
                >
                  <p>{getProperty("create-post.follower")}</p>
                </div>
                <div
                  className="col-3 search-option"
                  id="off-paths-option"
                  onClick={() => manageSelectedOption("off-paths-option")}
                >
                  <p>{getProperty("create-post.off-paths")}</p>
                </div>
              </div>
            )}
          </div>
          <div className="invite-users-section">
            {inviteUsersSelectedType === STRINGS.FRIENDS_OPTION &&
              isEmpty(searchUserKeyword) &&
              friendList.data.collaborators.map((friendData) => (
                <div className="row pt-2 pb-2 p-1 align-items-center">
                  <div className="col-2 invite-user-icon">
                    <img
                      alt=""
                      src={friendData.collaboratorProfileUrl}
                      onError={() => handleImageError()}
                    />
                  </div>
                  <div className="col-6 invite-user-first-last-name">
                    <p className="invite-user-paths-username">
                      {friendData.collaboratorDisplayName}
                    </p>
                    <p className="invite-user-full-name">
                      {friendData.collaboratorDisplayName}
                    </p>
                  </div>
                  <div className="col-4 invite-user-btn justify-content-end">
                    <div
                      className="d-inline-block invite-section-btn"
                      id={`invite-${friendData.collaboratorUserId}`}
                      onClick={() => inviteUser(friendData.collaboratorUserId)}
                    >
                      {getProperty("create-post.invite")}
                    </div>
                  </div>
                </div>
              ))}
            {inviteUsersSelectedType === STRINGS.FOLLOWING_OPTION &&
              isEmpty(searchUserKeyword) &&
              followingList.data.collaborators.map((followingData) => (
                <div className="row pt-2 pb-2 p-1 align-items-center">
                  <div className="col-2 invite-user-icon">
                    <img
                      alt=""
                      src={followingData.collaboratorProfileUrl}
                      onError={() => handleImageError()}
                    />
                  </div>
                  <div className="col-6 invite-user-first-last-name">
                    <p className="invite-user-paths-username">
                      {followingData.collaboratorDisplayName}
                    </p>
                    <p className="invite-user-full-name">
                      {followingData.collaboratorDisplayName}
                    </p>
                  </div>
                  <div className="col-4 invite-user-btn justify-content-end">
                    <div
                      className="d-inline-block invite-section-btn"
                      id={`invite-${followingData.collaboratorUserId}`}
                      onClick={() =>
                        inviteUser(followingData.collaboratorUserId)
                      }
                    >
                      {getProperty("create-post.invite")}
                    </div>
                  </div>
                </div>
              ))}
            {inviteUsersSelectedType === STRINGS.FOLLOWER_OPTION &&
              isEmpty(searchUserKeyword) &&
              followerList.data.collaborators.map((followerData) => (
                <div className="row pt-2 pb-2 p-1 align-items-center">
                  <div className="col-2 invite-user-icon">
                    <img
                      alt=""
                      src={followerData.collaboratorProfileUrl}
                      onError={() => handleImageError()}
                    />
                  </div>
                  <div className="col-6 invite-user-first-last-name">
                    <p className="invite-user-paths-username">
                      {followerData.collaboratorDisplayName}
                    </p>
                    <p className="invite-user-full-name">
                      {followerData.collaboratorDisplayName}
                    </p>
                  </div>
                  <div className="col-4 invite-user-btn justify-content-end">
                    <div
                      className="d-inline-block invite-section-btn"
                      id={`invite-${followerData.collaboratorUserId}`}
                      onClick={() =>
                        inviteUser(followerData.collaboratorUserId)
                      }
                    >
                      {getProperty("create-post.invite")}
                    </div>
                  </div>
                </div>
              ))}
            {!isEmpty(searchUsersData) &&
              searchUsersData.users.map((userData) => (
                <div className="row pt-2 pb-2 p-1 align-items-center">
                  <div className="col-2 invite-user-icon">
                    <img
                      alt="profile pic"
                      src={userData.profilePhotoUrl}
                      onError={() => handleImageError()}
                    />
                  </div>
                  <div className="col-6 invite-user-first-last-name">
                    <p className="invite-user-paths-username">
                      {userData.userName}
                    </p>
                    <p className="invite-user-full-name">
                      {userData.firstName + ` ` + userData.lastName}
                    </p>
                  </div>
                  <div className="col-4 invite-user-btn justify-content-end">
                    <div
                      className="d-inline-block invite-section-btn"
                      onClick={() => inviteUser(userData.uid)}
                    >
                      {getProperty("create-post.invite")}
                    </div>
                  </div>
                </div>
              ))}
            {!isEmpty(searchUsersData) && searchUsersData.users.length === 0 && (
              <div className="row pt-2 pb-2 p-1 text-center">
                <p className="no-paths-account">
                  {getProperty("create-post.no-paths-account-found")}
                </p>
              </div>
            )}
            {!isEmpty(searchUserKeyword) && isEmpty(searchUsersData) && (
              <div className="row pt-2 pb-2 p-1 justify-content-center">
                <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ fontSize: "25px", color: "#F19A7E" }}
                ></i>
              </div>
            )}
          </div>
        </Fragment>
      )}
      {isQRConfirm && (
        <div className="qr-confirm-entry-screen">
          <div className="qr-confirm-entry-header">
            <h2>{getProperty("create-post.done-congrats")}</h2>
            <p>{getProperty("create-post.get-more-contributors")}</p>
          </div>
          <div className="qr-confirm-entry-section">
            <div className="collab-tab-section">
              <div className="popup-qr-code-header">
                <div className="qr-code-header-content">
                  <img
                    alt="paths icon"
                    src={IMAGES.PATHS_ICON.default}
                    className="user-profile-card-header-icon"
                  />
                  <p className="user-profile-card-header-text">
                    {getProperty("label.qr-code-header-text")}
                  </p>
                </div>
                <div className="feed-navigation qr-code">
                  <div className="navbar-root paths-navbar">
                    <div className="clearboth">
                      <nav className="navbar navbar-nav mb-4 navbar-path header-spacing">
                        <ul className="nav tabs group nav-pills collabtab-list">
                          <li className="active ">
                            <a
                              href
                              className="nav-link anchor_style user-profile-card"
                            >
                              <div className="circle"></div>
                            </a>
                          </li>
                          <li className="nav-item margintop">
                            <a href className="nav-link icon-plus">
                              <img
                                alt="add me icon"
                                src={IMAGES.ADD_ME.default}
                                className="add-me-icon user-profile-card"
                              />
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="new-post-code-header-main qr-code">
                  <div className="post-header-text user-profile-card">
                    {postTitle && (
                      <p className="post-title user-profile-card">
                        {postTitle}
                      </p>
                    )}
                    {postData.userInfo &&
                      postData.userInfo.user &&
                      postData.userInfo.user.displayName &&
                      defaultCreationTime && (
                        <span className="post-user-name user-profile-card">
                          {postData.userInfo.user.displayName}&nbsp;|&nbsp;
                          {new Date(defaultCreationTime)
                            .toString()
                            .substring(0, 15)}
                        </span>
                      )}
                    {location && (
                      <p className="post-place user-profile-card">{location}</p>
                    )}
                  </div>
                </div>
                <div className="feed-post-carousel qr-code">
                  <PrintQRCode
                    qrcodeValue={createdPostUrl}
                    userProfile={false}
                    qrcodehighresolution={false}
                    postHeader={false}
                    isPopup={true}
                  />
                </div>
                <div className="feed-new-post-carousel-description">
                  {postDescription && (
                    <p className="single-post-description user-profile-card">
                      {postDescription != null && postDescription.length > 80
                        ? postDescription.substring(0, 80)
                        : postDescription}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="collab-tag-operations-footer">
            <div className="row save-qr-collab-tag-operation">
              <div className="col">
                <img
                  alt="save icon"
                  src={IMAGES.SAVE_ICON.default}
                  className="more-contribution-screen-icon"
                  onClick={() => highResolutionCollabTag()}
                />
                &nbsp;&nbsp;
                <span onClick={() => highResolutionCollabTag()}>
                  {getProperty("label.save")}
                </span>
              </div>
              <div className="col confirm-entry-save-qr-only-operation">
                <img
                  alt="Qr icon"
                  src={IMAGES.QR_ICON.default}
                  className="more-contribution-screen-icon"
                  onClick={() => downloadQRCode()}
                />
                &nbsp;&nbsp;
                <span onClick={() => downloadQRCode()}>
                  {getProperty("label.save-qr-only")}
                </span>
              </div>
            </div>
            <div className="print-collab-tag-operation">
              <img
                alt="print icon"
                src={IMAGES.PRINT_ICON.default}
                className="more-contribution-screen-icon"
                onClick={() => highResolutionCollabTag()}
              />
              &nbsp;&nbsp;
              <span onClick={() => highResolutionCollabTag()}>
                {getProperty("label.print")}
              </span>
            </div>

            <div className="row order-collab-tag-operation">
              <div className="col-6">
                <img
                  alt="ship to door icon"
                  src={IMAGES.SHIP_TO_DOOR.default}
                  className="more-contribution-screen-icon"
                />
                &nbsp;&nbsp;
                <span>{getProperty("label.order")}</span>
              </div>
              <div className="col-6">
                <p className="collab-tag-done-btn" onClick={navigateNextScreen}>
                  {getProperty("label.done-uppercase")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {isGetTheApp && (
        <div className="go-get-the-app-section">
          <h2>{getProperty("create-post.great")}</h2>
          <p>{getProperty("create-post.get-the-app")}</p>
          <p>{getProperty("create-post.does-more")}</p>
          <div className="get-the-app-icons">
            <img
              alt="play store icon"
              src={IMAGES.PLAY_STORE.default}
              onClick={() => redirectOnAppStore(STRINGS.GOOGLE)}
            />
            <img
              alt="app store icon"
              src={IMAGES.APP_STORE.default}
              onClick={() => redirectOnAppStore(STRINGS.APPLE)}
            />
          </div>
          <div className="go-get-the-app-btn">
            <div
              className="col-5 d-inline-block see-new-post-instead-button"
              onClick={() => showCreatedFullPost()}
            >
              {getProperty("create-post.see-memory-instead")}
            </div>
          </div>
        </div>
      )}
      {(isCreateMemory || isMemorySetting || isInvite) && (
        <div className="create-memory-footer-icons">
          <img
            alt="master shadow img"
            src={IMAGES.MASTER_SHADOW_IMAGE.default}
            className={`${footerShadowClass}-footer-shadow`}
          />
          {isCreateMemory && (
            <div className="create-memory-various-icons">
              <img
                alt="teachable takaway icon"
                src={IMAGES.TEACHABLE_TAKAWAY_ICON.default}
                onClick={() => setIsTeachableTakeaway(true)}
              />
              <img
                alt="gratitude journal icon"
                src={IMAGES.GRATITUDE_JOURNAL_ICON.default}
                onClick={() => setIsGratitudeJournal(true)}
              />
              <img
                alt="link list icon"
                src={IMAGES.LINK_LIST_ICON.default}
                onClick={() => setIsLinkList(true)}
              />
            </div>
          )}
          <div className="row create-memory-footer">
            {isInvite && (
              <div
                className="col-5 d-inline-block create-memory-next-button"
                id="memory-setting-pubish-btn"
                onClick={navigateNextScreen}
              >
                {getProperty("create-post.publish")}
              </div>
            )}
            {!isInvite &&
              (postDescription || carouelData ? (
                <div
                  className="col-5 d-inline-block create-memory-next-button"
                  id="memory-setting-next-btn"
                  onClick={navigateNextScreen}
                >
                  {getProperty("create-post.next")}
                </div>
              ) : (
                <div className="col-5 d-inline-block disabled-create-memory-next-button">
                  {getProperty("create-post.next")}
                </div>
              ))}
            <div className="col-7 make-memory-private justify-content-end">
              <div
                className="create-memory-custom-checkbox"
                onClick={() => setIsPrivate((isPrivate) => !isPrivate)}
              >
                {!isPrivate && (
                  <div className="d-inline-block custom-checkbox-grey"></div>
                )}
                {isPrivate && (
                  <div className="d-inline-block custom-checkbox-orange"></div>
                )}
              </div>
              <span>{getProperty("create-post.private")}</span>
            </div>
          </div>
        </div>
      )}
      <div className="hidden-qr-code">
        <PrintQRCode qrcodeValue={createdPostUrl} isDownloadQRCode={true} />
      </div>
    </Fragment>
  );
};

CreateMemoryScreen.proptype = {
  $stateParams: PropTypes.object,
  user: PropTypes.object,
};

export default memo(CreateMemoryScreen);