import "../styles/style.css";

import isEmpty from "lodash/isEmpty";
import React, { Component, Fragment } from "react";

import { getProperty, isValidPostData } from "../helper/helper";
import UserPost from "./posts/UserPost";
import SignIn from "../components/loginpage/SignIn";
import EntryUserProfile from "../components/UserProfile/EntryUserProfile";
import FeedUserProfile from "../components/UserProfile/FeedUserProfile";
import { IMAGES, STRINGS } from "../constants/constants";
import Loader from "./layouts/TextLoader";
import {googleStoreLink, appleStoreLink} from '../constants/constants';

class Home extends Component {
  redirectOnAppStore = (storeName) => {
    if (storeName === "apple") {
      window.open(appleStoreLink, "_blank");
    } else {
      window.open(googleStoreLink, "_blank");
    }
  };

  isCurrentPost = (post) => {
    let postCreationTime = new Date(post.auditInfo.createdTime).getTime();
    let currentTime = new Date().getTime();
    return currentTime >= postCreationTime;
  };

  render() {
    const {
      getPosts,
      userFeedPosts,
      transition,
      nextPageToken,
      isPathMain,
      userInfo,
      postData,
      totalPost,
      postOwner,
      isLoadingData,
      isFirstLoading,
      showUpdatedPost
    } = this.props;
    const windowURL = window.location.href;
    const entryPage = windowURL.includes("posts");
    return (
      <Fragment>
        <div className="row">
          <div className="paths-user-profile col-md-3">
            {entryPage && <EntryUserProfile transition={transition}/>}
            {!entryPage && <FeedUserProfile postData={postData} postOwner={postOwner}/>}
          </div>
          <div className="col-md-6">
            {isLoadingData && isFirstLoading && (
              <Loader isRequiredMinHeight={true} />
            )}
            {userFeedPosts &&
              userFeedPosts.map(
                (post, index) =>
                  isValidPostData(post) && this.isCurrentPost(post) && post.id !== sessionStorage.getItem(STRINGS.DELETED_POST_ID) && (                    
                    <Fragment>
                      <UserPost
                        post={post}
                        getPosts={getPosts}
                        transition={transition}
                        userFeedPosts={userFeedPosts}
                        nextPageToken={nextPageToken}
                        isPathMain={isPathMain}
                        userLength={userFeedPosts.length}
                        index={index}
                        userInfo={userInfo}
                        postOwner={postOwner}
                        showUpdatedPost={showUpdatedPost}
                      />
                      {index === totalPost - 1 && (
                        <div className="user-feed-post-footer">
                          <p className="feed-post-footer-head">
                            {getProperty(
                              "label.never-miss-memory-never-strugle-to-find"
                            )}
                          </p>
                          <p className="feed-post-footer-section">
                            {getProperty("label.user-feed-post-footer-section")}
                          </p>
                          <p className="feed-post-footer-tail">
                            {getProperty("label.use-it-for-free")}
                          </p>
                          <div className="feed-post-footer-buttons">
                            <img
                              alt="play store"
                              src={IMAGES.PLAY_STORE.default}
                              onClick={() => this.redirectOnAppStore("google")}
                            />
                            <img
                              alt="app store"
                              src={IMAGES.APP_STORE.default}
                              onClick={() => this.redirectOnAppStore("apple")}
                            />
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )
              )}
          </div>
          <div className="paths-sign-up-content col-md-3">
            <SignIn />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Home;
