import React, { Fragment, memo, useState } from "react";
import { IMAGES, NAVIGATION, STRINGS } from "../../constants/constants";
import { getProperty } from "../../helper/helper";
import {googleStoreLink, appleStoreLink} from '../../constants/constants';
const FeedFooter = (props) => {
  const [isFooterDetails, setIsFooterDetails] = useState(false);

  const createMemory = () => {
    const { transition, postOwner, userInfo} = props;
    let userId = "";
    if(userInfo && userInfo.uid) {
      userId = userInfo.uid;
    }
    transition.router.stateService.go(NAVIGATION.CREATE_NEW_POST, {
      userId: postOwner.userId,
      postOwner: postOwner,
      userInfo: userInfo,
    });
  };

  const redirectOnAppStore = (storeName) => {
    if (storeName === "apple") {
      window.open(appleStoreLink, "_blank");
    } else {
      window.open(googleStoreLink, "_blank");
    }
  };

  const footerNavigationLinks = (name) => {
    if(name === STRINGS.HOME) {
      window.open(NAVIGATION.GETPATHS);
    } else if(name === STRINGS.PRIVACY) {
      window.open(NAVIGATION.PRIVACY_POLICY);
    } else if(name === STRINGS.TERMS) {
      window.open(NAVIGATION.TERMS_OF_USE);
    } else if(name === STRINGS.HELP) {
      window.open(NAVIGATION.HELP);
    }
  };

  return (
    <Fragment>
      {!props.userInfo.user && (
        <div className="row footer-section">
          <div className="col footer-section-navigation-links">
            <div className="footer-navigation-links">
              <span onClick={(name) => footerNavigationLinks(STRINGS.HOME)}>
                {getProperty("footer.home")}
              </span>
              <span onClick={(name) => footerNavigationLinks(STRINGS.TERMS)}>
                {getProperty("footer.terms")}
              </span>
              <span onClick={(name) => footerNavigationLinks(STRINGS.PRIVACY)}>
                {getProperty("footer.privacy")}
              </span>
              <span onClick={(name) => footerNavigationLinks(STRINGS.HELP)}>
                {getProperty("footer.help")}
              </span>
            </div>
            <p>{getProperty("footer.patent-number")}</p>
          </div>
          <div className="footer-marketplace-icons">
            <img
              alt="play store icon"
              src={IMAGES.PLAY_STORE.default}
              onClick={() => redirectOnAppStore("google")}
            />
            <img
              alt="app store icon"
              src={IMAGES.APP_STORE.default}
              onClick={() => redirectOnAppStore("apple")}
            />
          </div>
        </div>
      )}
      {props.userInfo.user && (
        <div className="logged-in-footer-section">
          <div className="logged-in-footer-paths-logo">
            <img
              alt="paths color logo"
              src={IMAGES.PATHS_COLOUR_LOGO.default}
              onClick={() => setIsFooterDetails(true)}
            />
            {isFooterDetails && (
              <div className="logged-in-detail-footer-section">
                <img
                  alt="close button"
                  src={IMAGES.CLOSE_BUTTON.default}
                  className="close-detail-footer-section"
                  onClick={() => setIsFooterDetails(false)}
                />
                <p className="footer-colaborate-detail">
                  {getProperty("footer.entry-footer-paths-detail")}
                </p>
                <div className="footer-detail-marketplace-icons">
                  <img
                    alt="play store icon"
                    src={IMAGES.PLAY_STORE.default}
                    onClick={() => redirectOnAppStore("google")}
                  />
                  <img
                    alt="app store icon"
                    src={IMAGES.APP_STORE.default}
                    onClick={() => redirectOnAppStore("apple")}
                  />
                </div>
                <div className="detail-footer-section-navigation-links">
                  <div className="footer-navigation-links">
                    <span
                      onClick={(name) => footerNavigationLinks(STRINGS.HOME)}
                    >
                      {getProperty("footer.home")}
                    </span>
                    <span
                      onClick={(name) => footerNavigationLinks(STRINGS.TERMS)}
                    >
                      {getProperty("footer.terms")}
                    </span>
                    <span
                      onClick={(name) => footerNavigationLinks(STRINGS.PRIVACY)}
                    >
                      {getProperty("footer.privacy")}
                    </span>
                    <span
                      onClick={(name) => footerNavigationLinks(STRINGS.HELP)}
                    >
                      {getProperty("footer.help")}
                    </span>
                  </div>
                  <p>{getProperty("footer.patent-number")}</p>
                </div>
              </div>
            )}
          </div>
          <img
            alt="create entry button"
            src={IMAGES.CREATE_ENTRY_BUTTON.default}
            className="footer-create-entry-button"
            onClick={() => createMemory()}
          />
        </div>
      )}
    </Fragment>
  );
};

export default memo(FeedFooter);
