import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import QRCodeWithPost from "../posts/QRCode/QRCodeWithPost";
import { getProperty } from "../../helper/helper";
import { NAVIGATION } from "../../constants/constants";
import "firebase/storage";
import firebase from "firebase/app";
import { isEmpty } from "lodash";
import html2canvas from "html2canvas";
import {publicStorageBucket} from '../../constants/constants';
const SaveCollabTag = (props) => {
  const [postData, setPostdata] = useState(props.$stateParams);
  const [showLoader, setShowLoader] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [isNewlyCreatedPost, setIsNewlyCreatedPost] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (postData.post && postData.post.auditInfo && postData.post.auditInfo.modifiedTime) {
      const creationTime = new Date(postData.post.auditInfo.modifiedTime).getTime();
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - creationTime;
      if (timeDifference / 60000 > 10) {
        setIsNewlyCreatedPost(false);
      } else {
        setIsNewlyCreatedPost(true);
      }
    } else if(postData.post && postData.post.postData && postData.post.postData.auditInfo && postData.post.postData.auditInfo.modifiedTime) {
      const creationTime = new Date(postData.post.postData.auditInfo.modifiedTime).getTime();
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - creationTime;
      if (timeDifference / 60000 > 10) {
        setIsNewlyCreatedPost(false);
      } else {
        setIsNewlyCreatedPost(true);
      }
    } else {
      setIsNewlyCreatedPost(false);
    }
  }, []);

  const backToEntry = () => {
    const { transition } = props;
    let userId = "";
    let postId = "";
    if (postData.post && postData.post.parentPostOwnerId) {
      userId = postData.post.parentPostOwnerId;
    } else {
      userId = postData.post.owner.userId;
    }
    if (postData.post && postData.post.parentPostId) {
      postId = postData.post.parentPostId;
    } else {
      postId = postData.post.id;
    }
    transition.router.stateService.go(NAVIGATION.POSTDETAIL, {
      userId: userId,
      postId: postId,
    });
  };

  const printCollabTag = () => {
    window.print();
  };

  const downloadCollabTag = () => {
    setShowLoader(true);
    const seconds = new Date().getTime();
    html2canvas(document.getElementById("collab-tag-high-resolution"), {
      backgroundColor: "#ffffff",
    }).then(function (canvas) {
      const base64Url = canvas.toDataURL("image/jpeg");
      var storage = firebase.app().storage(publicStorageBucket);
      const uploadTask = storage
        .ref(
          postData.userId +
            "/posts/" +
            postData.postId +
            "/collabtag/" +
            seconds +
            "/"
        )
        .child("Collab Tag Composite")
        .putString(base64Url, "data_url", {
          contentType: "image/jpeg",
        });
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          storage
            .ref(
              postData.userId +
                "/posts/" +
                postData.postId +
                "/collabtag/" +
                seconds +
                "/"
            )
            .child("Collab Tag Composite")
            .getDownloadURL()
            .then((url) => {
              setShowLoader(false);
              setDownloadUrl(url);
            })
            .catch((error) => {
              setShowLoader(false);
            });
        }
      );
    });
  };

  return (
    <div className="row">
      <div className="col-md-3"></div>
      <div className="qr-code-composite col-md-6">
        <QRCodeWithPost
          post={postData.post}
          postHeader={false}
          highResolution={true}
          qrCodeValue={postData.postUrl}
        />
        <div className="text-center">
          {!showLoader && isEmpty(downloadUrl) && (
            <p onClick={downloadCollabTag} className="download-collabtag-now">
              {getProperty("label.download-now")}
            </p>
          )}
          {!showLoader && !isEmpty(downloadUrl) && (
            <a
              href={downloadUrl}
              target="_blank"
              className="download-collabtag-now"
            >
              {getProperty("label.open-in-new-tab")}
            </a>
          )}
          {showLoader && (
            <span aria-hidden="true" className="collab-tag-download-spinner">
              <i className="fa fa-spinner fa-spin "></i>
            </span>
          )}
        </div>
        <p onClick={printCollabTag} className="back-to-entry-from-collab">
          {getProperty("label.print-full-collab-tag")}
        </p>
        {isNewlyCreatedPost && (
          <div className="text-center mb-4">
            <div className="col-5 d-inline-block see-new-post-instead-button" onClick={() => backToEntry()}>
              {getProperty("create-post.see-memory-instead")}
            </div>
          </div>
        )}
        {!isNewlyCreatedPost && (
          <p onClick={backToEntry} className="back-to-entry-from-collab">
            {getProperty("label.back-to-entry")}
          </p>
        )}        
      </div>
      <div className="col-md-3"></div>
    </div>
  );
};

SaveCollabTag.proptype = {
  $stateParams: PropTypes.object,
  user: PropTypes.object,
};

export default memo(SaveCollabTag);