import "react-phone-number-input/style.css";

import "../../styles/style.css";

import firebase from "firebase/app";
import React, { Component, Fragment } from "react";
import PhoneInput, {
  getCountryCallingCode,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";

import { updateAdditionalUserInfo, updateUser } from "../../ducks/actions";
import { functions } from "../../firebase/firebaseConfig";
import { addCowner, getProperty, sendSignupWelcomeSMS } from "../../helper/helper";
import { GAtrackLoginDetails, GAtrackUserIdDetails } from "../../helper/helper";
import Loader from "../layouts/Loader";
import { IMAGES, NAVIGATION } from "../../constants/constants";
import { connect } from "react-redux";
import generator from "generate-password";
import { isEmpty } from "lodash";
import {googleStoreLink, appleStoreLink} from '../../constants/constants';
const data = require("../../data/data.json");
const apiList = require("../../data/apiList.json");
const apiName = require("../../data/apiList.json");

const addUser = functions.httpsCallable(apiName.addUser);
const searchUserByUsername = functions.httpsCallable(
  apiName.searchUserByUsername
);
const checkPhoneNumberExists = functions.httpsCallable(
  apiName.checkPhoneNumberExists
);
const sendOtpToUserFor2FA = functions.httpsCallable(
  apiName.sendOtpToUserFor2FA
);
const getUserByEmailAddressES = functions.httpsCallable(
  apiName.getUserByEmailAddressES
);
const getUserDataES = functions.httpsCallable(apiName.getUserDataES);
const mailchimpSubscribe = functions.httpsCallable(apiList.mailchimpSubscribe);
class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      firstname: "",
      username: "",
      lastname: "",
      emailId: "",
      password: "",
      enteredPin: "",
      pin: "",
      pinError: "",
      error: "",
      isOpen: false,
      errors: "",
      isValid: false,
      isSelected: false,
      onButtonLoader: "",
      showLoader: false,
      mobileNumber: "",
      countryCalling: "",
      countryCode: "",
      phoneNuberInput: "",
      isValidUserName: false,
      isValidEmail: true,
      isValidFirstName: false,
      isValidPassword: false,
      isValidPhoneNumber: false,
      isPinSend: false,
      isEnteredPinValid: false,
      isDone: false,
      isValidPin: false,
      isGetPaths: false,
      hidePassword: true,
      resendPin: "",
      isResendPin: false,
      phoneNuberCode: `(${data.defaultCountry.calling})`,
    };
  }

  validateEmailAddress = (emailId) => {
    const expression =
      /^\w+([\.-]?[+\w-._]+)+@\w+([+\.:._-]?\w+)+(\.[a-zA-Z0-9]{2,})+$/;
    return expression.test(String(emailId).toLowerCase());
  };

  validateUserName = (username) => {
    if (username.length >= 8) {
      const regex = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
      return !regex.test(username);
    }
    return false;
  };

  validatePassword = (password) => {
    const regex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*()+\-_=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    return regex.test(password);
  };

  validateMobileNumber = (mobileNumber) => {
    const regex = /^\d{10}$/;
    return regex.test(mobileNumber);
  };

  handlePinValidation = (enteredPin) => {
    let { isEnteredPinValid } = this.state;
    let isValid = true;

    if (isEmpty(enteredPin)) {
      isValid = false;
      document.getElementById("pin").style.borderColor = "red";
      this.setState({
        pinError: getProperty("login.enter-pin"),
      });
    } else if (!isEnteredPinValid || parseInt(enteredPin) !== this.state.pin) {
      isValid = false;
      document.getElementById("pin").style.borderColor = "red";
      this.setState({
        pinError: getProperty("login.not-valid-pin"),
      });
    }

    return isValid;
  };

  handleEmailIdValidation = () => {
    let { emailId, errors } = this.state;
    let isValid = true;

    if (!emailId) {
      isValid = false;
      errors = getProperty("login.enter-email");
      this.setState({ emailErrors: errors });
      document.getElementById("emailId").style.borderColor = "red";
      document.getElementById("emailId").style.color = "red";
    } else if (!this.validateEmailAddress(emailId)) {
      isValid = false;
      errors = getProperty("login.not-valid-email");
      this.setState({ emailErrors: errors });
      document.getElementById("emailId").style.borderColor = "red";
      document.getElementById("emailId").style.color = "red";
    }

    return isValid;
  };

  handleUserNameValidation = () => {
    let { username, errors } = this.state;
    let isValid = true;

    if (!username) {
      isValid = false;
      errors = getProperty("login.enter-username");
      this.setState({ userNameErrors: errors });
      document.getElementById("username").style.borderColor = "red";
      document.getElementById("username").style.color = "red";
    } else if (!this.validateUserName(username) || username.indexOf(" ") >= 0) {
      isValid = false;
      errors = getProperty("login.not-valid-username");
      this.setState({ userNameErrors: errors });
      document.getElementById("username").style.borderColor = "red";
      document.getElementById("username").style.color = "red";
    } else {
      errors = "";
      this.setState({ userNameErrors: errors });
    }

    return isValid;
  };

  handlePasswordValidation = () => {
    let { password, errors } = this.state;
    let isValid = true;

    if (!password) {
      isValid = false;
      errors = getProperty("login.enter-password");
      this.setState({ passwordErrors: errors });
      document.getElementById("input-password").style.borderColor = "red";
      document.getElementById("input-password").style.color = "red";
    } else if (!this.validatePassword(password)) {
      isValid = false;
      errors = getProperty("login.not-valid-password");
      this.setState({ passwordErrors: errors });
      document.getElementById("input-password").style.borderColor = "red";
      document.getElementById("input-password").style.color = "red";
    }

    return isValid;
  };

  handleFirstNameValidation = () => {
    let { firstname, errors } = this.state;
    let isValid = true;

    if (!firstname) {
      isValid = false;
      errors = getProperty("login.enter-first-name");
      this.setState({ firstNameError: errors });
      document.getElementById("firstName").style.borderColor = "red";
      document.getElementById("firstName").style.color = "red";
    }

    const format = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
    if (format.test(firstname)) {
      isValid = false;
      errors = getProperty("login.invalid-first-name");
      this.setState({ firstNameError: errors });
      document.getElementById("firstName").style.borderColor = "red";
      document.getElementById("firstName").style.color = "red";
    }

    return isValid;
  };

  handleMobileNumberValidation = () => {
    let { mobileNumber, countryCalling, errors } = this.state;
    let isValid = true;

    if (mobileNumber) {
      if (!isPossiblePhoneNumber(`${countryCalling}${mobileNumber}`)) {
        isValid = false;
        errors = getProperty("login.invalid-mobile");
        this.setState({ mobileError: errors });
        document.getElementById("phoneNumber").style.borderColor = "red";
        document.getElementById("phoneNumber").style.color = "red";
      }
    } else {
      isValid = false;
      errors = getProperty("login.enter-mobile");
      this.setState({ mobileError: errors });
      document.getElementById("phoneNumber").style.borderColor = "red";
      document.getElementById("phoneNumber").style.color = "red";
    }

    if (mobileNumber && !countryCalling) {
      isValid = false;
      errors = getProperty("login.select-country-code");
      this.setState({ mobileError: errors });
      document.getElementById("phoneNumber").style.borderColor = "red";
      document.getElementById("phoneNumber").style.color = "red";
    }

    return isValid;
  };

  managePassword = () => {
    let { hidePassword } = this.state;
    let passwordType = document.getElementById("input-password");
    if (passwordType.type === "password") {
      passwordType.type = "text";
    } else {
      passwordType.type = "password";
    }
    this.setState({
      hidePassword: !hidePassword,
    });
  };

  resendOTP = () => {
    let { isResendPin } = this.state;
    const otp = Math.floor(100000 + Math.random() * 900000);
    if (!isResendPin) {
      this.setState({
        pin: otp,
        isEnteredPinValid: true,
        resendPin: getProperty("login.resent"),
        isResendPin: true,
      });
      setTimeout(() => {
        this.setState({ isEnteredPinValid: false, pin: "" });
      }, 600000);
      setTimeout(() => {
        this.setState({
          resendPin: getProperty("login.resend"),
          isResendPin: false,
          pinError: "",
        });
      }, 5000);
      let param = {
        otp: otp,
        email: this.state.emailId,
        apiKey: data.apiKey,
      };
      sendOtpToUserFor2FA(param).then().catch();
    } else {
      this.setState({
        pinError: getProperty("login.can-not-resend"),
      });
    }
  };

  /* When user SignUp or Register with Firebase auth and JSON Conversion */
  signupUser = () => {
    const { manageLoader } = this.props;
    if (this.state.isValidEmail && this.handleEmailIdValidation()) {
      manageLoader(true);
      getUserByEmailAddressES({
        email: this.state.emailId,
        apiKey: data.apiKey,
      })
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.users &&
            resp.data.users.length > 0
          ) {
            this.setState({
              emailErrors: getProperty("login.email-exist"),
            });
            manageLoader(false);
          } else {
            this.setState({
              isValidEmail: false,
              isValidFirstName: true,
            });
            manageLoader(false);
          }
        })
        .catch(() => {
          manageLoader(false);
        });
    } else if (
      this.state.isValidFirstName &&
      this.handleFirstNameValidation()
    ) {
      manageLoader(true);
      const { emailId } = this.state;
      const truncatedUserName = emailId.substring(
        0,
        emailId.indexOf(".", emailId.indexOf("@"))
      );
      const customizedUserName = truncatedUserName.replace("@", ".");
      const suggestedUserName = customizedUserName.replace(
        /[^a-zA-Z0-9.-_]/g,
        ""
      );
      if (suggestedUserName.length >= 8) {
        searchUserByUsername({
          userName: suggestedUserName,
          apiKey: data.apiKey,
        })
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.users &&
              response.data.users.length === 0
            ) {
              this.setState({
                isValidFirstName: false,
                isValidUserName: true,
                username: suggestedUserName,
              });
              manageLoader(false);
            } else {
              const randomDigit = Math.floor(
                Math.random() * (999 - 100 + 1) + 100
              );
              const updatedUserName =
                suggestedUserName.slice(0, suggestedUserName.indexOf(".")) +
                randomDigit +
                suggestedUserName.slice(suggestedUserName.indexOf("."));
              this.setState({
                isValidFirstName: false,
                isValidUserName: true,
                username: updatedUserName,
              });
              manageLoader(false);
            }
            document.getElementById("username-no-space").style.visibility =
              "visible";
            document.getElementById(
              "username-no-special-char"
            ).style.visibility = "visible";
            document.getElementById("username-must-unique").style.visibility =
              "visible";
            document.getElementById("username-characters").style.visibility =
              "visible";
          })
          .catch(() => {
            manageLoader(false);
          });
      }
    } else if (this.state.isValidUserName && this.handleUserNameValidation()) {
      manageLoader(true);
      searchUserByUsername({
        userName: this.state.username,
        apiKey: data.apiKey,
      })
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.users &&
            resp.data.users.length > 0
          ) {
            this.setState({
              userNameErrors: getProperty("login.username-exist"),
            });
            manageLoader(false);
          } else {
            const newPassword = generator.generate({
              length: 10,
              lowercase: true,
              uppercase: true,
              numbers: true,
              symbols: true,
              exclude: "`~",
              strict: true,
            });
            console.log("new----pass",newPassword);
            this.setState({
              isValidUserName: false,
              isValidPassword: true,
              password: newPassword,
                // 'f8as7d09f8a0sd9@f87a',
            });
            setTimeout(() => {
              this.managePassword();
              document.getElementById("password-characters").style.visibility =
                "visible";
              document.getElementById("password-number").style.visibility =
                "visible";
              document.getElementById(
                "password-special-character"
              ).style.visibility = "visible";
            }, 100);
            manageLoader(false);
          }
        })
        .catch(() => {
          manageLoader(false);
        });
    } else if (this.state.isValidPassword && this.handlePasswordValidation()) {
      this.setState({
        isValidPassword: false,
        isValidPhoneNumber: true,
      });
    } else if (
      this.state.isValidPhoneNumber &&
      this.handleMobileNumberValidation()
    ) {
      manageLoader(true);
      const { isPinSend } = this.state;
      if (!isPinSend) {
        let phoneNumberWithCallingCode = {
          countryCode: this.state.countryCode,
          number: this.state.mobileNumber,
          countryCalling: this.state.countryCalling,
        };
        let param = {
          phoneNumber: phoneNumberWithCallingCode,
          apiKey: data.apiKey,
        };
        checkPhoneNumberExists(param)
          .then((resp) => {
            const respData = resp.data;
            if (
              respData &&
              respData.error &&
              respData.error === "auth/phone-number-already-exists"
            ) {
              this.setState({
                mobileError: getProperty("login.mobile-number-exist"),
              });
              manageLoader(false);
            } else {
              const otp = Math.floor(100000 + Math.random() * 900000);
              this.setState({
                isPinSend: true,
                pin: otp,
                isEnteredPinValid: true,
                resendPin: getProperty("login.resend"),
              });
              setTimeout(() => {
                this.setState({ isEnteredPinValid: false, pin: "" });
              }, 600000);
              let param = {
                otp: otp,
                email: this.state.emailId,
                apiKey: data.apiKey,
              };
              sendOtpToUserFor2FA(param).then().catch();
              manageLoader(false);
            }
          })
          .catch(() => {
            manageLoader(false);
          });
      } else {
        this.setState({
          isValidPhoneNumber: false,
          isValidPin: true,
        });
        manageLoader(false);
      }
    } else if (
      this.state.isValidPin &&
      this.handlePinValidation(this.state.enteredPin)
    ) {
      manageLoader(true);
      const { emailId, password } = this.state;
      const { updateDoneScreen } = this.props;
      firebase
        .auth()
        .createUserWithEmailAndPassword(emailId, password)
        .then((user) => {
          let userData = JSON.parse(JSON.stringify(user.user));
          this.createUserTemplate(userData);
          manageLoader(false);
          // manageGetPaths();
          updateDoneScreen();
          // this.props.updateShowLogin();
          localStorage.setItem(emailId, 1);
          this.setState({
            isValidPin: false,
            isDone: true,
          });
        })
        .catch((error) => {
          this.setState({
            error: error.message,
            showLoader: false,
          });
          manageLoader(false);
        });
    }
  };

  //This will add user in custom template
  createUserTemplate = (userData) => {
    const { isInvite, invitationId } = this.props;
    const {
      mobileNumber,
      countryCalling,
      countryCode,
      firstname,
      lastname,
      username,
    } = this.state;
    let hasMobileNumber = mobileNumber && countryCalling;

    userData.firstName = firstname;
    userData.lastName = lastname;
    userData.userName = username;
    //set source value and invitation id in user data while ceating user
    userData.source = "WEB";
    if (isInvite) {
      userData.invitationId = invitationId;
    }
    //creating phone number object
    let phoneNumber = {
      countryCalling: countryCalling,
      countryCode: countryCode,
      number: mobileNumber,
    };
    if (hasMobileNumber) {
      userData.phoneNumber = phoneNumber;
    }
    addUser({ user: userData, apiKey: data.apiKey, source: data.apiSource })
      .then(async (resp) => {
        if (resp && resp.data) {
          if (resp.data.error === "auth/phone-number-already-exists") {
            this.props.showPhoneNumberExists();
          }
        }
        this.addUserCallback(userData);
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          showLoader: false,
        });
      });
  };

  addUserCallback = async (userData) => {
    const { firstname, lastname, mobileNumber, countryCalling, countryCode } =
      this.state;
    const { post, isInvite, invitationId } = this.props;
    let coOwnerType = "";
    let isMessage = null;
    let displayMessage = "";
    let firebaseContextUserRequest = {};
    let displayName = firstname + " " + lastname;
    let hasMobileNumber = mobileNumber && countryCalling;
    //creating phone number object
    let phoneNumber = {
      countryCalling: countryCalling,
      countryCode: countryCode,
      number: mobileNumber,
    };

    if (hasMobileNumber) {
      firebaseContextUserRequest = {
        displayName: displayName,
        phoneNumber: phoneNumber,
      };
    } else {
      firebaseContextUserRequest = {
        displayName: displayName,
      };
    }
    //Tracking user info in analytics
    GAtrackLoginDetails(data.LOGIN_with_EMAIL);
    GAtrackUserIdDetails(userData.uid);

    //fetching additional info if not available and saving into redux store
    getUserDataES({ apiKey: data.apiKey, source: data.apiSource })
      .then(async (additionalInfo) => {
        try {
          this.props.updateAdditionalUserInfo(additionalInfo.data);
          if (post) {
            try {
              let addCoownerData = await addCowner(
                post,
                userData,
                additionalInfo.data,
                isInvite,
                invitationId
              );
              if (addCoownerData) {
                coOwnerType = addCoownerData.type;
                isMessage = addCoownerData.isMessage;
                displayMessage = addCoownerData.displayMessage;
              }
            } catch (error) {
              coOwnerType = "";
              isMessage = null;
              displayMessage = "";
            }
          }
        } catch (error) {}
        // this.props.updateShowLogin(coOwnerType, isMessage, displayMessage);
        // this.props.updateIsLoggedIn();
      })
      .catch();

    //Updating user data in redux store and firebase context
    userData.displayName = displayName;
    if (hasMobileNumber) {
      userData.phoneNumber = phoneNumber;
    }
    this.props.updateUser({ user: userData });
    firebase.auth().currentUser.updateProfile(firebaseContextUserRequest);
    //sending welcome sms
    if (hasMobileNumber) {
      sendSignupWelcomeSMS();
      // let sendSMS = sendSignupWelcomeSMS();
    }

    //sending mail using mailchimp
    // let sendWelcomeEmail = mailchimpSubscribe({
    mailchimpSubscribe({
      apiKey: data.apiKey,
      source: data.apiSource,
    });

    // const redirectTostore = redirectToAppStore();
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if ([event.target.name] === "emailId") {
      this.setState({ emailErrors: "", error: "" });
      document.getElementById("emailId").style.borderColor = "#B3B3B3";
      document.getElementById("emailId").style.color = "#707070";
    } else if ([event.target.name] === "password") {
      this.setState({ passwordErrors: "", error: "" });
      document.getElementById("input-password").style.borderColor = "#B3B3B3";
      document.getElementById("input-password").style.color = "#707070";
      let password = event.target.value;
      if (password.length >= 8) {
        document.getElementById("password-characters").style.visibility =
          "visible";
      } else {
        document.getElementById("password-characters").style.visibility =
          "hidden";
      }

      const regex = /\d/;
      if (regex.test(password)) {
        document.getElementById("password-number").style.visibility = "visible";
      } else {
        document.getElementById("password-number").style.visibility = "hidden";
      }

      const format = /[!@#$%^&*()+\-_=\[\]{};':"\\|,.<>\/?]+/;
      if (format.test(password)) {
        document.getElementById("password-special-character").style.visibility =
          "visible";
      } else {
        document.getElementById("password-special-character").style.visibility =
          "hidden";
      }
    } else if ([event.target.name] === "firstname") {
      this.setState({ firstNameError: "", error: "" });
      document.getElementById("firstName").style.borderColor = "#B3B3B3";
      document.getElementById("firstName").style.color = "#707070";
      let firstName = event.target.value;
      const format = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
      if (!format.test(firstName)) {
        document.getElementById("first-name-criteria").style.visibility =
          "visible";
      } else {
        document.getElementById("first-name-criteria").style.visibility =
          "hidden";
      }
    } else if ([event.target.name] === "username") {
      this.setState({
        userNameErrors: "",
        error: "",
        username: event.target.value,
      });
      document.getElementById("username").style.borderColor = "#B3B3B3";
      document.getElementById("username").style.color = "#707070";
      let userName = event.target.value;

      if (userName.length > 0) {
        searchUserByUsername({
          userName: userName,
          apiKey: data.apiKey,
        })
          .then((resp) => {
            if (
              resp &&
              resp.data &&
              resp.data.users &&
              resp.data.users.length > 0
            ) {
              document.getElementById("username-must-unique").style.visibility =
                "hidden";
            } else {
              if (userName.length >= 8) {
                document.getElementById(
                  "username-must-unique"
                ).style.visibility = "visible";
              } else {
                document.getElementById(
                  "username-must-unique"
                ).style.visibility = "hidden";
              }
            }
          })
          .catch();
      }

      if (userName.length >= 8) {
        document.getElementById("username-characters").style.visibility =
          "visible";
        if (!(userName.indexOf(" ") >= 0)) {
          document.getElementById("username-no-space").style.visibility =
            "visible";
        } else {
          document.getElementById("username-no-space").style.visibility =
            "hidden";
        }

        const format = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
        if (!format.test(userName)) {
          document.getElementById("username-no-special-char").style.visibility =
            "visible";
        } else {
          document.getElementById("username-no-special-char").style.visibility =
            "hidden";
        }
      } else {
        document.getElementById("username-no-space").style.visibility =
          "hidden";
        document.getElementById("username-no-special-char").style.visibility =
          "hidden";
        document.getElementById("username-characters").style.visibility =
          "hidden";
      }
    } else if ([event.target.name] === "mobileNumber") {
      this.setState({
        mobileError: "",
        error: "",
        mobileNumber: event.target.value,
      });
      document.getElementById("phoneNumber").style.borderColor = "#B3B3B3";
    } else if ([event.target.name] === "enteredPin") {
      this.setState({
        pinError: "",
        error: "",
        enteredPin: event.target.value,
      });
      document.getElementById("pin").style.borderColor = "#B3B3B3";
      document.getElementById("pin").style.color = "#707070";
    }
  };

  //This method will be invoked on change of mobile number field.
  phoneChange = (value) => {
    if (value) {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        const { countryCallingCode, country, nationalNumber } = phoneNumber;
        this.setState({
          countryCode: country || data.defaultCountry.code,
          mobileNumber: nationalNumber,
          countryCalling:
            `+${countryCallingCode}` || data.defaultCountry.calling,
        });
      }
    } else {
      this.setState({ mobileNumber: "" });
    }
    if (this.state.mobileError) {
      this.setState({ mobileError: "" });
    }
    document.getElementById("phoneNumber").style.borderColor = "#B3B3B3";
    document.getElementById("phoneNumber").style.color = "#707070";
  };

  manageGetPaths = () => {
    this.setState({
      isGetPaths: !this.state.isGetPaths,
    });
  };

  //This method will be invoked on change of country dropdown field.
  onCountryChange = (value) => {
    let ccode = "";
    if (value) {
      ccode = `(+${getCountryCallingCode(value)})`;
    }
    this.setState({ phoneNuberCode: ccode || value });
  };

  showUserNameRequirements = () => {
    if (document.getElementById("username-requirements")) {
      document.getElementById("username-requirements").style.display = "block";
    }
  };

  showPasswordRequirements = () => {
    if (document.getElementById("firstname-requirements")) {
      document.getElementById("firstname-requirements").style.display = "block";
    }
  };

  redirectToTermsUse = () => {
    window.location.href = NAVIGATION.TERMS_OF_USE;
  };

  redirectToPrivacyPolicy = () => {
    window.location.href = NAVIGATION.PRIVACY_POLICY;
  };

  redirectToStore = (storeName) => {
    if (storeName === "apple") {
      window.open(appleStoreLink, "_blank");
    } else {
      window.open(googleStoreLink, "_blank");
    }
  };

  render() {
    const { owner, isInvite, entryCreatorFirstName } = this.props;
    const { firstname, firstNameError } = this.state;
    const {
      emailId,
      emailErrors,
      password,
      passwordErrors,
      username,
      userNameErrors,
      pinError,
      enteredPin,
    } = this.state;
    const { radioError, onButtonLoader, showLoader, errors, hidePassword } =
      this.state;
    const { mobileError, phoneNuberInput, phoneNuberCode } = this.state;
    const {
      isValidUserName,
      isValidEmail,
      isValidFirstName,
      isValidPassword,
      isValidPhoneNumber,
      isValidPin,
      isPinSend,
      isDone,
    } = this.state;

    return (
      <Fragment>
        {owner && isInvite && (
          <p className="text-success signup-text">
            To make it simple to merge your memories with {owner.name}'s and
            more!
          </p>
        )}
        {isValidEmail && (
          <div className="signup-container">
            <h2 className="text-header text-center mt-3 sign-up">
              {getProperty("login.sign-up")}
              {entryCreatorFirstName
                ? entryCreatorFirstName
                : getProperty("login.your-friend")}
            </h2>
            <p className="signup-header-bottom">
              {getProperty("label.comment-collaborate-connect")}
            </p>

            <input
              className="input-btn placeholder"
              type="text"
              name="emailId"
              value={emailId}
              onChange={this.handleInputChange}
              placeholder={getProperty("login.email-address-required")}
              id="emailId"
            />
            <p className="error">{emailErrors}</p>

            <div className="terms-privacy-policy">
              <span>{getProperty("label.terms-privacy-policy")}</span>
              <span className="terms-of-use" onClick={this.redirectToTermsUse}>
                {getProperty("label.terms-of-use")}
              </span>
              <span>{getProperty("label.and")}</span>
              <span
                className="terms-of-use"
                onClick={this.redirectToPrivacyPolicy}
              >
                {getProperty("label.privacy-policy")}
              </span>
            </div>
            <div className="signup-modal">
              {!showLoader && (
                <span
                  className="signup-btn text-center"
                  onClick={this.signupUser}
                >
                  {getProperty("create-post.next")}
                </span>
              )}
              {showLoader && (
                <span className="signup-btn text-center">
                  <Loader onButtonLoader={onButtonLoader} />
                </span>
              )}
            </div>
            <p className="error radio-error">{radioError}</p>
            {errors && <p className="text-danger">{errors}</p>}
          </div>
        )}

        {isValidFirstName && (
          <div className="signup-container">
            <h2 className="text-header text-center mt-3 sign-up">
              {getProperty("login.got-it")}
            </h2>
            <p className="signup-header-bottom">
              {getProperty("login.provide-your-first-name")}
            </p>
            <p class="name-container">
              <input
                className="input-btn placeholder"
                type="text"
                name="firstname"
                value={firstname}
                onChange={this.handleInputChange}
                onFocus={this.showPasswordRequirements}
                placeholder={getProperty("login.first-name-required")}
                id="firstName"
              />
            </p>
            <p className="error">{firstNameError}</p>
            <div
              className="username-validation-requirements"
              id="firstname-requirements"
            >
              <div>{getProperty("label.firstname-requirements")}</div>
              <div className="username-validations">
                <img
                  alt="check img"
                  src={IMAGES.TINY_CHECK.default}
                  id="first-name-criteria"
                />
                &nbsp;&nbsp;
                <span>{getProperty("label.no-special-character")}</span>
              </div>
            </div>

            <div className="signup-modal">
              {!showLoader && (
                <span
                  className="signup-btn text-center"
                  onClick={this.signupUser}
                >
                  {getProperty("create-post.next")}
                </span>
              )}
              {showLoader && (
                <span className="signup-btn text-center">
                  <Loader onButtonLoader={onButtonLoader} />
                </span>
              )}
            </div>
            {errors && <p className="text-danger">{errors}</p>}
          </div>
        )}

        {isValidUserName && (
          <div className="signup-container">
            <h2 className="text-header text-center mt-3 sign-up">
              {getProperty("login.thanks")}
              {firstname}!
            </h2>
            <p className="signup-header-bottom-first">
              {getProperty("login.lock-a-username")}
            </p>
            <p className="signup-header-bottom">
              {getProperty("login.username-may-be-changed")}
            </p>
            <input
              className="input-btn placeholder"
              type="text"
              name="username"
              value={username}
              onChange={this.handleInputChange}
              onFocus={this.showUserNameRequirements}
              placeholder={getProperty("login.username")}
              id="username"
            />
            <p className="error">{userNameErrors}</p>
            <div
              className="username-validation-requirements"
              id="username-requirements"
            >
              <div>{getProperty("label.userName-requirements")}</div>
              <div className="username-validations">
                <img
                  alt="check img"
                  src={IMAGES.TINY_CHECK.default}
                  id="username-no-space"
                />
                &nbsp;&nbsp;
                <span>{getProperty("label.no-spaces")}</span>
              </div>
              <div className="username-validations">
                <img
                  alt="check img"
                  src={IMAGES.TINY_CHECK.default}
                  id="username-no-special-char"
                />
                &nbsp;&nbsp;
                <span>{getProperty("label.no-special-character")}</span>
              </div>
              <div className="username-validations">
                <img
                  alt="check img"
                  src={IMAGES.TINY_CHECK.default}
                  id="username-must-unique"
                />
                &nbsp;&nbsp;
                <span>{getProperty("label.must-be-unique")}</span>
              </div>
              <div className="username-validations">
                <img
                  alt="check img"
                  src={IMAGES.TINY_CHECK.default}
                  id="username-characters"
                />
                &nbsp;&nbsp;
                <span>
                  {getProperty("label.must-contain-at-least-characters")}
                </span>
              </div>
            </div>

            <div className="signup-modal">
              {!showLoader && (
                <span
                  className="signup-btn text-center"
                  onClick={this.signupUser}
                >
                  {getProperty("create-post.next")}
                </span>
              )}
              {showLoader && (
                <span className="signup-btn text-center">
                  <Loader onButtonLoader={onButtonLoader} />
                </span>
              )}
            </div>
            {errors && <p className="text-danger">{errors}</p>}
          </div>
        )}

        {isValidPassword && (
          <div className="signup-container">
            <h2 className="text-header text-center mt-3 sign-up">
              {getProperty("create-post.great")}
            </h2>
            <p className="signup-header-bottom-first">
              {getProperty("login.create-a-pasword")}
            </p>
            <p className="signup-header-bottom">
              {getProperty("login.password-can-changed-later")}
            </p>
            <div className="input-password-hide-show">
              <input
                className="input-btn placeholder"
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                onFocus={this.showPasswordRequirements}
                placeholder={getProperty("login.password")}
                size="10"
                id="input-password"
              />
              {hidePassword && (
                <img
                  alt="hide password"
                  src={IMAGES.HIDE_PASSWORD.default}
                  className="hide-password-image"
                  onClick={this.managePassword}
                />
              )}
              {!hidePassword && (
                <img
                  alt="show password"
                  src={IMAGES.SHOW_PASSWORD.default}
                  className="show-password-image"
                  onClick={this.managePassword}
                />
              )}
            </div>
            <p className="error password-error-text">{passwordErrors}</p>
            <div
              className="username-validation-requirements"
              id="password-requirements"
            >
              <div>{getProperty("label.password-requirements")}</div>
              <div className="username-validations">
                <img
                  alt="check img"
                  src={IMAGES.TINY_CHECK.default}
                  id="password-characters"
                />
                &nbsp;&nbsp;
                <span>
                  {getProperty("label.must-contain-at-least-characters")}
                </span>
              </div>
              <div className="username-validations">
                <img
                  alt="check img"
                  src={IMAGES.TINY_CHECK.default}
                  id="password-number"
                />
                &nbsp;&nbsp;
                <span>{getProperty("label.must-contain-at-least-number")}</span>
              </div>
              <div className="username-validations">
                <img
                  alt="check img"
                  src={IMAGES.TINY_CHECK.default}
                  id="password-special-character"
                />
                &nbsp;&nbsp;
                <span>
                  {getProperty("label.must-contain-at-least-special-character")}
                </span>
              </div>
            </div>

            <div className="signup-modal">
              {!showLoader && (
                <span
                  className="signup-btn text-center"
                  onClick={this.signupUser}
                >
                  {getProperty("create-post.next")}
                </span>
              )}
              {showLoader && (
                <span className="signup-btn text-center">
                  <Loader onButtonLoader={onButtonLoader} />
                </span>
              )}
            </div>
            {errors && <p className="text-danger">{errors}</p>}
          </div>
        )}

        {isValidPhoneNumber && (
          <div className="signup-container">
            <h2 className="text-header text-center mt-3 sign-up">
              {getProperty("login.almost-done")}
              {firstname}!
            </h2>
            <p className="signup-header-bottom">
              {getProperty("login.need-your-phone-number")}
            </p>
            <p className="phone-container">
              <PhoneInput
                placeholder={getProperty("login.phone-number-required")}
                value={phoneNuberInput}
                onChange={this.phoneChange}
                defaultCountry={data.defaultCountry.code}
                onCountryChange={this.onCountryChange}
                className="paths-phone"
                international={false}
                addInternationalOption={false}
                id="phoneNumber"
              />
              <span className="phone-code">{phoneNuberCode}</span>
            </p>
            <p className="error">{mobileError}</p>
            {!isPinSend && (
              <p className="signup-footer-header">
                {getProperty("login.we-will-send-code")}
              </p>
            )}
            <div className="signup-modal">
              {!showLoader && (
                <span
                  className="signup-btn text-center"
                  onClick={this.signupUser}
                >
                  {isPinSend
                    ? getProperty("label.sent")
                    : getProperty("label.send")}
                </span>
              )}
              {showLoader && (
                <span className="signup-btn text-center">
                  <Loader onButtonLoader={onButtonLoader} />
                </span>
              )}
            </div>
            {isPinSend && (
              <p className="signup-footer-bottom">
                {getProperty("login.check-email-for-code")}
              </p>
            )}
          </div>
        )}

        {isValidPin && (
          <div className="signup-container">
            <h2 className="text-header text-center mt-3 sign-up">
              {getProperty("login.check-your-email-or-text")}
            </h2>
            <p className="signup-header-bottom">
              {getProperty("login.enter-code")}
            </p>
            <p class="name-container">
              <input
                className="input-btn placeholder"
                type="text"
                name="enteredPin"
                value={enteredPin}
                onChange={this.handleInputChange}
                placeholder={getProperty("login.6-digit-pin")}
                id="pin"
              />
            </p>
            <p className="error">{pinError}</p>
            <div className="signup-modal">
              {!showLoader && (
                <span
                  className="signup-btn text-center"
                  onClick={this.signupUser}
                >
                  {getProperty("label.all-done")}
                </span>
              )}
              {showLoader && (
                <span className="signup-btn text-center">
                  <Loader onButtonLoader={onButtonLoader} />
                </span>
              )}
            </div>
          </div>
        )}

        {isDone && (
          <div className="signup-container">
            <h2 className="text-header text-center mt-3 activate-account">
              {getProperty("login.good-job")}
              {firstname}
              {getProperty("login.mission-complete")}
            </h2>
            <div className="signup-animated-paths-logo">
              <img
                alt="signup paths logo"
                src={IMAGES.ANIMATED_PATHS_LOGO.default}
              />
            </div>
            <p className="signup-footer-header">
              {getProperty("login.install-app-or-close-window")}
              {firstname}
              {getProperty("login.shared")}
            </p>
            <div className="done-signup-store-images">
              <img
                alt="playStore img"
                src={IMAGES.PLAY_STORE.default}
                onClick={() => this.redirectToStore("google")}
              />
              <img
                alt="AppStore img"
                src={IMAGES.APP_STORE.default}
                onClick={() => this.redirectToStore("apple")}
              />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
  updateAdditionalUserInfo: (user) => dispatch(updateAdditionalUserInfo(user)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
