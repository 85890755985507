import { UPDATE_ADDITIONAL_USER_INFO, UPDATE_USER } from '../actions/actionType';

const intialState = {
  user: {},
  additionalUserInfo: {},
};

export default function (state = intialState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, user: action.payload };
    case UPDATE_ADDITIONAL_USER_INFO:
      return { ...state, additionalUserInfo: action.payload };
    default:
      return state;
  }
}
