import React, { Component } from "react";
import { CloseButton,Modal,ModalBody } from "react-bootstrap";

import ForgotPassword from "./ForgotPassword";
import Login from "./Login";

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPassword: false,
      enteredEmailId: "",
    };
  }

  updaForgotPasswordFlag = () => {
    this.setState({ showForgotPassword: !this.state.showForgotPassword });
  };

  manageSignUpPopup = () =>  {
    const { enteredEmailId } = this.state;
    const { updateShowLogin,updateDoneScreen,updateWelcomePopUp,isMainHeader,isWelcomeOverlay } = this.props;
    updateShowLogin();
    if(isMainHeader) {
      updateDoneScreen();  
    }
    if(isWelcomeOverlay) {
      updateWelcomePopUp();
    }
    if(enteredEmailId) {
      if(parseInt(localStorage.getItem(enteredEmailId)) === 0) {
        localStorage.removeItem(enteredEmailId);
      }
    }
  }

  manageEmailId = (emailId) => {
    this.setState({
      enteredEmailId: emailId
    });
  };

  render() {
    const { showForgotPassword } = this.state;
    const {
      show,
      updateShowLogin,
      post,
      owner,
      isInvite,
      invitationId,
      showSignUpPopup,
      showDoneScreen,
      updateDoneScreen,
      isMainHeader,
      manageGetPaths,
      entryCreatorFirstName
    } = this.props;

    return (
      <div className="signin-modal">
        <Modal
          show={show}
          dialogClassName="modal-90w custom-dialog"
          onHide={updateShowLogin}
          {...this.props}
          size="lg"
          backdrop="static"
        >
          <ModalBody>
            <CloseButton onClick={this.manageSignUpPopup} />
            {!showForgotPassword && (
              <Login
                isInvite={isInvite}
                invitationId={invitationId}
                owner={owner}
                updateShowLogin={updateShowLogin}
                isDisplaySignup={true}
                post={post}
                showPhoneNumberExists={this.props.showPhoneNumberExists}
                updaForgotPasswordFlag={this.updaForgotPasswordFlag}
                showSignUpPopup={showSignUpPopup}
                showDoneScreen = {showDoneScreen}
                updateDoneScreen = {updateDoneScreen}
                isMainHeader = {isMainHeader}
                manageGetPaths={manageGetPaths}
                manageEmailId={this.manageEmailId}
                entryCreatorFirstName={entryCreatorFirstName}
                // updateSignUpPopup={updateSignUpPopup}
              />
            )}
            {showForgotPassword && (
              <ForgotPassword
                updaForgotPasswordFlag={this.updaForgotPasswordFlag}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default LoginModal;
