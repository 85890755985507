import React, { Component } from "react";

import { firebaseApp } from "../../firebase/firebaseConfig";
import { getProperty, validateEmailAddress } from "../../helper/helper";
import Loader from "../layouts/Loader";

// const data = require('../../data/data.json');
export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: "",
      emailId: "",
      showForgotPassword: false,
      showLoader: false,
      showSuccessMessage: false,
    };
  }

  //will be called on click of sbumit button
  handleSubmit = () => {
    const { emailId } = this.state;
    console.log("email", emailId);
    if (validateEmailAddress(emailId)) {
      this.setState({ showLoader: true });
      this.setState({ emailError: "" });
      this.sendPasswordResetRequest(emailId);
    } else {
      if (emailId) {
        this.setState({ emailError: getProperty("login.not-valid-email") });
      } else {
        this.setState({ emailError: getProperty("login.enter-email") });
      }
    }
  };

  handleEmailChange = (evt) => {
    this.setState({ emailId: evt.target.value });
    if (this.state.emailError) {
      this.setState({ emailError: "" });
    }
  };

  //sending request to firebase to reset password
  sendPasswordResetRequest = (emailId) => {
    firebaseApp
      .auth()
      .sendPasswordResetEmail(emailId)
      .then((response) => {
        this.setState({
          showSuccessMessage: true,
          showLoader: false,
          emailId: "",
        });
        setTimeout(() => {
          this.setState({ showSuccessMessage: false });
        }, 3000);
      })
      .catch((error) => {
        const { code, message } = error;
        const errorMessage = getProperty(`labelError.${code}`) || message;
        this.setState({ emailError: errorMessage, showLoader: false });
      });
  };

  render() {
    const { emailId, emailError, showLoader, showSuccessMessage } = this.state;
    return (
      <div className="paths-login-container ">
        <div
          className="forgot-password"
          style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
        >
          {showSuccessMessage && (
            <div className="alert alert-success message">
              <span>{getProperty("forgot-password.success")}</span>
            </div>
          )}
          <div className="line row">
            <p className="forgot-password-header">
              {getProperty("forgot-password.header")}
            </p>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  onChange={this.handleEmailChange}
                  type="email"
                  className="input-btn placeholder"
                  placeholder="Email Address*"
                  value={emailId}
                />
                {emailError && <p className="error">{emailError}</p>}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="login-btn-container">
                {!showLoader && (
                  <button
                    className="submit-btn text-center"
                    onClick={this.handleSubmit}
                  >
                    {getProperty("label.submit")}
                  </button>
                )}
                {showLoader && (
                  <span className="login-btn text-center">
                    <Loader onButtonLoader="" />
                  </span>
                )}
              </div>
            </div>
            <div className="text-center mt-3 mb-3 w-100">
              <a
                href={() => false}
                className="login-link"
                onClick={this.props.updaForgotPasswordFlag}
              >
                {getProperty("login.already-registered-2")}
              </a>
              {getProperty("login.already-registered-3")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
