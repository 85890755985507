import "../../styles/style.css";

import isEmpty from "lodash/isEmpty";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { functions } from "../../firebase/firebaseConfig";
import { GAtrackPageDetails, getProperty } from "../../helper/helper";
import EntryFooter from "../footer/EntryFooter";
import Error from "../layouts/Error";
import MySpacePost from "../layouts/MySpacePost";
import OptionsOverlay from "../layouts/OptionsOverlay";
import PostBloggerContent from "../layouts/PostBloggerContent";
import PostBloggerMetaData from "../layouts/PostBloggerMetaData";
import PostCarousel from "../layouts/PostCarousel";
import PostDescription from "../layouts/PostDescription";
import PostProvider from "../layouts/PostProvider";
import Loader from "../layouts/TextLoader";
import SignIn from "../loginpage/SignIn";
import PostComment from "../posts/PostComment/PostComment";
import PostHeader from "../posts/PostHeader";
import PostNavigation from "../posts/PostNavigation/PostNavigation";
import EntryUserProfile from "../UserProfile/EntryUserProfile";
import InviteHeader from "./index";
import InviteParentPostWrapper from "./InviteParentPostWrapper";

const data = require("../../data/data.json");
const apiName = require("../../data/apiList.json");
const getUserDataWithoutAuth = functions.httpsCallable(
  apiName.getUserDataWithoutAuth
);
const getAnonymousUserData = functions.httpsCallable(
  apiName.getAnonymousUserData
);

class PostItemData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: props.post,
      selectedUser: [],
      collaboratorNotPosted: false,
      showLoader: false,
      selectedIndex: -1,
      phoneNumberExists: false,
      postOwner:null,
      showOptionOverlay: false,
      selectedPostId: "",
      isSelectedPostPrivate: false,
      unlinkedPostId: ""
    };
  }

  componentDidMount() {
    const { userId } = this.props;
    if (window.addthis) {
      window.addthis.init();
      window.addthis.update("share", "url", window.location.href);
    }
    const { post } = this.props;
    this.setState({ post: post });
    getUserDataWithoutAuth({
      userId,
      apiKey: data.apiKey,
      source: data.apiSource,
    })
      .then((response) => {
        const postOwner = {
          name: response.data.userName,
          photoURL: response.data.profilePhotoUrl,
          pathUserName: response.data.pathUserName,
          userId: userId,
        };
        this.setState({ postOwner: postOwner });          
      })
      .catch((error) => {
        getAnonymousUserData({
          anonymousUserId: userId,
          apiKey: data.apiKey,
          source: data.apiSource,
        }).then((response) => {
          const postOwner = {
            name: response.data.displayName,
            photoURL: response.data.profilePhotoUrls.original,
            pathUserName: response.data.userName,
            userId: userId,
          };
          this.setState({ postOwner: postOwner });
        }).catch((error) => {});
      });
  }

  /* When press on the coOwner,it gets the post of particular coOwner */
  onPressCoOwner = (post) => {
    if (this.state.collaboratorNotPosted) {
      this.setState({ collaboratorNotPosted: false });
    }
    this.setState({ post: post, showLoader: false });
  };

  resetDefaultUser = (isReset) => {
    if (isReset) {
      if (this.state.collaboratorNotPosted) {
        this.setState({ collaboratorNotPosted: false });
      }
      this.setState({ post: this.props.post, showLoader: false });
    }
  };

  /* When coOwer's number of post is not empty and owner click on that, it gives the 
  coOwner's post */
  handleClick = (coOwner, postIndex) => {
    const { userInfo } = this.props;

    const getPostsById = userInfo.user
      ? functions.httpsCallable("getPostByIdES")
      : functions.httpsCallable("getPostByIdWithoutAuth");
    GAtrackPageDetails();
    this.setState({
      selectedUser: coOwner,
      showLoader: true,
      selectedIndex: postIndex,
    });
    if (coOwner != null && coOwner.postId) {
      getPostsById({
        postId: coOwner.postId,
        includeComments: true,
        apiKey: data.apiKey,
        source: data.apiSource,
      })
        .then((res) => {
          if (!isEmpty(res)) {
            this.onPressCoOwner(res.data);
          } else {
            this.setState({ showLoader: false });
          }
        })
        .catch((error) => {
          this.setState({ showLoader: false });
        });
    } else if (postIndex === -1) {
      this.resetDefaultUser(true);
    } else if (!coOwner.postId) {
      this.setState({ collaboratorNotPosted: true, showLoader: false });
    }
  };

  manageOptionOverlay = (postId, isPrivate) => {
    this.setState({
      showOptionOverlay: !this.state.showOptionOverlay,
      selectedPostId: postId,
      isSelectedPostPrivate: isPrivate
    });
  };

  hideUnlinkedPost = (coOwnerId) => {
    this.setState({
      unlinkedPostId: coOwnerId
    });
  }

  render() {
    const {
      post,
      isLoadingData,
      isError,
      collaboratorNotPosted,
      selectedUser,
      showLoader,
      selectedIndex,
      postOwner,
      showOptionOverlay,
      selectedPostId,
      isSelectedPostPrivate,
      unlinkedPostId
    } = this.state;
    const {
      transition,
      nextPageToken,
      posts,
      userInfo,
      getPostByPostId,
      invitationId,
      postData,
      showUpdatedPost
    } = this.props;
    const { additionalUserInfo, isInvitationAccepted } = this.props;
    const imageUrl =
      additionalUserInfo && additionalUserInfo.user
        ? additionalUserInfo.user.profilePhotoUrls.medium
        : null;

    if (isLoadingData) {
      return <Loader />;
    } else if (isError) {
      return <Error />;
    } else if (post && post.parentPostId && selectedIndex === -1) {
      return (
        <InviteParentPostWrapper
          parentPostId={post.parentPostId}
          parentPostOwnerId={post.parentPostOwnerId}
          isMainFeed={false}
          imageUrl={imageUrl}
          owner={post && this.props.owner}
          userInfo={userInfo}
          getPosts={getPostByPostId}
          showPhoneNumberExists={this.props.showPhoneNumberExists}
          isInvitationAccepted={isInvitationAccepted}
        />
      );
    } else {
      return (
        <Fragment>
          <div className="row justify-content-md-center">
            <div className="paths-user-profile col-md-3">
              <EntryUserProfile transition={transition} post={post}/>
            </div>
            <div className="feed-post col-md-6">
              <InviteHeader
                isMainFeed={false}
                btnText={getProperty("label.include-memory")}
                msgText={post ? getProperty("label.invite-message") : ""}
                imageUrl={imageUrl}
                owner={post && this.props.owner}
                post={post}
                userInfo={userInfo}
                getPosts={getPostByPostId}
                invitationId={invitationId}
                isInvitationAccepted={isInvitationAccepted}
                showPhoneNumberExists={this.props.showPhoneNumberExists}
                postOwner={postOwner}
                postData={postData}
                transition={transition}
              />

              <PostNavigation
                {...this.props}
                post={this.props.post}
                isArrowVisible={true}
                handleClick={this.handleClick}
                onPressCollaborator={this.onPressCoOwner}
                resetDefaultUser={this.resetDefaultUser}
                transition={transition}
                nextPageToken={nextPageToken}
                posts={posts}
                isPathMain={false}
                selectedIndex={selectedIndex}
                selectedUser={this.state.selectedUser}
                isInvite={true}
                invitationId={invitationId}
                userIdData={this.props.userId}
                getPosts={getPostByPostId}
                owner={this.props.owner}
                showPhoneNumberExists={this.props.showPhoneNumberExists}
                unlinkedPostId={unlinkedPostId}
                postOwner={postOwner}
              />
              {!collaboratorNotPosted ? (
                <PostHeader
                  post={post}
                  selectedUser={selectedUser}
                  isClickable={false}
                  manageOptionOverlay={(postId, isPrivate) => this.manageOptionOverlay(postId, isPrivate)}
                />
              ) : (
                <div className="post-header-main">
                  <div className="post-header-text">
                    <span className="post-user-name">
                      {selectedUser.displayName}
                    </span>
                  </div>
                </div>
              )}

              {!collaboratorNotPosted ? (
                showLoader ? (
                  <div className="nopost-container">
                    <Loader />
                  </div>
                ) : (
                  <div className="feed-post-carousel">
                    {post.provider && (
                      <PostProvider
                        provider={post.provider}
                        owner={post.owner}
                      />
                    )}
                    <PostCarousel isClickable={false} post={post} />
                  </div>
                )
              ) : showLoader ? (
                <div className="nopost-container">
                  <Loader />
                </div>
              ) : (
                <div className="nopost-container">
                  <h1>
                    {getProperty("label.waiting-for-1")}{" "}
                    {selectedUser.displayName}{" "}
                    {getProperty("label.waiting-for-2")}
                  </h1>
                </div>
              )}
              {!collaboratorNotPosted && post.description && !showLoader && (
                <PostDescription
                  post={post}
                  isClickable={false}
                  description={post.description}
                />
              )}
              {!collaboratorNotPosted && post.htmlContent && (
                <Fragment>
                  <PostBloggerContent
                    isClickable={false}
                    post={post}
                    goToPostDetails={this.goToPostDetails}
                  />
                  <PostBloggerMetaData isClickable={false} post={post} />
                </Fragment>
              )}

              {!collaboratorNotPosted && post.myspace && (
                <MySpacePost
                  isClickable={false}
                  post={post}
                  goToPostDetails={this.goToPostDetails}
                />
              )}

              {!collaboratorNotPosted && !showLoader && (
                <PostComment transition={transition} post={post} />
              )}

              {showOptionOverlay && (
                <OptionsOverlay
                  show={showOptionOverlay} 
                  manageOptionOverlay={this.manageOptionOverlay} 
                  userInfo={userInfo} 
                  selectedPostId={selectedPostId} 
                  selectedUserId={this.props.userId} 
                  isSelectedPostPrivate={isSelectedPostPrivate}
                  postOwner={postOwner}
                  post={post}
                  transition={transition}
                  hideUnlinkedPost={this.hideUnlinkedPost}
                  isPathMain={false}
                  showUpdatedPost={showUpdatedPost}
                />
              )}

              <EntryFooter />
            </div>
            <div className="paths-sign-up-content col-md-3">
              <SignIn />
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, {})(PostItemData);
