import Invite from '../components/InviteSection/InviteMainData';
import PathMain from '../components/PathMain';
import PostItem from '../components/posts/SinglePost/PostItem';
import AccountSetting from '../components/UserProfile/AccountSetting';
import MakeCollabTagOrder from '../components/Order/MakeCollabTagOrder';
import SaveCollabTag from '../components/CollabTag/SaveCollabTag';
import CreateMemoryScreen from '../components/createMemory/CreateMemoryScreen';

var uiRouterConfigFn = function (router) {
  router.urlService.rules.otherwise(function (_router, _url) {
    let currentState = router.stateService.current.name;
    if (!currentState) {
      router.stateService.go('posts', {
        userId: '',
      });
    }
    return;
  });
};

var app_states = [
  {
    name: 'posts',
    url: '/:userId',
    component: PathMain,
    params: {
      postId: null,
      nextPageToken: null,
      posts: null,
      postOwner: null,
    },
    resolve: [
      {
        token: 'posts',
        deps: ['$transition$'],
        resolveFn: (trans) => {
          // Get userId param
          const userId = trans.params().userId;
          const postOwner = trans.params().postOwner;
          return (userId, postOwner);
        },
      },
    ],
  },
  {
    name: 'postDetail',
    url: '/:userId/posts/:postId',
    component: PostItem,
    params: { nextPageToken: null, posts: null, collabUserID: null, postOwner: null, newPostId: null },
    resolve: [
      {
        token: 'postDetail',
        deps: ['$transition$'],
        resolveFn: (trans) => {
          // Get userId, postId param
          const userId = trans.params().userId;
          const postId = trans.params().postId;
          const collabUserID = trans.params().collabUserID;
          const postOwner = trans.params().postOwner;
          const newPostId = trans.params().newPostId;
          // Fetch data
          return { userId, postId, collabUserID, postOwner, newPostId };
        },
      },
    ],
  },
  {
    name: 'orderCollabTag',
    url: '/order-collabtag',
    component: MakeCollabTagOrder,
    params: {
      postId: null,
      userId:null,
      nextPageToken: null,
      post: null,
      postUrl: null,
    },
    resolve: [
      {
        token: 'orderCollabTag',
        deps: ['$transition$'],
        resolveFn: (trans) => {
          // Get userId param
          const userId = trans.params().userId;
          const postId = trans.params().postId;
          const post = trans.params().post;
          const postUrl = trans.params().postUrl;
          return (userId, postId, post, postUrl);
        },
      },
    ],
  },
  {
    name: 'saveCollabTag',
    url: '/save-collabtag',
    component: SaveCollabTag,
    params: {
      postId: null,
      userId:null,
      nextPageToken: null,
      post: null,
      postUrl: null,
    },
    resolve: [
      {
        token: 'orderCollabTag',
        deps: ['$transition$'],
        resolveFn: (trans) => {
          // Get userId param
          const userId = trans.params().userId;
          const postId = trans.params().postId;
          const post = trans.params().post;
          const postUrl = trans.params().postUrl;
          return (userId, postId, post, postUrl);
        },
      },
    ],
  },
  {
    name: 'createNewPost',
    url: '/create-new-post',
    component: CreateMemoryScreen,
    params: {
      userId:null,
      nextPageToken: null,
      userInfo: null,
      postOwner: null,
      postUrl: null,
      parentPostId: null,
      parentPostPrivacy: null,
      post: null,
    },
    resolve: [
      {
        token: 'orderCollabTag',
        deps: ['$transition$'],
        resolveFn: (trans) => {
          // Get userId param
          const userId = trans.params().userId;
          const userInfo = trans.params().userInfo;
          const postUrl = trans.params().postUrl;
          const postOwner = trans.params().postOwner;
          const parentPostId = trans.params().parentPostId;
          const parentPostPrivacy = trans.params().parentPostPrivacy;
          const post = trans.params().post;
          return (userId, userInfo, postUrl, postOwner, parentPostId, parentPostPrivacy, post);
        },
      },
    ],
  },
  {
    name: 'invite',
    url: '/invite?id',
    component: Invite,
    params: {
      postId: null,
      nextPageToken: null,
      posts: null,
    },
    resolve: [
      {
        token: 'invite',
        deps: ['$transition$'],
        resolveFn: (trans) => {
          // Get userId param
          const id = trans.params().id;
          return id;
        },
      },
    ],
  },
  {
    name: 'editProfile',
    url: '/editProfile',
    component: AccountSetting,
    params: { userId: null },
    resolve: [
      {
        token: 'editProfile',
        deps: ['$transition$'],
        resolveFn: (trans) => {
          // Get userId param
          const userId = trans.params().userId;
          return { userId };
        },
      },
    ],
  },
];

export { app_states, uiRouterConfigFn };
