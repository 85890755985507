import React, { memo } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import FeedUserProfile from "./FeedUserProfile";

const FeedUserProfileModal = (props) => {
  return (
    <div className="signin-modal">
      <Modal
        show={props.show}
        dialogClassName="modal-lg custom-dialog"
        onHide={props.manageUserProfile}
        {...props}
        size="lg"
        backdrop="static"
      >
        <ModalBody>
          {/* <CloseButton onClick={props.manageUserProfile} /> */}
          <FeedUserProfile
            owner={props.owner}
            isPopup={true}
            closePopup={props.manageUserProfile}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default memo(FeedUserProfileModal);
