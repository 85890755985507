import React, { Fragment, useEffect, useState } from "react";

import { IMAGES, NAVIGATION } from "../../constants/constants";
import { functions } from "../../firebase/firebaseConfig";
import { getProperty } from "../../helper/helper";
import defaultUserImage from "../../images/icons/user.jpg";
import QRCodeWithPost from "../posts/QRCode/QRCodeWithPost";
/**
 * If image is broken, Adding default user image.
 */
const handleImageError = (ev) => {
  ev.target.src = defaultUserImage;
};
// const data = require("../../data/data.json");
const apiName = require("../../data/apiList.json");
const getUserDataWithoutAuth = functions.httpsCallable(
  apiName.getUserDataWithoutAuth
);

const HeaderSection = (props) => {
  // let [showLogin, setShowLogin] = useState(false);
  // let [showSignUpPopup, setShowSignUpPopup] = useState(true);
  // let [showSignIn, setShowSignIn] = useState(false);
  // let [showDoneScreen, setShowDoneScreen] = useState(false);
  let [photoUrl, setPhotoUrl] = useState("");
  // let [name, setName] = useState("");
  // let [pathUserName, setPathUserName] = useState("");
  const { postOwner } = props;

  useEffect(() => {
    const { postOwner } = props;
    if (postOwner) {
      setPhotoUrl(postOwner.photoURL);
      // setName(postOwner.name);
      // setPathUserName(postOwner.pathUserName);
    }
  }, []);

  const backToFeedPage = () => {
    const { transition, postOwner, postData } = props;
    let userId = "";
    userId = postData.userId;
    transition.router.stateService.go(NAVIGATION.POSTS, {
      userId: userId,
      postOwner: postOwner,
    });
  };

  // const redirectOnAppStore = (storeName) => {
  //   if (storeName === "apple") {
  //     window.open(appleStoreLink, "_blank");
  //   } else {
  //     window.open(googleStoreLink, "_blank");
  //   }
  // };

  return (
    <Fragment>
      <div className="row">
        <div className="col justify-content-center user-icon-name">
          <img
            alt="back arrow"
            src={IMAGES.BACK_ARROW.default}
            className="header-back-arrow-image"
            onClick={backToFeedPage}
          />
          <div className="header-paths-user-details">
            {postOwner && postOwner.photoURL ? (
              <img
                alt="header img"
                src={postOwner.photoURL}
                className="img-fluid header-image"
                onError={handleImageError}
                onClick={backToFeedPage}
              />
            ) : (
              <img
                alt="header img"
                src={photoUrl}
                className="img-fluid header-image"
                onError={handleImageError}
                onClick={backToFeedPage}
              />
            )}
            <div style={{ lineHeight: "15px" }}>
              {postOwner && postOwner.name && (
                <p className="header-paths-user-first-last-name">
                  <span>{postOwner.name}</span>
                </p>
              )}
              {postOwner && postOwner.pathUserName && (
                <p className="header-paths-user-name">
                  {postOwner.pathUserName}
                </p>
              )}
            </div>
          </div>
        </div>
        {props.userInfo.user && (
          <div className="post-header-collab-tag-caption">
            {getProperty("label.use-qrcode-to-help-collaboration")}
          </div>
        )}
        {props.userInfo.user && (
          <div className="col text-right d-flex justify-content-end post-header-title">
            <div className="text-right post-header-title">
              <QRCodeWithPost
                post={props.post}
                postHeader={true}
                highResolution={false}
                userProfile={false}
                transition={props.transition}
              />
            </div>
          </div>
        )}
        <img
          alt="master shadow img"
          src={IMAGES.MASTER_SHADOW_IMAGE.default}
          className="post-header-divider-img"
        />
      </div>
    </Fragment>
  );
};

export default HeaderSection;
