import "react-responsive-carousel/lib/styles/carousel.css";
import "video-react/dist/video-react.css";

import isEmpty from "lodash/isEmpty";
import React, { Component, Fragment } from "react";
import { Carousel } from "react-responsive-carousel";
import { BigPlayButton, ControlBar, Player } from "video-react";

import mySpaceErrorImage from "../../images/icons/mySpaceErrorImage.jpg";
import LinkList from "./LinkList";
import TeachableAndGratitude from "./TeachableAndGratitude";
import { IMAGES, STRINGS } from "../../constants/constants";
import { getProperty } from "../../helper/helper";

class PostCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: this.props.post,
      postContent: [],
    };
  }

  componentDidMount() {
    const { post } = this.state;
    if (!isEmpty(post.attachments)) {
      this.getPosts(post);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.post.id !== nextProps.post.id) {
      this.setState({
        post: nextProps.post,
      });
      this.getPosts(nextProps.post);
    }
  }

  /**
   * If image is broken, Adding default image for MySpace.
   */
  handleImageError = (ev) => {
    const { isMySpace } = this.props;
    ev.target.src = isMySpace ? mySpaceErrorImage : "";
  };

  /* It gets the posts like images and videos with videoPlayer */
  getPosts = (post) => {
    var postContent = [];
    const { isClickable } = this.props;
    if (!isEmpty(post.attachments)) {
      post.attachments.map((feed, i) => {
        if (feed.type === "image") {
          postContent.push(
            <div key={i}>
              <div
                className="post-background"
                style={{ background: `url(${feed.backgroundImageURL})` }}
              ></div>
              <div className="post-container">
                <img
                  onError={this.handleImageError}
                  alt="No Preview Available"
                  src={feed.URL}
                  className="img-fluid feed-container"
                />
              </div>
            </div>
          );
        } else if (feed.type === "video") {
          postContent.push(
            <div key={i}>
              <div
                className="post-background"
                style={{ background: `url(${feed.backgroundImageURL})` }}
              ></div>
              <div className="post-container">
                <Player
                  ref={(player) => {
                    this.player = player;
                  }}
                  autoPlay={false}
                  //playsInline
                  muted={false}
                  src={feed.URL || feed.thumbnailURL}
                  className="feed-container-video"
                  preload="none"
                  playsInline={true}
                  poster={feed.thumbnailURL}
                >
                  {!isClickable && (
                    <ControlBar autoHide={true}>
                      <BigPlayButton position="center" />
                    </ControlBar>
                  )}
                  {isClickable && (
                    <ControlBar disableDefaultControls={true}>
                      <BigPlayButton position="center" />
                    </ControlBar>
                  )}
                </Player>
              </div>
            </div>
          );
        }
      });
    }

    this.setState({
      postContent: postContent,
    });
  };

  render() {
    const { isClickable, parentPost, post } = this.props;
    const { postContent } = this.state;
    return (
      <Fragment>
        {postContent && postContent.length > 0 ? (
          <Carousel
            showThumbs={false}
            showStatus={false}
            autoPlay={false}
            emulateTouch={true}
            onClickItem={() =>
              isClickable && this.props.goToPostDetails(parentPost.id)
            }
            showIndicators={postContent.length > 1 ? true : false}
            selectedItem={postContent.length - 1}
            infiniteLoop={postContent.length > 1 ? true : false}
          >
            {postContent}
          </Carousel>
        ) : (
          <Carousel
            showThumbs={false}
            showStatus={false}
            autoPlay={false}
            emulateTouch={true}
            onClickItem={() =>
              isClickable && this.props.goToPostDetails(parentPost.id)
            }
            showIndicators={false}
            infiniteLoop={false}
          >
            {post.description && (
              <div className="nopost-container">
                <p>
                  {post.description.length > STRINGS.MAX_DESCRIPTION_LENGTH
                    ? `${post.description.substring(0, STRINGS.MAX_DESCRIPTION_LENGTH)}`
                    : post.description}
                  {post.description.length > STRINGS.MAX_DESCRIPTION_LENGTH && (
                    <span>...</span>
                  )}
                </p>
              </div>
            )}
          </Carousel>
        )}

        {!post.description && (
          <div className="feed-post-footer-without-description">
            {!isClickable && !post.description && post.teachableTakeaway && (
              <TeachableAndGratitude
                iconClass={"teachable-takeaway-icon"}
                title={post.teachableTakeaway.title}
                ttGJdescription={post.teachableTakeaway.description}
                icon={IMAGES.TEACHABLE_TAKAWAY_ICON}
                ttGjTitle={getProperty("label.teachable-takaway")}
              />
            )}

            {!isClickable && !post.description && post.gratitudeJournal && (
              <TeachableAndGratitude
                iconClass={"gratitude-journal-icon"}
                title={post.gratitudeJournal.title}
                ttGJdescription={post.gratitudeJournal.description}
                icon={IMAGES.GRATITUDE_JOURNAL_ICON}
                ttGjTitle={getProperty("label.gratitude-journal")}
              />
            )}

            {!post.description &&
              !isEmpty(post.siteLinks) &&
              (post.siteLinks.length === 1 ? (
                !isClickable &&
                !isEmpty(post.siteLinks) &&
                post.siteLinks.map((link, index) => {
                  return (
                    <LinkList
                      isLink={true}
                      url={link}
                      icon={IMAGES.LINK_LIST_ICON}
                      ttGjTitle={getProperty("label.link-list")}
                      index={index}
                      siteLinksLength={post.siteLinks.length}
                    />
                  );
                })
              ) : (
                <div className="row m-0 justify-content-center tt-gj-wrapper">
                  <div className="col-md-11 mt-3 link-list-container">
                    {!isClickable &&
                      post.siteLinks &&
                      post.siteLinks.length > 0 &&
                      post.siteLinks.map((link, index) => {
                        return (
                          <LinkList
                            isLink={true}
                            url={link}
                            icon={IMAGES.LINK_LIST_ICON}
                            ttGjTitle={getProperty("label.link-list")}
                            index={index}
                            siteLinksLength={post.siteLinks.length}
                          />
                        );
                      })}
                  </div>
                </div>
              ))}
          </div>
        )}
      </Fragment>
    );
  }
}

export default PostCarousel;
