import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NAVIGATION } from "../../constants/constants";
import { functions } from "../../firebase/firebaseConfig";
import { getMobileOperatingSystem, getProperty } from "../../helper/helper";
import defaultUserImage from "../../images/icons/user.jpg";
import { downloadQRCode } from "../../util/util";
import QRCodeWithPost from "../posts/QRCode/QRCodeWithPost";
import PrintQRCode from "./PrintQRCode";

// const data = require("../../data/data.json");
const apiName = require("../../data/apiList.json");
const getUserDataWithoutAuth = functions.httpsCallable(
  apiName.getUserDataWithoutAuth
);
class EntryUserProfile extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
      showOrder: false,
      photoUrl: "",
      name: "",
      userName: "",
      mobileOS: "",
    };
  }

  handleImageError = (ev) => {
    ev.target.src = defaultUserImage;
  };

  componentDidMount = () => {
    const { postOwner } = this.props;
    this.setState({
      mobileOS: getMobileOperatingSystem(),
    });
    if (postOwner) {
      this.setState({
        photoUrl: postOwner.photoURL,
        name: postOwner.name,
        userName: postOwner.pathUserName,
      });
    }
  };

  printQRCode = () => {
    window.print();
  };

  backToFeedPage = () => {
    const { transition, post, postOwner } = this.props;
    let userId = "";
    if (post && post.parentPostOwnerId) {
      userId = post.parentPostOwnerId;
    } else {
      userId = post.owner.userId;
    }
    transition.router.stateService.go(NAVIGATION.POSTS, {
      userId: userId,
      postOwner: postOwner,
    });
  };

  showOrderCollabTag = (event) => {
    const { transition, post } = this.props;
    transition.router.stateService.go(NAVIGATION.ORDERCOLLABTAG, {
      postId: post.id,
      userId: post.owner.userId,
      post: post,
      postUrl: window.location.href,
    });
  };

  highResolutionCollabTag = () => {
    const { transition, post } = this.props;
    transition.router.stateService.go(NAVIGATION.SAVECOLLABTAG, {
      postId: post.id,
      userId: post.owner.userId,
      post: post,
      postUrl: window.location.href,
    });
  };

  printCollabTag = () => {
    return <p>{getProperty("label.print-full-collab-tag")}</p>;
  };

  updateShowCollabTag = () => {
    this.setState({ showOrder: !this.state.showOrder });
  };

  render() {
    const { photoUrl, mobileOS } = this.state;
    const { post, postOwner } = this.props;
    // const { user } = this.props.additionalUserInfo;
    return (
      <Fragment>
        <div className="user-profile-section make-me-sticky">
          <div className="profile-header-web-feed">
            {postOwner && postOwner.photoURL ? (
              <img
                alt="user icon feed"
                src={postOwner.photoURL}
                className="user-icon-feed"
                onClick={this.backToFeedPage}
                onError={this.handleImageError}
              />
            ) : (
                <img
                  alt="user icon"
                src={photoUrl}
                className="user-icon-feed"
                onClick={this.backToFeedPage}
                onError={this.handleImageError}
              />
            )}
            {postOwner && postOwner.name && (
              <p className="display-name-feed">{postOwner.name}</p>
            )}
            {postOwner && postOwner.pathUserName && (
              <p className="display-user-name">{postOwner.pathUserName}</p>
            )}
          </div>
          <div className="user-profile-collab-tag-caption">
            {getProperty("label.use-qrcode-to-help-collaboration")}
          </div>
          <div className="user-profile-qr-code-image">
            <QRCodeWithPost
              post={post}
              postHeader={false}
              highResolution={false}
              userProfile={true}
            />
          </div>
          <div className="qr-links">
            <p onClick={downloadQRCode}>{getProperty("label.save-qr-only")}</p>
            {mobileOS !== "iOS" && (
              <p onClick={this.highResolutionCollabTag}>
                {getProperty("label.save-full-collab-tag")}
              </p>
            )}
            {/* <ReactToPrint
              trigger={this.printCollabTag}
              content={() => this.printCollabTagRef}
            />
            <div className="hidden-collab-tag-print">
              <QRCodeWithPost
                ref={(element) => (this.printCollabTagRef = element)}
                post={post}
                postHeader={false}
                highResolution={false}
                userProfile={true}
              />
            </div>
            <p onClick={this.showOrderCollabTag}>
              {getProperty("label.order-collab-tag-decals")}
            </p> */}
          </div>
          <div className="hidden-qr-code">
            <PrintQRCode
              qrcodeValue={window.location.href}
              isDownloadQRCode={true}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps)(EntryUserProfile);
