import "../../../styles/style.css";

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { updateAdditionalUserInfo, updateUser } from "../../../ducks/actions";
import { firebase } from "../../../firebase/firebaseConfig";
import { getProperty } from "../../../helper/helper";

class Logout extends Component {
  /* Get the user sign out with firebase and update the user and reload the main 
  feeds page*/
  logOutUser = () => {
    if (this.props.userInfo.user) {
      this.props.updateUser({});
      this.props.updateAdditionalUserInfo({});
      firebase
        .auth()
        .signOut()
        .then(() => {
          if (this.props.isPathMain) {
            //redirect to page not found page on click of logout
            //window.location.href = "#/";
            window.location.reload();
          } else this.reloadMainFeed();
        });
    }
  };

  /* It reloads the main feed page */
  reloadMainFeed = () => {
    const { transition, userIdData } = this.props;
    const userId = transition.params().userId
      ? transition.params().userId
      : userIdData;
    transition.router.stateService.go("posts", {
      userId,
      postId: "",
      nextPageToken: "",
      posts: "",
    });
  };

  dismiss() {
    document.getElementById("dismiss").parentNode.style.display = "none";
  }

  render() {
    const { userInfo } = this.props;
    return (
      <Fragment>
        {userInfo.user && (
          <span onClick={this.logOutUser} className="logout">
            {/* <i className='fa fa-sign-out logout-icon'></i> */}
            <span aria-hidden="true" className="d-inline-block logout-btn">
              {getProperty("label.logout")}
            </span>
          </span>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
  updateAdditionalUserInfo: (additionalUserInfo) =>
    dispatch(updateAdditionalUserInfo(additionalUserInfo)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
