import React, { memo } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { IMAGES } from "../../constants/constants";
import { getProperty } from "../../helper/helper";

const WelcomeOverlay = (props) => {

  return (
    <div>
      <Modal
        show={props.show}
        dialogClassName="modal-lg get-paths-overlay-dialog"
        onHide={() => props.manageWelcomePopup()}
        size="lg"
        backdrop="static"
      >
        <ModalBody>
          <div
            className="welcome-overlay-scrollable"
            style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
          >
            <div className="welcome-overlay">
              <img
                alt=""
                src={IMAGES.PATHS_COLOUR_LOGO.default}
                className="welcome-overlay-paths-logo"
              />
              <div className="welcome-overlay-welcome-message">
                <h1>{props.entryCreatorFirstName}</h1>
                <h3>{getProperty("welcome-popup.welcome-to-paths")}</h3>
                <p>{props.entryTitle}</p>
                <img alt="add me img" src={IMAGES.ADD_ME.default} />
              </div>
              <div className="welcome-overlay-further-guide">
                <p>{getProperty("welcome-popup.click-add-me-to-contribute")}</p>
                <div className="welcome-overlay-register-options">
                  <div className="signup-modal"> 
                    <span
                      className="signup-btn text-center"
                      onClick={() => props.showLoginModal()}
                    >
                      {getProperty("label.sign-up")}
                    </span>
                  </div>
                  <p
                    className="header-sign-in-text"
                    onClick={() => props.showSignInModal()}
                  >
                    {getProperty("label.sign-in")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default memo(WelcomeOverlay);