import React, { useState } from "react";

import { getProperty } from "../../helper/helper";
import SignIn from "../loginpage/SignIn";
import LoginModal from "../loginpage/LoginModal";
import { IMAGES, NAVIGATION } from "../../constants/constants";
import GetPathsOverlay from "./GetPathsOverlay";

const Error = (props) => {
  const {
    message,
    // showLoginModal,
    postOwner,
    userInfo,
    transition,
    postData,
  } = props;
  // const errorClass = typeof showLoginModal === "function" ? "error-showLogin" : "";
  let [showLogin, setShowLogin] = useState(false);
  let [showSignUpPopup, setShowSignUpPopup] = useState(true);
  let [showSignIn, setShowSignIn] = useState(false);
  let [showDoneScreen, setShowDoneScreen] = useState(false);
  let [showGetPaths, setShowGetPaths] = useState(false);

  const redirectToPaths = () => {
    window.location.href = NAVIGATION.GETPATHS;
  };

  const redirectToFeedback = () => {
    window.open(NAVIGATION.PATHS_FEEDBACK, "_blank");
  };

  const redirectToFeedPage = () => {
    transition.router.stateService.go(NAVIGATION.POSTS, {
      userId: postData.userId,
    });
  };

  return message === getProperty("labelError.permission-denied") ||
    message === getProperty("labelError.internal") ? (
    <div className="permission-denied-section">
      <img
        alt="paths color logo"
        src={IMAGES.PATHS_COLOUR_LOGO.default}
        className="permission-denied-paths-logo"
      />
      <div className="permission-denied-header-text">
        <span>{getProperty("error.must-login")}</span>
        <span>{postOwner && postOwner.name ? postOwner.name : ""}</span>
        {/* <span>User Post</span> */}
        <span>{getProperty("error.part-of-collabtab")}</span>
      </div>
      <p className="permission-denied-footer-text">
        {getProperty("error.request-to-join-collabtab")}
      </p>
      {userInfo && !userInfo.user && (
        <span
          className="d-inline-block btn-headersection"
          onClick={() => setShowLogin(true)}
        >
          {getProperty("label.sign-up-uppercase")}
        </span>
      )}
      {userInfo && !userInfo.user && (
        <p
          className="permission-denied-signin-label"
          onClick={() => setShowSignIn((showSignIn = !showSignIn))}
        >
          {getProperty("label.sign-in")}
        </p>
      )}
      {userInfo && userInfo.user && (
        <div
          className="permission-denied-feed-page-redirect"
          onClick={() => redirectToFeedPage()}
        >
          <span>{getProperty("error.go-to")}</span>
          <span className="permission-denied-post-owner-name">
            {postOwner && postOwner.name ? postOwner.name : ""}
          </span>
          <span>{getProperty("error.feed-page")}</span>
        </div>
      )}
      {(showLogin || showDoneScreen) && (
        <LoginModal
          show={true}
          updateShowLogin={() => setShowLogin((showLogin) => !showLogin)}
          showPopup={true}
          showSignUpPopup={showSignUpPopup}
          updateSignUpPopup={() =>
            setShowSignUpPopup((showSignUpPopup) => !showSignUpPopup)
          }
          showDoneScreen={showDoneScreen}
          updateDoneScreen={() =>
            setShowDoneScreen((showDoneScreen) => !showDoneScreen)
          }
          manageGetPaths={() =>
            setShowGetPaths((showGetPaths) => !showGetPaths)
          }
        />
      )}
      {showGetPaths && (
        <GetPathsOverlay
          show={showGetPaths}
          manageGetPaths={() =>
            setShowGetPaths((showGetPaths) => !showGetPaths)
          }
        />
      )}
      {showSignIn && (
        <LoginModal
          show={showSignIn}
          updateShowLogin={() => setShowSignIn((showSignIn = !showSignIn))}
          showPhoneNumberExists={true}
        />
      )}
    </div>
  ) : (
    <div className="row page-not-found">
      <div className="col-md-3"></div>
      <div className="error-page col-md-6">
        <div className="error-page-header">
          <p>{getProperty("label.page-not-found")}</p>
        </div>
        <div className="error-page-section">
          <p>{getProperty("label.page-fallen-off-its-path")}</p>
          <p>{getProperty("label.links-set-you-in-right-direction")}</p>
          <div className="error-page-section-links">
            <p onClick={redirectToPaths}>{getProperty("label.get-paths")}</p>
            <div>
              <span onClick={() => setShowLogin(true)}>
                {getProperty("label.sign-up-slash")}
              </span>
              {(showLogin || showDoneScreen) && (
                <LoginModal
                  show={true}
                  updateShowLogin={() =>
                    setShowLogin((showLogin) => !showLogin)
                  }
                  showPopup={true}
                  showSignUpPopup={showSignUpPopup}
                  updateSignUpPopup={() =>
                    setShowSignUpPopup((showSignUpPopup) => !showSignUpPopup)
                  }
                  showDoneScreen={showDoneScreen}
                  updateDoneScreen={() =>
                    setShowDoneScreen((showDoneScreen) => !showDoneScreen)
                  }
                  manageGetPaths={() =>
                    setShowGetPaths((showGetPaths) => !showGetPaths)
                  }
                />
              )}
              {showGetPaths && (
                <GetPathsOverlay
                  show={showGetPaths}
                  manageGetPaths={() =>
                    setShowGetPaths((showGetPaths) => !showGetPaths)
                  }
                />
              )}
              <span onClick={() => setShowSignIn((showSignIn = !showSignIn))}>
                {getProperty("label.sign-in")}
              </span>
              {showSignIn && (
                <LoginModal
                  show={showSignIn}
                  updateShowLogin={() =>
                    setShowSignIn((showSignIn = !showSignIn))
                  }
                  showPhoneNumberExists={true}
                />
              )}
            </div>
            <p onClick={redirectToFeedback}>
              {getProperty("label.leave-feedback")}
            </p>
          </div>
        </div>
      </div>
      <div className="paths-sign-up-content col-md-3">
        <SignIn />
      </div>
    </div>
  );
};
export default Error;
