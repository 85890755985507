import React, { Fragment, memo, useEffect, useState } from "react";
import "../../styles/style.css";
import { downloadQRCode } from "../../util/util";
import QRCode from "qrcode.react";

const PrintQRCode = ({
  qrcodeValue,
  userProfile,
  postHeader,
  qrcodehighresolution,
  isPopup,
  isDownloadQRCode,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Fragment>
      {qrcodehighresolution && (
        <div
          onClick={downloadQRCode}
          className="entrypage-user-profile-qrCodeContainer"
        >
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={550}
            level={"H"}
            includeMargin={true}
          />
        </div>
      )}

      {postHeader && width < 1022 && (
        <div
          // onClick={downloadQRCode}
          className="entrypage-user-profile-qrCodeContainer"
        >
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={225}
            level={"H"}
            includeMargin={true}
          />
        </div>
      )}

      {postHeader && width > 1124 && (
        <div
          // onClick={downloadQRCode}
          className="entrypage-user-profile-qrCodeContainer"
        >
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={50}
            level={"H"}
            includeMargin={true}
          />
        </div>
      )}

      {postHeader && width < 1124 && width > 1022 && (
        <div
          // onClick={downloadQRCode}
          className="entrypage-user-profile-qrCodeContainer"
        >
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={50}
            level={"M"}
            includeMargin={true}
          />
        </div>
      )}

      {userProfile && width > 1124 && (
        <div
          onClick={downloadQRCode}
          className="entrypage-user-profile-qrCodeContainer"
        >
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={150}
            level={"H"}
            includeMargin={true}
          />
        </div>
      )}

      {userProfile && width < 1124 && width > 1022 && (
        <div
          onClick={downloadQRCode}
          className="entrypage-user-profile-qrCodeContainer"
        >
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={150}
            level={"M"}
            includeMargin={true}
          />
        </div>
      )}

      {userProfile && width < 1022 && width > 767 && (
        <div
          onClick={downloadQRCode}
          className="entrypage-user-profile-qrCodeContainer"
        >
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={100}
            level={"L"}
            includeMargin={true}
          />
        </div>
      )}

      {userProfile && width < 767 && (
        <div
          //onClick={downloadQRCode}
          className="entrypage-user-profile-qrCodeContainer"
        >
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={140}
            level={"H"}
            includeMargin={true}
          />
        </div>
      )}

      {!userProfile && !qrcodehighresolution && !isPopup && width > 1124 && (
        <div className="user-profile-qrCodeContainer">
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={200}
            level={"H"}
            includeMargin={true}
          />
        </div>
      )}

      {!userProfile && !qrcodehighresolution && !isPopup && width < 1124 && width > 1022 && (
        <div onClick={downloadQRCode} className="user-profile-qrCodeContainer">
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={150}
            level={"M"}
            includeMargin={true}
          />
        </div>
      )}

      {!userProfile && !qrcodehighresolution && !isPopup && width < 1022 && width > 767 && (
        <div onClick={downloadQRCode} className="user-profile-qrCodeContainer">
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={150}
            level={"M"}
            includeMargin={true}
          />
        </div>
      )}

      {isPopup && (
        <div className="entrypage-user-profile-qrCodeContainer">
          <QRCode
            id="attached-qr-code"
            value={qrcodeValue}
            size={150}
            level={"M"}
            includeMargin={true}
          />
        </div>
      )}

      {isDownloadQRCode && width > 767 && (
        <div className="downloadable-qrCodeContainer">
          <QRCode
            id="big-attached-qr-code"
            value={qrcodeValue}
            size={500}
            level={"H"}
            includeMargin={true}
          />
        </div>
      )}

      {isDownloadQRCode && width < 767 && (
        <div className="downloadable-qrCodeContainer">
          <QRCode
            id="big-attached-qr-code"
            value={qrcodeValue}
            size={250}
            level={"H"}
            includeMargin={true}
          />
        </div>
      )}
    </Fragment>
  );
};

export default memo(PrintQRCode);
