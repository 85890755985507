import '../../styles/style.css';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { IMAGES } from '../../constants/constants';
import { updateAdditionalUserInfo, updateUser } from '../../ducks/actions';
import { auth, functions } from '../../firebase/firebaseConfig';
import { addEditProfileLink, GAtrackPageDetails, getProperty, isValidPostData } from '../../helper/helper';
import Header from '../header/Header';
import Error from '../layouts/Error';
import Loader from '../layouts/TextLoader';
import Logout from '../posts/PostNavigation/Logout';
import InviteHeader from './index';
import PostItemData from './InviteDetail';
import WelcomeDialog from './WelcomeDialog';

const data = require('../../data/data.json');
const getCollabtabInvitationDetails = functions.httpsCallable('getCollabtabInvitationDetails');
const getUserDataES = functions.httpsCallable('getUserDataES');
class InviteMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: [],
      isLoadingData: false,
      isError: false,
      errorMessage: '',
      postId: '',
      userId: '',
      displayDialog: false,
      isValidInvitaion: true,
      isInvitationAccepted: false,
      phoneNumberExists: false,
      showLogin: false,
      postData: null,
    };
  }

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  closeWelcomeModal = () => {
    this.setState({ displayDialog: false });
    this.getPostByPostId(this.state.postId);
  };

  openThanxModal = (postId) => {
    this.setState({ displayDialog: true });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    let self = this;
    //will get invitaion details
    const invitationId = self.props.$stateParams.id;
    let postData = {};
    this.setState({ isLoadingData: true });
    getCollabtabInvitationDetails({ invitationId, source: data.apiSource })
      .then((response) => {
        self.setState({ isValidInvitaion: true, postData: response.data });
        postData = response.data;
        this.updateUserInfoState(postData);
      })
      .catch((error) => {
        //catch of getCollabtabInvitationDetails
        this.updateUserInfoState(postData);
        if (error.message === 'Post invitation with given id is not found.') {
          this.setState({ isValidInvitaion: false });
        } else {
          this.setState({ isError: true });
        }
        this.setState({ isLoadingData: false });
      });
  };

  updateUserInfoState = (postData) => {
    let self = this;
    this.unsubscribe = auth.onAuthStateChanged(function (user) {
      if (user) {
        addEditProfileLink(self.props.transition);
        self.props.updateUser({ user });
        if (isEmpty(self.props.additionalUserInfo)) {
          getUserDataES({ apiKey: data.apiKey, source: data.apiSource })
            .then((userData) => {
              self.props.updateAdditionalUserInfo(userData.data);
            })
            .catch((error) => {
              //catch of getUserDataES
              self.setState({
                isLoadingData: false,
              });
            });
        }
      } else {
        self.props.updateUser({});
        self.props.updateAdditionalUserInfo({});
      }
      if (!isEmpty(postData)) {
        self.setState({
          postId: postData.postId,
          userId: postData.userId,
        });
        if (isEmpty(self.state.post)) {
          self.getPostByPostId(postData.postId);
        }
      }
    });
  };

  /* It gets the particular post with the help of postID  */
  getPostByPostId = (postId) => {
    this.setState({ isLoadingData: true });

    const { userInfo } = this.props;
    const getPostsById = userInfo.user
      ? functions.httpsCallable('getPostByIdES')
      : functions.httpsCallable('getPostByIdWithoutAuth');
    GAtrackPageDetails();
    getPostsById({ postId: postId, includeComments: true, apiKey: data.apiKey, source: data.apiSource })
      .then((res) => {
        this.setState({
          post: res.data,
          isLoadingData: false,
          errorMessage: '',
          isError: false,
        });
        this.checkInvitationStatus();
      })
      .catch((error) => {
        const { code, message } = error;
        const errorMessage = getProperty(`labelError.${code}`) || message;
        this.setState({
          errorMessage: errorMessage,
          isLoadingData: false,
          isError: true,
        });
      });
  };

  checkInvitationStatus = () => {
    const { post } = this.state;
    const { userInfo } = this.props;
    if (!isEmpty(post) && !isEmpty(post.coOwners) && !isEmpty(userInfo)) {
      if (post.coOwners.approved.some((item) => item.userId === userInfo.user.uid)) {
        this.setState({ isInvitationAccepted: true });
      }
    }
  };

  getPostOwnerDetail = () => {
    const { post } = this.state;
    if (!isEmpty(post)) {
      return {
        name: post.auditInfo.createdBy,
        photoURL: post.owner.photoURL,
      };
    } else {
      return {
        name: '',
        photoURL: '',
      };
    }
  };

  //Updating falg to show phone number warning
  showPhoneNumberExists = () => {
    this.setState({ phoneNumberExists: true });
  };

  //Closing phone number warning
  closePhoneNumberWarning = () => {
    this.setState({ phoneNumberExists: false });
  };

  updateShowLogin = (msgType, isMessage, message) => {
    this.setState({ showLogin: !this.state.showLogin });
  };

  showLoginModal = (event) => {
    this.setState({ showLogin: true });
  };

  showUpdatedPost = () => {
    const { postId } = this.state;
    this.getPostByPostId(postId);
  }

  render() {
    const { post, isLoadingData, isError, displayDialog, isValidInvitaion, isInvitationAccepted, postData } = this.state;
    const { phoneNumberExists, errorMessage } = this.state;
    const { userInfo, transition, additionalUserInfo } = this.props;

    const imageUrl =
      additionalUserInfo && additionalUserInfo.user ? additionalUserInfo.user.profilePhotoUrls.medium : null;

    if (isLoadingData) {
      return (
        <Fragment>
          <Header userInfo={userInfo}/>
          <img alt="master shadow img" src={IMAGES.MASTER_SHADOW_IMAGE.default} className="feed-header-section-dividor"/>
          <InviteHeader
            isMainFeed={false}
            imageUrl={imageUrl}
            owner={post ? this.getPostOwnerDetail() : null}
            post={post}
            userInfo={userInfo}
            isLoadingData={isLoadingData}
            invitationId={this.props.$stateParams.id}
            showPhoneNumberExists={this.showPhoneNumberExists}
          />
          <Loader />
        </Fragment>
      );
    } else if (isError) {
      return (
        <Fragment>
          <Header userInfo={userInfo}/>
          <img alt="master shadow img" src={IMAGES.MASTER_SHADOW_IMAGE.default} className="feed-header-section-dividor"/>          
          {errorMessage ? (
            <Error message={errorMessage} userInfo={userInfo} />
          ) : (
            <Error message={getProperty('label.refresh-again')} showRefresh={true} />
          )}
          <Logout
            isLoadingData={isLoadingData}
            userInfo={userInfo}
            transition={transition}
            userIdData={this.state.userId}
            isPathMain={true}
          />
        </Fragment>
      );
    } else if (!isValidInvitaion) {
      return (
        <Fragment>
          <Header userInfo={userInfo}/>
          <img alt="master shadow img" src={IMAGES.MASTER_SHADOW_IMAGE.default} className="feed-header-section-dividor"/>
          <InviteHeader
            isMainFeed={false}
            imageUrl={imageUrl}
            owner={post ? this.getPostOwnerDetail() : null}
            post={post}
            userInfo={userInfo}
            isLoadingData={isLoadingData}
            invitationId={this.props.$stateParams.id}
            showPhoneNumberExists={this.showPhoneNumberExists}
          />
          <Error message={getProperty('label.no-invitation')} />
          <Logout
            isLoadingData={isLoadingData}
            userInfo={userInfo}
            transition={transition}
            userIdData={this.state.userId}
            isPathMain={true}
          />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Header userInfo={userInfo}/>
          <img alt="master shadow img" src={IMAGES.MASTER_SHADOW_IMAGE.default} className="feed-header-section-dividor"/>
          {!post && <Error message={getProperty('label.no-post')} />}
          {post && isValidPostData(post) && (
            <PostItemData
              post={post}
              transition={transition}
              nextPageToken={null}
              posts={null}
              collabUserID={''}
              collabUser={''}
              userInfo={userInfo}
              userId={this.state.userId}
              getPostByPostId={this.openThanxModal}
              invitationId={this.props.$stateParams.id}
              owner={post ? this.getPostOwnerDetail() : null}
              isInvitationAccepted={isInvitationAccepted}
              showPhoneNumberExists={this.showPhoneNumberExists}
              postData={postData}
              showUpdatedPost={this.showUpdatedPost}
            />
          )}
          <Logout
            isLoadingData={isLoadingData}
            userInfo={userInfo}
            transition={transition}
            userIdData={this.state.userId}
            isPathMain={true}
          />

          {displayDialog && (
            <WelcomeDialog
              show={displayDialog}
              closeWelcomeModal={this.closeWelcomeModal}
              userInfo={userInfo && userInfo.user}
              phoneNumberExists={phoneNumberExists}
              closePhoneNumberWarning={this.closePhoneNumberWarning}
            />
          )}
        </Fragment>
      );
    }
  }
}

InviteMain.proptype = {
  $stateParams: PropTypes.object,
  router: PropTypes.object,
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
  updateAdditionalUserInfo: (user) => dispatch(updateAdditionalUserInfo(user)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteMain);
