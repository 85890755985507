import { isEmpty } from "lodash";
import React, { Fragment, memo, useState } from "react";
import { functions } from "../../firebase/firebaseConfig";
import defaultMetaPreviewImage from "../../images/icons/globe-icon.png";

const handleImageError = (ev) => {
  ev.target.src = defaultMetaPreviewImage;
};
const data = require("../../data/data.json");
const apiName = require("../../data/apiList.json");
const getMetaDataFromUrl = functions.httpsCallable(
  apiName.getMetaDataFromUrl
);
const LinkList = (props) => {
  const [metaPreviewImage, setMetaPreviewImage] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const {
    title,
    icon,
    ttGjTitle,
    isLink,
    url,
    index,
    siteLinksLength,
  } = props;
  const urlHostName = document.createElement("a");
  urlHostName.setAttribute("href",url);
  getMetaDataFromUrl({
    url,
    apiKey: data.apiKey,
    source: data.apiSource,
  }).then((response) => {
    if(response.data && response.data.result && response.data.result.ogDescription) {
      setMetaDescription(response.data.result.ogDescription);
    }
    if(response.data && response.data.result && response.data.result.ogImage && response.data.result.ogImage.url) {
      setMetaPreviewImage(response.data.result.ogImage.url);
    }
  }).catch((error) => {
  })
  if (index === 0 && index === siteLinksLength - 1) {
    return (
      <Fragment>
        <div className="row m-0 justify-content-center tt-gj-wrapper">
          <div className="col-md-11 mt-3 link-list-container">
            <div className="tt-gj-container">
              <div className="tt-gj-icon">
                {isLink ? (
                  <img alt="" src={icon.default} />
                ) : (
                  <img alt="" src={icon.default} />
                )}
              </div>
              <div className="tt-gj-text">
                <p>{ttGjTitle}</p>
              </div>
            </div>
            <div className="teachable-gratitude-body">
              <div className="teachable-gratitude-title">
                {isLink ? (
                  <a href={url} target="_blank" rel="noreferrer">
                    {urlHostName.hostname}
                  </a>
                ) : (
                  title
                )}
              </div>
              <div className="box">
                <img alt="meta preview img" src={metaPreviewImage} onError={handleImageError} />
              </div>
              {!isEmpty(metaDescription) && (
                <div className="box-text">{metaDescription}</div>
              )}
              {/* {!isEmpty(ttGJdescription) && ttGJdescription} */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else if (index === 0) {
    return (
      <Fragment>
        <div className="tt-gj-container">
          <div className="tt-gj-icon">
            {isLink ? (
              <img alt="" src={icon.default} />
            ) : (
              <img alt="" src={icon.default} />
            )}
          </div>
          <div className="tt-gj-text">
            <p>{ttGjTitle}</p>
          </div>
        </div>
        <div className="teachable-gratitude-body">
          <div className="teachable-gratitude-title">
            {isLink ? (
              <a href={url} target="_blank" rel="noreferrer">
                {urlHostName.hostname}
              </a>
            ) : (
              title
            )}
          </div>
          <div className="box">
            <img src={metaPreviewImage} onError={handleImageError} alt="meta preview img"/>
          </div>
          {!isEmpty(metaDescription) && (
            <div className="box-text">{metaDescription}</div>
          )}
          {/* {!isEmpty(ttGJdescription) && ttGJdescription} */}
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="teachable-gratitude-body">
          <div className="teachable-gratitude-title">
            {isLink ? (
              <a href={url} target="_blank" rel="noreferrer">
                {urlHostName.hostname}
              </a>
            ) : (
              title
            )}
          </div>
          <div className="box">
            <img src={metaPreviewImage} onError={handleImageError} alt="meta preview img"/>
          </div>
          {!isEmpty(metaDescription) && (
            <div className="box-text">{metaDescription}</div>
          )}
          {/* {!isEmpty(ttGJdescription) && ttGJdescription} */}
        </div>
      </Fragment>
    );
  }
};

export default memo(LinkList);
