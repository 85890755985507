import '../../styles/style.css';

import React from 'react';

const Loader = (props) => {
  const { onButtonLoader } = props;
  const buttonLoader = onButtonLoader === '' ? 'btn-loader' : 'loader mt-4';

  return (
    <div className={`${buttonLoader}`}>
      {' '}
      <span aria-hidden='true'>
        <i className='fa fa-spinner fa-spin '></i>
      </span>
    </div>
  );
};

export default Loader;
