import isEmpty from "lodash/isEmpty";
//component for display teachable takeaway and gratitude journals.
import React, { Fragment } from "react";

const TeachableAndGratitude = (props) => {
  const { title, ttGJdescription, icon, ttGjTitle, isLink, url } =
    props;
  return (
    <Fragment>
      <div className="row m-0 justify-content-center tt-gj-wrapper">
        <div className="col-md-11 teachable-gratitude-container">
          <div className="tt-gj-container">
            <div className="tt-gj-icon">
              {isLink ? (
                <img alt="" src={icon.default} />
              ) : (
                <img alt="" src={icon.default} />
              )}
            </div>
            <div className="tt-gj-text">
              <p>{ttGjTitle}</p>
            </div>
          </div>
          <div className="teachable-gratitude-body">
            <div className="teachable-gratitude-title">
              {isLink ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {url}
                </a>
              ) : (
                title
              )}
            </div>
            {!isEmpty(ttGJdescription) && ttGJdescription}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TeachableAndGratitude;
