import React, { memo, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { STRINGS } from "../../constants/constants";
import { getMobileOperatingSystem, getProperty } from "../../helper/helper";
import { validateImage, validateVideo } from "../../util/validation";

const ChooseCarousel = (props) => {
  const [imageError, setImageError] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [videoLengthError, setVideoLengthError] = useState(false);

  const carouselCreationTime = (files) => {
    let currentDate = new Date(files[0].lastModified);
    let defaultCreationTime =
      currentDate.getFullYear() +
      STRINGS.HYPHEN +
      ((currentDate.getMonth() + 1 < STRINGS.TEN ? STRINGS.ZERO : "") +
        (currentDate.getMonth() + 1)) +
      STRINGS.HYPHEN +
      ((currentDate.getDate() < STRINGS.TEN ? STRINGS.ZERO : "") +
        currentDate.getDate()) +
      STRINGS.CONSTANT_T +
      ((currentDate.getHours() < STRINGS.TEN ? STRINGS.ZERO : "") +
        currentDate.getHours()) +
      STRINGS.COLON +
      ((currentDate.getMinutes() < STRINGS.TEN ? STRINGS.ZERO : "") +
        currentDate.getMinutes());
    props.setPostCreationTime(defaultCreationTime);
  };

  const changeSelectedVideo = (event) => {
    let files = event.target.files;
    // let type = files[0].type;
    let videoData = {
      data: "",
      thumbnail: "",
      type: "",
      extension: "",
    };
    let arrayOfVideoData = [];
    const fileType = files[0].type.split(STRINGS.FORWARD_SLASH)[0];
    carouselCreationTime(files);
    if (validateVideo(files[0])) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        let video = document.createElement("video");
        video.preload = "metadata";
        video.src = URL.createObjectURL(files[0]);
        video.play();
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          let duration = video.duration;
          if (duration <= 60) {
            var canvas = document.createElement("canvas");
            canvas.width = 360;
            canvas.height = 240;
            var context = canvas.getContext("2d");
            video.addEventListener("loadeddata", function () {
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              var dataURI = canvas.toDataURL("image/jpeg");
              videoData.thumbnail = dataURI;
            });
            videoData.data = reader.result;
            videoData.type = fileType;
            videoData.extension = files[0].type.split(STRINGS.FORWARD_SLASH)[1];
            arrayOfVideoData.push(videoData);
            props.manageCarouselData(arrayOfVideoData);
            props.manageCarouselOverlay();
          } else {
            setVideoLengthError(true);
          }
        };
      };
    } else {
      setVideoError(true);
    }
  };

  const changeSelectedImage = (event) => {
    event.preventDefault();
    let files = event.target.files;
    let multipleImageData = [];
    carouselCreationTime(files);
    for (let i = 0; i < files.length; i++) {
      let imageData = {
        data: "",
        type: "",
        extension: "",
      };
      // let type = files[i].type;
      const fileType = files[i].type.split(STRINGS.FORWARD_SLASH)[0];
      imageData.type = fileType;
      imageData.extension = files[i].type.split(STRINGS.FORWARD_SLASH)[1];
      if (validateImage(files[i])) {
        let reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
          imageData.data = reader.result;
          multipleImageData.push(imageData);
          if (i + 1 === files.length) {
            props.manageCarouselData(multipleImageData);
            props.manageCarouselOverlay();
          }
        };
      } else {
        setImageError(true);
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        dialogClassName="modal-lg get-paths-overlay-dialog"
        onHide={() => props.manageCarouselOverlay()}
        size="lg"
        backdrop="static"
        fullscreen={true}
      >
        <ModalBody>
          <div className="choose-carousel-body">
            {!videoLengthError && (
              <div>
                {(getMobileOperatingSystem() === STRINGS.IOS ||
                  getMobileOperatingSystem() === STRINGS.ANDROID) && (
                  <div>
                    <label className="lab">
                      <p>{getProperty("create-post.take-picture")}</p>
                      <input
                        type="file"
                        name="file"
                        className="inputfile"
                        onChange={(event) => changeSelectedImage(event)}
                        accept="image/*"
                        capture="environment"
                      />
                    </label>
                    <hr />
                  </div>
                )}
                <label className="lab">
                  <p>{getProperty("create-post.choose-image")}</p>
                  {imageError && (
                    <p>{getProperty("create-post.select-correct-file")}</p>
                  )}
                  <input
                    type="file"
                    name="file"
                    className="inputfile"
                    onChange={(event) => changeSelectedImage(event)}
                    accept="image/*"
                    multiple
                  />
                </label>
                <hr />
                <label className="lab">
                  <p>{getProperty("create-post.choose-video")}</p>
                  {videoError && (
                    <p>{getProperty("create-post.select-correct-file")}</p>
                  )}
                  <input
                    type="file"
                    name="file"
                    className="inputfile"
                    onChange={(event) => changeSelectedVideo(event)}
                    accept="video/*"
                  />
                </label>
                <hr />
                <div
                  className="d-inline-block close-carousel-overlay-btn"
                  onClick={() => props.manageCarouselOverlay()}
                >
                  {getProperty("create-post.cancel")}
                </div>
              </div>
            )}
            {videoLengthError && (
              <div className="max-video-length-overlay">
                <p className="one-minute-max-length">
                  {getProperty("create-post.max-duration-one-minute")}
                </p>
                <p>
                  {getProperty(
                    "create-post.video-duration-greator-than-one-minute"
                  )}
                </p>
                <div
                  className="max-video-length-overlay-footer"
                  onClick={() => props.manageCarouselOverlay()}
                >
                  {getProperty("label.ok")}
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default memo(ChooseCarousel);