import React, { Component } from 'react';
import { connect } from 'react-redux';
class PostProvider extends Component {
  //Only post owner will be able to see provider info
  showPostProvider = () => {
    const { owner, userInfo } = this.props;
    const { user } = userInfo;
    if (user) {
      return owner.userId === user.uid;
    } else {
      return false;
    }
  };
  render() {
    const { provider } = this.props;
    const showProvider = this.showPostProvider();

    return showProvider ? <div className='post-provider'>{provider}</div> : null;
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
  };
};

export default connect(mapStateToProps, {})(PostProvider);
