import React, { Component, Fragment } from 'react';
import parse from 'html-react-parser';
export default class PostBloggerContent extends Component {
  handleClick = () => {
    const { isClickable, goToPostDetails, parentPost } = this.props;
    isClickable && goToPostDetails(parentPost.id);
  };

  render() {
    const { post } = this.props;

    return (
      <Fragment>
        <div onClick={() => this.handleClick()} className='post-overlay'>
          {parse(post.htmlContent)}
        </div>
      </Fragment>
    );
  }
}
