import React, { Fragment, memo, useState } from "react";
import { IMAGES } from "../../constants/constants";
import { getProperty } from "../../helper/helper";
import GetPathsOverlay from "../layouts/GetPathsOverlay";
import LoginModal from "../loginpage/LoginModal";
import {googleStoreLink, appleStoreLink} from '../../constants/constants';
const Header = (props) => {
  // const { userInfo, owner } = props;
  let [showLogin, setShowLogin] = useState(false);
  let [showSignUpPopup, setShowSignUpPopup] = useState(true);
  let [showSignIn, setShowSignIn] = useState(false);
  let [showDoneScreen, setShowDoneScreen] = useState(false);
  let [showGetPaths, setShowGetPaths] = useState(false);

  const redirectOnAppStore = (storeName) => {
    if (storeName === "apple") {
      window.open(appleStoreLink, "_blank");
    } else {
      window.open(googleStoreLink, "_blank");
    }
  };
  
  return (
    <div className="row header-section">
      <div className="col header-section-paths-logo">
        <img alt="new paths logo" src={IMAGES.NEW_PATHS_LOGO.default} />
      </div>
      <div className="header-section-login-buttons-marketplace-icons">
        {(!props.userInfo.user || showDoneScreen) && (
          <div>
            <span
              className="d-inline-block sign-up-btn-headersection"
              onClick={() => setShowLogin(true)}
            >
              {getProperty("label.sign-up")}
            </span>
            <Fragment>
              {showLogin && (
                <LoginModal
                  show={true}
                  updateShowLogin={() =>
                    setShowLogin((showLogin) => !showLogin)
                  }
                  showPopup={true}
                  showSignUpPopup={showSignUpPopup}
                  updateSignUpPopup={() =>
                    setShowSignUpPopup((showSignUpPopup) => !showSignUpPopup)
                  }
                  showDoneScreen={showDoneScreen}
                  isMainHeader={true}
                  updateDoneScreen={() =>
                    setShowDoneScreen((showDoneScreen) => !showDoneScreen)
                  }
                  manageGetPaths={() =>
                    setShowGetPaths((showGetPaths) => !showGetPaths)
                  }
                  entryCreatorFirstName={props.entryCreatorFirstName}
                />
              )}
            </Fragment>
            <p
              className="header-sign-in-text"
              onClick={() => setShowSignIn((showSignIn = !showSignIn))}
            >
              {getProperty("label.sign-in")}
            </p>
            <Fragment>
              {showSignIn && (!props.userInfo.user || showDoneScreen) && (
                <LoginModal
                  show={showSignIn}
                  updateShowLogin={() =>
                    setShowSignIn((showSignIn = !showSignIn))
                  }
                  showPhoneNumberExists={true}
                  manageGetPaths={() =>
                    setShowGetPaths((showGetPaths) => !showGetPaths)
                  }
                  updateDoneScreen={() =>
                    setShowDoneScreen((showDoneScreen) => !showDoneScreen)
                  }
                  showDoneScreen={showDoneScreen}
                  isMainHeader={true}
                />
              )}
            </Fragment>
          </div>
        )}
        {showGetPaths && (
          <GetPathsOverlay
            show={showGetPaths}
            manageGetPaths={() =>
              setShowGetPaths((showGetPaths) => !showGetPaths)
            }
          />
        )}
        <div className="header-section-marketplace-icons">
          <img
            alt="app store icon"
            src={IMAGES.APP_STORE_ICON.default}
            onClick={() => redirectOnAppStore("apple")}
          />
          <img
            alt="play store icon"
            src={IMAGES.PLAY_STORE_ICON.default}
            onClick={() => redirectOnAppStore("google")}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
