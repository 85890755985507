import React, { memo } from "react";
import { IMAGES, NAVIGATION, STRINGS } from "../../constants/constants";
import { getProperty } from "../../helper/helper";

const EntryFooter = () => {
  const footerNavigationLinks = (name) => {
    if(name === STRINGS.HOME) {
      window.open(NAVIGATION.GETPATHS);
    } else if(name === STRINGS.PRIVACY) {
      window.open(NAVIGATION.PRIVACY_POLICY);
    } else if(name === STRINGS.TERMS) {
      window.open(NAVIGATION.TERMS_OF_USE);
    } else if(name === STRINGS.HELP) {
      window.open(NAVIGATION.HELP);
    }
  };

  return (
    <div className="entry-footer">
      <div className="entry-footer-section">
        <div className="entry-paths-logo">
          <img alt="paths color logo" src={IMAGES.PATHS_COLOUR_LOGO.default} />
        </div>
        <div className="">
          <p className="footer-colaborate-detail">
            {getProperty("footer.entry-footer-paths-detail")}
          </p>
        </div>
      </div>
      <div className="detail-footer-section-navigation-links">
        <div className="footer-navigation-links">
          <span onClick={(name) => footerNavigationLinks(STRINGS.HOME)}>{getProperty("footer.home")}</span>
          <span onClick={(name) => footerNavigationLinks(STRINGS.TERMS)}>{getProperty("footer.terms")}</span>
          <span onClick={(name) => footerNavigationLinks(STRINGS.PRIVACY)}>{getProperty("footer.privacy")}</span>
          <span onClick={(name) => footerNavigationLinks(STRINGS.HELP)}>{getProperty("footer.help")}</span>
        </div>
        <p>{getProperty("footer.patent-number")}</p>
      </div>
    </div>
  );
};

export default memo(EntryFooter);
