import 'react-phone-number-input/style.css';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { CloseButton, Modal, ModalBody } from 'react-bootstrap';
import PhoneInput, { getCountryCallingCode, isPossiblePhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';

import { updateAdditionalUserInfo, updateUser } from '../../ducks/actions';
import { auth, firebase, functions } from '../../firebase/firebaseConfig';
import { addEditProfileLink, getFormattedPhoneNumber, getOriginalPhoneNumber, getProperty } from '../../helper/helper';
import pathsLogo from '../../images/logo/paths-logo.png';
import { validateImage } from '../../util/validation';
import Error from '../layouts/Error';
import Notification from '../layouts/Notifications';
import TextLoader from '../layouts/TextLoader';
import LoginModal from '../loginpage/LoginModal';
import Logout from '../posts/PostNavigation/Logout';
import {privacyPageLink, termsPageLink} from '../../constants/constants';
const apiName = require('../../data/apiList.json');
const data = require('../../data/data.json');

const getUserDataES = functions.httpsCallable(apiName.getUserDataES);
const deleteUserAccount = functions.httpsCallable(apiName.deleteUserAccount);
const updateUserProfilePhoto = functions.httpsCallable(apiName.updateUserProfilePhoto);
const updateUserData = functions.httpsCallable(apiName.updateUser);
class AccountSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      phoneNumberExists: false,
      showAccountSettings: false,
      showLoader: true,
      showDeleteConfirmation: false,
      showDeleteLoader: false,
      profilePhoto: '',
      profilePhotoError: '',
      fileType: '',
      isProfileImageChanged: false,
      firstName: '',
      lastName: '',
      mobileNumber: '',
      countryCalling: data.defaultCountry.calling,
      countryCode: data.defaultCountry.code,
      // mobileNumber: '',
      isProfilePicUnSaved: false,
      isUserProfileUnSaved: false,
      updateUserError: '',
      updateUserSuccess: false,
      firstNameError: '',
      lastNameError: '',
      phoneNuberInput: '',
    };
    this.profilePictureInput = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let self = this;
    this.unsubscribe = auth.onAuthStateChanged(function (user) {
      if (user) {
        addEditProfileLink();
        self.props.updateUser({ user });
        if (isEmpty(self.props.additionalUserInfo)) {
          self.setState({ showLoader: true });
          getUserDataES({ apiKey: data.apiKey, source: data.apiSource })
            .then((userData) => {
              self.props.updateAdditionalUserInfo(userData.data);
              self.setUserDataInView(userData.data);
              self.setProfilePicData(userData.data);
              self.setState({ showLoader: false });
            })
            .catch((error) => {
              self.setState({ showLoader: false });
            });
        } else {
          const { additionalUserInfo } = self.props;
          self.setUserDataInView(additionalUserInfo);
          self.setProfilePicData(additionalUserInfo);
          self.setState({ showAccountSettings: true, showLoader: false });
        }
      } else {
        self.setState({ showLoader: false, showAccountSettings: false });
      }
    });
  }

  componentWillUpdate(nextProps, nextState) {
    const { additionalUserInfo } = this.props;
    if (!isEmpty(nextProps.additionalUserInfo) && nextProps.additionalUserInfo !== additionalUserInfo) {
      this.setState({ showAccountSettings: true, showLoader: false });
      this.setProfilePicData(nextProps.additionalUserInfo);
    }
  }

  //Fetching data and updating in state to show on ui
  setUserDataInView = (additionalUserInfo) => {
    const { firstName, lastName, phoneNumber, accountPrivacy } = additionalUserInfo.user;
    const { userInfo } = this.props;
    const { email } = userInfo.user;
    const isPrivate = accountPrivacy !== 'public';

    this.setState({
      firstName: firstName,
      lastName: lastName,
      email: email,
      accountPrivacy: isPrivate,
    });

    if (phoneNumber) {
      const { countryCalling, countryCode, number } = phoneNumber;
      this.setState({
        phoneNumber: phoneNumber,
        countryCalling: countryCalling,
        countryCode: countryCode,
        mobileNumber: getFormattedPhoneNumber(countryCode, number),
        phoneNuberInput: `${countryCalling}${number}`,
        mobileError: '',
      });
    }
  };

  //Fetching profile picture data to show on ui
  setProfilePicData = (additionalUserInfo) => {
    const profilePhotoUrls = this.getProfilePhotoUrls(additionalUserInfo);
    const { original } = profilePhotoUrls;
    this.setState({ profilePhoto: original });
  };

  //This will return all profile pic urls from additional user data
  getProfilePhotoUrls = (additionalUserInfo) => {
    if (additionalUserInfo && additionalUserInfo.user) {
      const { profilePhotoUrls } = additionalUserInfo.user;
      return profilePhotoUrls;
    }
  };

  showLoginModal = () => {
    this.setState({ showLogin: true });
  };

  updateShowLogin = (msgType, isMessage, message) => {
    this.setState({ showLogin: !this.state.showLogin });
  };

  //Updating falg to show phone number warning
  showPhoneNumberExists = () => {
    this.setState({ phoneNumberExists: true });
  };

  //Closing phone number warning
  closePhoneNumberWarning = () => {
    this.setState({ phoneNumberExists: false });
  };

  //Open delete confirmation popup
  deleteAccountHandler = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  //Close delete confirmation popup
  closeDeleteConfirmation = () => {
    this.setState({ showDeleteConfirmation: false, error: '' });
  };

  //Deleting User Account
  onClickConfirmDeleteAccount = () => {
    this.setState({ showDeleteLoader: true });
    deleteUserAccount({ apiKey: data.apiKey, source: data.apiSource })
      .then((response) => {
        this.signOutFirebase();
      })
      .catch((error) => {
        const { code } = error;
        const errorMessage = getProperty(`labelError.${code}`) || getProperty('labelError.something-wrong');

        this.setState({
          error: errorMessage,
          showDeleteLoader: false,
        });
      });
  };

  //Remove user context from firebase indexed db
  signOutFirebase = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.props.updateUser({});
        this.props.updateAdditionalUserInfo({});
        window.location.reload();
      });
  };

  //Handler of Profile Picture selection
  onChangeProfileImage = (event) => {
    event.preventDefault();
    let files = event.target.files;
    if (!isEmpty(files) && validateImage(files[0])) {
      this.updateProfilePictureInView(files[0]);
    } else {
      this.setState({ profilePhotoError: 'Please select valid profile image.' });
    }
  };

  //Update profile picture on ui when new one is selected.
  updateProfilePictureInView = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      this.setState({
        fileType: file.type,
        profilePhoto: e.target.result,
        profilePhotoError: '',
        isProfileImageChanged: true,
      });
    };
  };

  //This will validate form and trigger submit flow
  handleSubmit = () => {
    const { isProfileImageChanged } = this.state;
    if (this.validateForm()) {
      this.setState({ showSubmitLoader: true });
      this.saveUserDetails();
      if (isProfileImageChanged) {
        this.saveUserProfilePicture();
      }
    }
  };

  validateForm = () => {
    const { firstName, lastName } = this.state;
    const { countryCalling, mobileNumber } = this.state;
    let isValid = true;

    if (!firstName || isEmpty(firstName.trim())) {
      isValid = false;
      let errorMessage = getProperty('login.enter-first-name');
      this.setState({ firstNameError: errorMessage, firstName: '' });
    }

    if (!lastName || isEmpty(lastName.trim())) {
      isValid = false;
      let errorMessage = getProperty('login.enter-last-name');
      this.setState({ lastNameError: errorMessage, lastName: '' });
    }

    if (mobileNumber) {
      if (!isPossiblePhoneNumber(`${countryCalling}${mobileNumber}`)) {
        isValid = false;
        let errorMessage = getProperty('login.invalid-mobile');
        this.setState({ mobileError: errorMessage });
      }
      if (!countryCalling) {
        isValid = false;
        let errorMessage = getProperty('login.select-country-code');
        this.setState({ mobileError: errorMessage });
      }
    }
    return isValid;
  };

  //This will save userdata in firebase
  saveUserDetails = () => {
    const { firstName, lastName, accountPrivacy } = this.state;
    const { countryCalling, countryCode, mobileNumber } = this.state;
    const phoneNumber = {
      countryCalling: mobileNumber ? countryCalling : '',
      countryCode: mobileNumber ? countryCode : '',
      number: getOriginalPhoneNumber(mobileNumber),
    };
    this.setState({ isUserProfileUnSaved: true });
    const params = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      accountPrivacy: accountPrivacy ? 'private' : 'public',
      apiKey: data.apiKey,
      source: data.apiSource,
    };

    updateUserData(params)
      .then((response) => {
        if (response.data && !response.data.error) {
          this.updateAdditionUserDataStore();
        } else {
          this.handleUpdateUserError(response.data);
        }
      })
      .catch((error) => {
        const { code } = error;
        const errorMessage = getProperty(`labelError.${code}`) || getProperty('labelError.something-wrong');
        this.setState({ updateUserSuccess: false, updateUserError: errorMessage, isUserProfileUnSaved: false });
        this.hideUpdateNotification();
      });
  };

  //This will save profile picture data in firebase
  saveUserProfilePicture = () => {
    const { profilePhoto, fileType } = this.state;
    this.setState({ isProfilePicUnSaved: true });
    let base64Data = profilePhoto.split(',')[1];
    const params = {
      base64Data: base64Data,
      fileType: fileType,
      apiKey: data.apiKey,
      source: data.apiSource,
    };
    updateUserProfilePhoto(params)
      .then((response) => {
        const { profilePhotoUrls } = response.data;
        const { additionalUserInfo } = this.props;
        additionalUserInfo.user.profilePhotoUrls = profilePhotoUrls;
        this.props.updateAdditionalUserInfo(additionalUserInfo);
        this.setState({
          updateUserSuccess: true,
          updateUserError: '',
          isProfileImageChanged: false,
          fileType: '',
          isProfilePicUnSaved: false,
        });
        this.hideUpdateNotification();
      })
      .catch((error) => {
        const { code } = error;
        const errorMessage = getProperty(`labelError.${code}`) || getProperty('labelError.something-wrong');
        this.setState({ updateUserSuccess: false, updateUserError: errorMessage, isProfilePicUnSaved: false });
        this.hideUpdateNotification();
      });
  };

  //This will update additional userdata in redux store
  updateAdditionUserDataStore = () => {
    const { firstName, lastName, accountPrivacy } = this.state;
    const { countryCalling, countryCode, mobileNumber } = this.state;
    const { additionalUserInfo } = this.props;
    const { user } = additionalUserInfo;
    const phoneNumber = {
      countryCalling: mobileNumber ? countryCalling : '',
      countryCode: mobileNumber ? countryCode : '',
      number: getOriginalPhoneNumber(mobileNumber),
    };

    user.firstName = firstName;
    user.lastName = lastName;
    user.phoneNumber = phoneNumber;
    user.accountPrivacy = accountPrivacy ? 'private' : 'public';
    this.props.updateAdditionalUserInfo(additionalUserInfo);
    if (!mobileNumber) {
      this.setState({ countryCalling: data.defaultCountry.calling, countryCode: data.defaultCountry.code });
    }
    this.setState({ isUserProfileUnSaved: false, updateUserError: '', updateUserSuccess: true });
    this.hideUpdateNotification();
  };

  hideUpdateNotification = () => {
    this.change = setTimeout(() => {
      this.setState({ updateUserSuccess: false, updateUserError: '' });
    }, 3000);
  };

  //This will enable flag to show error while updating userdata
  handleUpdateUserError = (data) => {
    const { error } = data;
    const errorMessage = getProperty(`labelError.${error}`) || getProperty('labelError.something-wrong');
    this.setState({ updateUserError: errorMessage, isUserProfileUnSaved: false, updateUserSuccess: false });
    this.hideUpdateNotification();
  };

  //This will close success or error notification
  closeUpdateUserMessage = () => {
    this.setState({ updateUserError: '', updateUserSuccess: false });
  };

  handleInputChange = (event) => {
    if ([event.target.name] === 'mobileNumber') {
      const { countryCode } = this.state;
      const formattedNumber = getFormattedPhoneNumber(countryCode, event.target.value);
      this.setState({ [event.target.name]: formattedNumber, mobileError: '' });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      if ([event.target.name] === 'firstName') {
        this.setState({ firstNameError: '' });
      } else if ([event.target.name] === 'lastName') {
        this.setState({ lastNameError: '' });
      } else if ([event.target.name] === 'firstname') {
        this.setState({ firstNameError: '' });
      }
    }
  };

  //Updating account privacy checkmark on ui
  handleAccountPrivacyChange = (event) => {
    const target = event.target;
    this.setState({ accountPrivacy: target.checked });
  };

  //This will open user's feed in new tab
  openMyFeed = () => {
    const { userInfo } = this.props;
    const userName = userInfo.user && userInfo.user.uid;
    const { pathname } = window.location;
    const myFeedsLink = `${pathname}#/${userName}`;
    window.open(myFeedsLink, '_blank');
  };

  //Redirect back to user's feeds page
  redirectBackToUserFeeds = () => {
    const { transition } = this.props;
    const { userId } = transition.params();
    transition.router.stateService.go('posts', {
      userId,
    });
  };

  phoneChange = (value) => {};

  onCountryChange = (value) => {
    if (value) {
      const callingCode = getCountryCallingCode(value);
      const callingCodeWithPlus = `+${callingCode}`;
      const formattedNumber = getFormattedPhoneNumber(value, this.state.mobileNumber);
      this.setState({ countryCalling: callingCodeWithPlus, countryCode: value, mobileNumber: formattedNumber });
    }
  };

  render() {
    const { showLogin, phoneNumberExists, showAccountSettings, showLoader, showDeleteConfirmation } = this.state;
    const { showDeleteLoader, error, profilePhotoError, isProfilePicUnSaved, isUserProfileUnSaved } = this.state;
    const { firstName, lastName, email, profilePhoto, accountPrivacy } = this.state;
    const { countryCode, countryCalling, mobileNumber, updateUserError, updateUserSuccess } = this.state;
    const { userInfo, transition } = this.props;
    const { firstNameError, lastNameError, mobileError, phoneNuberInput } = this.state;

    return (
      <div className="account-setting-wrapper">
        <Logout
          isLoadingData={false}
          userInfo={userInfo}
          transition={transition}
          isPathMain={true}
        ></Logout>
        {showLoader && <TextLoader />}
        {!showAccountSettings && !showLoader && (
          <Error
            message={getProperty("label.guest-login-warning")}
            showLoginModal={this.showLoginModal}
          />
        )}
        {showLogin && (
          <LoginModal
            show={showLogin}
            updateShowLogin={this.updateShowLogin}
            showPhoneNumberExists={this.showPhoneNumberExists}
          />
        )}
        {phoneNumberExists && (
          <Notification
            type="warning"
            message={getProperty("label.phone-number-exists")}
            closeModal={this.closePhoneNumberWarning}
          />
        )}

        {showAccountSettings && (
          <Fragment>
            <div className="invite-postheader">
              <div className="post-header-title">
                <h4 className="title">
                  {getProperty("accountSettings.header-title")}
                </h4>
                <p>{getProperty("accountSettings.header-subtitle")}</p>
              </div>
            </div>
            <div className="account-info-section">
              <div className="row">
                <div className="col-lg-3 col-md-4 profile-picture-wrapper">
                  {transition.params().userId && (
                    <span
                      className="back-arrow-link"
                      onClick={this.redirectBackToUserFeeds}
                    >
                      <span aria-hidden="true" className="icon-style">
                        <i className="fa fa-arrow-left"></i>
                      </span>
                    </span>
                  )}
                  <div
                    className="profile-picture-container"
                    style={{ backgroundImage: `url(${profilePhoto})` }}
                  >
                    <div className="profile-upload">
                      <label for="profile-input">
                        <i
                          className="fa fa-pencil custom-profile-uploader"
                          aria-hidden="true"
                        ></i>
                      </label>
                      <input
                        id="profile-input"
                        type="file"
                        onChange={this.onChangeProfileImage}
                        ref={this.profilePictureInput}
                        accept=".jpeg,.jpg,.png,.apng,.gif,.svg,.bmp,.ico"
                      />
                    </div>
                  </div>
                  {profilePhotoError && (
                    <p className="error text-center">{profilePhotoError}</p>
                  )}
                  <div className="myfeed-container">
                    <span>
                      <button
                        className="btn btn-myfeed"
                        onClick={this.openMyFeed}
                      >
                        {getProperty("accountSettings.myfeed-button-label")}
                        <i className="fa fa-external-link"></i>
                      </button>
                    </span>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 edit-user-form">
                  <div className="row">
                    <div className="col-lg-9">
                      <p className="name-container">
                        <label for="firstName">
                          {getProperty("accountSettings.first-name")}
                        </label>
                        <input
                          className="input-btn placeholder"
                          type="text"
                          id="firstName"
                          name="firstName"
                          value={firstName}
                          onChange={this.handleInputChange}
                          placeholder={getProperty("login.first-name-required")}
                          maxLength={data.signUpMaxChar}
                          required
                        />
                        <span>
                          {(firstName && firstName.length) || 0}/
                          {data.signUpMaxChar}
                        </span>
                        {firstNameError && (
                          <p className="error">{firstNameError}</p>
                        )}
                      </p>
                    </div>
                    <div className="col-lg-9">
                      <p className="name-container">
                        <label for="lastName">
                          {getProperty("accountSettings.last-name")}
                        </label>
                        <input
                          className="input-btn placeholder"
                          type="text"
                          id="lastName"
                          name="lastName"
                          value={lastName}
                          onChange={this.handleInputChange}
                          placeholder={getProperty("login.last-name-required")}
                          maxLength={data.signUpMaxChar}
                          required
                        />
                        <span>
                          {(lastName && lastName.length) || 0}/
                          {data.signUpMaxChar}
                        </span>
                        {lastNameError && (
                          <p className="error">{lastNameError}</p>
                        )}
                      </p>
                    </div>
                    <div className="col-lg-9">
                      <p>
                        <label for="email">
                          {getProperty("accountSettings.email")}
                        </label>
                        <input
                          className="input-btn placeholder"
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          readOnly
                        />
                      </p>
                    </div>
                    <div className="col-lg-9">
                      <p>
                        <PhoneInput
                          placeholder={getProperty(
                            "login.optional-mobile-number"
                          )}
                          value={phoneNuberInput}
                          onChange={this.phoneChange}
                          defaultCountry={countryCode}
                          onCountryChange={this.onCountryChange}
                          className="paths-phone account-settings"
                          addInternationalOption={false}
                        />
                        <p className="custom-mobile-number">
                          <span className="phone-code">{`(${countryCalling})`}</span>
                          <input
                            className="input-btn placeholder mobile-number-field"
                            type="mobileNumber"
                            name="mobileNumber"
                            value={mobileNumber}
                            onChange={this.handleInputChange}
                            placeholder={getProperty(
                              "login.optional-mobile-number"
                            )}
                          />
                        </p>
                        {mobileError && <p className="error">{mobileError}</p>}
                      </p>
                    </div>
                    <div className="col-lg-9 ">
                      <p className="privacy-wrapper">
                        <input
                          type="checkbox"
                          id="accountPrivacy"
                          name="accountPrivacy"
                          checked={accountPrivacy}
                          onChange={this.handleAccountPrivacyChange}
                        />
                        <label for="accountPrivacy">
                          <span></span>
                        </label>
                        <label className="privacy-label">
                          {getProperty("accountSettings.privacy-label")}
                          <span className="privacy-sublabel">
                            {getProperty("accountSettings.privacy-sublabel")}
                          </span>
                        </label>
                      </p>
                    </div>
                    <div className="col-lg-9 text-center">
                      <button
                        className="btn btn-update text-center"
                        onClick={this.handleSubmit}
                      >
                        {isProfilePicUnSaved || isUserProfileUnSaved ? (
                          <i className="fa fa-spinner fa-spin "></i>
                        ) : (
                          getProperty("label.save")
                        )}
                      </button>
                      {updateUserSuccess && (
                        <Notification
                          type="success"
                          message={getProperty(
                            "accountSettings.update-success-message"
                          )}
                          closeModal={this.closeUpdateUserMessage}
                        />
                      )}
                      {updateUserError && (
                        <Notification
                          type="danger"
                          message={updateUserError}
                          closeModal={this.closeUpdateUserMessage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="section-divider" />
              <div className="row">
                <div className="col-lg-6 delete-section-links">
                  <p className="items">
                    <a href={privacyPageLink} target="_blank" rel="noreferrer">
                      <i className="fa fa-external-link"></i>
                      <span className="link-label">
                        {" "}
                        {getProperty("label.data-privacy-policy")}
                      </span>
                    </a>
                  </p>
                  <p className="items">
                    <a href={termsPageLink} target="_blank" rel="noreferrer">
                      <i className="fa fa-external-link"></i>
                      <span className="link-label">
                        {" "}
                        {getProperty("label.terms-condition")}
                      </span>
                    </a>
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="delete-message">
                        {getProperty("accountSettings.delete-message")}
                      </p>
                    </div>
                    <div className="col-sm-6 text-center delete-account-button-container">
                      <p className="delete-logo">
                        <i className="fa fa-exclamation-triangle"></i>
                      </p>
                      <span
                        className="btn-delete-account"
                        onClick={this.deleteAccountHandler}
                      >
                        {getProperty("accountSettings.delete-button-label")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}

        <Modal
          show={showDeleteConfirmation}
          dialogClassName="modal-lg custom-dialog"
          onHide={this.closeDeleteConfirmation}
          size="lg"
          backdrop="static"
        >
          <ModalBody>
            <CloseButton onClick={this.closeDeleteConfirmation} />
            {showDeleteLoader && (
              <div className="login-overlay">
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            )}
            <div
              className="confirm-delete-account"
              style={{ maxHeight: "calc(100vh - 50px)", overflowY: "auto" }}
            >
              <div className="confirm-delete-account-container text-center">
                <p className="delete-account-title">
                  Delete My Account Forever
                </p>
                <img alt="path logo" src={pathsLogo} className="paths-logo"></img>
                <p className="delete-account-title">
                  Are you sure want to delete your account permanently?
                </p>
                <hr />
                <p>
                  Clicking the button below will remove all data of you and your
                  activity, forever. You will have to start over.
                </p>
                <span
                  className="btn-delete-account"
                  onClick={this.onClickConfirmDeleteAccount}
                >
                  Yes, please delete account
                </span>
                {error && <span className="error">{error}</span>}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AccountSetting.proptype = {
  $stateParams: PropTypes.object,
  router: PropTypes.object,
  transition: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
  updateAdditionalUserInfo: (user) => dispatch(updateAdditionalUserInfo(user)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetting);
