import React, { Component, Fragment } from 'react';

class Notification extends Component {
  render() {
    return (
      <Fragment>
        <div className={`logoutalert alert alert-${this.props.type}`}>
          <span className='closebttn' id='dismiss' onClick={this.props.closeModal}>
            &times;
          </span>
          {this.props.message}
        </div>
      </Fragment>
    );
  }
}

export default Notification;
