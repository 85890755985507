import React, { Component, Fragment } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";

import { getProperty } from "../../helper/helper";
import Loader from "../layouts/Loader";

export default class ConfirmModal extends Component {
  render() {
    const { isInvite, post } = this.props;
    return (
      <Fragment>
        <Modal
          show={this.props.isCollabUser}
          dialogClassName="confirm-dialog"
          onHide={this.props.closeModal}
        >
          <ModalHeader closeButton>
            <ModalTitle>{getProperty("label.collab-user")}</ModalTitle>
          </ModalHeader>

          <ModalBody>
            {isInvite && <p> {getProperty("label.confirm-accept-invite")}</p>}
            {!isInvite && (
              <div>
                <span> {getProperty("label.confirm-accept-collab")}</span>
                {post && post.auditInfo && post.auditInfo.createdBy && (
                  <span className="post-created-by">{post.auditInfo.createdBy}</span>
                )}
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            {this.props.showLoader && (
              <Button className="btn-width">
                <Loader onButtonLoader="" />
              </Button>
            )}
            {!this.props.showLoader && (
              <Button
                onClick={this.props.collabUserWithPost}
                variant="secondary"
              >
                {isInvite && <span> {getProperty("label.accept")}</span>}
                {!isInvite && <span> {getProperty("label.ok")} </span>}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
