import 'react-phone-number-input/style.css';

import isEmpty from 'lodash/isEmpty';
import React, { Component, Fragment } from 'react';
import { CloseButton, Modal, ModalBody } from 'react-bootstrap';
import PhoneInput, { getCountryCallingCode, isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';

import { updateAdditionalUserInfo } from '../../ducks/actions';
import { functions } from '../../firebase/firebaseConfig';
import { getProperty, sendSignupWelcomeSMS } from '../../helper/helper';
import Notification from '../layouts/Notifications';
import {googleStoreLink, appleStoreLink} from '../../constants/constants';
const data = require('../../data/data.json');
const updateUserPhoneNumber = functions.httpsCallable('updateUserPhoneNumber');
class WelcomeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: '',
      countryCalling: '',
      countryCode: '',
      showLoader: false,
      isPhoneNumberAdded: false,
      showNotification: false,
      phoneNuberInput: '',
      phoneNuberCode: `(${data.defaultCountry.calling})`,
    };
  }

  componentDidMount = () => {
    this.checkPhoneNumberField();
  };

  validateMobile = () => {
    let isValid = true;
    const { mobileNumber, countryCalling } = this.state;
    if (!mobileNumber || !countryCalling) {
      isValid = false;
      this.setState({ mobileError: getProperty('welcome-popup.invalid-mobile-number') });
    }

    if (mobileNumber) {
      if (!isPossiblePhoneNumber(`${countryCalling}${mobileNumber}`)) {
        isValid = false;
        this.setState({ mobileError: getProperty('welcome-popup.invalid-mobile-number-format') });
      }
    }

    return isValid;
  };

  sendWelcomeSMS = async () => {
    const { mobileNumber, countryCalling, countryCode } = this.state;
    const { additionalUserInfo } = this.props;

    if (additionalUserInfo.user && !isEmpty(additionalUserInfo.user.phoneNumber)) {
      this.setState({ showLoader: true });
      let sendSMS = await sendSignupWelcomeSMS();
      this.setState({ showNotification: true, showLoader: false });
    } else {
      if (this.validateMobile()) {
        this.setState({ showLoader: true });
        let mobile_No = {
          countryCalling: countryCalling,
          countryCode: countryCode,
          number: mobileNumber,
        };
        let request = {
          apiKey: data.apiKey,
          phoneNumber: mobile_No,
          source: data.apiSource,
        };
        let userData = JSON.parse(JSON.stringify(additionalUserInfo));

        updateUserPhoneNumber(request)
          .then(async (response) => {
            if (!response.error) {
              userData.user.phoneNumber = mobile_No;
              let sendSMS = await sendSignupWelcomeSMS();
              this.props.updateAdditionalUserInfo(userData);

              this.setState({ showLoader: false, isPhoneNumberAdded: true, showNotification: true });
            }
          })
          .catch((error) => {
            this.setState({ showLoader: false });
          });
      }
    }
  };
  redirectOnAppStore = (storeName) => {
    if (storeName === 'apple') {
      window.open(appleStoreLink, '_blank');
    } else {
      window.open(googleStoreLink, '_blank');
    }
  };

  checkPhoneNumberField = () => {
    const { additionalUserInfo } = this.props;

    if (!additionalUserInfo.user) {
      this.setState({ isPhoneNumberAdded: false });
    } else {
      if (!isEmpty(additionalUserInfo.user.phoneNumber)) {
        this.setState({ isPhoneNumberAdded: true });
      } else {
        this.setState({ isPhoneNumberAdded: false });
      }
    }
  };

  closeNotification = () => {
    this.setState({ showNotification: false });
  };

  //This method will be invoked on change of mobile number field.
  phoneChange = (value) => {
    if (value) {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        const { countryCallingCode, country, nationalNumber } = phoneNumber;
        this.setState({
          countryCode: country || data.defaultCountry.code,
          mobileNumber: nationalNumber,
          countryCalling: `+${countryCallingCode}` || data.defaultCountry.calling,
        });
      }
    } else {
      this.setState({ mobileNumber: '' });
    }
    if (this.state.mobileError) {
      this.setState({ mobileError: '' });
    }
  };

  //This method will be invoked on change of country dropdown field.
  onCountryChange = (value) => {
    let ccode = '';
    if (value) {
      ccode = `(+${getCountryCallingCode(value)})`;
    }
    this.setState({ phoneNuberCode: ccode || value });
  };

  render() {
    const { show, closeWelcomeModal, userInfo, isCollabClicked } = this.props;
    const { showLoader, isPhoneNumberAdded, mobileError } = this.state;
    const { showNotification, phoneNuberInput, phoneNuberCode } = this.state;

    let userData = JSON.parse(JSON.stringify(userInfo));

    return (
      <Modal show={show} dialogClassName='modal-lg custom-dialog' onHide={closeWelcomeModal} backdrop='static'>
        <ModalBody>
          <div className='invite-confirmation' style={{ maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' }}>
            {this.props.phoneNumberExists && (
              <Notification
                type='warning'
                message={getProperty('label.phone-number-exists')}
                closeModal={this.props.closePhoneNumberWarning}
              />
            )}
            <div className='close-collab-dialog'>
              <CloseButton onClick={closeWelcomeModal} />
            </div>
            {!isCollabClicked && (
              <h1 className='welcome-dialog-header header'>{getProperty('welcome-popup.thanks-joining')}</h1>
            )}
            <div className='welcome-dialog-header'>
              <p>{getProperty('welcome-popup.line1')}</p>
              <h3 className='header'>{getProperty('welcome-popup.line2')}</h3>
              <p>
                <span>{getProperty('welcome-popup.line3')}</span>
                <span>{getProperty('welcome-popup.line4')}</span>
                <span>{getProperty('welcome-popup.line5')}</span>
              </p>
              <p style={{ color: '#28a745' }}>{getProperty('welcome-popup.line6')}</p>
            </div>
            <div className='text-center'>
              <div className='store-button apple-button' onClick={() => this.redirectOnAppStore('apple')}></div>
              <div className='store-button google-button' onClick={() => this.redirectOnAppStore('google')}></div>
            </div>
            <div className='d-flex thanks-footer'>
              {!isPhoneNumberAdded && !isCollabClicked && (
                <Fragment>
                  <p>{getProperty('welcome-popup.line7')}</p>

                  <div className='paths-login-container signup-container col-md-9 mb-3'>
                    <PhoneInput
                      placeholder={getProperty('login.optional-mobile-number')}
                      value={phoneNuberInput}
                      onChange={this.phoneChange}
                      defaultCountry={data.defaultCountry.code}
                      onCountryChange={this.onCountryChange}
                      className='paths-phone'
                      international={false}
                      addInternationalOption={false}
                    />
                    <span className='phone-code'>{phoneNuberCode}</span>
                  </div>
                  <div className='signup-container col-md-3'>
                    {!showLoader && (
                      <button className='signup-btn text-center' onClick={this.sendWelcomeSMS}>
                        {getProperty('welcome-popup.text-me')}
                      </button>
                    )}
                    {showLoader && (
                      <div className={'text-center confirmation-loader'}>
                        <span aria-hidden='true'>
                          <i className='fa fa-spinner fa-spin '></i>
                        </span>
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
              {isPhoneNumberAdded && !isCollabClicked && (
                <div className='signup-container col-md-3'>
                  {!showLoader && (
                    <button className='signup-btn text-center' onClick={this.sendWelcomeSMS}>
                      {getProperty('welcome-popup.text-me')}
                    </button>
                  )}
                  {showLoader && (
                    <div className={'text-center confirmation-loader'}>
                      <span aria-hidden='true'>
                        <i className='fa fa-spinner fa-spin '></i>
                      </span>
                    </div>
                  )}
                </div>
              )}
              <p className='error'>{mobileError}</p>
              {showNotification && (
                <Notification
                  type='success'
                  message={getProperty('welcome-popup.text-me-notification')}
                  closeModal={this.closeNotification}
                />
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateAdditionalUserInfo: (user) => dispatch(updateAdditionalUserInfo(user)),
});

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user,
    additionalUserInfo: state.user.additionalUserInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeDialog);
