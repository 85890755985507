import { isEmpty } from "lodash";
import React, { Fragment, useState } from "react";
import { IMAGES } from "../../constants/constants";

import { appleStoreLink, googleStoreLink } from '../../constants/constants';
import defaultUserImage from "../../images/icons/user.jpg";
import FeedUserProfileModal from "../UserProfile/FeedUserProfileModal";
import PrintQRCode from "../UserProfile/PrintQRCode";
/**
 * If image is broken, Adding default user image.
 */
const handleImageError = (ev) => {
  ev.target.src = defaultUserImage;
};

const MainHeader = (props) => {
  const { owner } = props;
  // let [showLogin, setShowLogin] = useState(false);
  // let [showSignUpPopup, setShowSignUpPopup] = useState(true);
  // let [showSignIn, setShowSignIn] = useState(false);
  // let [showDoneScreen, setShowDoneScreen] = useState(false);
  let [showUserProfile, setShowUserProfile] = useState(false);

  const redirectOnAppStore = (storeName) => {
    if (storeName === "apple") {
      window.open(appleStoreLink, "_blank");
    } else {
      window.open(googleStoreLink, "_blank");
    }
  };

  return (
    <div className="invite-header">
      {/* {isEmpty(owner) && isEmpty(userInfo) && (
        <Fragment>
          <BtnSection
            message={props.btnText}
            userCollabSetup={props.userCollabSetup}
          />
          <div className="invite-message">
            <h5>+{props.btnText}</h5>
            <h5>{props.msgText}</h5>
          </div>
        </Fragment>
      )} */}
      {!isEmpty(owner) && (
        <Fragment>
          <div className="row">
            <div className="col-12">
              <div className="owner-detail">
                {!isEmpty(owner) && (
                  <div className="post-header-title">
                    <img
                      className="owner-profile"
                      src={owner.photoURL}
                      alt="No Preview Available"
                      onError={handleImageError}
                      onClick={() =>
                        setShowUserProfile(
                          (showUserProfile) => !showUserProfile
                        )
                      }
                    />
                    <p className="header-paths-user-first-last-name">
                      <span>{owner.name}</span>
                    </p>
                    <p className="header-paths-user-name">
                      {owner.pathUserName}
                    </p>
                    <div className="hidden-qr-code">
                      <PrintQRCode
                        qrcodeValue={window.location.href}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {showUserProfile && (
              <FeedUserProfileModal
                show={showUserProfile}
                owner={owner}
                manageUserProfile={() =>
                  setShowUserProfile((showUserProfile) => !showUserProfile)
                }
              />
            )}
          </div>
          <img alt="master shadow img" src={IMAGES.MASTER_SHADOW_IMAGE.default} className="feed-post-header-shadow"/>
        </Fragment>
      )}
    </div>
  );
};

export default MainHeader;
