import React, { memo } from "react";
import { Modal, ModalBody, CloseButton } from "react-bootstrap";
import { IMAGES } from "../../constants/constants";
import { getProperty } from "../../helper/helper";
import {googleStoreLink, appleStoreLink} from '../../constants/constants';
const GetPathsOverlay = (props) => {

  const redirectToStore = (storeName) => {
    if (storeName === "apple") {
      window.open(appleStoreLink, "_blank");
    } else {
      window.open(googleStoreLink, "_blank");
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        dialogClassName="modal-lg get-paths-overlay-dialog"
        onHide={() => props.manageGetPaths()}
        size="lg"
        backdrop="static"
      >
        <ModalBody>
          <CloseButton onClick={() => props.manageGetPaths()} />
          <div className="get-paths-overlay">
            <div className="get-paths-overlay-logo">
              <img
                alt="paths color logo"
                src={IMAGES.PATHS_COLOUR_LOGO.default}
              />
              <p>{getProperty("label.get-the-app")}</p>
            </div>
            <div className="done-signup-images">
              <img
                alt="Play store logo"
                src={IMAGES.PLAY_STORE.default}
                onClick={() => redirectToStore("google")}
              />
              <img
                alt="App store logo"
                src={IMAGES.APP_STORE.default}
                onClick={() => redirectToStore("apple")}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default memo(GetPathsOverlay);